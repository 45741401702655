import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { Checkbox, Col, DatePicker, Divider, Form, Input, Row, Select } from 'antd'
import {Typography} from 'antd'
import { Option } from 'antd/lib/mentions'
import Title from 'antd/lib/typography/Title'
import React, { useEffect, useState } from 'react'
import dayjs from "dayjs";

const {Text} = Typography

const PersonalInformationForm = ({form,handleNext, formData, setFormData, updateFormData}) => {

    // const goalsOptions = [
    //     "Improve cognitive function",
    //     "Increase energy",
    //     "Minimize symptoms of fatigue",
    //     "Improve depression",
    //     "Minimize symptoms of menopause",
    //     "Reduce cancer risk/care for cancer",
    //     "Reduce risk of osteoporosis",
    //     "Improve overall health",
    //     "Reduce cardiovascular disease",
    //     "Improve sleep",
    //     "Improve memory",
    //     "Stabilize mood",
    //     "Reduce headaches/migraines",
    //     "Reduce joint pain",
    //     "Reduce food allergies",
    //     "Improve digestive issues",
    //     "Other",
    //   ];

    const [healthGoals, setHealthGoals] = useState([
      { id: "cognitive", label: "Improve cognitive function", checked: false },
      { id: "energy", label: "Increase energy", checked: false },
      { id: "fatigue", label: "Minimize symptoms of fatigue", checked: false },
      { id: "depression", label: "Improve depression", checked: false },
      { id: "sleep", label: "Improve sleep", checked: false },
      { id: "mood", label: "Stabilize mood", checked: false },
      { id: "osteoporosis", label: "Reduce risk of osteoporosis", checked: false },
      { id: "pain", label: "Reduce joint pain", checked: false },
      { id: "minimize_symptoms_of_menopause", label: "Minimize symptoms of menopause", checked: false },
      { id: "reduce_cancer_risk_care_for_cancer", label: "Reduce cancer risk/care for cancer", checked: false },
      { id: "improve_overall_health", label: "Improve overall health", checked: false },
      { id: "reduce_cardiovascular_disease", label: "Reduce cardiovascular disease", checked: false },
      { id: "improve_memory", label: "Improve memory", checked: false },
      { id: "migraines", label: "Reduce headaches/migraines", checked: false },
      { id: "reduce_food_allergies", label: "Reduce food allergies", checked: false },
      { id: "improve_digestive_issues", label: "Improve digestive issues", checked: false },
      { id: "other", label: "Other", checked: false },
    ]);

    useEffect(() => {      
      if (formData?.goals.value?.length > 0) {
      const retrievedGoals = formData?.goals?.value || [];
      if (Array.isArray(retrievedGoals)) {
          const updatedGoals = healthGoals.map((goal) => ({
            ...goal,
            checked: retrievedGoals.includes(goal.label) 
          }));
          
          setHealthGoals(updatedGoals)
      }}      
    }, [formData]);
    
    
        const [otherValue, setOtherValue] = useState("");

        // Previously used method that was unchecking all other options when other was being checked
        // const handleCheckboxChange = (id) => {
        //   let updatedGoals = healthGoals.map((goal) =>
        //     goal.id === id ? { ...goal, checked: !goal.checked } : goal
        //   );
        
        //   if (id === "other") {
        //     const isOtherChecked = !healthGoals.find((goal) => goal.id === "other").checked;
        
        //     // Uncheck all other goals if "Other" is checked
        //     updatedGoals = updatedGoals.map((goal) =>
        //       goal.id !== "other" ? { ...goal, checked: false } : goal
        //     );
        
        //     if (!isOtherChecked) {
        //       setOtherValue(""); // Reset input if "Other" is unchecked
        //       // form.setFieldsValue( "goals", []);
        //       setFormData({...formData,goals:[]})
        //     }
        //   } else {
        //     // If any goal other than "Other" is checked, uncheck "Other"
        //     updatedGoals = updatedGoals.map((goal) =>
        //       goal.id === "other" ? { ...goal, checked: false } : goal
        //     );
        //     setOtherValue(""); // Reset "Other" input
        //   }
        
        //   setHealthGoals(updatedGoals);
        
        //   // **Fix: Ensure actual label values are stored in form field**
        //   const selectedGoals = updatedGoals
        //     .filter((goal) => goal.checked && goal.id !== "other") // Exclude "Other" if unchecked
        //     .map((goal) => goal.label); // Store labels only
        
        //   // form.setFieldsValue( "goals", selectedGoals);
        //   setFormData({ ...formData, goals: selectedGoals });
        // };
        
        const handleCheckboxChange = (id) => {
          let updatedGoals = healthGoals.map((goal) =>
            goal.id === id ? { ...goal, checked: !goal.checked } : goal
          );
        
          setHealthGoals(updatedGoals);
        
          const selectedGoals = updatedGoals
            .filter((goal) => goal.checked) 
            .map((goal) => goal.label);
        
          setFormData({ ...formData, goals: { ...formData.goals, value: selectedGoals } });
        };
        
        const handleOtherInputChange = (e) => {
          const value = e.target.value;
          setOtherValue(value);
        
          if (healthGoals.find((g) => g.id === "other")?.checked) {
            setFormData({ ...formData, goals: { ...formData.goals, otherValue: value.trim() } });
          }
        };
        
      // const handleGoalToggle = (id) => {
      //   setHealthGoals((goals) =>
      //     goals.map((goal) =>
      //       goal.id === id ? { ...goal, checked: !goal.checked } : goal
      //     )
      //   );
    
      //   const updatedGoals = healthGoals
      //     .map((goal) =>
      //       goal.id === id ? { ...goal, checked: !goal.checked } : goal
      //     )
      //     .filter((goal) => goal.checked)
      //     .map((goal) => goal.label);
    
       
      //   // Exclude "Other" from the goals array
      //   const finalGoals = updatedGoals.filter(goal => goal !== 'Other');
    
      //   const updatedData = {
      //     ...form.getFieldsValue(true),
      //     goals: finalGoals,
      //   };
    
      //   form.setFieldsValue(updatedData);
        
      // };

    

  return (
    <>
            <div className="bg-white p-6 rounded-2xl">
              <div className='w-full flex flex-col'>

          <Title level={4}>Personal Information</Title>
          <div className='flex flex-col gap-5'>
          <Row gutter={16}>
            <Col span={8}>
            <p className='text-sm pb-2'>First Name</p>
                <Input placeholder="Enter first name" value={formData?.firstName} onChange={(e)=>updateFormData('firstName',e.target.value)}/>
            </Col>
            {/* <Col span={8}>
            <p className='text-sm pb-2'>Middle Name</p>
                <Input placeholder="Enter middle name" value={formData?.middleName} onChange={(e)=>updateFormData('middleName',e.target.value)}/>
            </Col> */}
            <Col span={8}>
            <p className='text-sm pb-2'>Last Name</p>
                <Input placeholder="Enter last name" value={formData?.lastName} onChange={(e)=>updateFormData('firstName',e.target.value)}/>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
                <p className='text-sm pb-2'>Date of Birth</p>
                <DatePicker
                  style={{ width: "100%" }}
                  format={"MM-DD-YYYY"}
                  value={formData?.dob ? dayjs(formData.dob, "YYYY-MM-DD") : null}
                  onChange={(date) =>
                    setFormData({ ...formData, dob: date ? date.format("YYYY-MM-DD") : null })
                  }
                />
              </Col>
            <Col span={8}>
            <p className='text-sm pb-2'>Gender</p>
                <Select placeholder="Select gender" value={formData?.sex} style={{width:'100%'}}   onChange={(value) => updateFormData("sex", value)}>
                  <Option value="male">Male</Option>
                  <Option value="female">Female</Option>
                  <Option value="other">Other</Option>
                </Select>
            </Col>
            <Col span={8}>
            <p className='text-sm pb-2'>Race</p>
                <Select placeholder="Select race" value={formData?.race} style={{width:'100%'}}   onChange={(value) => updateFormData("race", value)}>
                  <Option value="asian">Asian</Option>
                  <Option value="black">Black</Option>
                  <Option value="hispanic">Hispanic</Option>
                  <Option value="white">White</Option>
                  <Option value="other">Other</Option>
                </Select>
            </Col>
          </Row>
          </div>
          <Divider />

          <Title level={4}>Contact Information</Title>
          <div className='flex flex-col gap-4'>
          <Row gutter={16}>
            <Col span={12}>
              <p className='text-sm pb-2'>Phone Number</p>
                <Input placeholder="Enter phone number" value={formData?.phoneNumber} onChange={(e)=>updateFormData('phoneNumber',e.target.value)}/>
            </Col>
            <Col span={12}>
              <p className='text-sm pb-2'>Email</p>
                <Input placeholder="Enter email address" value={formData?.email} onChange={(e)=>updateFormData('email',e.target.value)}/>
            </Col>
          </Row>
          <Row gutter={16}>
          <Col span={24}>
          <p className='text-sm pb-2'>Address Line 1</p>
            <Input placeholder="Enter street address" value={formData?.addressLine1} onChange={(e)=>updateFormData('addressLine1',e.target.value)}/>
            </Col>
            </Row>
            <Row gutter={16}>
            <Col span={24}>
            <p className='text-sm pb-2'>Address Line 2</p>
            <Input placeholder="Apt, Suite, etc. (optional)" value={formData?.addressLine2} onChange={(e)=>updateFormData('addressLine2',e.target.value)}/>
            </Col>
            </Row>
          <Row gutter={16}>
            <Col span={8}>
            <p className='text-sm pb-2'>City</p>
                <Input placeholder="Enter city" value={formData?.addressCity} onChange={(e)=>updateFormData('addressCity',e.target.value)}/>
            </Col>
            <Col span={8}>
            <p className='text-sm pb-2'>Province</p>
                <Input placeholder="Enter province" value={formData?.addressProvince} onChange={(e)=>updateFormData('addressProvince',e.target.value)}/>
            </Col>
            <Col span={8}>
              <p className='text-sm pb-2'>Zip Code</p>
                <Input placeholder="Enter zip code" value={formData?.addressZipCode} onChange={(e)=>updateFormData('addressZipCode',e.target.value)}/>
            </Col>
          </Row>
          </div>

          <Divider />

          <Title level={4}>Emergency Contact</Title>
          <div className='flex flex-col gap-5'>
          <Row gutter={16}>
            <Col span={12}>
              <p className='text-sm pb-2'>First Name</p>
                <Input placeholder="Emergency contact first name" value={formData?.emergencyContactFirstName} onChange={(e)=>updateFormData('emergencyContactFirstName',e.target.value)}/>
            </Col>
            <Col span={12}>
            <p className='text-sm pb-2'>Last Name</p>
                <Input placeholder="Emergency contact last name" value={formData?.emergencyContactLastName}  onChange={(e)=>updateFormData('emergencyContactLastName',e.target.value)}/>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
            <p className='text-sm pb-2'>Relationship</p>
                <Input placeholder="Relationship to patient" value={formData?.emergencyContactRelationship} onChange={(e)=>updateFormData('emergencyContactRelationship',e.target.value)}/>
            </Col>
            <Col span={12}>
            <p className='text-sm pb-2'>Phone Number</p>
                <Input placeholder="Emergency contact phone" value={formData?.emergencyContactPhoneNumber} onChange={(e)=>updateFormData('emergencyContactPhoneNumber',e.target.value)}/>
            </Col>
          </Row>
          </div>

          <Divider />

          <Title level={4}>Health Information</Title>
          <div className='flex flex-col gap-4'>
          <Row gutter={16}>
            <Col span={24}>
          <p className='text-sm pb-2'>How did you hear about us?</p>
            <Input placeholder="Enter referral source" value={formData?.referralSource} onChange={(e)=>updateFormData('referralSource',e.target.value)}/>
            </Col>
          </Row>  
          <Row gutter={16}>
            <Col span={24}>
          <p className='text-sm pb-2'>Health Goals</p>
          <Row gutter={[16, 16]}>
            {healthGoals.map((goal) => (
              <Col span={12} key={goal.id}> {/* Each column takes half the width (12 out of 24) */}
                <Checkbox
                  checked={goal.checked}
                  onChange={() => handleCheckboxChange(goal.id)}
                  // disabled={healthGoals.find((g) => g.id === "other")?.checked && goal.id !== "other"}
                >
                  {goal.label}
                </Checkbox>
              </Col>
            ))}
            
          </Row>

            
          {healthGoals.find((g) => g.id === "other")?.checked && (
            <Input
              style={{ marginTop: 10, width: '50%' }}
              placeholder="Enter other goal"
              value={formData?.goals?.otherValue}
              onChange={handleOtherInputChange}
            />
          )}
          </Col>
          </Row>

          </div>
          <div className="flex justify-between">
            <IconButton>
              <NavigateBefore />
            </IconButton>
                <IconButton onClick={handleNext}>
                  <NavigateNext />
                </IconButton>
              </div>
              </div>
            </div>          
    </>
  )
}

export default PersonalInformationForm