import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import "./navbar.css";
import LogoImage from "../Assets/SUNRIDGELogo 1.png";

// Import icons from lucide-react
import * as LucideIcons from "lucide-react";

function Navbar({ onToggle }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isDevMenuOpen, setIsDevMenuOpen] = useState(false);
  const initialActiveLink = localStorage.getItem("activeLink") || "day";
  const [activeLink, setActiveLink] = useState(initialActiveLink);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname) {
      const foundNavLink = [...mainNavLinks, ...devNavLinks].find(
        (navLink) => navLink.path === `/${location.pathname.split("/")[1]}`
      );
      if (foundNavLink) {
        handleLinkClick(foundNavLink.name);
      }
    }
  }, [location.pathname]);

  const handleLinkClick = (link) => {
    setActiveLink(link);
    localStorage.setItem("activeLink", link);
  };

  const isActive = (link) => activeLink === link;

  // Function to render icons with consistent styling
  const renderIcon = (iconName, isActive) => {
    const IconComponent = LucideIcons[iconName];
    if (!IconComponent) return null;

    return (
      <IconComponent
        size={24}
        strokeWidth={1.5}
        className={`transition-all duration-200 ${
          isActive 
            ? "text-blue-400 filter drop-shadow-glow" 
            : "text-gray-400 hover:text-gray-300"
        }`}
      />
    );
  };

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
    onToggle(!isOpen);
  };

  const toggleDevMenu = () => {
    setIsDevMenuOpen(!isDevMenuOpen);
  };

  const handleSignOut = () => {
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };

  const mainNavLinks = [
    { 
      name: "n.todayview", 
      displayName: "Today",
      path: "/",
      icon: "LayoutDashboard"
    },
    { 
      name: "n.treatment", 
      displayName: "Treatment (New)",
      path: "/treatmentview",
      icon: "Beaker"
    },

    { 
      name: "treatment", 
      displayName: "Treatment (Old)",
      path: "/treatmentviewdisplay",
      icon: "Pill"
    },
    { 
      name: "prep", 
      displayName: "Prep",
      path: "/auditview",
      icon: "ClipboardList"
    },
    { 
      name: "filters", 
      displayName: "Filters",
      path: "/filters",
      icon: "Filter"
    },
    { 
      name: "lab", 
      displayName: "Lab",
      path: "/labtracker",
      icon: "FlaskConical"
    },
    { 
      name: "emr", 
      displayName: "EMR",
      path: "/emr",
      icon: "MonitorStop"
    },
    { 
      name: "front desk", 
      displayName: "Front Desk",
      path: "/frontdesk",
      icon: "Building2"
    },
    { 
      name: "admin", 
      displayName: "Admin",
      path: "/updations",
      icon: "UserSquare2"
    },
    {
      name: "contacts",
      displayName: "Contacts",
      path: "https://contacts.sunridgemedical.com/",
      icon: "Contact2",
      external: true,
    }
  ];

  const devNavLinks = [


   
    { 
      name: "n.patientform", 
      displayName: "Patient Form",
      path: "/patientform",
      icon: "ClipboardSignature"
    },
    { 
      name: "room", 
      displayName: "Room View",
      path: "/roomview",
      icon: "Component"
    },
    { 
      name: "export data", 
      displayName: "Export Data",
      path: "/exportdata",
      icon: "FileSpreadsheet"
    },
    { 
      name: "patient form", 
      displayName: "Patient Form",
      path: "/patientformold",
      icon: "ClipboardSignature"
    },
    { 
      name: "patient summary", 
      displayName: "Patient Summary",
      path: "/patientsummary",
      icon: "FileText"
    }
  ];

  return (
    <div className={`navbar ${isOpen ? "open" : "half-closed"}`}>
      <div className="toggle-button" onClick={toggleNavbar}>
        {isOpen ? 
          renderIcon("ChevronLeft", false) :
          renderIcon("ChevronRight", false)
        }
      </div>
      <div className="Navbar-items overflow-y-auto overflow-x-hidden">
        <div className="navbar-items-flex">
          <div>
            <div className="my-9">
              {isOpen && <img src={LogoImage} alt="Logo" className="mx-auto" />}
            </div>
            <div className="navbar-links">
              {mainNavLinks.map(({ name, displayName, path, icon, external }) => (
                external ? (
                  <a
                    key={name}
                    href={path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`navbarlink-cotent-flex ${isActive(name) ? 'active' : ''}`}
                  >
                    <div className={`active-dive ${isActive(name) ? 'active-bar' : ''}`}></div>
                    <div className="navbar-link-active-flex">
                      {renderIcon(icon, isActive(name))}
                      {isOpen && <p>{displayName}</p>}
                    </div>
                  </a>
                ) : (
                  <NavLink
                    key={name}
                    to={path}
                    className={({ isActive }) =>
                      `navbarlink-cotent-flex ${isActive ? "active" : ""}`
                    }
                    onClick={() => handleLinkClick(name)}
                  >
                    <div
                      className={`active-dive ${isActive(name) ? "active-bar" : ""}`}
                    ></div>
                    <div className="navbar-link-active-flex">
                      {renderIcon(icon, isActive(name))}
                      {isOpen && <p>{displayName}</p>}
                    </div>
                  </NavLink>
                )
              ))}

              {/* Development Section */}
              <div className="dev-menu-section">
                <button
                  onClick={toggleDevMenu}
                  className="dev-menu-toggle w-full text-left px-4 py-2 flex items-center"
                >
                  {renderIcon("ChevronRight", false)}
                  {isOpen && (
                    <span className="ml-2 text-gray-400 hover:text-gray-200">
                      Under Development
                    </span>
                  )}
                </button>
                
                {isDevMenuOpen && (
                  <div className="dev-menu-items">
                    {devNavLinks.map(({ name, displayName, path, icon }) => (
                      <NavLink
                        key={name}
                        to={path}
                        className={({ isActive }) =>
                          `navbarlink-cotent-flex ${isActive ? "active" : ""}`
                        }
                        onClick={() => handleLinkClick(name)}
                      >
                        <div
                          className={`active-dive ${
                            isActive(name) ? "active-bar" : ""
                          }`}
                        ></div>
                        <div className="navbar-link-active-flex">
                          {renderIcon(icon, isActive(name))}
                          {isOpen && <p>{displayName}</p>}
                        </div>
                      </NavLink>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          
          <div className="navbar-signout-btn">
            <button onClick={handleSignOut} className="signout-button">
              {isOpen && <span>Sign out</span>}
              {renderIcon("LogOut", false)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;