import React, { useState, useEffect } from 'react';
import { Button, Modal, Spin } from 'antd';
import PatientFormComparisonModal from '../PatientFormComparisonModal';
import { Warning } from '@mui/icons-material';
import { colors } from '@mui/material';

const PatientFormSubmissions = () => {
    const [isApprovalsModalVisible, setIsApprovalsModalVisible] = useState(false);
    const [isComparisonModalVisible, setIsComparisonModalVisible] = useState(false);
    const [selectedSubmission, setSelectedSubmission] = useState(null);
    const [submissions, setSubmissions] = useState([]);
    const [loading, setLoading] = useState(false); // To handle loading state
    const [error, setError] = useState(null); // To handle errors


    const fetchUnapprovedForms = async () => {
        setLoading(true);
        try {
            const response = await fetch('/unapproved-forms'); // Backend URL
            if (!response.ok) {
                throw new Error('Failed to fetch unapproved forms');
            }
            const data = await response.json();
            // Assuming the response data format is [{ formLink, formId, patientName }]
            setSubmissions(data);
        } catch (err) {
            setError(err.message); // Set error message
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        // Function to fetch unapproved forms
        fetchUnapprovedForms();
    }, []); // Empty dependency array ensures this runs once when component mounts

    const showApprovalsModal = () => {
        setIsApprovalsModalVisible(true);
    };

    const closeApprovalsModal = () => {
        setIsApprovalsModalVisible(false);
    };

    const showComparisonModal = (submission) => {
        setSelectedSubmission(submission);
        setIsComparisonModalVisible(true);
    };

    const closeComparisonModal = () => {
        setIsComparisonModalVisible(false);
        setSelectedSubmission(null);
    };

    return (
        <div>
            <div className="relative inline-block">
                <Button
                    className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
                    type="primary"
                    onClick={showApprovalsModal}
                >
                    Approvals
                </Button>
                <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-600 text-white text-lg font-medium rounded-full h-7 w-7 flex items-center justify-center">
                    {submissions.length}
                </span>
            </div>

            <Modal
                title="Pending Approvals"
                visible={isApprovalsModalVisible}
                onCancel={closeApprovalsModal}
                footer={[
                    <Button key="close" onClick={closeApprovalsModal}>
                        Close
                    </Button>,
                ]}
                width={800}
            >
                {loading ? (
                    <div className="flex justify-center items-center">
                        <Spin size="large" />
                    </div>
                ) : error ? (
                    <div className="h-96 w-full flex items-center justify-center">
                        <div className="flex items-center justify-center">
                            <Warning sx={{ color: colors.red['700'], fontSize: "36px" }} />
                            <p className="text-lg font-medium text-red-600">Error: {error}</p>
                        </div>
                    </div>
                ) : submissions.length > 0 ? (
                    submissions.map((submission) => (
                        <div
                            key={submission.formId} // Use formId (which is the _id from MongoDB)
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '10px',
                                borderBottom: '1px solid #ddd',
                            }}
                        >
                            <div>
                                <p>
                                    <strong>Patient Name:</strong> {submission.patientName}
                                </p>
                            </div>
                            <Button onClick={() => showComparisonModal(submission)}>
                                View Submission
                            </Button>
                        </div>
                    ))
                ) : (
                    <div className="h-96 w-full flex items-center justify-center">
                        <div className="flex items-center justify-center">
                            <Warning sx={{ color: colors.yellow['700'], fontSize: "36px" }} />
                            <p className="text-lg font-medium">There are no pending approvals to show</p>
                        </div>
                    </div>
                )}
            </Modal>

            {selectedSubmission && (
                <PatientFormComparisonModal
                    formId={selectedSubmission.formId}
                    current={selectedSubmission.current}
                    incoming={selectedSubmission.incoming}
                    visible={isComparisonModalVisible}
                    onClose={closeComparisonModal}
                    fetchUnapprovedForms={fetchUnapprovedForms}
                />
            )}
        </div>
    );
};

export default PatientFormSubmissions;
