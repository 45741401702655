import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { Checkbox, Col, Form, Input, Radio, Row, Slider } from 'antd'
import Title from 'antd/lib/typography/Title'
import React, { useEffect, useState } from 'react'

const HealthQuestionsForm = ({ form, handleNext, handlePrev, formData, setFormData, updateFormData }) => {

  const yesNoOptions = ["yes", "no"];

  const [symptomsOptionsState, setSymptomsOptionsState] = useState([
    { id: "fever_chills", label: "Fever/Chills", checked: false },
    { id: "unexplained_weight_change", label: "Unexplained change in weight", checked: false },
    { id: "fatigue", label: "Fatigue/Malaise/Generalized weakness", checked: false },
    { id: "headaches_migraines", label: "Headaches/Migraines", checked: false },
    { id: "dizziness", label: "Dizziness", checked: false },
    { id: "sinus_pain", label: "Sinus Pain/Pressure/Discharge", checked: false },
    { id: "excessive_snoring", label: "Excessive snoring", checked: false },
    { id: "wheezing_cough", label: "Wheezing/Chronic Cough", checked: false },
    { id: "shortness_of_breath", label: "Shortness of breath", checked: false },
    { id: "chest_pain", label: "Chest pain, pressure or tightness", checked: false },
    { id: "swelling_extremities", label: "Swelling of hands/feet/ankles", checked: false },
    { id: "nausea_vomiting", label: "Nausea/Vomiting", checked: false },
    { id: "abdominal_pain", label: "Abdominal pain", checked: false },
    { id: "heartburn", label: "Heartburn", checked: false },
    { id: "constipation_diarrhea", label: "Constipation or diarrhea", checked: false },
    { id: "joint_pain", label: "Stiffness/Pain in joints/muscles", checked: false },
    { id: "joint_swelling", label: "Joint swelling", checked: false },
    { id: "bleeding_bruising", label: "Bleeding/Easy bruising", checked: false },
    { id: "excessive_urination", label: "Excessive urination", checked: false },
    { id: "excessive_thirst_hunger", label: "Excessive thirst/hunger", checked: false },
    { id: "hot_flashes", label: "Hot flashes", checked: false },
    { id: "painful_bloody_urination", label: "Painful/Bloody urination", checked: false },
    { id: "difficulty_urinating", label: "Difficulty urinating/Night-time urination", checked: false },
    { id: "urinary_incontinence", label: "Urinary incontinence (leakage)", checked: false },
    { id: "sexual_difficulties", label: "Sexual Difficulties/Painful intercourse", checked: false },
    { id: "rash", label: "Rash", checked: false },
    { id: "anxiety_panic_attacks", label: "Anxiety/Panic Attacks", checked: false },
    { id: "concentration_difficulty", label: "Concentration Difficulty", checked: false },
    { id: "feelings_of_guilt", label: "Feelings of Guilt", checked: false },
    { id: "insomnia_sleep_problems", label: "Insomnia/Problems with Sleep", checked: false },
    { id: "loss_of_energy", label: "Loss of energy", checked: false },
    { id: "self_harm_thoughts", label: "Thoughts of harming self or others", checked: false }
  ]);

  useEffect(() => {
    if (formData?.symptoms?.length > 0) {
      const retrievedSymptoms = formData?.symptoms || [];
      if (Array.isArray(retrievedSymptoms)) {
        const predefinedSymptomsSet = new Set(symptomsOptionsState.map((goal) => goal.label));
        const otherValue = retrievedSymptoms.find((goal) => !predefinedSymptomsSet.has(goal)) || "";

        if (otherValue) {
          const updatedArray = symptomsOptionsState.map(item =>
            item.id === 'other' ? { ...item, checked: true } : item
          );
          setSymptomsOptionsState(updatedArray)
          setOtherValue(otherValue);
        } else {
          const updatedSymptoms = symptomsOptionsState.map((goal) => ({
            ...goal,
            checked: retrievedSymptoms.includes(goal.label)
          }));
          setSymptomsOptionsState(updatedSymptoms)
          setOtherValue("");
        }
      }
    }
  }, [formData]);


  const [otherValue, setOtherValue] = useState("");

  const handleCheckboxChange = (id) => {
    let updatedSymptoms = symptomsOptionsState.map((goal) =>
      goal.id === id ? { ...goal, checked: !goal.checked } : goal
    );

    if (id === "other") {
      const isOtherChecked = !symptomsOptionsState.find((goal) => goal.id === "other").checked;

      // Uncheck all other goals if "Other" is checked
      updatedSymptoms = updatedSymptoms.map((goal) =>
        goal.id !== "other" ? { ...goal, checked: false } : goal
      );

      if (!isOtherChecked) {
        setOtherValue(""); // Reset input if "Other" is unchecked
        // form.setFieldsValue( "goals", []);
        setFormData({ ...formData, symptoms: [] })
      }
    } else {
      // If any goal other than "Other" is checked, uncheck "Other"
      updatedSymptoms = updatedSymptoms.map((goal) =>
        goal.id === "other" ? { ...goal, checked: false } : goal
      );
      setOtherValue(""); // Reset "Other" input
    }

    setSymptomsOptionsState(updatedSymptoms);

    // **Fix: Ensure actual label values are stored in form field**
    const selectedSymptoms = updatedSymptoms
      .filter((goal) => goal.checked && goal.id !== "other") // Exclude "Other" if unchecked
      .map((goal) => goal.label); // Store labels only

    // form.setFieldsValue( "goals", selectedGoals);
    setFormData({ ...formData, symptoms: selectedSymptoms });
  };

  const handleOtherInputChange = (e) => {
    const value = e.target.value;
    setOtherValue(value);

    if (symptomsOptionsState.find((g) => g.id === "other")?.checked) {
      form.setFieldsValue({ symptoms: value.trim() ? [value.trim()] : [] });
    }
  };


  return (
    <>
      <div className="bg-white p-6 rounded-2xl">
        <div className='w-full flex flex-col'>
          <Row gutter={16} >
            <Col xs={24} md={12} style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
              <div>
                <Title level={4}>Head, Ears, Eyes, Nose, Throat</Title>
                <Row gutter={16}>
                  <Col span={24}>
                    <p className='text-sm pb-2'>Do you have or have you ever had eye, ear, nose, mouth or throat problems?</p>
                    <Radio.Group onChange={(e) => updateFormData('healthQuestions.headEarNoseMouthEyesThroatProblems', e.target.value)} value={formData?.healthQuestions?.headEarNoseMouthEyesThroatProblems}>
                      {yesNoOptions.map((option, index) => (
                        <Radio key={index} value={option}>
                          {option}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Col>
                </Row>
              </div>
              <div>
                <Title level={4}>Heart</Title>
                <Row gutter={16}>
                  <Col span={24}>
                    <p className='text-sm pb-2'>Do you have or have you ever had cardiovascular problems (e.g., chest pain, heart attack, heart valve problems, high blood pressure, leg cramps)?</p>
                    <Radio.Group onChange={(e) => updateFormData('healthQuestions.heartProblems', e.target.value)} value={formData?.healthQuestions?.heartProblems}>
                      {yesNoOptions.map((option, index) => (
                        <Radio key={index} value={option}>
                          {option}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col xs={24} md={12} style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
              <div>
                <Title level={4}>Lungs</Title>
                <Row gutter={16}>
                  <Col span={24}>
                    <p className='text-sm pb-2'>Do you have or have you ever had lung problems (e.g., asthma, bronchitis, shortness of breath, pneumonia)?</p>
                    <Radio.Group onChange={(e) => updateFormData('healthQuestions.lungsProblems', e.target.value)} value={formData?.healthQuestions?.lungsProblems}>
                      {yesNoOptions.map((option, index) => (
                        <Radio key={index} value={option}>
                          {option}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Col>
                </Row>
              </div>
              <div>
                <Title level={4}>Gastrointestinal</Title>
                <Row gutter={16}>
                  <Col span={24}>
                    <p className='text-sm pb-2'>Do you have or have you ever had gastrointestinal problems (e.g., blood in your stool, cramping, hepatitis, liver disease, pancreas problems)?</p>
                    <Radio.Group onChange={(e) => updateFormData('healthQuestions.gastrointestinalProblems', e.target.value)} value={formData?.healthQuestions?.gastrointestinalProblems}>
                      {yesNoOptions.map((option, index) => (
                        <Radio key={index} value={option}>
                          {option}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24}>
              <Title level={4}>Symptoms Checklist</Title>
              <Row gutter={16}>
                <Col span={24}>
                  <p className="text-sm pb-2">
                    Check if you have ever experienced any of the following symptoms:
                  </p>

                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
                      gap: "8px 16px",
                      width: "100%",
                    }}
                  >
                    {symptomsOptionsState.map((symptom, index) => (
                      <Checkbox
                        checked={symptom.checked}
                        onChange={() => handleCheckboxChange(symptom.id)}
                        disabled={symptomsOptionsState.find((g) => g.id === "other")?.checked && symptom.id !== "other"}
                      >
                        {symptom.label}
                      </Checkbox>
                    ))}
                  </div>

                  {symptomsOptionsState.find((g) => g.id === "other")?.checked && (
                    <Input
                      style={{ marginTop: 10, width: '50%' }}
                      placeholder="Enter other goal"
                      value={otherValue}
                      onChange={handleOtherInputChange}
                    />
                  )}
                </Col>
              </Row>

            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24}>
              <Row gutter={16}>
                <Col span={24}>
                  <p className='text-sm pb-2'>How healthy do you feel in general?</p>
                  <Slider
                    min={0}
                    max={10}
                    marks={{ 0: "0", 5: "5", 10: "10" }}
                    value={formData.generalHealthRating}
                    onChange={(value) => updateFormData("generalHealthRating", value)}
                  />
                </Col>
              </Row>

            </Col>
          </Row>

          <div className="flex justify-between">
            <IconButton onClick={handlePrev}>
              <NavigateBefore />
            </IconButton>
            <IconButton onClick={handleNext}>
              <NavigateNext />
            </IconButton>
          </div>
        </div>


      </div>
    </>
  )
}

export default HealthQuestionsForm 