import { ConfigProvider, Input, message, Pagination, Segmented, Spin } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import LPDVIconButtons from '../LPDVIconButtons'
import LPDPopup from '../../../../Components/LPDPopup'

const PastEncountersSection = ({ addTab, selectedPatient }) => {

  const [encounters, setEncounters] = useState([])
  const [encountersLoading, setEncountersLoading] = useState(false)
  const [filteredEncounters, setFilteredEncounters] = useState(encounters);
  const [total, setTotal] = useState(0);
  const [activeTab, setActiveTab] = useState('All');
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [showModal, setShowModal] = useState(false)
  const [medications, setMedications] = useState([])
  const [modalType, setModalType] = useState(false)
  const [referencedSoapNotes, setReferencedSoapNotes] = useState([]);
  const [activeTreatmentId, setActiveTreatmentId] = useState(false)
  const [activeTreatment, setActiveTreatment] = useState({});
  const containerRef = useRef(null);
  const soapNoteCreatedRef = useRef(false);
  const getAllSoapNotes = async (pageNumber) => {
    setEncountersLoading(true)
    try {
      const response = await axios.get(`/get-all-encounters/${selectedPatient._id}/${selectedPatient['Customer ID']}?page=${pageNumber}&limit=${limit}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
        }
      })
      if (response.data) {
        setTotal(response.data.total);
        setEncounters(response.data.data)
        setEncountersLoading(false)
      }
    }
    catch (error) {
      console.log("Error fetching all notes", error)
      setEncountersLoading(false)
    }
  }

  useEffect(() => {
    if (selectedPatient) {
      getAllSoapNotes(page);
    }
  }, [selectedPatient])

  useEffect(() => {
    getAllSoapNotes(page);
  }, [page]);

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };

  function getBackgroundColor(type) {

    if (type) {
      if (type === 'Treatment') {
        return 'bg-blue-500 hover:!bg-blue-400';
      }
      else if (type === 'Order' || type === 'pickup') {
        return 'bg-orange-500 hover:!bg-orange-400';
      }
    } else return 'bg-gray-200';
  }

  const fetchAllSOAPNotesWithTreatmentReference = async () => {
    try {
      const response = await axios.get(`/soap-notes-with-treatment/${selectedPatient._id}`);
      console.log('response of emr api', response.data)
      setReferencedSoapNotes(response.data)
    } catch (error) {
      console.error("Error saving EMR data:", error);
    }
  }

  const fetchAllMedications = async () => {
    try {
      const response = await axios.get(`/emr/get-medications/${selectedPatient._id}`);
      console.log('response of emr api', response.data)
      setMedications(response.data)
    } catch (error) {
      console.error("Error saving EMR data:", error);
    }
  }

  useEffect(() => {
    if (selectedPatient) {
      // getAllSoapNotes();
      fetchAllMedications()

      fetchAllSOAPNotesWithTreatmentReference()
    }
  }, [selectedPatient])

  const handleChangeCustomName = (customNameValue, treatmentId) => {
    const noteDetails = referencedSoapNotes?.find(soapNote => soapNote.treatmentId === treatmentId);
    setReferencedSoapNotes((prevState) =>
      prevState.map((note) => {
        if (noteDetails._id === note._id) {
          return { ...note, customName: customNameValue };
        }
        return note;
      })
    );
  };

  const handleUpdateCustomName = async (treatmentId) => {
    // const note = encounters.find(note => note._id === noteId)
    const note = referencedSoapNotes?.find(soapNote => soapNote.treatmentId === treatmentId);

    if (note) {
      try {
        const response = await axios.post(`/update-encounter-custom-name`, {
          noteId: note._id,
          patientId: selectedPatient._id,
          customName: note.customName
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
          }
        });
        if (response.status === 200) {
          message.success("Custom name for encounter updated")
        }
      } catch (error) {
        console.error("Error saving EMR data:", error);
      }
    }
  }

  useEffect(() => {
    const filterEncounters = () => {
      if (activeTab === 'All') {
        return encounters;
      }

      if (activeTab === 'Treatments') {
        return encounters.filter(encounter => encounter?.type && encounter?.type === 'Treatment');
      }

      if (activeTab === 'Orders') {
        return encounters.filter(encounter => encounter?.type && (encounter?.type === 'Order' || encounter?.type === 'pickup'));
      }
      
      if (activeTab === 'SOAP') {
        return encounters.filter(encounter => !Object.keys(encounter).includes("type"));
      }
      return encounters;
    };


    setFilteredEncounters(filterEncounters());
  }, [activeTab, encounters]);

  function splitByLastDash(columnName) {
    // Find the index of the last dash
    const lastIndex = columnName.lastIndexOf("-");

    // If there's no dash, or the dash is the first character, return an error or handle it as needed
    if (lastIndex <= 0) {
      console.error(
        "No dash found, or the dash is at the start of the string"
      );
      return columnName;
    }

    // Split the string into two parts
    const itemName = columnName.substring(0, lastIndex);
    const field = columnName.substring(lastIndex + 1);

    return [itemName, field];
  }
  
  const handleCellChange = (
    patientId,
    columnName,
    newValue,
    category,
    itemData,
    review
  ) => {
    let payload;
    if (category === "vitals") {
      // Make sure newValue is an array and directly assign it to updates
      payload = {
        category: category,
        updates: newValue, // Ensure this is correctly formatted as an array of vitals
      };
    } else if (category === "patientLabs") {
      payload = {
        category: category,
        updates: {
          checked: newValue,
          type: "item_number",
          value: itemData.item_number,
        },
      };
    } else if (category === "newpatientLab") {
      payload = {
        category: 'patientLabs',
        newData: {
          checked: newValue,
          isNew: true,
          test_name: itemData?.test_name,
        },
      };
    } else {
      // Handling for other categories remains the same
      const identifierKey =
        category === "userProcedures" ? "procedure_name" : "name";
      let [itemName, field] = splitByLastDash(columnName);

      if (category === "patientInfo") {
        field = columnName;
      }

      payload = {
        category: category,
        identifier: {
          key: identifierKey,
          value: itemName,
        },
        updates: {
          [field]: newValue,
        },
        newData: itemData,
      };
    }

    // Send the update request using axios
    axios
      .put(
        `/patient/order/update/${patientId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      )
      .then((response) => {
        getAllSoapNotes(page)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div className='px-4 py-2 h-full'>
      <div className="w-full mb-2 flex flex-wrap items-center justify-between">
        <p className="font-bold text-lg">Past Encounters</p>
        <div className='flex flex-row flex-wrap items-start text-black gap-3'>
          <div className='flex items-center gap-1'>
            <div className="bg-gray-200 p-2 mx-1 rounded-xl"></div>
            <p className='text-lg'>SOAP Note</p>
          </div>
          <div className='flex items-center gap-1'>
            <div className="bg-blue-400 p-2 mx-1 rounded-xl"></div>
            <p className='text-lg'>Treatment</p>
          </div>
          <div className='flex items-center gap-1'>
            <div className="bg-orange-400 p-2 mx-1 rounded-xl"></div>
            <p className='text-lg'>Order</p>
          </div>
          {/* <div className='flex items-center gap-1'>
                        <div className="bg-yellow-300 p-2 mx-1 rounded-xl"></div>
                        <p className='text-lg'>Both</p>
                      </div> */}
        </div>
      </div>
      {
        encountersLoading ? <Spin size="small" style={{ margin: '0 auto', width: '100%' }} /> : <div className='w-full items-center pr-2 flex flex-col gap-2'>
          <ConfigProvider
            theme={{
              components: {
                Segmented: {
                  itemSelectedBg: '#4CAF50',
                  itemSelectedColor: 'white'
                },
              },
            }}
          >
            <Segmented
              options={['All', 'Treatments', 'Orders', 'SOAP']}
              value={activeTab}
              onChange={handleTabSelect}
              size="large"
              block
              className='w-full font-medium'
            />
          </ConfigProvider>
          {
            filteredEncounters.length > 0 ?
              <>
                {filteredEncounters?.map((item) => {
                  return (
                    <div className={`${getBackgroundColor(item?.type ?? "")} flex w-full items-center justify-between shadow-sm border-[1px] border-gray-200 px-4 py-3 rounded-xl cursor-pointer hover:bg-gray-100 bg-opacity-50 transition-all duration-300`} onClick={() => {
                      if (item.createdAt) {
                        addTab(item?.createdAt.split('T')[0], item)
                      }
                      else if (item.date) {
                        item.type !== "Treatment" && addTab(item?.date?.split('T')[0], item)
                        item.type === "Treatment" && message.warning("This is a treatment, please open an order or soap note")
                      }
                    }}  >
                      <div className='flex items-center gap-2'>
                        {item?.createdAt && <p className="font-medium w-full">{moment(item?.createdAt?.split('T')[0]).format('MM-DD-YYYY')}</p>}
                        {item?.date && <p className="font-medium w-full">{moment(item?.date.split('T')[0]).format('MM-DD-YYYY')}</p>}

                        {referencedSoapNotes?.some(soapNote => soapNote.treatmentId === item._id) && <Input.TextArea autoSize={{ maxRows: 1 }} value={referencedSoapNotes?.find(soapNote => soapNote.treatmentId === item._id).customName} style={{ maxWidth: 200 }} className='w-full' onChange={(event) => handleChangeCustomName(event.target.value, item._id)} onBlur={() => handleUpdateCustomName(item._id)} onClick={(event) => event.stopPropagation()} />}
                        {(item.type === 'Treatment' || item.type === 'Order' || item.type === 'pickup') && <div onClick={(event) => event.stopPropagation()}>
                          <LPDVIconButtons treatment={item} setShowModal={setShowModal} setModalType={setModalType} setActiveTreatmentId={setActiveTreatmentId} setActiveTreatment={setActiveTreatment} iconButtons={["L", "P", "D", "V", "N"]} />
                        </div>}
                        {(item.type==='Order' || item.type==='pickup') && referencedSoapNotes?.some(soapNote => (soapNote.treatmentId === item._id || soapNote._id === item._id)) && <div className='px-2 py-1 rounded-[30%] bg-gray-300 text-black'>SOAP</div> }
                      </div>

                    </div>
                  )
                })}
                <Pagination
                  current={page}
                  pageSize={limit}
                  total={total}
                  onChange={(pageNumber) => setPage(pageNumber)}
                  showSizeChanger={false}
                  style={{ marginTop: 20, textAlign: "center" }}
                />
              </> :
              <div className='h-auto overflow-auto pr-2 flex flex-col gap-2'>
                <p className='my-0 mx-auto text-sm'>No Encounters</p>
              </div>
          }
        </div>
      }
      <LPDPopup
        type={modalType}
        patientName={selectedPatient["Full Name"]}
        setAllTreatments={setEncounters}
        activeTreatment={activeTreatment}
        showModal={showModal}
        onCloseModal={() => {
          setShowModal(false)
          setModalType(null)
          setActiveTreatment({})
        }}
        setModalType={setModalType}
        handleCellChange={handleCellChange}
        from="emr"
        ref={{ containerRef, soapNoteCreatedRef }}
      />
    </div>
  )
}

export default PastEncountersSection