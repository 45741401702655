import { Button, Checkbox, DatePicker, Modal, Select } from 'antd';
import axios from 'axios';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast as toastifyToast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


dayjs.extend(utc);
dayjs.extend(timezone);

const userTimezone = "America/Phoenix";
const { Option } = Select;

const CreateOrderModal = ({
  isOpen,
  closeModal,
  patientProfiles=[],
  toggleInactivePatients=()=>{},
  showInactivePatients=()=>{},
  selectedPatient=()=>{},
  refreshAllEncounters=()=>{},
  from=""
}) => {
  const [selectedPatientId, setSelectedPatientId] = useState(undefined);
  const [showOrderLoading, setShowOrderLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs.utc());

  useEffect(() => {
    if(selectedPatient && patientProfiles.length<=0){
      handlePatientSelect(selectedPatient["Customer ID"])
    }
  }, [selectedPatient,isOpen])

  // Handle patient select
  const handlePatientSelect = (value) => {
    setSelectedPatientId(value);
  };
  
  // Create a new order

  const createOrder = async () => {
    setShowOrderLoading(true);
    if (!selectedPatientId) {
      toastifyToast.error("Please select patient");
      setShowOrderLoading(false);
      return;
    }
    
    try {
      const response = await axios.post(
        `/api/patient/order/new/${selectedPatientId}/Order`, 
        { 
          createdFromView: "frontdeskview", 
          date: selectedDate ? selectedDate.format("YYYY-MM-DD") : dayjs.utc().format("YYYY-MM-DD") 
        }, 
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        toastifyToast.success("Order created successfully");
        if (from === "emr") {
          refreshAllEncounters()
        }
        closeModal()
        setSelectedPatientId(undefined);
        setSelectedDate(dayjs.utc());
        setShowOrderLoading(false);
        
      } else {
        toastifyToast.error("Failed to create order");
        setShowOrderLoading(false);
      }
    } catch (error) {
      toastifyToast.error(
        error.response?.data?.message || "An error occurred while creating the order"
      );
      setShowOrderLoading(false);
    } finally {
      setShowOrderLoading(false);
    }
  };

  const handleDateChange = (date) => {
    if (date) {
      // Convert to UTC directly without timezone manipulation
      setSelectedDate(dayjs.utc(date));
    } else {
      setSelectedDate(null);
    }
  };

  return (
    <>
      <Modal
        title="Create Order"
        open={isOpen}
        onCancel={()=>{
          closeModal()
          setSelectedPatientId(undefined);
          setSelectedDate(dayjs().tz(userTimezone))
        }}
        footer={null}
      >
        <div className='flex flex-col gap-2'>
        {/* Patient Select */}
        {patientProfiles.length > 0 && <div className="mb-4 flex flex-col gap-2" >
          <Select
            className="w-full"
            placeholder="Select a patient"
            onChange={handlePatientSelect}
            value={selectedPatientId}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children
                ?.toLowerCase()
                ?.indexOf(input?.toLowerCase()) >= 0
            }
          >
            {patientProfiles.map(patient => (
              <Option key={patient._id} value={patient["Customer ID"]}>
                {patient["Full Name"]}
              </Option>
            ))}
          </Select>
          {patientProfiles.length > 0 && <div className="flex justify-end">
            <Checkbox
              id="showInactive"
              checked={showInactivePatients}
              onChange={(e) => toggleInactivePatients(e.target.checked)}
            >
              Show Inactive Patients
            </Checkbox>
          </div>}
          
        </div>}
        {selectedPatient && <div className='flex items-center'>
        <p className='text-base font-medium'>{selectedPatient["Full Name"]} </p>
        </div>}
        <div className="flex items-center gap-1 w-full mb-2">

        <DatePicker
  value={selectedDate ? dayjs.utc(selectedDate) : null}
  onChange={handleDateChange}
  format="MM-DD-YYYY"
  allowClear={false}
  style={{ width: "100%" }}
/>
        </div>
        </div>
        {/* Action Buttons */}
        <div className="flex justify-end space-x-4">
          <Button onClick={()=>{
            closeModal()
          }}>
            Cancel
          </Button>
          <Button
            style={{
              color: "white",
              backgroundColor: "#3B82F6",
            }}
            onClick={createOrder}
            loading={showOrderLoading}
          >
            Create
          </Button>
        </div>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default CreateOrderModal;