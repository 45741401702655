import React, { useEffect, useState } from "react";
import { Modal, Checkbox, Collapse, message, Button, DatePicker } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const { Panel } = Collapse;
const userTimezone = dayjs.tz.guess();

const CreateTreatmentFromExistingOrder = ({ visible, onClose, orderData }) => {
  const [selectedKeys, setSelectedKeys] = useState(new Set());
  const [selectedDate, setSelectedDate] = useState(dayjs().tz(userTimezone));
  const [treatmentLoader, setTreatmentLoader] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});

  const activeLPDS = {
    userLabs: {
      label: "Labs",
      data: orderData?.userLabs?.filter((lab) => lab.checked).map((lab) => ({ itemDetails: { ...lab, name: lab.test_name }, itemName: lab.test_name })) || [],
    },
    userProcedures: {
      label: "Procedures",
      data: orderData?.userProcedures
        ?.filter(
          (procedure) =>
            procedure.checked === true ||
            (procedure.selectedField && procedure.selectedField !== "" && !procedure.custom)
        )
        .map((procedure) => {
          let procedureName = procedure.procedure_name;
          if (procedure.selectedField && procedure.selectedField !== "") {
            procedureName += ` (${procedure.selectedField})`;
          }
          if (procedure.custom) {
            procedureName += " (Custom)";
          }
          return { itemDetails: { ...procedure, name: procedure.procedure_name }, itemName: procedureName };
        }) || [],
    },
    userdispensed: {
      label: "Dispensed Items",
      data: orderData?.userdispensed
        ?.filter((d) => d.quantity > 0)
        .map((d) => {
          let dispensedName = d.name;
          if (d.custom) {
            dispensedName += " (Custom)";
          }
          return { itemDetails: { ...d, name: d.name }, itemName: `${dispensedName} (${d.selectedField}) x${d.quantity}` };
        }) || [],
    },
    userSupplements: {
      label: "Supplements",
      data: orderData?.userSupplements
        ?.filter((s) => s.quantity > 0)
        .map(
          (s) => ({
            itemDetails: { ...s, name: s.name },
            itemName: `${s.name} ${s?.selectedField ? `(${s?.selectedField})` : ""} x${s.quantity} SUP ${s.custom ? "(Custom)" : ""
              }`
          })
        ) || [],
    },
  };

  const createTreatmentWithOrderData = async (patientId, treatmentId) => {
    setTreatmentLoader(true);
    const sessionToken = localStorage.getItem("sessionToken");

    if (!sessionToken) {
      throw new Error("No session token found");
    }

    try {
      const response = await axios.post(
        "/create-treatment-from-order-with-selected-keys",
        {
          PatientId: patientId,
          Scheduled_Date: selectedDate.format("YYYY-MM-DD"),
          type: "Treatment",
          status: "scheduled",
          // keysToInclude: [...selectedKeys],
          selectedLabs: checkedItems?.userLabs || [],
          selectedDispensed: checkedItems?.userdispensed || [],
          selectedSupplements: checkedItems.userSupplements || [],
          selectedProcedures: checkedItems.userProcedures || [],
          prevOrderId: treatmentId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        setTreatmentLoader(false);
        message.success("Treatment Created Successfully");
        setSelectedKeys(new Set());
        setCheckedItems({});
        setSelectedDate(dayjs().tz(userTimezone))
        onClose();
      } else {
        setTreatmentLoader(false);
        message.error("Failed to create schedule");
        setSelectedKeys(new Set());
        setCheckedItems({});
        setSelectedDate(dayjs().tz(userTimezone))
        onClose();
      }
    } catch (error) {
      setTreatmentLoader(false);
      message.error(
        error.response.data.message || "An error occurred while copying"
      );
      console.error("Error creating copy:", error);
      setSelectedKeys(new Set());
      setCheckedItems({});
      setSelectedDate(dayjs().tz(userTimezone))
      onClose();
    }
  };

  const handleDateChange = (date) => {
    if (date) {
      const userTimezone = dayjs.tz.guess();
      const newDateTime = dayjs(date)
        .tz(userTimezone)
        .hour(dayjs().hour())
        .minute(dayjs().minute())
        .second(dayjs().second());
      setSelectedDate(newDateTime);
    } else {
      setSelectedDate(null);
    }
  };

  const handleHeaderCheckboxChange = (key, isChecked, items) => {
    setCheckedItems((prev) => ({
      ...prev,
      [key]: isChecked ? items.map((item) => item.itemDetails.name) : [], 
    }));
  };

  const handleSelectAll = (isChecked) => {
    setCheckedItems(
      isChecked
        ? Object.fromEntries(
            Object.entries(activeLPDS).map(([key, item]) => [
              key,
              item.data.map((dataItem) => dataItem.itemDetails.name),
            ])
          )
        : {}
    );
  };
  
  const handleCheckboxChange = (key, item) => {
    setCheckedItems((prev) => {
      const updatedItems = new Set(prev[key] || []);
      if (updatedItems.has(item?.itemDetails?.name)) {
        updatedItems.delete(item?.itemDetails?.name);
      } else {
        updatedItems.add(item?.itemDetails?.name);
      }
      return {
        ...prev,
        [key]: [...updatedItems],
      };
    });

    setSelectedKeys((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(item)) {
        newSet.delete(item);
      } else {
        newSet.add(item);
      }
      return newSet;
    });
  };

  return (
    <Modal
      title="Create Treatment From Order"
      visible={visible}
      style={{ minWidth: '45vw' }}
      onCancel={() => {
        setSelectedKeys(new Set());
        setCheckedItems({});
        setSelectedDate(dayjs().tz(userTimezone))
        setTreatmentLoader(false);
        onClose();
      }}
      footer={[
        <Button
          key="back"
          disabled={treatmentLoader}
          onClick={() => {
            setSelectedKeys(new Set());
            setCheckedItems({});
            setSelectedDate(dayjs().tz(userTimezone))
            onClose();
          }}
        >
          Cancel
        </Button>,
        <Button
        key="submit"
        className="bg-blue-500 text-white hover:!bg-blue-400 hover:!text-white"
        onClick={() => handleSelectAll(true)}
      >
        Select All
      </Button>,
        <Button
          key="submit"
          loading={treatmentLoader}
          className="bg-blue-500 text-white hover:!bg-blue-400 hover:!text-white"
          onClick={() => createTreatmentWithOrderData(orderData.PatientId, orderData._id)}
        >
          Create
        </Button>,
      ]}
    >
      <div className="flex flex-col gap-2 mt-5">
        <div className="flex items-center gap-1 w-full mb-2">
          <p className="text-base font-medium">Date:</p>
          <DatePicker
            value={selectedDate}
            onChange={handleDateChange}
            format="MM-DD-YYYY"
            allowClear={false}
          />
        </div>
        {Object.keys(activeLPDS).some(key => activeLPDS[key].data.length > 0) ?
          <>
            <p className="text-base font-medium">Select the sections you want to get copied in new treatment</p>
            <div className="flex gap-4">
              <div className="w-full">

                <Collapse defaultActiveKey={["userLabs", "userProcedures", "userdispensed", "userSupplements"]}>
                  {Object.entries(activeLPDS).map(([key, item]) => {
                    if (item.data.length > 0) return <Panel
                      key={key}
                      header={
                        <div className="flex items-center justify-between gap-[8px]">
                          <span>{item.label}</span>
                          <Checkbox
                            checked={checkedItems[key]?.length === item.data.length && item.data.length > 0}

                            onChange={(e) => handleHeaderCheckboxChange(key, e.target.checked, item.data)}
                            disabled={item.data.length === 0}
                            onClick={(e) => e.stopPropagation()}
                          />
                        </div>
                      }
                    >
                      {item.data.length > 0 ? (
                        <ul style={{ listStyle: "disc", paddingLeft: "20px" }}>
                          {item.data.map((item, index) => (
                            <li key={index}>
                              <div className="flex items-center justify-between">
                                <span>{item?.itemName}</span>
                                <Checkbox
                                  checked={checkedItems[key]?.includes(item?.itemDetails?.name)}
                                  onChange={() => handleCheckboxChange(key, item)}
                                  onClick={(e) => e.stopPropagation()}
                                />
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p className="text-orange-400">No data available</p>
                      )}
                    </Panel>
                  }
                  )}
                </Collapse>
              </div>
            </div>
          </> : <div className="w-full flex items-center justify-center">
            <p className="text-base font-medium">No data available to show</p>
          </div>
        }
      </div>
    </Modal>
  );
};

export default CreateTreatmentFromExistingOrder;
