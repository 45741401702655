import React, { useEffect, useState } from 'react';
import { Modal, Select, DatePicker, TimePicker, Button, Spin, Input, Checkbox } from 'antd';
import moment from 'moment';
import axios from 'axios';
import { ToastContainer, toast as toastifyToast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";

const { Option } = Select;

const ScheduleTreatmentModal = ({
  isOpen,
  closeModal,
  patientProfiles,
  createWatch,
  getCalendarCidByType,
  getCalendarIdByType,
  treatments,
  setTreatments,
  toggleInactivePatients,
  showInactivePatients,
  from="",
  dateFromView=null,
}) => {
  const [selectedPatientId, setSelectedPatientId] = useState(undefined);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [note, setNote] = useState('');
  const [showScheduleLoading, setShowScheduleLoading] = useState(false);
  const [showPickupLoading, setShowPickupLoading] = useState(false);
  const [shouldCreateEvent, setShouldCreateEvent] = useState(false);

  // Handle patient select
  const handlePatientSelect = (value) => {
    setSelectedPatientId(value);
  };
  
  // Create a new schedule

  const createSchedule = async () => {
    setShowScheduleLoading(true);
    if (!selectedPatientId || !selectedDate || (shouldCreateEvent && !selectedTime)) {
      toastifyToast.error("Please select all fields");
      setShowScheduleLoading(false);
      return;
    }      

    const date = dayjs(selectedDate)

    // Commented this part of code where date and time formatting is handled with moment library

    // const datePart = date.format("YYYY-MM-DD");
    // const timePart = date ? date.format("hh:mm A") : moment().format("hh:mm A");
    // const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // const combinedDateTime = moment.tz(`${datePart} ${timePart}`, "YYYY-MM-DD hh:mm A", userTimezone);
    
    // Now formatting date and time with dayjs library, because previously the date was getting changed itself
    
    // Extract only the time from the user input (or use the current time as fallback)
    const selectedTimePart = selectedTime ? dayjs(selectedTime, "hh:mm A") : dayjs();
    const timePart = selectedTimePart.format("hh:mm A"); 
    const timePartObject = dayjs(timePart, "hh:mm A"); 
    const datePart = date.format("YYYY-MM-DD");
    const combinedDateTime = date.hour(timePartObject.hour()).minute(timePartObject.minute());

    const existingTreatment = await axios.get(`/check-treatment-exists?patientId=${selectedPatientId}&date=${combinedDateTime}`)
    if (existingTreatment?.data?.exists) {
      try {
        const userConfirmed = await new Promise((resolve) => {
          Modal.confirm({
            title: `${existingTreatment?.data?.type === "pickup" ? "Order" : existingTreatment?.data?.type ?? "Patient"} Already Exists`,
            content: `${existingTreatment?.data?.type === "pickup" ? "Order" : existingTreatment?.data?.type} for this patient already exists for today. Would you like to continue or cancel?`,
            okButtonProps: {
              className: "bg-blue-600 text-white"
            },
            okText: 'Continue',
            onOk: () => resolve(true),
            onCancel: () => resolve(false),
          });
        });

        if (!userConfirmed) {
          setShowScheduleLoading(false);
          return;
        }
      } catch (error) {
        console.error("Error in confirmation:", error);
        setShowScheduleLoading(false);
        return;
      }
    }

    try {
      const response = await axios.post(
        "/patient/order/new/scheduled",
        {
          PatientId: selectedPatientId,
          Scheduled_Date: combinedDateTime.toISOString(), // Send the combined date-time in ISO format
          timezone: "America/Phoenix",
          type: "Treatment",
          status: "scheduled",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        toastifyToast.success("Schedule created successfully");
        if(shouldCreateEvent){
          createEventWithTime(
            timePart,
            "scheduled",
            response.data._id,
            "America/Phoenix",
            note,
            patientProfiles.find(p => p["Customer ID"] === selectedPatientId)?.["Full Name"],
            datePart
          );
  
          handleCellChange(response.data._id, "time", timePart, "patientInfo");
          if (treatments && treatments.length > 0 && setTreatments) {
            const selectedPatientName=patientProfiles.find(p => p["Customer ID"] === selectedPatientId)?.["Full Name"]
              setTreatments([
            ...treatments,
            { ...response.data, patientName: selectedPatientName, time: timePart },
          ]);
          }
        }
        else{
          if (treatments && treatments.length > 0 && setTreatments) {
            const selectedPatientName=patientProfiles.find(p => p["Customer ID"] === selectedPatientId)?.["Full Name"]
              setTreatments([
            ...treatments,
            { ...response.data, patientName: selectedPatientName},
          ]);
          }
        }
        

        
        closeModal();
        if (from === 'tv') {
          const formattedDate = formatDateForAPI(dateFromView)
          setSelectedDate(formattedDate)
        }
        else {
          setSelectedDate(dayjs().format("YYYY-MM-DD"))
        }
        setSelectedTime(null);
        setSelectedPatientId(undefined);
        setNote("");
        setShowScheduleLoading(false);
      } else {
        toastifyToast.error("Failed to create schedule");
        setShowScheduleLoading(false);
      }
    } catch (error) {
      toastifyToast.error(
        error.response?.data?.message || "An error occurred while creating the schedule"
      );
      setShowScheduleLoading(false);
    } finally {
      setShowScheduleLoading(false);
    }
  };

  // Handle pickup
  const handlePickUp = async () => {
    setShowPickupLoading(true);
    if (!selectedPatientId || !selectedDate || (shouldCreateEvent && !selectedTime)) {
      toastifyToast.error("Please select all fields");
      setShowPickupLoading(false);
      return;
    }
    const date = dayjs(selectedDate)
    const datePart = date.format("YYYY-MM-DD");
    const timePart = selectedTime ? selectedTime.format("hh:mm A") : moment().format("hh:mm A");
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const combinedDateTime = moment.tz(`${datePart} ${timePart}`, "YYYY-MM-DD hh:mm A", userTimezone);

    const existingTreatment = await axios.get(`/check-treatment-exists?patientId=${selectedPatientId}&date=${selectedDate}`)
    if (existingTreatment?.data?.exists) {
      try {
        const userConfirmed = await new Promise((resolve) => {
          Modal.confirm({
            title: `${existingTreatment?.data?.type === "pickup" ? "Order" : existingTreatment?.data?.type ?? "Patient"} Already Exists`,
            content: `${existingTreatment?.data?.type === "pickup" ? "Order" : existingTreatment?.data?.type} for this patient already exists for today. Would you like to continue or cancel?`,
            okButtonProps: {
              className: "bg-blue-600 text-white"
            },
            okText: 'Continue',
            onOk: () => resolve(true),  
            onCancel: () => resolve(false),
          });
        });
  
        if (!userConfirmed) {
          setShowPickupLoading(false);
          return; 
        }
      } catch (error) {
        console.error("Error in confirmation:", error);
        setShowPickupLoading(false);
        return;
      }
    }

    try {
      const response = await fetch(
        `/schedule-pickup/${datePart}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
          body: JSON.stringify({
            dispensed: [],
            supplements: [],
            patientId: selectedPatientId,
          }),
        }
      );
      if (response.status === 200 || response.status === 201) {
        toastifyToast.success("Pick up scheduled successfully!");
        const jsonResponse = await response.json()
        if (treatments && treatments.length > 0 && setTreatments) {
          const selectedPatientName = patientProfiles.find(p => p["Customer ID"] === selectedPatientId)?.["Full Name"]
            setTreatments([
          ...treatments,
          { ...jsonResponse, patientName: selectedPatientName, time: timePart ?? null },
        ]);
        }
        if (selectedTime && shouldCreateEvent) {
          createEventWithTime(
          timePart,
          "pickup",
          jsonResponse._id,
          "America/Phoenix",
          note,
          patientProfiles.find(p => p["Customer ID"] === selectedPatientId)?.["Full Name"],
          datePart
          );
          handleCellChange(jsonResponse._id, "time", timePart, "patientInfo");
          if (treatments && treatments.length > 0 && setTreatments) {
            const selectedPatientName=patientProfiles.find(p => p["Customer ID"] === selectedPatientId)?.["Full Name"]
              setTreatments([
            ...treatments,
            { ...jsonResponse.data, patientName: selectedPatientName, time: timePart },
          ]);
          }
        }
        else {
          if (treatments && treatments.length > 0 && setTreatments) {
            const selectedPatientName=patientProfiles.find(p => p["Customer ID"] === selectedPatientId)?.["Full Name"]
              setTreatments([
            ...treatments,
            { ...response.data, patientName: selectedPatientName},
          ]);
          }
          closeModal()
        }
        closeModal();
        setSelectedDate(dayjs().format('YYYY-MM-DD'));
        setSelectedTime(null);
        setSelectedPatientId(undefined);
        setNote("");
        setShowPickupLoading(false)
      } else {
        toastifyToast.error("Error scheduling pick up!");
        setShowPickupLoading(false)
      }
    } catch (error) {
      toastifyToast.error("An error occurred while scheduling the pickup.");
      setShowPickupLoading(false)
    } finally {
      setShowPickupLoading(false);
    }
  };

  // Call event creation function
  const createEventWithTime = async (
    time,
    status,
    treatmentId,
    timezone,
    note,
    patientName,
    selectedDate
  ) => {
    try {
      const cid = getCalendarCidByType(status);

      const response = await axios.post(
        `/create-new-event`,
        { time, patientName, timezone, note, cid, selectedDate }
      );
      const idc = getCalendarIdByType(status);
      createWatch(response.data.id, treatmentId, idc, status);
      toastifyToast.success("New event created for treatment");
      handleCellChange(treatmentId, "event", response.data.id, "patientInfo");

      setSelectedPatientId(undefined);
      setSelectedDate(dayjs().format('YYYY-MM-DD'));
      setSelectedTime(null);
      closeModal();
    } catch (error) {
      toastifyToast.error("Error creating calendar event.");
      console.error(error);
    }
  };


  const handleCellChange = (
    patientId,
    columnName,
    newValue,
    category,
    newData,
  ) => {
    let payload;


    // Handling for other categories remains the same
    const identifierKey =
      category === "userProcedures" ? "procedure_name" : "name";
    let [itemName, field] = splitByLastDash(columnName);

    if (category === "patientInfo") {
      field = columnName;
    }

    payload = {
      category: category,
      identifier: {
        key: identifierKey,
        value: itemName,
      },
      updates: {
        [field]: newValue,
      },
      newData,
    };
    // Send the update request using axios
    axios
      .put(`/patient/order/update/${patientId}`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          "Content-Type": "application/json", // Specify the content type of your request
        },
      })
      .then((response) => {
      })
      .catch((error) => {
        toastifyToast.error("Error updating the treatment");
        console.error("Error:", error);
      });
  };


  function splitByLastDash(columnName) {
    // Find the index of the last dash
    const lastIndex = columnName.lastIndexOf("-");

    // If there's no dash, or the dash is the first character, return an error or handle it as needed
    if (lastIndex <= 0) {
      console.error("No dash found, or the dash is at the start of the string");
      return columnName;
    }

    // Split the string into two parts
    const itemName = columnName.substring(0, lastIndex);
    const field = columnName.substring(lastIndex + 1);

    return [itemName, field];
  }

  const disabledHours = () => {
    const hours = [];
    for (let i = 0; i < 8; i++) {
      hours.push(i);
    }
    for (let i = 19; i < 24; i++) {
      hours.push(i);
    }
    return hours;
  };

  const formatDateForAPI = (date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  };

  useEffect(()=>{
    if(from==='tv'){
      const formattedDate = formatDateForAPI(dateFromView)
      setSelectedDate(formattedDate)
    }
    else {
      setSelectedDate(dayjs())
    }
  },[isOpen])

  return (
    <>
      <Modal
        title="Schedule a Treatment"
        open={isOpen}
        onCancel={()=>{
          closeModal()
          if (from === 'tv') {
            const formattedDate = formatDateForAPI(dateFromView)
            setSelectedDate(formattedDate)
          }
          else {
            setSelectedDate(dayjs().format('YYYY-MM-DD'))
          }
          setSelectedTime(null);
          setSelectedPatientId(undefined);
          setNote("");
          setShouldCreateEvent(false)
        }}
        footer={null}
      >
        {/* Patient Select */}
        <div className="mb-4 flex flex-col gap-2" >
          <Select
            className="w-full"
            placeholder="Select a patient"
            onChange={handlePatientSelect}
            value={selectedPatientId}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children
                ?.toLowerCase()
                ?.indexOf(input?.toLowerCase()) >= 0
            }
          >
            {patientProfiles.map(patient => (
              <Option key={patient._id} value={patient["Customer ID"]}>
                {patient["Full Name"]}
              </Option>
            ))}
          </Select>
          <div className="flex justify-end">
            <Checkbox
              id="showInactive"
              checked={showInactivePatients}
              onChange={(e) => toggleInactivePatients(e.target.checked)}
            >
              Show Inactive Patients
            </Checkbox>
          </div>
        </div>

        {/* Date Picker */}
        <div className="mb-4 flex flex-col gap-2">
          {selectedDate && <DatePicker
            className="w-full"
            placeholder="Select date"
            value={dayjs(selectedDate)}
            onChange={setSelectedDate}
            format={'MM-DD-YYYY'}
            allowClear={false}
          />}
            <div className="flex justify-end">
            <Checkbox
              id="shouldCreateEvent"
              checked={shouldCreateEvent}
              onChange={(e) => setShouldCreateEvent(e.target.checked)}
            >
              Create Event
            </Checkbox>
          </div>
        </div>

        { shouldCreateEvent && <>
          {/* Time Picker */}
          <div className="mb-4">
            <TimePicker
              use12Hours
              format="h:mm A"
              minuteStep={15}
              allowClear={true}
              value={selectedTime}
              onChange={setSelectedTime}
              showNow={false}
              needConfirm={false}
              disabledHours={disabledHours}
              className="w-full"
              placeholder="Select time"
            />
          </div>

          {/* Note Input */}
          <div className="mb-4">
            <Input
              className="w-full"
              placeholder="Add a calendar note"
              value={note}
              onChange={e => setNote(e.target.value)}
            />
          </div>
        </>}

        {/* Action Buttons */}
        <div className="flex justify-end space-x-4">
          <Button onClick={()=>{
            setShouldCreateEvent(false)
            closeModal()
          }}>
            Cancel
          </Button>
          <Button
            style={{
              color: "white",
              backgroundColor: "#3B82F6",
            }}
            onClick={handlePickUp}
            loading={showPickupLoading}
          >
            Pick Up
          </Button>
          <Button
            style={{
              color: "white",
              backgroundColor: "#3B82F6",
              paddingLeft:"20px",
              paddingRight:"20px"
            }}
            onClick={createSchedule}
            loading={showScheduleLoading}
          >
            Schedule
          </Button>
        </div>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default ScheduleTreatmentModal;
