import { EditFilled, PlusOutlined } from '@ant-design/icons';
import { Print } from '@mui/icons-material';
import { Printer } from "lucide-react";
import { IconButton } from '@mui/material';
import { Badge, Button, Dropdown, Input, Menu, message, Spin, Tooltip } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import smsIcon from '../../../../Assets/sms.png';
import LPDPopup from '../../../../Components/LPDPopup';
import Messages from '../../../../Components/Messages';
import LPDVIconButtons from '../LPDVIconButtons';
import PrintModal from '../PrintModal';
import ReceiptPrintOptionsModal from "../Shopifyreceipt/ReceiptPrintOptionsModal";
import { Select, Checkbox } from 'antd';
import { toast } from 'react-toastify';
import SoapNoteTemplateManagement from '../../../../Components/Updations/SoapTemplateManagement';
import styles from './SOAPNoteSection.module.css'
import { DatePicker } from 'antd';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
const SOAPNotesSection = forwardRef(({ createTabContent, setTabContent, setShowSOAPNotesSection, scrollToDiagnosisDiv, chiefConcern, patientProfile, notesData = {}, tabKey = "", newDate = null},ref) => {
  const [subjective, setSubjective] = useState(notesData?.subjective || '');
  
  useImperativeHandle(ref, () => ({
    save: handleBlur,
  }));
  const [appointmentType, setAppointmentType] = useState('none');
  const [type, setType] = useState();
  dayjs.extend(utc);
const [isComped, setIsComped] = useState(false);
  const [receiptModalVisible, setReceiptModalVisible] = useState(false);
  const [objective, setObjective] = useState(notesData?.objective || '');
  const [assessment, setAssessment] = useState(notesData?.assessment || '');
  const [careplan, setCareplan] = useState(notesData?.careplan || '');
  const [planNote, setPlanNote] = useState(notesData?.plan?.note || '');
  const [medicationsNote, setMedicationsNote] = useState(notesData?.medicationsNote || '');
  const [labs, setLabs] = useState(notesData?.plan?.labs || '');
  const [procedures, setProcedures] = useState(notesData?.plan?.procedures || '');
  const [dispensed, setDispensed] = useState(notesData?.plan?.dispensed || '');
  const [customName, setCustomName] = useState(notesData?.customName || '');
  const [storedChiefConcern, setStoredChiefConcern] = useState(notesData?.chiefConcern || '');
  const [noteId, setNoteId] = useState((!notesData.type && notesData?._id) || null);
  const [date, setDate] = useState(() => {
    if (newDate) {
      return dayjs.utc(newDate.split('T')[0] || newDate).format('YYYY-MM-DD');
    } else if (notesData?.date) {
      return dayjs.utc(notesData.date.split('T')[0] || notesData.date).format('YYYY-MM-DD');
    } else if (notesData?.createdAt) {
      return dayjs.utc(notesData.createdAt.split('T')[0] || notesData.createdAt).format('YYYY-MM-DD');
    } else {
      return dayjs.utc().format('YYYY-MM-DD');
    }
  });
  const [printModalVisible, setPrintModalVisible] = useState(false);
  const [patientId, setPatientId] = useState('');
  const [icd10CMCodes, setICD10CMCodes] = useState([])
  const userTimezone = moment.tz.guess();
  const currentDate = dayjs.utc().format('YYYY-MM-DD');
  // const [orders, setOrders] = useState([]);
  const [treatment, setTreatment] = useState(null);
  const [newOrderLoading, setNewOrderLoading] = useState(false);
  const [editCustomName, setEditCustomName] = useState(false);
  const [showMessagesModal, setShowMessagesModal] = useState(false);
  const [isManuallyUpdated, setIsManuallyUpdated] = useState(false);
  const [currentTreatmentLoading, setCurrentTreatmentLoading] = useState(false);
  const [soapNoteCreated, setSoapNoteCreated] = useState(false);
  const [soapNoteCreatedFromLPD, setsoapNoteCreatedFromLPD] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showItemsMenu, setShowItemsMenu] = useState(false);
  const [itemsForSelection, setItemsForSelection] = useState([]);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [showShortcutsMenu, setShowShortcutsMenu] = useState(false);
  const [shortcuts, setShortcuts] = useState([]);
  const quillRef = useRef(null);
  const [customTemplates, setCustomTemplates] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [activeTreatment, setActiveTreatment] = useState({});
  const [showLPDV, setShowLPDV] = useState(false);
  const [createOrderLoader, setCreateOrderLoader] = useState(false);
  const [medications, setMedications] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState(false)
  const [planNoteSaved, setPlanNoteSaved] = useState(notesData?.saved || false)
  const [medicationsNoteSaved, setMedicationsNoteSaved] = useState(notesData?.medicationsNoteSaved || false)
  const [createdOrderId, setCreatedOrderId] = useState(null);
  const [planNoteLoader, setPlanNoteLoader] = useState(false);
  const [medicationsNoteLoader, setMedicationsNoteLoader] = useState(false);
  const [treatmentId, setTreatmentId] = useState(null);
  const [LPDIconButtonsLoader, setLPDIconButtonsLoader] = useState(false);
  const [defaultSupplements, setDefaultSupplements] = useState([]);
  const [selectedPatientSupplements, setSelectedPatientSupplements] = useState([]);
  const [historicalSupplements, setHistoricalSupplements] = useState([]);
  const [patientActiveMedications, setPatientActiveMedications] = useState([]);
  const [activeDispensedItems, setActiveDispensedItems] = useState([]);
  const [prevAppointmentType, setPrevAppointmentType] = useState(null);
const [prevIsComped, setPrevIsComped] = useState(null);
const [activeSupplementsExcludingPatientSupplements, setActiveSupplementsExcludingPatientSupplements] = useState([]);
const [soapNoteDataBeingFetched, setSOAPNoteDataBeingFetched] = useState(false);



useEffect(() => {
  // Check if we have stored values for this note
  if (noteId) {
    const storedAppType = localStorage.getItem(`appointment_type_${noteId}`);
    const storedIsComped = localStorage.getItem(`is_comped_${noteId}`);
    
    if (storedAppType) {
      setPrevAppointmentType(storedAppType);
      setAppointmentType(storedAppType);
    }
    
    if (storedIsComped !== null) {
      const isCompedValue = storedIsComped === 'true';
      setPrevIsComped(isCompedValue);
      setIsComped(isCompedValue);
    }
  }
}, [noteId]);

  const fetchAllICD10CMCodes = async () => {
    try {
      const response = await axios.get(`/get-codes`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
        }
      });
      setICD10CMCodes(response.data)
    } catch (error) {
      console.error("Error saving EMR data:", error);
    }
  }

  const fetchSoapNote = async (noteId) => {
    setSOAPNoteDataBeingFetched(true)
    try {
      const response = await axios.get(`/get-soap-note/${noteId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
        }
      });
      
      if (response.status === 200 || response.status === 201) {
        setSubjective(response.data?.soapNote?.subjective);
        setObjective(response.data?.soapNote?.objective);
        setAssessment(response.data?.soapNote?.assessment);
        setCareplan(response.data?.soapNote?.careplan);
        setPlanNote(response.data?.soapNote?.plan?.note);
        setMedicationsNote(response.data?.soapNote?.medicationsNote);
        setLabs(response.data?.soapNote?.plan?.labs);
        setProcedures(response.data?.soapNote?.plan?.procedures);
        setDispensed(response.data?.soapNote?.plan?.dispensed);
        setCustomName(response.data?.soapNote?.customName);
        setNoteId(response?.data?.soapNote?._id);
        setPlanNoteSaved(response?.data?.soapNote?.saved)
        setMedicationsNoteSaved(response?.data?.soapNote?.medicationsNoteSaved)
        setTreatmentId(response?.data?.soapNote?.treatmentId ?? null);
        setStoredChiefConcern(response?.data?.soapNote?.chiefConcern);
        setSOAPNoteDataBeingFetched(false)
        // Only update appointment type and comped status if no stored value exists
        if (!prevAppointmentType && response.data?.soapNote?.appointmentType) {
          setAppointmentType(response.data.soapNote.appointmentType);
          setPrevAppointmentType(response.data.soapNote.appointmentType);
        }
        
        if (prevIsComped === null && response.data?.soapNote?.isComped !== undefined) {
          setIsComped(response.data.soapNote.isComped);
          setPrevIsComped(response.data.soapNote.isComped);
        }
      }
    } catch (error) {
      console.error("Error fetching SOAP note data:", error);
    }
  };
  
 
const fetchSoapNoteByTreatmentId = async (patientId, treatmentId) => {
  setSOAPNoteDataBeingFetched(true)
  try {
    const response = await axios.get(`/get-soap-note/${patientId}/${treatmentId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
      }
    });
    
    if (response.status === 200 || response.status === 201) {
      setSubjective(response.data?.subjective)
      setObjective(response.data?.objective)
      setAssessment(response.data?.assessment)
      setCareplan(response.data?.careplan)
      setPlanNote(response.data?.plan?.note)
      setMedicationsNote(response.data?.medicationsNote)
      setLabs(response.data?.plan?.labs)
      setProcedures(response.data?.plan?.procedures)
      setDispensed(response.data?.plan?.dispensed)
      setCustomName(response.data?.customName)
      setPlanNoteSaved(response.data?.saved)
      setMedicationsNoteSaved(response.data?.medicationsNoteSaved)
      setNoteId(response.data?._id)
      setSOAPNoteDataBeingFetched(false)
      // Only set appointment type and comped status if not manually updated
      // or if no stored values exist
      const storedNoteId = response.data?._id;
      if (storedNoteId) {
        const storedAppType = localStorage.getItem(`appointment_type_${storedNoteId}`);
        const storedIsComped = localStorage.getItem(`is_comped_${storedNoteId}`);
        
        if (storedAppType) {
          // Use stored values if they exist
          setAppointmentType(storedAppType);
          setPrevAppointmentType(storedAppType);
        } else if (response.data?.appointmentType) {
          // Otherwise use the values from the API response
          setAppointmentType(response.data.appointmentType);
          setPrevAppointmentType(response.data.appointmentType);
        }
        
        if (storedIsComped !== null) {
          const isCompedValue = storedIsComped === 'true';
          setIsComped(isCompedValue);
          setPrevIsComped(isCompedValue);
        } else if (response.data?.isComped !== undefined) {
          setIsComped(response.data.isComped);
          setPrevIsComped(response.data.isComped);
        }
      } else {
        // If we don't have a note ID yet, just use the response values
        if (response.data?.appointmentType) {
          setAppointmentType(response.data.appointmentType);
          setPrevAppointmentType(response.data.appointmentType);
        }
        
        if (response.data?.isComped !== undefined) {
          setIsComped(response.data.isComped);
          setPrevIsComped(response.data.isComped);
        }
      }
    }
  } catch (error) {
    console.error("Error fetching SOAP note data:", error);
  }
}

  useEffect(() => {
    if (isManuallyUpdated) {
      // Store in localStorage that we've made a manual change to this note
      if (noteId) {
        localStorage.setItem(`soap_manual_change_${noteId}`, 'true');
      }
      
      // Reset the flag after a short delay
      const timer = setTimeout(() => {
        setIsManuallyUpdated(false);
      }, 2000);
      
      return () => clearTimeout(timer);
    }
  }, [isManuallyUpdated, noteId]);
  const chiefConcernData = icd10CMCodes.find(item => item.code === chiefConcern) || "";

  useEffect(() => {
    setPatientId(patientProfile._id)
    fetchAllICD10CMCodes()
    fetchAllMedications()
    fetchSupplementNames()
    fetchPatientProfile(patientProfile['Customer ID'])
    fetchActiveDispensedItems(patientProfile['Customer ID'])
    fetchActiveSupplementsExcludingPatientPlanSupplements(patientProfile['Customer ID'])
    if (noteId) {
      // Check if we've made manual changes to this note before
      const hasManualChanges = localStorage.getItem(`soap_manual_change_${noteId}`) === 'true';
      if (hasManualChanges) {
        setIsManuallyUpdated(true);
      }
      fetchSoapNote(noteId)
      fetchAllMedications()
    }
    if(notesData.type){
      fetchSoapNoteByTreatmentId(patientProfile._id, notesData._id)
    }
  }, [patientProfile])

  useEffect(() => {
    if (notesData?.type) {
      if (!soapNoteCreatedFromLPD) {
        fetchTreatment(notesData?._id);
      }
    }
    if(treatmentId !== null){
      fetchTreatment(treatmentId)
    }
  }, [notesData, noteId,treatmentId])

  // Previously implemented code where appointment type and isComped are linked with soap note
  // useEffect(() => {
  //   if (notesData && notesData.appointmentType) {
  //     setAppointmentType(notesData.appointmentType);
  //   }
  //   if (notesData && notesData.isComped !== undefined) {
  //     setIsComped(notesData.isComped);
  //   }
  // }, [notesData]);

  const updateTabContent = (tabKey, updatedNotesData, updatedChiefConcern) => {
    setTabContent((prevTabContent) => ({
      ...prevTabContent,
      [tabKey]: createTabContent(tabKey, updatedNotesData, updatedChiefConcern), // Update content for the specific tab
    }));
  };
 
  const [loading, setLoading] = useState(false); // Track loading state

const showToast = (message, type) => {
    toast(message, { type });
  };


// 1. In SOAPNotesSection.jsx, update the date handling in handleBlur function:

const handleBlur = async (
  treatmentId, 
  newPlanNote = planNote, 
  newMedicationsNote = medicationsNote
) => {
  
  if (createdOrderId !== null && createdOrderId !== "") {
    // If createdOrderId is null or empty, allow execution (move to else)
    try {
      if (!noteId) {
        setLoading(true);
      }
      
      // Use the date from the order if provided, otherwise use the current SOAP note date
      const noteDate = treatmentId && notesData?.date 
        ? notesData.date 
        : (notesData?.createdAt ? notesData.createdAt : date);
      
      let requestBody = {
        patientId: patientId || patientProfile._id,
        chiefConcern: chiefConcernData,
        subjective,
        objective,
        assessment,
        careplan,
        plan: {
          note: newPlanNote || "", 
          labs: labs,
          procedures: procedures,
          dispensed: dispensed,
        },
        medicationsNote: newMedicationsNote || "",
        createdAt: date,
        noteId
      };
      
      if (treatmentId) {
        requestBody.treatmentId = treatmentId;
        
        // Get the order date directly if possible
        try {
          const orderResponse = await axios.get(`/orderget/${treatmentId}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem("sessionToken")}` },
          });
          
          if (orderResponse.status === 200 && orderResponse.data?.date) {
            // Use the order date for the SOAP note
            requestBody.createdAt = dayjs.utc(orderResponse.data.date).format('YYYY-MM-DD');
          }
        } catch (orderError) {
          console.error('Error fetching order date:', orderError);
        }
      } else if (notesData.type && notesData._id) {
        requestBody.treatmentId = notesData._id;
      }
      
      const response = await axios.post('/soap-note', requestBody);
      console.log('SOAP Note response:', response.data);
      
      if (!noteId && !soapNoteCreated && response.data?.soapNote?._id) {
        console.log("Note id is not present");
        updateTabContent(tabKey, response.data?.soapNote, response.data?.soapNote?.chiefConcern);
        setNoteId(response.data.soapNote._id);
        setDate(response.data?.soapNote?.createdAt);
        setStoredChiefConcern(response?.data?.chiefConcern);
        setSoapNoteCreated(true);
        if (setShowSOAPNotesSection) {
          setShowSOAPNotesSection({
            action: 'updateSoapId',
            tabKey: tabKey,
            soapNoteId: response.data.soapNote._id
          });
        }
        console.log("show soapnotes section",setShowSOAPNotesSection)
        // Still save the current values once we have a note ID
        localStorage.setItem(`appointment_type_${response.data.soapNote._id}`, appointmentType);
        localStorage.setItem(`is_comped_${response.data.soapNote._id}`, isComped.toString());
      }
        
      setNoteId(response.data.soapNote._id);
      
      return response.data;
    } catch (error) {
      console.error('Error saving SOAP note:', error);
      return null;
    } finally {
      setLoading(false); // Hide loader once request completes
    }
  } else if (!notesData.type && !noteId && shouldStopExecution(subjective, objective, assessment, planNote, careplan, medications)) {
    console.log("Execution stopped: All fields are empty.");
    return;
  } else {
    try {
      if (!noteId) {
        setLoading(true);
      }
      
      // Use the date from the order if provided, otherwise use the current SOAP note date
      const noteDate = treatmentId && notesData?.date 
        ? notesData.date 
        : (notesData?.createdAt ? notesData.createdAt : date);
      
      let requestBody = {
        patientId: patientId || patientProfile._id,
        chiefConcern: chiefConcernData,
        subjective,
        objective,
        assessment,
        careplan,
        plan: {
          note: newPlanNote || "", 
          labs: labs,
          procedures: procedures,
          dispensed: dispensed,
        },
        medicationsNote: newMedicationsNote || "",
        createdAt: date,
        noteId
      };
      
      if (treatmentId) {
        requestBody.treatmentId = treatmentId;
        
        // Get the order date directly if possible
        try {
          const orderResponse = await axios.get(`/orderget/${treatmentId}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem("sessionToken")}` },
          });
          
          if (orderResponse.status === 200 && orderResponse.data?.date) {
            // Use the order date for the SOAP note
            requestBody.createdAt = dayjs.utc(orderResponse.data.date).format('YYYY-MM-DD');
          }
        } catch (orderError) {
          console.error('Error fetching order date:', orderError);
        }
      } else if (notesData.type && notesData._id) {
        requestBody.treatmentId = notesData._id;
      }
      
      const response = await axios.post('/soap-note', requestBody);
      console.log('SOAP Note response:', response.data);
      
      if (!noteId && !soapNoteCreated && response.data?.soapNote?._id) {
        console.log("Note id is not present");
        updateTabContent(tabKey, response.data?.soapNote, response.data?.soapNote?.chiefConcern);
        setNoteId(response.data.soapNote._id);
        setDate(response.data?.soapNote?.createdAt);
        setStoredChiefConcern(response?.data?.chiefConcern);
        setSoapNoteCreated(true);
        
        // ADD THIS NEW BLOCK - This is what you need to add
        if (setShowSOAPNotesSection) {
          setShowSOAPNotesSection({
            action: 'updateSoapId',
            tabKey: tabKey,
            soapNoteId: response.data.soapNote._id
          });
        }
        // END OF NEW BLOCK
        
        // Still save the current values once we have a note ID
        localStorage.setItem(`appointment_type_${response.data.soapNote._id}`, appointmentType);
        localStorage.setItem(`is_comped_${response.data.soapNote._id}`, isComped.toString());
      }
        
      setNoteId(response.data.soapNote._id);
      
      return response.data;
    } catch (error) {
      console.error('Error saving SOAP note:', error);
      return null;
    } finally {
      setLoading(false); // Hide loader once request completes
    }
  }
};
const updateAppointmentDetails = async (newAppointmentType, newIsComped) => {
  try {
    console.log('Updating appointment details:', newAppointmentType, newIsComped);
    
    // Store the values in localStorage to persist across refreshes
    if (noteId) {
      localStorage.setItem(`appointment_type_${noteId}`, newAppointmentType);
      localStorage.setItem(`is_comped_${noteId}`, newIsComped.toString());
      
      // Update prev values to prevent duplicate updates
      setPrevAppointmentType(newAppointmentType);
      setPrevIsComped(newIsComped);
    }
    
    const requestBody = {
      appointmentType: newAppointmentType,
      isComped: newIsComped,
    };
    
    const response = await axios.put(`/update-appointment-type-and-comped/${treatment._id}`, requestBody, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
      }
    });
    
    if (response.status === 200 || response.status === 201) {
      console.log('Appointment details updated successfully');
      
      // Update treatment object with new values if it exists
      if (treatment) {
        setTreatment(prev => ({
          ...prev,
          appointmentType: newAppointmentType,
          isComped: newIsComped
        }));
      }
      
      return response.data;
    }
    return null;
  } catch (error) {
    console.error('Error in updateAppointmentDetails:', error);
    return null;
  }
};

useEffect(() => {
  // Only update if treatment exists and values have changed
  if (treatment && 
      (treatment.appointmentType !== appointmentType || 
       treatment.isComped !== isComped)) {
    setTreatment(prev => ({
      ...prev,
      appointmentType: appointmentType,
      isComped: isComped
    }));
  }
}, [appointmentType, isComped, treatment]);

const handleAppointmentTypeChange = (value) => {
  // Skip if value hasn't actually changed
  if (value === prevAppointmentType) return;
  
  // Immediately update the local state
  setAppointmentType(value);
  
  // Handle special case for lab-review and none
  let updatedIsComped = isComped;
  if (value === 'lab-review' || value === 'none') {
    setIsComped(false);
    updatedIsComped = false;
  }
  
  // Mark as manually updated
  setIsManuallyUpdated(true);
  
  // Save to localStorage
  if (noteId) {
    localStorage.setItem(`appointment_type_${noteId}`, value);
    localStorage.setItem(`is_comped_${noteId}`, updatedIsComped.toString());
    
    // Update prev values to prevent duplicate updates
    setPrevAppointmentType(value);
    setPrevIsComped(updatedIsComped);
  }
  
  // Call the API with the updated values
  updateAppointmentDetails(value, updatedIsComped);
  
  // Also update the treatment object with new values if it exists
  if (treatment) {
    setTreatment(prev => ({
      ...prev,
      appointmentType: value,
      isComped: updatedIsComped
    }));
  }
};

const handleCompedChange = (e) => {
  const newCompedValue = e.target.checked;
  
  // Skip if value hasn't actually changed
  if (newCompedValue === prevIsComped) return;
  
  // Immediately update the local state
  setIsComped(newCompedValue);
  
  // Mark as manually updated
  setIsManuallyUpdated(true);
  
  // Save to localStorage
  if (noteId) {
    localStorage.setItem(`is_comped_${noteId}`, newCompedValue.toString());
    setPrevIsComped(newCompedValue);
  }
  
  // Call the API with the updated values
  updateAppointmentDetails(appointmentType, newCompedValue);
  
  // Also update the treatment object with new values if it exists
  if (treatment) {
    setTreatment(prev => ({
      ...prev,
      isComped: newCompedValue
    }));
  }
};

  
  const supplementsSortingFunction = (items) => {
    const filledItems = items.filter((item) => item.checked).sort((a, b) => a.originalOrder - b.originalOrder);
    const unfilledItems = items.filter((item) => !item.checked).sort((a, b) => a.originalOrder - b.originalOrder);
    return [...filledItems, ...unfilledItems];
  };
  const fetchAllSupplements = async (treatment) => {
    try {
      const response = await axios.get("/getAllSupplements");
      
      const updatedSupplements = treatment?.userSupplements.map((supp) => {
        const matchedSupplement = response.data.find(
          (supplement) => supplement.supplement_name === supp.name
        );
        
        if (matchedSupplement) {
          return {
            ...supp,
            frequencyOptions: [...matchedSupplement.frequency],
          };
        }
        return supp;
      });
      
      const updatedTreatment = {
        ...treatment,
        userSupplements: supplementsSortingFunction([...updatedSupplements])
      };
      
      return updatedTreatment;
    } catch (error) {
      console.error("Error fetching supplements:", error);
      // setShowModal(false);
    }
  };

  const fetchTreatment = async (treatmentId) => {
    try {
      const response = await axios.get(
        `/orderget/${treatmentId}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("sessionToken")}` },
        }
      )
      if (response.status === 200 || response.status === 201) {
        // if(response.data?.treatments?.length <= 0){
        //   setShowLPDV(true);
        // }
        setAppointmentType(response.data?.appointmentType);
        setIsComped(response.data?.isComped);
        setTreatment(response.data)
        setShowLPDV(true);
        if(activeTreatment){
          const treatmentfound= response?.data?._id===activeTreatment._id
          if(treatmentfound){
            const updatedTreatment= await fetchAllSupplements(treatmentfound)
            setActiveTreatment(updatedTreatment);
          }
        }
      }

    }
    catch (error) {
      console.error("Error fetching current date orders ", error)
    }
  }

  function isTagEmpty(html) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    tempDiv.querySelectorAll("br").forEach(br => br.remove());
    const content = tempDiv.textContent.trim();
    return content === "";
  }

  function shouldStopExecution(subjective, objective, assessment, planNote, careplan, medications) {
    const fields = [subjective, objective, assessment, planNote, careplan, medications];

    // Check if all fields are empty
    return fields.every(field => !field || isTagEmpty(field));
  }

  const dataReset=(treatmentId="")=>{
    // fetchCurrentDateOrders(notesData?.createdAt);
    if(treatmentId){
      fetchTreatment(treatmentId);
    }
    else{
      fetchTreatment(notesData?.treatmentId);
    }
  }

  const MessageIcon = ({ patient }) => {

    const openModal = () => {
      setShowMessagesModal(true);
    };

    const closeModal = () => {
      setShowMessagesModal(false);
    };
    const unreadCount = patient?.messages?.filter(
      (msg) => msg.readStatus === false
    ).length;

    console.log(unreadCount);
    return (
      <>
        <Badge count={unreadCount} overflowCount={99} offset={[-5, 5]}>
          <img
            src={smsIcon}
            alt="sms"
            style={{ width: "36px", height: "36px", background: "none", cursor: "pointer" }}
            onClick={openModal}
          />
        </Badge>
        {showMessagesModal && <Messages patient={patient} onClose={closeModal} />}
      </>
    );
  };

  const closeModal = () => setPrintModalVisible(false);

  const soapNotes = {
    subjective,
    medicationsNote,
    objective,
    assessment,
    careplan,
    planNote
  }

  const handleUpdateCustomName = async () => {
    try {
      const response = await axios.post(`/update-soap-note-custom-name`, {
        noteId,
        patientId,
        customName
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
        }
      });
      setEditCustomName(false)
    } catch (error) {
      console.error("Error saving EMR data:", error);
    }
  }

  const [activeTreatmentId, setActiveTreatmentId] = useState(false)

  const createNewTreatment = async (type) => {
    setNewOrderLoading(true)
    let payload = {}
    if (notesData) {
      payload = {
        date: dayjs.utc(notesData?.createdAt).format('YYYY-MM-DD'),
        createdFromView:"emr",
        customName: notesData?.customName ? notesData?.customName : ""
      }
    }
    else {
      payload = {
        date: dayjs.utc().format('YYYY-MM-DD'),
        createdFromView:"emr",
        customName: notesData?.customName ? notesData?.customName : ""
      }
    }
    try {
      const response = await axios.post(
        `/api/patient/order/new/${patientProfile["Customer ID"]}/${type}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        message.success(`New ${type.toLowerCase()} created successfully`);
        setTreatment(response.data)
        setNewOrderLoading(false)
        setCreateOrderLoader(false)
        return response.data; 
      } else {
        setNewOrderLoading(false)
        setCreateOrderLoader(false)
        throw new Error(`Failed to create new ${type.toLowerCase()}`);
      }
    } catch (error) {
      console.error(`Error creating new ${type.toLowerCase()}:`, error);
      message.error(
        `Failed to create new ${type.toLowerCase()}: ${error.message}`
      );
      setNewOrderLoading(false)
      setCreateOrderLoader(false)
    }
  };

  const fetchDefaultLPD = async () => {
    setNewOrderLoading(true)
    try {
      const response = await axios.get(
        `/api/patient/order/default-items`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        setTreatment({ 
              userLabs:response.data.labs,
              userProcedures:response.data.procedures,
              userdispensed:response.data.dispensed,
              userSupplements:response.data.supplements,
            });
        
        setNewOrderLoading(false)
      } else {
        throw new Error(`Failed to create new order`);
        setNewOrderLoading(false)
      }
    } catch (error) {
      console.error(`Error creating new order:`, error);
      message.error(
        `Failed to create new order: ${error.message}`
      );
      setNewOrderLoading(false)
    }
  };



  const getDefaultContent = (type, name, dosage, frequency) => {
    switch (type) {
      case "procedure":
        return `<p>The procedure <strong>${name}</strong> was administered to the patient</p>`;
      case "dispense":
        return `<p>The <strong>${name}</strong> was dispensed to the patient</p>`;
      case "supplement":
        return `${name} ${(dosage !== "" && dosage !== undefined && dosage !== null) && `(${dosage})`} ${frequency && `(${frequency})`} `;
      case "lab":
        return `<p>The <strong>${name}</strong> lab was drawn</p>`;
      case "vital":
        return `<p>The patient's vitals were monitored today</p></br>`;
      default:
        return "";
    }
  };

  const filterProcedures = () => {
    return treatment.userProcedures?.filter(
      (item) =>
        (item.checked && (!item.dosages || item.dosages?.length === 0)) ||
        (item.dosages && item.dosages?.length > 0 && !!item.selectedField)
    ) || [];
  };

  useEffect(() => {
    const fetchSoapNote = async () => {
      if (!noteId) {
        setTimeout(() => {
          resetPlanNotes();
          resetMedicationsNotes();
        }, 2000);
        return;
      }
  
      try {
        const response = await axios.get(`/get-soap-note/${noteId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        });
  
        if (response.status === 200) {
          const soapNote = response.data?.soapNote;
          
          setMedicationsNote(soapNote?.medicationsNote || "");
          setPlanNote(soapNote?.plan?.note || "");
          setPlanNoteSaved(soapNote?.saved ?? false);
          setMedicationsNoteSaved(soapNote?.medicationsNoteSaved ?? false);
  
          if (soapNote.saved === false) {      
            setTimeout(resetPlanNotes, 2000);
          }
          if (soapNote.medicationsNoteSaved === false) {
            setTimeout(resetMedicationsNotes, 2000);
          }
        }
      } catch (error) {
        console.error("Error fetching SOAP note:", error);
      }
    };
  
    fetchSoapNote();
  }, [noteId, treatment]);
  

  const fetchSelectedTemplateByName = async (type, name, dosage, frequency) => {
    const url = `/api/soap-note-templates/type/${type}/name/${encodeURIComponent(name)}`;
    try {
      const response = await axios.get(
        url,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      return response.data.content;
    } catch (error) {
      return getDefaultContent(type, name, dosage,frequency);
    }
  };

  const savePlanNote = async (noteId,saveValue) => {
    try {
      const response = await axios.put(
        `/soap-note/saved/${noteId}`, {
          saved:saveValue
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
    } catch (error) {
      return console.log("Error:" , error)
    }
  };

  const saveMedicationsNote = async (noteId,saveValue) => {
    try {
      const response = await axios.put(
        `/soap-note/medications-note-saved/${noteId}`,
        { medicationsNoteSaved:saveValue },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
    } catch (error) {
      return console.log("Error:" , error)
    }
  };

  const isMounted = useRef(false);

  useEffect(()=>{
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
    if(!noteId && planNoteSaved){
      createdOrderId ? handleBlur(createdOrderId) : handleBlur()
    }
    if(noteId && !soapNoteDataBeingFetched){
      // createdOrderId && handleBlur(createdOrderId)
      savePlanNote(noteId,planNoteSaved)
    }
  },[planNoteSaved,soapNoteDataBeingFetched])

  useEffect(()=>{
    if(!noteId && medicationsNoteSaved){
      createdOrderId ? handleBlur(createdOrderId) : handleBlur()
    }
    if(noteId && !soapNoteDataBeingFetched){
        if(medicationsNoteSaved){
          saveMedicationsNote(noteId,medicationsNoteSaved)
        }
    }
  },[medicationsNoteSaved,soapNoteDataBeingFetched])


  const handlePlanNoteSaveChange = (saved) => {
    setPlanNoteSaved(!planNoteSaved)
  }

  const handleMedicationsNoteSaveChange = (saved) => {
    if(!medicationsNoteSaved===false){
      setMedicationsNoteSaved(!medicationsNoteSaved)
      saveMedicationsNote(noteId,!medicationsNoteSaved)
    }
    else if (!medicationsNoteSaved===true) {
      setMedicationsNoteSaved(!medicationsNoteSaved)
    }
  }
  const applyTemplate = (category, item, templateContent) => {
    if (!templateContent) return "";

    if (category === "custom") {
      return templateContent; // No need for replacements
    }
    switch (category) {
      case "procedure":
        templateContent = templateContent.replace(
          "{{procedure_name}}",
          item.procedure_name || ""
        );
        templateContent = templateContent.replace(
          "{{dosage}}",
          item.selectedField || "N/A"
        );
        templateContent = templateContent.replace(
          "{{notes}}",
          item.note || "N/A"
        );
        templateContent = templateContent.replace(
          "{{cusDosage}}",
          item.customDosage || "N/A"
        );
        break;
      case "dispense":
        templateContent = templateContent.replace(
          "{{item_name}}",
          item.name || ""
        );
        templateContent = templateContent.replace(
          "{{quantity}}",
          item.quantity.toString() || "0"
        );
        templateContent = templateContent.replace(
          "{{dosage}}",
          item.selectedField || "N/A"
        );
        templateContent = templateContent.replace(
          "{{notes}}",
          item.note || "N/A"
        );
        break;
        case "supplement":
        templateContent = templateContent.replace(
          "{{item_name}}",
          item.name || item.supplementName || ""
        );
        templateContent = templateContent.replace(
          "{{quantity}}",
          item?.quantity?.toString() || (item.supplementName && "1") || "0"
        );
        templateContent = templateContent.replace(
          "{{dosage}}",
          item.frequency || "N/A"
        );
        templateContent = templateContent.replace(
          "{{notes}}",
          item.note || "N/A"
        );
        break;
      case "lab":
        templateContent = templateContent.replace(
          "{{test_name}}",
          item.test_name || ""
        );
        templateContent = templateContent.replace(
          "{{item_number}}",
          item.item_number || ""
        );
        templateContent = templateContent.replace(
          "{{result}}",
          item.result ? "Positive" : "Negative"
        );
        break;
      case "vitals":
        const hr = item.HR != null ? item.HR.toString() : "0";
        const rr = item.RR != null ? item.RR.toString() : "0";
        const spo2 = item.SPO2 != null ? item.SPO2.toString() : "0";
        const t = item.T != null ? item.T.toString() : "0";
        const w = item.W != null ? item.W.toString() : "0";

        templateContent = templateContent.replace("{{time}}", item.time || "");
        templateContent = templateContent.replace("{{BP}}", item.BP || "");
        templateContent = templateContent.replace("{{HR}}", hr);
        templateContent = templateContent.replace("{{RR}}", rr);
        templateContent = templateContent.replace("{{SPO2}}", spo2);
        templateContent = templateContent.replace("{{T}}", t);
        templateContent = templateContent.replace("{{W}}", w);
        break;
      default:
        // Handle other cases or default behavior
        break;
    }

    return `<h3>${templateContent}</h3>`;
  };

  const generateProceduresContent = async () => {
    const filteredProcedures = filterProcedures();
    let tempNote = "";

    if (filteredProcedures?.length > 0) {
      const proceduresContent = await Promise.all(
        filteredProcedures.map(async (proc) => {
          const content = await fetchSelectedTemplateByName(
            "procedure",
            proc?.procedure_name,
            proc?.selectedField,
            proc?.frequency
          );
          return applyTemplate("procedure", proc, content);
        })
      ).then((contents) =>
        contents.map((content) => `<h3>${content}</h3>`).join("")
        
      );
      tempNote += `<div><h2><strong>Procedures</strong></h2>${proceduresContent}</br></div>`;
    }

    return tempNote;
  };

  const filterDispensedItems = () => {
    return treatment?.userdispensed?.filter(
      (item) =>
        (item.checked && (!item.dosages || item.dosages?.length === 0)) ||
        (item.dosages && item.dosages?.length > 0 && !!item.selectedField)
    ) || [];
  };

  const filterSupplementItems = () => {
    return treatment?.userSupplements?.filter(
      (item) => item.quantity > 0) || medications || [];
  };

  const generateDispensedItemsContent = async () => {
    // Logic to generate and return dispensed items content
    const filteredDispensedItems = filterDispensedItems();
    let tempNote = "";

    if (filteredDispensedItems?.length > 0) {
      const dispensedItemsContent = await Promise.all(
        filteredDispensedItems.map(async (item) => {
          const content = await fetchSelectedTemplateByName(
            "dispense",
            item.name,
            item.selectedField,
            item.frequency
          );
          return applyTemplate("dispense", item, content);
        })
      ).then((contents) =>
        contents.map((content) => `<h3>${content}</h3>`).join("")
      );
      tempNote += `<div><h2><strong>Dispensed Items</strong></h2>${dispensedItemsContent}</br></div>`;
    }

    return tempNote;
  };

  const generateSupplementsContent = async () => {
    // Logic to generate and return dispensed items content
    const filteredSupplements = filterSupplementItems();
    let tempNote = "";    
    if (filteredSupplements?.length > 0) {
      const supplementsContent = await Promise.all(
        filteredSupplements.map(async (item) => {
          const content = await fetchSelectedTemplateByName(
            "supplement",
            item.name || item.supplementName,
            item.selectedField,
            item.frequency
          );
          return applyTemplate("supplement", item, content);
        })
      ).then((contents) =>{
        return contents.map((content) => `<h3>${content}</h3>`).join("")
      }
      );
      tempNote += `<div><h2><strong>Supplements</strong></h2>${supplementsContent}</br></div>`;
    }

    return tempNote;
  };


  const fetchSpecificTemplate = async (category, itemName) => {
    try {
      const encodedItem = encodeURIComponent(itemName);
      const response = await axios.get(
        `/api/soap-note-templates/${category}/${encodedItem}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (response.data && response.data.templates) {
        const selectedTemplate = response.data.templates.find(
          (t) => t.selected
        );
        return selectedTemplate ? selectedTemplate.content : "";
      }
    } catch (error) {
      console.error("Error fetching template for", itemName, error);
      return "";
    }
  };

  const generateLabsContent = async () => {
    // Logic to generate and return labs content
    const filteredLabs = treatment?.userLabs?.filter((lab) => lab.checked) || [];
    let tempNote = "";

    if (filteredLabs?.length > 0) {
      const labsContent = await Promise.all(
        filteredLabs.map(async (lab) => {
          const content = await fetchSpecificTemplate(
            "lab",
            lab.test_name
          );
          return applyTemplate("lab", lab, content);
        })
      ).then((contents) =>
        contents.map((content) => `<h3>${content}</h3>`).join("")
        
      );
      tempNote += `<div><h2><strong>Laboratory Orders</strong></h2>${labsContent}</br></div>`;
    }

    return tempNote;
  };

  const generateDefaultContentForLPD = async () => {
    setPlanNoteLoader(true)
    setCurrentTreatmentLoading(true);
    let tempNote = "";
    let structure = [{ type: 'lab', enabled: true }, { type: 'procedure', enabled: true }, { type: 'dispense', enabled: true }];
    
    for (const element of structure) {
      if (!element.enabled) continue;

      switch (element.type) {
        case "procedure":
          tempNote += await generateProceduresContent();
          break;
        case "dispense":
          tempNote += await generateDispensedItemsContent();
          break;
        case "lab":
          tempNote += await generateLabsContent();
          break;
        default:
          break;
      }
    }
    if (tempNote === "") {
      // message.error("No data available to generate notes");
      return;
    }
    return (tempNote)
  };

  const generateDefaultContentForMedications = async () => {
    setMedicationsNoteLoader(true)
    setCurrentTreatmentLoading(true);
    let medicationsNote = "";
    let structure = [{ type: 'supplement', enabled: true }];
    
    for (const element of structure) {
      if (!element.enabled) continue;

      switch (element.type) {
        case "supplement":
          medicationsNote += await generateSupplementsContent();
          break;
        default:
          break;
      }
    }
    if (medicationsNote === "") {
      // message.error("No data available to generate notes");
      return;
    }
    return (medicationsNote)
  };

  const resetPlanNotes =async () => {    
    setTimeout(()=>{
          if(treatment!==null && treatment?.type !== undefined){
          generateDefaultContentForLPD().then((content) => {
            setPlanNote(content)
            setPlanNoteLoader(false)
             handleBlur(treatment._id, content, undefined);
          }).catch(()=>{
            setPlanNoteLoader(false)
          });
        }
        },500)
      }

      const resetMedicationsNotes =async () => {    
        setTimeout(()=>{
          if(treatment!==null && treatment?.type !== undefined){
            generateDefaultContentForMedications().then((content) => {
              setMedicationsNote(content)
              setMedicationsNoteLoader(false)             
              handleBlur(treatment._id, undefined, content );
            }).catch(()=>{
              setMedicationsNoteLoader(false)
            });
          }
        },500)
      }


  const fetchCustomTemplates = async () => {
    try {
      const response = await axios.get(
        "/api/templates/custom",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      setCustomTemplates(response.data.templates);
    } catch (error) {
      console.error("Error fetching custom templates:", error);
      message.error("Error fetching custom templates");
    }
  };


  useEffect(() => {
    if (selectedCategory === "custom") {
      fetchCustomTemplates(); // Fetch custom templates when 'custom' is selected
    }
  }, [selectedCategory]);

  const fetchShortcuts = async (query) => {
    if (!query) return; // Exit if the query is empty

    try {
      const response = await axios.get(
        `/api/soap-note-templates/shortcut/search/${query}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      setShortcuts(response.data);
      setShowShortcutsMenu(true);
    } catch (error) {
      console.error("Error fetching shortcuts:", error);
    }
  };

  const shortcutMenuRef = useRef(null);
  const soapNoteCreatedRef = useRef(false);

  const shortcutsMenu = (
    <Menu ref={shortcutMenuRef}>
      {shortcuts.map((shortcut, index) => (
        <Menu.Item
          key={index}
          onClick={() =>
            shortcutMenuRef.current && onShortcutMenuClick(shortcut.shortcut)
          }
        >
          {shortcut.shortcut}
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleLabsCheckboxChange = (labId, checked) => {
    
    treatment?.userLabs?.map((lab) => {
      if (lab._id === labId) {
        return { ...lab, checked: checked };
      }
      return lab;
    });
    
    const lab = treatment?.userLabs?.find((lab) => lab._id === labId);
    
    handleCellChange(
      treatment?._id,
      ``,
      checked,
      "patientLabs",
      lab,
      treatment?.review
    );
    
  };

  const handleChange = (itemData, itemName, key, value, type) => {
  
    if (type === "Procedures") {
       treatment?.userProcedures.map((procedure) => {
        if (procedure.procedure_name === itemName && key !== "dosages") {
          let updatedProcedure = { ...procedure, [key]: value };
      if (key === "checked" && (itemData.isNew=== true || itemData.custom===true)) {
        let updatedProcedureData = { ...itemData, editItemName: false, checked:value };
        itemData = updatedProcedureData;
        handleCellChange(
          treatment._id,
          `${itemName}-selectedField`,
          itemData.selectedField,
          "userProcedures",
          itemData,
        );
      }
      else if (key==="checked" && (!itemData.isNew && !itemData.isCustom)){
        let updatedProcedureData = { ...itemData, editItemName: false, checked:value };
        itemData = updatedProcedureData;

        handleCellChange(
          treatment._id,
          `${itemName}-selectedField`,
          value?itemData.dosages[0]:"",
          "userProcedures",
          itemData,
        );
      }
      
      if ((key === "selectedField" && (value === "Empty" || value === "" ) && (!itemData?.isNew && !itemData?.custom) )) {
        // Remove the procedure from active procedures
        updatedProcedure = {
          ...updatedProcedure,
          selectedField: "",
          checked: false,
        }; // Uncheck the checkbox
        handleCellChange(
          treatment._id,
          `${itemName}-checked`,
          false,
          "userProcedures",
          itemData,
        );
      } else if (key === "selectedField" && value !== "" && (!itemData.isNew && !itemData.custom)) {
        // Mark the procedure as checked when a dosage is selected
        updatedProcedure.checked = true;
        handleChange(
          procedure,
          procedure.procedure_name,
          "checked",
          true,
        );
      }
          if ( !noteId && !soapNoteCreatedRef.current &&(updatedProcedure.checked || updatedProcedure.selectedField)) {
            console.log(" lpd pop up called")
            // handleBlur();
            setsoapNoteCreatedFromLPD(true);
            soapNoteCreatedRef.current = true;
          }
          return updatedProcedure;
        }
        return procedure;
      });
    } else if (type === "Dispensed") {
      treatment?.userdispensed.map((item) => {
        if (item.name === itemName) {
          let isFilled = key === "selectedField" ? !!value : item.isFilled;
          let updatedItem = { ...item, [key]: value, isFilled };
      if(key === "quantity" && itemData.isNew && value){
        let updatedProcedureData = { ...itemData, editItemName: false }; 
        itemData = updatedProcedureData; 
      }

          if (key === "quantity") {
            updatedItem.quantity = value
            updatedItem.checked = value > 0 ? true : false
            if (itemData?.isNew === true || item?.custom === true) {
              updatedItem.selectedField = itemData.selectedField
              handleCellChange(
                treatment._id,
                `${itemName}-selectedField`,
                itemData.selectedField,
                "userdispensed",
                itemData
              )
            }
            else {
              updatedItem.selectedField = value > 0 ? itemData.selectedField ? itemData.selectedField : itemData.dosages[0] : ""
              handleCellChange(
                treatment._id,
                `${itemName}-selectedField`,
                value > 0 ? itemData.selectedField ? itemData.selectedField : itemData.dosages[0] : "",
                "userdispensed",
                itemData
              );
            }
          }
          if (key === "selectedField" && (!itemData.isNew || !itemData.custom)) {
            updatedItem.quantity = value ? itemData.quantity > 0 ? itemData.quantity : 1 : 0;
            updatedItem.checked = value ? true : false;
            if (itemData?.editItemName !== false || (itemData.custom && itemData?.custom !== true)) {
              handleCellChange(
                treatment._id,
                `${itemName}-quantity`,
                updatedItem.quantity,
                "userdispensed",
                itemData
              );
            }
          }
          return updatedItem;
        }
        return item;
      });
    }
    else if (type === "Supplements") {
      treatment.userSupplements.map((item) => {
        if (item.name === itemName) {
          let updatedItem = { ...item, [key]: value };
  
          if(key === "quantity" && itemData.isNew && value){
            let updatedSupplementsData = { ...itemData, editItemName: false }; 
            itemData = updatedSupplementsData; 
          }
          if (key === "n" ) {
            updatedItem.checked = value ? true : false;
            updatedItem.isNew= false
            updatedItem.custom= true
          }
  
          if (key === "quantity") {
            updatedItem.quantity = value
            updatedItem.checked = value > 0 ? true : false
            if (itemData?.isNew === true || item?.custom === true) {
              updatedItem.selectedField = itemData.selectedField
              handleCellChange(
                treatment._id,
                `${itemName}-selectedField`,
                itemData.selectedField,
                "userSupplements",
                itemData
              )}
              else{
              updatedItem.selectedField = value > 0 ? itemData.dosages[0] : ""
              handleCellChange(
                treatment._id,
                `${itemName}-selectedField`,
                value>0?item.dosages[0]:"",
                "userSupplements",
                itemData
              );
            }
          }
  
          if (key === "selectedField" && (!itemData.isNew || !itemData.custom)) {
            updatedItem.quantity = value ? itemData.quantity> 0 ? itemData.quantity : 1 : 0;
            updatedItem.checked = value ? true : false;          
            if(itemData?.editItemName !== false || (itemData.custom && itemData?.custom !== true)){
            handleCellChange(
              treatment._id,
              `${itemName}-quantity`,
              updatedItem.quantity,
              "userSupplements",
              itemData
            );
          }
          }
          return updatedItem;
        }
        return item;
      });
    }

    setTimeout(() => {
      if (type === "Procedures") {
        handleCellChange(
          treatment?._id,
          `${itemName}-${key}`,
          value,
          "userProcedures",
          itemData,
          treatment?.review
        );
      } else if (type === "Dispensed") {
        handleCellChange(
          treatment?._id,
          `${itemName}-${key}`,
          value,
          "userdispensed",
          itemData
        );
      } else if (type === "Supplement") {
        handleCellChange(
          treatment?._id,
          `${itemName}-${key}`,
          value,
          "userSupplements",
          itemData
        );
      }
    }, 1000);
  };
  const searchLPDItem = async (type, name) => {
    const itemType= type==='dispensed'? 'dispense': type==='labs'?'lab':type
    try {
      const response = await axios.post("/search-lpd-item", { type:itemType, name, orderId:treatment._id }, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      
      if (!response.status=== 200) {
        throw new Error("Item not found or an error occurred");
      }
  
      const data = await response.data;
      
      return data.item; 
    } catch (error) {
      console.error("Error searching item:", error);
      throw error; 
    }
  };

  const onShortcutMenuClick = async (selectedShortcut) => {
    const editor = quillRef.current.getEditor();
    const selectedTemplate = shortcuts.find(
      (s) => s.shortcut === selectedShortcut
    );

    let itemDetail = {};
    if (selectedTemplate?.type !== 'custom') {
      console.log('Template details:', selectedTemplate);

      if (!selectedTemplate?.type || !selectedTemplate?.name) {
        console.error('Invalid template type or name:', selectedTemplate);
      } else {
        try {
          itemDetail = await searchLPDItem(selectedTemplate?.type, selectedTemplate.name);
          console.log('Item detail:', itemDetail);
        } catch (error) {
          console.error('Error fetching item detail:', error);
        }
      }
    }

    if (itemDetail) {
      if (selectedTemplate.type === 'procedure') {
        handleChange(
          itemDetail,
          itemDetail.procedure_name,
          "selectedField",
          itemDetail.dosages[0],
          "Procedures"
        )
        handleChange(
          itemDetail,
          itemDetail.procedure_name,
          "checked",
          true,
          "Procedures"
        );
      } else if (selectedTemplate.type === 'dispense') {
        handleChange(
          itemDetail,
          itemDetail.name,
          "selectedField",
          itemDetail.dosages[0],
          "Dispensed"
        )
        handleChange(
          itemDetail,
          itemDetail.name,
          "quantity",
           1,
           "Dispensed"
        )
      } else if (selectedTemplate.type === 'supplement') {
        handleChange(
          itemDetail,
          itemDetail.name,
          "selectedField",
          itemDetail.dosages[0],
          "Supplement"
        )
        handleChange(
          itemDetail,
          itemDetail.name,
          "quantity",
           1,
           "Supplement"
        )
      } else if (selectedTemplate.type === 'lab'){
        handleLabsCheckboxChange(itemDetail._id,true)
      }
    }

    if (selectedTemplate && editor) {
      let templateContent = selectedTemplate.templates[0].content;
      let itemData;

      if (selectedTemplate.type !== "custom") {
        const itemType = selectedTemplate.type === 'dispense'? 'dispensed' : selectedTemplate.type
        itemData = findItemData(itemType, selectedTemplate.name);
        templateContent = applyTemplate(
          selectedTemplate.type,
          itemData,
          templateContent
        );
      }
      
      const dotShortcutRegex = /\.\w+$/;
      const match = editor
        .getText()
        .substring(0, cursorPosition)
        .match(dotShortcutRegex);
      if (match && match.index !== undefined) {
        const startIndex = match.index;
        editor.deleteText(startIndex, match[0]?.length); // Remove the ".text"
        editor.clipboard.dangerouslyPasteHTML(startIndex, templateContent); // Insert the template
        setPlanNote(editor.root.innerHTML); // Update the editor content in the state
      }
    }

    setShowShortcutsMenu(false);
  };


  const formatVitalString = (vital) => {
    const vitalParts = [];
    if (vital.HR !== 0) vitalParts.push(`</br>HR: ${vital.HR}`);
    if (vital.BP !== "") vitalParts.push(`</br>BP: ${vital.BP}`);
    if (vital.T !== 0) vitalParts.push(`</br>T: ${vital.T}`);
    if (vital.W !== 0) vitalParts.push(`</br>W: ${vital.W}`);
    if (vital.RR !== 0) vitalParts.push(`</br>RR: ${vital.RR}`);
    if (vital.SPO2 !== 0) vitalParts.push(`</br>SPO2: ${vital.SPO2}`);

    return vitalParts?.length > 0
      ? `Time: ${vital.time}, ${vitalParts.join(", ")} </br></br>`
      : null;
  };
  const parseVitalInfo = (vitalString) => {
    const vitalInfo = {};
    const regex =
      /Time: (.*), BP: (.*), HR: (.*), RR: (.*), SPO2: (.*), T: (.*), W: (.*)/;
    const matches = vitalString.match(regex);

    if (matches) {
      vitalInfo.time = matches[1];
      vitalInfo.BP = matches[2] || "";
      vitalInfo.HR = matches[3] !== "null" ? matches[3] : "0";
      vitalInfo.RR = matches[4] !== "null" ? matches[4] : "0";
      vitalInfo.SPO2 = matches[5] !== "null" ? matches[5] : "0";
      vitalInfo.T = matches[6] !== "null" ? matches[6] : "0";
      vitalInfo.W = matches[7] !== "null" ? matches[7] : "0";
    }

    return vitalInfo;
  };

  const formatVitalStringItemsMenu = (vital) => {
    // Format the string to match the format of `selectedItem`
    return `Time: ${vital.time}, BP: ${vital.BP}, HR: ${vital.HR}, RR: ${vital.RR}, SPO2: ${vital.SPO2}, T: ${vital.T}, W: ${vital.W}`;
  };

  const onItemsMenuClick = async (selectedItem) => {
    const editor = quillRef.current.getEditor();
    let templateContent = "";
    
    let itemDetail = {};
    if (selectedCategory !== 'custom') {
      // console.log('Template details:', selectedTemplate);

      if (!selectedCategory || !selectedItem) {
        console.error('Invalid template type or name:', selectedCategory, selectedItem);
      } else {
        try {
          itemDetail = await searchLPDItem(selectedCategory, selectedItem);
          console.log('Item detail:', itemDetail);
        } catch (error) {
          console.error('Error fetching item detail:', error);
        }
      }
    }

    if (itemDetail) {
      if (selectedCategory === 'procedure') {
        handleChange(
          itemDetail,
          itemDetail.procedure_name,
          "selectedField",
          itemDetail.dosages[0],
          "Procedures"
        )
        handleChange(
          itemDetail,
          itemDetail.procedure_name,
          "checked",
          true,
          "Procedures"
        );
      } else if (selectedCategory === 'dispensed') {
        handleChange(
          itemDetail,
          itemDetail.name,
          "selectedField",
          itemDetail.dosages[0],
          "Dispensed"
        )
        handleChange(
          itemDetail,
          itemDetail.name,
          "quantity",
           1,
           "Dispensed"
        )
      } else if (selectedCategory === 'supplement') {
        handleChange(
          itemDetail,
          itemDetail.name,
          "selectedField",
          itemDetail.dosages[0],
          "Supplement"
        )
        handleChange(
          itemDetail,
          itemDetail.name,
          "quantity",
           1,
           "Supplement"
        )
      } else if (selectedCategory === 'labs'){
        handleLabsCheckboxChange(itemDetail._id,true)
      }
    }
    if (selectedCategory === "custom") {
      templateContent = await fetchSpecificTemplate("custom", selectedItem);
      const currentCursorPos = cursorPosition;
      editor.deleteText(currentCursorPos - 2, 2); // Remove ".."
      editor.clipboard.dangerouslyPasteHTML(
        currentCursorPos - 2,
        `<h3>${templateContent}</h3>` || ""
      );
    } else {
      setPlanNote(editor.root.innerHTML);

      switch (selectedCategory) {
        case "procedure":
          const procedure = treatment.userProcedures.find(
            (proc) => proc.procedure_name === selectedItem
          );
          templateContent = await fetchSpecificTemplate(
            "procedure",
            procedure.procedure_name
          );
          break;
        case "dispensed":
          const dispensedItem = treatment.userdispensed.find(
            (item) => item.name === selectedItem
          );
          templateContent = await fetchSpecificTemplate(
            "dispense",
            dispensedItem.name
          );
          break;
        case "supplement":
          const supplementItem = treatment.userSupplements.find(
            (item) => item.name === selectedItem
          );
          templateContent = await fetchSpecificTemplate(
            "supplement",
            supplementItem.name
          );
          break;
        case "labs":
          const labTest = treatment.userLabs.find(
            (lab) => lab.test_name === selectedItem
          );
          templateContent = await fetchSpecificTemplate(
            "lab",
            labTest.test_name
          );
          break;
        case "vitals":
          const vitalInfo = parseVitalInfo(selectedItem);
          const selectedVital = treatment.vitals.find((vital) => {
            return (
              vital.time === vitalInfo.time &&
              vital.BP === vitalInfo.BP &&
              (vital.HR !== null ? vital.HR.toString() : "0") ===
              vitalInfo.HR &&
              (vital.RR !== null ? vital.RR.toString() : "0") ===
              vitalInfo.RR &&
              (vital.SPO2 !== null ? vital.SPO2.toString() : "0") ===
              vitalInfo.SPO2 &&
              (vital.T !== null ? vital.T.toString() : "0") === vitalInfo.T &&
              (vital.W !== null ? vital.W.toString() : "0") === vitalInfo.W
            );
          });
          if (selectedVital) {
            templateContent = await fetchSpecificTemplate(
              "vital",
              formatVitalStringItemsMenu(selectedVital)
            );
          }
          break;
        default:
          break;
      }

      if (templateContent) {
        let itemData;
        switch (selectedCategory) {
          case "procedure":
            itemData = treatment.userProcedures.find(
              (proc) => proc.procedure_name === selectedItem
            );
            break;
          case "dispensed":
            itemData = treatment.userdispensed.find(
              (item) => item.name === selectedItem
            );
            break;
          case "supplement":
            itemData = treatment.userSupplements.find(
              (item) => item.name === selectedItem
            );
            break;
          case "labs":
            itemData = treatment.userLabs.find(
              (lab) => lab.test_name === selectedItem
            );
            break;
          case "vitals":
            const vitalInfo = parseVitalInfo(selectedItem);
            itemData = treatment.vitals.find((vital) => {
              return (
                vital.time === vitalInfo.time &&
                vital.BP === vitalInfo.BP &&
                (vital.HR !== null ? vital.HR.toString() : "0") ===
                vitalInfo.HR &&
                (vital.RR !== null ? vital.RR.toString() : "0") ===
                vitalInfo.RR &&
                (vital.SPO2 !== null ? vital.SPO2.toString() : "0") ===
                vitalInfo.SPO2 &&
                (vital.T !== null ? vital.T.toString() : "0") === vitalInfo.T &&
                (vital.W !== null ? vital.W.toString() : "0") === vitalInfo.W
              );
            });
            break;
          default:
            itemData = {};
            break;
        }
        const statement = applyTemplate(
          selectedCategory,
          itemData,
          templateContent
        );
        editor.deleteText(cursorPosition - 2, 2); // Remove ".."
        editor.clipboard.dangerouslyPasteHTML(cursorPosition - 2, statement); // Insert the statement
        setPlanNote(editor.root.innerHTML); // Update the editor content in the state
      }
    }
    setSearchText("");
    setPlanNote(editor.root.innerHTML);
    setShowItemsMenu(false);
  };


  const updateDropdownPosition = () => {
    const editor = quillRef.current.getEditor();
    const range = editor.getSelection(false);
    if (range && range?.length === 0) {
      const editorContainer = editor.container.getBoundingClientRect();
      const bounds = editor.getBounds(range.index);

      // Vertical adjustment: Add a small offset to align with ".."
      const scrollTop = editor.scrollingContainer.scrollTop;
      const topOffset = bounds.top - scrollTop + 150; // Adjust '5' as needed for alignment

      // Horizontal position remains the same
      const scrollLeft = editorContainer.left;
      const leftOffset = bounds.left + scrollLeft - 80;

      setDropdownPosition({
        top: topOffset,
        left: leftOffset,
      });
    }
  };

  const findItemData = (type, name) => {
    let itemData;

    switch (type) {
      case "procedure":
        itemData = treatment.userProcedures.find(
          (proc) => proc.procedure_name === name
        );
        break;
      case "dispensed":
        itemData = treatment.userdispensed.find(
          (item) => item.name === name
        );
        break;
      case "supplement":
        itemData = treatment.userSupplements.find(
          (item) => item.name === name
        );
        break;
      case "labs":
        itemData = treatment.userLabs.find(
          (lab) => lab.test_name === name
        );
        break;
      case "vitals":
        itemData = treatment.vitals.find((vital) => {
          // The condition here depends on how you identify a unique vital. You might need to adjust this.
          return formatVitalString(vital).includes(name);
        });
        break;
      default:
        itemData = {};
        break;
    }
    return itemData;
  };
  const handleShortcutEnterPress = async (query) => {
    let response;
    try {
      // Try to find an exact match
      response = await axios.get(
        `/api/templates/shortcut/exact-search/${query}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
    } catch (error) {
      // If exact match fails (404), try finding the closest match
      if (error.response && error.response.status === 404) {
        try {
          response = await axios.get(
            `/api/templates/shortcut/closest-search/${query}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              },
            }
          );
        } catch (closestError) {
          console.error("Error fetching closest match shortcut:", closestError);
          return;
        }
      } else {
        console.error("Error fetching exact match shortcut:", error);
        return;
      }
    }

    // Continue with processing the response
    const editor = quillRef.current.getEditor();
    const dotShortcutRegex = /\.\w+$/;
    const match = editor
      .getText()
      .substring(0, cursorPosition)
      .match(dotShortcutRegex);

    if (response.data && match) {
      let templateContent = response.data.templates[0].content;
      let itemData;

      if (response.data.type !== "custom") {
        itemData = findItemData(response.data.type, response.data.name);
        templateContent = applyTemplate(
          response.data.type,
          itemData,
          templateContent
        );
      }

      const startIndex = cursorPosition - match[0]?.length;
      editor.deleteText(startIndex, match[0]?.length); // Remove the ".text"
      editor.clipboard.dangerouslyPasteHTML(startIndex, templateContent); // Insert the template
      setPlanNote(editor.root.innerHTML); // Update the editor content in the state
    }
  };
  const onChangePlanNote = (content, delta, source, editor) => {
    setPlanNote(content);

    const currentText = editor.getText();
    const cursorPos = editor.getSelection()?.index;

    // Check for '..' functionality
    if (
      currentText &&
      cursorPos &&
      currentText.substring(cursorPos - 2, cursorPos) === ".."
    ) {
      setShowMenu(true);
      setShowShortcutsMenu(false);
      setCursorPosition(cursorPos);
      updateDropdownPosition();
    } else {
      setShowMenu(false);
    }

    // Regular expression to find '.word'
    const dotShortcutRegex = /\.\w+$/;

    const matchDotShortcut = currentText
      .substring(0, cursorPos)
      .match(dotShortcutRegex);

    if (matchDotShortcut && source === "user") {
      if (delta.ops?.length === 2 && delta.ops[1].insert === "\n") {
        // User pressed Enter after '.text'
        const query = matchDotShortcut[0].substring(1); // Remove the dot and get the query
        handleShortcutEnterPress(query); // Handle the Enter key press for shortcut
        setShowShortcutsMenu(false);
        return; // Prevent further processing
      }

      // Handle single dot functionality for other cases
      const query = matchDotShortcut[0].substring(1); // Remove the dot and get the query
      fetchShortcuts(query); // Fetch shortcuts with the query
      setShowShortcutsMenu(true);
      setCursorPosition(cursorPos);
      updateDropdownPosition();
    } else {
      setShowShortcutsMenu(false);
    }
  };


  const formatVitals = () => {
    const formattedVitals = treatment.vitals.map((vital, index) => {
      return `Time: ${vital.time}, BP: ${vital.BP}, HR: ${vital.HR}, RR: ${vital.RR}, SPO2: ${vital.SPO2}, T: ${vital.T}, W: ${vital.W}`;
    });
    return formattedVitals;
  };

  const onCustomTemplateClick = async (templateName) => {
    const templateContent = await fetchSpecificTemplate("custom", templateName);
    const editor = quillRef.current.getEditor();
    const currentCursorPos = cursorPosition;
    editor.deleteText(currentCursorPos - 2, 2); // Remove ".."
    editor.clipboard.dangerouslyPasteHTML(
      currentCursorPos - 2,
      templateContent || ""
    );
    setPlanNote(editor.root.innerHTML);
    setShowItemsMenu(false);
  };
  const onMenuClick = (e) => {
    const selectedOption = e.key;
    setSelectedCategory(selectedOption);

    if (selectedOption === "custom") {
      setShowItemsMenu(true); // Show items menu for custom templates
      setShowMenu(false);
    } else {
      let items = [];

      switch (selectedOption) {
        case "procedure":
          items = treatment.userProcedures.map(
            (item) => item.procedure_name
          );
          break;
        case "dispensed":
          items = treatment.userdispensed.map((item) => item.name);
          break;
        case "supplement":
          items = treatment.userSupplements.map((item) => item.name);
          break;
        case "labs":
          items = treatment.userLabs.map((item) => item.test_name);
          break;
        case "vitals":
          items = formatVitals();
          break;
        default:
          break;
      }

      setItemsForSelection(items);
      setShowItemsMenu(true);
      setShowMenu(false);
    }
  };
  const menu = (
    <Menu onClick={onMenuClick}>
      <Menu.Item key="procedure">Procedure</Menu.Item>
      <Menu.Item key="supplement">Supplement</Menu.Item>
      <Menu.Item key="dispensed">Dispensed</Menu.Item>
      <Menu.Item key="labs">Labs</Menu.Item>
    </Menu>
  );

  const customTemplatesMenu = (
    <Menu>
      {customTemplates.map((template, index) => (
        <Menu.Item
          key={index}
          onClick={() => onCustomTemplateClick(template.name)}
        >
          {template.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const getFilteredItemsForSelection = () => {
    if (!searchText) return itemsForSelection;
    return itemsForSelection.filter((item) =>
      item.toLowerCase().includes(searchText.toLowerCase())
    );
  };
  const itemsMenu = (
    <Menu>
      <Menu.Item key="search" disabled>
        <input
          type="text"
          placeholder="Search..."
          value={searchText}
          onChange={handleSearchChange}
          style={{ width: "100%", padding: "5px", color: "black" }}
        />
      </Menu.Item>
      <div
        style={{
          maxHeight: "200px",
          overflowY: "auto",
          scrollbarColor: "blue lightblue",
        }}
      >
        {getFilteredItemsForSelection().map((item, index) => {
          return (
            <Menu.Item key={index} onClick={() => onItemsMenuClick(item)}>
              {item}
            </Menu.Item>
          );
        })}
      </div>
    </Menu>
  );

  const fetchAllMedications = async () => {
    try {
      const response = await axios.get(`/emr/get-medications/${patientProfile._id}`);
      console.log('response of emr api', response.data)
      setMedications(response.data)
    } catch (error) {
      console.error("Error saving EMR data:", error);
    }
  }

  function splitByLastDash(columnName) {
    // Find the index of the last dash
    const lastIndex = columnName.lastIndexOf("-");

    // If there's no dash, or the dash is the first character, return an error or handle it as needed
    if (lastIndex <= 0) {
      console.error(
        "No dash found, or the dash is at the start of the string"
      );
      return columnName;
    }

    // Split the string into two parts
    const itemName = columnName.substring(0, lastIndex);
    const field = columnName.substring(lastIndex + 1);

    return [itemName, field];
  }
 const itemsSortingFunction = (items, itemType) => {
      // Define the dynamic keys for each item type
      let filledCondition, emptyCondition;

      switch (itemType) {
        case "procedures":
          filledCondition = (item) =>
            (item.selectedField && item.selectedField !== "Empty") ||
            item.checked;
          emptyCondition = (item) =>
            (!item.selectedField || item.selectedField === "Empty") &&
            !item.checked;
          break;

        case "labs":
          filledCondition = (item) => item.checked;
          emptyCondition = (item) => !item.checked;
          break;

        case "dispensed":
          filledCondition = (item) =>
            (item.selectedField && item.selectedField !== "Empty") ||
            item.quantity > 0;
          emptyCondition = (item) =>
            (!item.selectedField || item.selectedField === "Empty") &&
            item.quantity === 0;
          break;

        case "supplements":
          filledCondition = (item) =>
            (item.selectedField && item.selectedField !== "Empty") ||
            item.checked;
          emptyCondition = (item) =>
            (!item.selectedField || item.selectedField === "Empty") &&
            !item.checked;
          break;

        default:
          return items; // If the item type is not recognized, return the original array.
      }

      const filledItems = items
        .filter(filledCondition)
        .sort((a, b) => a.originalOrder - b.originalOrder);

      const unfilledItems = items
        .filter(emptyCondition)
        .sort((a, b) => a.originalOrder - b.originalOrder);

      return [...filledItems, ...unfilledItems];
    };
  const containerRef = useRef(null);
  const adjustedLeft = Math.min(dropdownPosition.left, window.innerWidth - '400px');

  let pendingTreatmentPromise = null; 
  let orderCreationInProgress = false; 
  
  const updateNonCustomSupplements = async (supplementsArray, treatmentId) => {
    const nonCustomSupplements = supplementsArray.filter(supplement => !supplement.isCustom);    
    let updatedTreatmentId = treatmentId;
    
    if (!updatedTreatmentId && nonCustomSupplements.length>0) {
      try {
        setPlanNoteLoader(true);
        setLPDIconButtonsLoader(true);
        const newTreatment = await createNewTreatment("Order");
        if (newTreatment && newTreatment._id) {
          updatedTreatmentId = newTreatment._id;
          setCreatedOrderId(newTreatment._id);
          !noteId && newTreatment._id && handleBlur(newTreatment._id)
          setPlanNoteLoader(false)
          setLPDIconButtonsLoader(false);
        } else {
          console.error("Failed to create a new treatment order.");
          setPlanNoteLoader(false)
          setLPDIconButtonsLoader(false);
          return;
        }
      } catch (error) {
        console.error("Error creating new treatment:", error);
        setPlanNoteLoader(false)
        setLPDIconButtonsLoader(false);
        return;
      }
    }
  
    await Promise.all(
      nonCustomSupplements.map(supplement =>
        handleCellChange(
          updatedTreatmentId,
          `${supplement.supplementName}-quantity`,
          1,
          "userSupplements",
          supplement
        )
      )
    );
  };
  


  // const handleCellChange = async (
  //   treatmentId,
  //   columnName,
  //   newValue,
  //   category,
  //   itemData,
  //   review
  // ) => {
  //   let updatedTreatmentId = treatmentId || createdOrderId;
  
  //   if (!updatedTreatmentId) {
  //     if (!orderCreationInProgress) {
  //       setCreateOrderLoader(true); 
  //       orderCreationInProgress = true;
  
  //       pendingTreatmentPromise = createNewTreatment("Order")
  //         .then((newTreatment) => {
  //           if (newTreatment && newTreatment._id) {
  //             setCreatedOrderId(newTreatment._id);
  //             newTreatment._id && handleBlur(newTreatment._id)
  //             return newTreatment._id;
  //           }
  //           throw new Error("Failed to retrieve new treatment orderId.");
  //         })
  //         .catch((error) => {
  //           console.error("Error creating new treatment:", error);
  //           return null;
  //         })
  //         .finally(() => {
  //           orderCreationInProgress = false; 
  //           pendingTreatmentPromise = null; 
  //           setCreateOrderLoader(false); 
  //         });
  //     }
  
  //     updatedTreatmentId = await pendingTreatmentPromise;
  //     if (!updatedTreatmentId) return;
  //   }
  // if(updatedTreatmentId){
  //   let payload;
  //   if (category === "vitals") {
  //     payload = { category, updates: newValue };
  //   } else if (category === "patientLabs") {
  //     payload = {
  //       category,
  //       updates: {
  //         checked: newValue,
  //         type: "item_number",
  //         value: itemData.item_number,
  //       },
  //     };
  //   } else if (category === "newpatientLab") {
  //     payload = {
  //       category: "patientLabs",
  //       newData: {
  //         checked: newValue,
  //         isNew: true,
  //         test_name: itemData?.test_name,
  //       },
  //     };
  //   } else {
  //     const identifierKey =
  //       category === "userProcedures" ? "procedure_name" : "name";
  //     let [itemName, field] = splitByLastDash(columnName);
  
  //     if (category === "patientInfo") field = columnName;
  
  //     payload = {
  //       category,
  //       identifier: { key: identifierKey, value: itemName },
  //       updates: { [field]: newValue },
  //       newData: itemData,
  //     };
  //   }
  
  //   axios
  //     .put(
  //       `/patient/order/update/${updatedTreatmentId}`,
  //       payload,
  //       {
  //         headers: { Authorization: `Bearer ${localStorage.getItem("sessionToken")}` },
  //       }
  //     )
  //     .then(() => {
  //       dataReset(updatedTreatmentId);
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });}
  // };
  


  const handleCellChange = async (
    treatmentId,
    columnName,
    newValue,
    category,
    itemData,
    review
  ) => {
    let updatedTreatmentId = treatmentId || createdOrderId;
    if (!updatedTreatmentId) {
      // If order is already being created, wait for it to resolve
      if (pendingTreatmentPromise) {
        updatedTreatmentId = await pendingTreatmentPromise;
      } else if (newOrderLoading === false) {
        setCreateOrderLoader(true);
        orderCreationInProgress = true;
        
        // Store the promise to prevent duplicate calls
        pendingTreatmentPromise = createNewTreatment("Order")
          .then((newTreatment) => {
            if (newTreatment && newTreatment._id) {
              setCreatedOrderId(newTreatment._id);
              // Pass current appointment type and comped status to handleBlur
              !noteId && handleBlur(
                newTreatment._id, 
                planNote, 
                medicationsNote, 
                appointmentType, 
                isComped
              );
              return newTreatment._id;
            }
            throw new Error("Failed to retrieve new treatment orderId.");
          })
          .catch((error) => {
            console.error("Error creating new treatment:", error);
            return null;
          })
          .finally(() => {
            orderCreationInProgress = false;
            pendingTreatmentPromise = null;
            setCreateOrderLoader(false);
          });
        
        updatedTreatmentId = await pendingTreatmentPromise;
      }
      
      if (!updatedTreatmentId) return;
    }
    
    if (updatedTreatmentId) {
      let payload;
      if (category === "vitals") {
        payload = { category, updates: newValue };
      } else if (category === "patientLabs") {
        payload = {
          category,
          updates: {
            checked: newValue,
            type: "item_number",
            value: itemData.item_number,
          },
        };
      } else if (category === "newpatientLab") {
        payload = {
          category: "patientLabs",
          newData: {
            checked: newValue,
            isNew: true,
            test_name: itemData?.test_name,
          },
        };
      } else {
        const identifierKey =
          category === "userProcedures" ? "procedure_name" : "name";
        let [itemName, field] = splitByLastDash(columnName);
        
        if (category === "patientInfo") field = columnName;
        
        payload = {
          category,
          identifier: { key: identifierKey, value: itemName },
          updates: { [field]: newValue },
          newData: itemData,
        };
      }
      
      axios
        .put(
          `/patient/order/update/${updatedTreatmentId}`,
          payload,
          {
            headers: { Authorization: `Bearer ${localStorage.getItem("sessionToken")}` },
          }
        )
        .then(() => {
          dataReset(updatedTreatmentId);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  

  const handleShowLPDV=(value)=>{
    setShowLPDV(value)
    if(value===true){
      fetchDefaultLPD()
    }
  }

  // This part of code is temporarily commented to disable synchronization between Order supplements and Patient Treatment Plan Supplements 
  // const handleSupplementCheckboxChange = (supId,frequency) => {
  //   const updatedSupplements= {
  //     ...selectedPatientSupplements,
  //     [supId]: selectedPatientSupplements[supId]
  //       ? { ...selectedPatientSupplements[supId], checked: true, frequency: frequency ? frequency : "" }
  //       : { checked: true, frequency: frequency ? frequency : "" },
  //   }
  //   setSelectedPatientSupplements(updatedSupplements);
  // };
  const fetchSupplementNames = async () => {
    try {
      const response = await axios.get(
        `/defaultSupplements`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      
      const supplements = response.data.map((supplement) => {
       
        if (!supplement.frequency) {
          supplement.frequency = [];
        }
        return supplement;
      });
      
      setDefaultSupplements(supplements);
    } catch (error) {
      console.error("Error fetching supplement names:", error);
      throw error;
    }
  };
  
  const fetchPatientProfile = async (patientCustomerId) => {
    try {
      const response = await axios.get(`/patient-profiles/${patientCustomerId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      });
      if(response.status ===200){
        setPatientActiveMedications(response?.data?.RecommendedSupplements)
        setHistoricalSupplements(response?.data?.HistoricalSupplements)
      }
      return response.data;
    } catch (error) {
      console.error("Error fetching patient profile:", error);
      return null;
    }
  };
  // This part of code is temporarily commented to disable synchronization between Order supplements and Patient Treatment Plan Supplements 
  useEffect(() => {
    if (modalType === "Supplements" && patientProfile) {
    
  
      const loadPatientData = async () => {
        const patientProfileData = await fetchPatientProfile(patientProfile['Customer ID']);
        if (!patientProfileData) return;
  
        const initialSelectedSupplements = {};
  
        patientProfileData.RecommendedSupplements?.forEach((recSupp) => {
          const matchingSupplement = defaultSupplements.find(
            (sup) => sup.supplement_name === recSupp.supplementName
          );
          if (matchingSupplement) {
            initialSelectedSupplements[matchingSupplement._id] = {
              checked: true,
              frequency: recSupp.frequency,
              inHistory: false,
              startDate: recSupp.startDate,
              endDate: recSupp.endDate,
            };
          }
        });
  
        patientProfileData.HistoricalSupplements?.forEach((histSupp) => {
          const matchingSupplement = defaultSupplements.find(
            (sup) => sup.supplement_name === histSupp.supplementName
          );
          if (matchingSupplement) {
            initialSelectedSupplements[matchingSupplement._id] = {
              checked: false,
              frequency: histSupp.frequency,
              inHistory: true,
              startDate: histSupp.startDate,
              endDate: histSupp.endDate,
            };
          }
        });
        setSelectedPatientSupplements(initialSelectedSupplements);
      };
  
      loadPatientData();
    }
  }, [patientProfile, defaultSupplements, modalType]);
 
  // This part of code is temporarily commented to disable synchronization between Order supplements and Patient Treatment Plan Supplements 
  // const handleSubmitSupplements = (e) => {
  //     const selectedData = Object.entries(selectedPatientSupplements)
  //       .filter(([, value]) => value.checked)
  //       .map(([key, value]) => {
  //         const supplement = defaultSupplements.find((sup) => sup._id === key);
  //         return {
  //           supplementName: supplement.supplement_name,
  //           frequency: value.frequency,
  //           startDate: value.startDate
  //         };
  //       });
  
  //     const profileData = {
  //       patientId: patientProfile['Customer ID'],
  //       RecommendedSupplements: selectedData,
  //     };
  
  //     axios
  //       .post(
  //         "/Historical-RecommendedSupplements",
  //         profileData,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         setSelectedPatientSupplements([])
  //       })
  //       .catch((error) => {
  //         message.error("Failed to update patient supplements data!");
  //         console.error(error);
  //       });
  //   };

  const fetchActiveDispensedItems = async (patientCustomerId) => {
      try {
        const response = await axios.get(`/active-dispensed-items/${patientCustomerId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        });
        if(response.status ===200){
          setActiveDispensedItems(response?.data)
        }
      } catch (error) {
        console.error("Error fetching patient profile:", error);
      }
    };
    
    const updateTabName = (tabKey, newDate) => {
      // Pass a message to parent component to update tab name
      if (setShowSOAPNotesSection) {
        setShowSOAPNotesSection({
          action: 'updateTabName',
          tabKey: tabKey,
          newDate: newDate
        });
      }
    };
    
    
    
    const updateSOAPNoteDate = async (newDate) => {
      try {
        const response = await axios.put(
          `/soap-note/update-date/${noteId}`,
          { newDate },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
            }
          }
        );
        
        if (response.status === 200) {
          message.success('SOAP note date updated successfully');
          
          if (tabKey && setTabContent) {
            // Update content with UTC date
            updateTabContent(tabKey, {...notesData, createdAt: newDate}, chiefConcern);
            
            // Update the tab name with UTC date
            updateTabName(tabKey, newDate);
          }
        }
      } catch (error) {
        console.error('Error updating SOAP note date:', error);
        message.error('Failed to update SOAP note date');
      }
    };

     const fetchActiveSupplementsExcludingPatientPlanSupplements = async (patientCustomerId) => {
        try {
          const response = await axios.get(`/active-supplements-items/${patientCustomerId}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          });
          if(response.status ===200){
            setActiveSupplementsExcludingPatientSupplements(response?.data)
          }
        } catch (error) {
          console.error("Error fetching patient profile:", error);
        }
      };

  

  return (
    <>
    <div className=" w-full h-[80%]">
      <div className='flex flex-col'>
      <div className='flex w-full justify-between items-center'>
  <div className="flex items-center gap-4">
    {/* Chief Concern */}
    <h1 className="text-2xl font-bold my-3">
      Chief Concern:
      {chiefConcernData?.code
        ? `${chiefConcernData.code} - ${chiefConcernData.name}`
        : storedChiefConcern
          ? `${storedChiefConcern.code} - ${storedChiefConcern.name}`
          : ""}
    </h1>
  </div>
  
  <div className="flex items-center gap-4">

  <DatePicker 
  value={date ? dayjs.utc(date) : null}
  onChange={(date) => {
    const formattedDate = date ? dayjs.utc(date).format('YYYY-MM-DD') : null;
    setDate(formattedDate);
    updateSOAPNoteDate(formattedDate);
  }}
  format="MM-DD-YYYY"
/>

    {/* Appointment Type Dropdown - moved to right side */}
    <Select
      value={appointmentType}
      onChange={handleAppointmentTypeChange}
      style={{ width: 180 }}
      options={[
        { value : 'none' , label: 'None'},
        { value: 'initial', label: 'Initial Appointment' },
        { value: 'follow-up', label: 'Follow-up' },
        { value: 'lab-review', label: 'Lab Review' },
      ]}
      defaultValue="none"
    />
    
 
    {(appointmentType !== 'lab-review' && appointmentType!== 'none') && (
      <Checkbox
        checked={isComped}
        onChange={handleCompedChange}
      >
        <span className="font-medium">Comped</span>
      </Checkbox>
    )}
    
   
    {treatment === null && (
      <Button
        className={`bg-[#111828] !py-5 !px-4 text-white !rounded-md !border-none !cursor-pointer !font-normal !text-sm !shadow-sm !transition-all !ease-in-out hover:!bg-[#3d3d3d] hover:!text-white hover:!border-none`}
        icon={<PlusOutlined />}
        iconPosition="start"
        onClick={() => {
          handleShowLPDV(true);
        }}
        loading={newOrderLoading}
      >
        New Order
      </Button>
    )}
   {(treatment?._id || createdOrderId) && (
  <Button
    type="button"
    onClick={() => {
      // Ensure treatment has latest appointment type and isComped values
      if (treatment) {
        const updatedTreatment = {
          ...treatment,
          appointmentType: appointmentType,
          isComped: isComped
        };
        setTreatment(updatedTreatment);
      }
      // Then open the modal
      setReceiptModalVisible(true);
    }}
    className="inline-flex items-center justify-center gap-2 px-4 py-2.5 bg-black text-white text-sm font-medium rounded-md shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-colors duration-200 ease-in-out"
  >
    <Printer className="h-4 w-4" />
    <span>Price List</span>
  </Button>
)}
<SoapNoteTemplateManagement
  showToast={showToast}
  from='soapnote'
  styles={styles}
/>
  </div>
</div>
        <div className='relative flex w-full items-center justify-between'>
          {/* {
            <h1 className="text-lg font-semibold my-3">
              Chief Concern:
              {chiefConcern
                ? `${chiefConcernData.code} - ${chiefConcernData.name}`
                : storedChiefConcern
                  ? `${storedChiefConcern.code} - ${storedChiefConcern.name}`
                  : ""}
            </h1>
          } */}
          <div className='flex items-center gap-3 '>
            <h1 className="text-lg font-semibold whitespace-nowrap mb-0">SOAP Note {customName && !editCustomName && `: ${customName}`}</h1>
            {noteId && editCustomName && <Input value={customName} onChange={(event) => setCustomName(event.target.value)} onBlur={handleUpdateCustomName} />}
            {noteId && !editCustomName && <IconButton onClick={() => setEditCustomName(true)}><EditFilled /></IconButton>}
          </div>
          <div className='flex items-center gap-3'>
            <MessageIcon patient={patientProfile} />
            <Tooltip title="Print">
              <Print
                className="cursor-pointer"
                onClick={() => setPrintModalVisible(true)}
                style={{ fontSize: 28, cursor: 'pointer', marginRight: 5 }}
              />
            </Tooltip>
          </div>
        </div>
        <Spin spinning={loading} size="large">

        <div className='grid grid-cols-2 gap-4 '>
          <div className="border bg-white p-4 soap-note-quill-container">
            <h2 className="font-bold text-2xl mb-2">Subjective</h2>
            <ReactQuill
              theme="snow"
              value={subjective}
              onChange={setSubjective}
              onBlur={() => handleBlur()}
              style={{ height: 'calc(100% - 110px)' }}
              readOnly={loading}
            />
          </div>
          <div className="border h-auto bg-white p-4 soap-note-quill-container">
            <div className='w-full flex justify-between items-center'>
              <h2 className="font-bold text-2xl mb-2">Objective</h2>
              {/* {orders.length > 0 && <LPDVIconButtons */}
              
              {((showLPDV && treatment !== null) || treatment !== null) && 
              <Spin spinning={LPDIconButtonsLoader}>
              <LPDVIconButtons
                treatment={treatment}
                setShowModal={setShowModal}
                setModalType={setModalType}
                fetchAllSupplements={fetchAllSupplements}
                setActiveTreatmentId={setActiveTreatmentId}
                setActiveTreatment={setActiveTreatment}
                fromSoapNote={true} iconButtons={["V"]}
              />
              </Spin>
              }
            </div>
            <ReactQuill
              theme="snow"
              value={objective}
              onChange={setObjective}
              onBlur={() => handleBlur()}
              readOnly={loading}
            />
          </div>
          

          
        </div>
        <div className="border bg-white p-4">
          <div className={`relative grid gap-4 grid-cols-2`}>
            {/* Assessment and Plan (Always in the first row with two columns) */}
            <div className="border p-4 bg-white h-auto col-span-1 soap-note-quill-container">
              <h2 className="font-bold text-2xl mb-2 whitespace-nowrap">Assessment</h2>
              <ReactQuill
                theme="snow"
                value={assessment}
                onChange={setAssessment}
                onBlur={() => handleBlur()}
                readOnly={loading}
              />
            </div>
            <div className="relative border p-4 bg-white col-span-1 soap-note-quill-container">
              <div className="w-full flex justify-between items-center">
                <h2 className="font-bold text-2xl mb-2">Medications</h2>
                {/* {orders.length > 0 && <LPDVIconButtons */}
                {((showLPDV && treatment !== null) || treatment !== null) && 
                <Spin spinning={LPDIconButtonsLoader}>
                <LPDVIconButtons
                  treatment={treatment}
                  setShowModal={setShowModal}
                  setModalType={setModalType}
                  setActiveTreatmentId={setActiveTreatmentId}
                  fromSoapNote={true}
                  fetchAllSupplements={fetchAllSupplements}
                  setActiveTreatment={setActiveTreatment}
                  iconButtons={['S']}
                />
                </Spin>
                }
              </div>     
              <div className="flex gap-3 items-center mb-2">
                    {/* <a className="font-medium text-blue-400 cursor-pointer">View Template</a>
                <a className="font-medium text-blue-400 cursor-pointer">Import Past Encounter</a> */}
                    {treatment !== null && <Button onClick={resetMedicationsNotes}>Reset</Button>}
                    {treatment !== null && <Button className={`${medicationsNoteSaved && 'bg-green-500'}`} onClick={()=>handleMedicationsNoteSaveChange(medicationsNoteSaved)}>Save</Button>}
                  </div>         
              <Spin spinning={medicationsNoteLoader} size="large">
              <ReactQuill
                theme="snow"
                value={medicationsNote}
                onChange={setMedicationsNote}
                onBlur={() => handleBlur()}                
                readOnly={medicationsNoteLoader || loading}
              />
              </Spin>              
            </div>

            
          </div>

        </div>

          
          {/* Care Plan & Plan Section */}
            <div className="border bg-white p-4">
              <div className='grid grid-cols-2 gap-4 '>
                {/* Plan Note (Placed next to Assessment in the same row) */}

                <div className="relative border p-4 bg-white col-span-1 soap-note-quill-container">
                  <div className="w-full flex justify-between items-center">
                    <h2 className="font-bold text-2xl mb-2">Plan</h2>
                    {/* {orders.length > 0 && <LPDVIconButtons */}
                    {((showLPDV && treatment !== null) || treatment !== null) &&
                      <Spin spinning={LPDIconButtonsLoader}>
                        <LPDVIconButtons
                          treatment={treatment}
                          setShowModal={setShowModal}
                          setModalType={setModalType}
                          setActiveTreatmentId={setActiveTreatmentId}
                          fromSoapNote={true}
                          fetchAllSupplements={fetchAllSupplements}
                          setActiveTreatment={setActiveTreatment}
                          iconButtons={['L', 'P', 'D']}
                        />
                      </Spin>
                    }
                  </div>
                  <div className="flex gap-3 items-center mb-2">
                    {/* <a className="font-medium text-blue-400 cursor-pointer">View Template</a>
                <a className="font-medium text-blue-400 cursor-pointer">Import Past Encounter</a> */}
                    {treatment !== null && <Button onClick={resetPlanNotes}>Reset</Button>}
                    {treatment !== null && <Button className={`${planNoteSaved && 'bg-green-500'}`} onClick={()=>handlePlanNoteSaveChange(planNoteSaved)}>Save</Button>}
                  </div>
                  <Spin spinning={planNoteLoader} size="large">
                    <ReactQuill
                      theme="snow"
                      value={planNote}
                      onChange={onChangePlanNote}
                      ref={quillRef}
                      onBlur={() => handleBlur()}
                      readOnly={planNoteLoader || loading}
                    />
                  </Spin>
                  {showMenu && (
                    <div
                      style={{
                        position: "absolute",
                        top: dropdownPosition.top,
                        left: adjustedLeft,
                        zIndex: 1000,
                      }}
                    >
                      <Dropdown overlay={menu} visible={true} trigger={["click"]} placement='bottomLeft'>
                        <span></span>
                      </Dropdown>
                    </div>
                  )}
                  {showItemsMenu && selectedCategory !== "custom" && (
                    <div
                      style={{
                        position: "absolute",
                        top: dropdownPosition.top,
                        left: adjustedLeft,
                        zIndex: 1000,
                      }}
                    >
                      <Dropdown
                        overlay={itemsMenu}
                        visible={true}
                        trigger={["click"]}
                        placement='bottomLeft'
                      >
                        <span></span>
                      </Dropdown>
                    </div>
                  )}
                  {showItemsMenu && selectedCategory === "custom" && (
                    <div
                      style={{
                        position: "absolute",
                        top: dropdownPosition.top,
                        left: adjustedLeft,
                        zIndex: 1000,
                      }}
                    >
                      <Dropdown
                        overlay={customTemplatesMenu}
                        visible={true}
                        trigger={["click"]}
                        placement='bottomLeft'
                      >
                        <span></span>
                      </Dropdown>
                    </div>
                  )}
                  {showShortcutsMenu && (
                    <div
                      style={{
                        position: "absolute",
                        top: dropdownPosition.top,
                        left: adjustedLeft,
                        zIndex: 1000,
                      }}
                    >
                      <Dropdown
                        overlay={shortcutsMenu}
                        visible={true}
                        trigger={["click"]}
                        placement='bottomLeft'
                      >
                        <span></span>
                      </Dropdown>
                    </div>
                  )}
                </div>
                {/* Care Plan Section */}
                <div className="border p-4 bg-white h-auto col-span-1 soap-note-quill-container">
                  <h2 className="font-bold text-2xl mb-2 whitespace-nowrap">Care Plan</h2>
                  <ReactQuill
                    theme="snow"
                    value={careplan}
                    onChange={setCareplan}
                    onBlur={() => handleBlur()}
                    readOnly={loading}
                  />
                </div>
                
              </div>

            </div>
        </Spin>    


      </div>

      <div>
          <ReceiptPrintOptionsModal
            visible={receiptModalVisible}
            onClose={() => setReceiptModalVisible(false)}
            orderId={treatment?._id || createdOrderId}
            patientProfile={patientProfile}
            {...(type ? { type } : { type: "Order" })} 
            fdv={false}
            appointmentType={appointmentType} 
            isComped={isComped}
          />
        </div>
      <div>
        <PrintModal isOpen={printModalVisible} onClose={closeModal} selectedPatient={patientProfile} soapNotes={soapNotes} formSoapNote={true} activeMedications={[...patientActiveMedications,...activeSupplementsExcludingPatientSupplements]} historicalMedications={historicalSupplements} activeDispensedItems={activeDispensedItems}/>
      </div>
      
        
      
    </div>
    <LPDPopup
    type={modalType}
    patientName={patientProfile["Full Name"]}
    // setAllTreatments={setOrders}
    activeTreatment={activeTreatment}
    showModal={showModal}
    onCloseModal={()=>{
      setShowModal(false)
      setModalType(null)
      setActiveTreatment({})
      // This part of code is temporarily commented to disable synchronization between Order supplements and Patient Treatment Plan Supplements 
      // handleSubmitSupplements()
      if (!showLPDV) {
        dataReset()
      }
    }}
    noteId={noteId}
    // handleCreateSoapNote={handleBlur}
    setsoapNoteCreatedFromLPD={setsoapNoteCreatedFromLPD}
    setModalType={setModalType}
    handleCellChange={handleCellChange}
    ref={{containerRef,soapNoteCreatedRef}}
    createOrderLoader={createOrderLoader}
    setCreateOrderLoader={setCreateOrderLoader}
    createdOrderId={createdOrderId}
    from="soapNote"
    // This part of code is temporarily commented to disable synchronization between Order supplements and Patient Treatment Plan Supplements 
    // handleSupplementCheckboxChange={handleSupplementCheckboxChange}
  />
  </>
  );
});

export default SOAPNotesSection;