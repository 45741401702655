import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet,useNavigate, useParams } from 'react-router-dom';

const ProtectedRoute = () => {
    const { name,formId } = useParams();
    const [authStatus, setAuthStatus] = useState(null);
    const navigate=useNavigate();
    useEffect(() => {
        const verifyToken = async () => {
            const token = localStorage.getItem("sessionToken");
            if (!token) {
                setAuthStatus(false);
                return;
            }

            try {
                const response = await axios.post(
                    "/verify-token",
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                if (response.status === 200) {
                    setAuthStatus(true);
                } else {
                    setAuthStatus(false);
                }
            } catch (error) {
                setAuthStatus(false);
            }
        };

        verifyToken();
        console.log('Protected Component Called' )
    }, [navigate]);


    if (authStatus === null) {
        return <div>Loading...</div>;
    }

    if ((name && !formId) || (!name && formId)) {
        return <Navigate to="/pagenotfound" />;
    }

    if(name && formId){
        return <Outlet />
    }
    if (!authStatus) {
        return <Navigate to="/login" />;
    }
    return <Outlet />;
};

export default ProtectedRoute;
