import React, { useEffect, useState, useRef } from 'react';
import { Progress, Spin, Tooltip, Typography, Upload, message } from 'antd';
import { DeleteFilled, FolderOpenOutlined, InboxOutlined } from '@ant-design/icons';
import axios from 'axios';
import { IconButton, Skeleton } from '@mui/material';
import { Edit } from '@mui/icons-material';

const { Dragger } = Upload;

const DocumentsSection = ({ selectedPatient }) => {

    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const patientId = selectedPatient['_id']
    const [uploadProgress, setUploadProgress] = useState({});
    const [folderName, setFolderName] = useState("");
    const [folderId, setFolderId] = useState("");
    const [editFolderName, setEditFolderName] = useState(false);
    const [editFileId, setEditFileId] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [folderNameLoading, setFolderNameLoading] = useState(null);
    const inputRef = useRef(null);
    

    const customRequest = async (options) => {
        setLoading(true)
        const { file, onSuccess, onError, onProgress } = options;

        const formData = new FormData();
        formData.append('multipleFiles', file);
        formData.append('patientId', `${selectedPatient['_id']}`);
        formData.append('patientName', `${selectedPatient['Full Name']}`);

        try {
            const response = await axios.post('/upload-files', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const percent = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
                    onProgress({ percent });
                    setUploadProgress((prev) => ({
                        ...prev,
                        [file.uid]: percent,
                    }));
                },
            });

            message.success('File uploaded successfully!');
            onSuccess(response.data);
            setFiles([...response.data.attachments]);
            setLoading(false)
            setUploadProgress([])
            fetchFiles();
        } catch (error) {
            console.error('File upload failed:', error);
            message.error('File upload failed. Please try again.');
            onError(error);
        }
    };

    const fetchFiles = async () => {
        try {
            setLoading(true)
            const response = await axios.get(`/fetch-files/${selectedPatient['_id']}`);
            setFiles(response.data.files);
            setFolderName(response.data.folderName);
            setFolderNameLoading(true)
            setFolderId(response.data.folderId);
        } catch (error) {
            console.error('Error fetching files');
            setFolderNameLoading(false)
        } finally {
            setLoading(false)
            setFolderNameLoading(false)
        }
    };
    useEffect(() => {
        setTimeout(()=>{
            fetchFiles();
        },1000)
    }, [patientId]);    

    const deleteFile = async(fileId) => {

        try {
            const response = await axios.delete(`/delete-file/${selectedPatient['_id']}/${fileId}`, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            message.success('File deleted successfully!');            
            setFiles((prevState) => (
                prevState.filter(file => file?.id !== fileId)
            ));
            setLoading(false)
            setUploadProgress([]);
        } catch (error) {
            console.error('File delete failed:', error);
            message.error('File delete failed. Please try again.');
        }
    }

    const updateFolderName = async() => {
        const payload={
            folderId,
            newName:folderName
        }
        try {
            const response = await axios.post(`/update-folder-name`, payload);

            message.success('Folder name updated successfully!');            
            setEditFolderName(false)
            setLoading(false)
            setUploadProgress([]);
        } catch (error) {
            console.error('File delete failed:', error);
            message.error('File delete failed. Please try again.');
        }
    }

    const handleChangeFolderName=(event)=>{
        setFolderName(event.target.value)
    }

    const handleChangeFileName = (e, index) => {
        const updatedItems = [...files];
        updatedItems[index].name = e.target.value;
        setFiles(updatedItems);
    };

    const updateFileName = async(fileId,fileName) => {
        const payload = {
            fileId,
            newName: fileName,
            patientId: selectedPatient?._id
        }
        try {
            await axios.post(`/update-file-name`, payload);
            message.success('File name updated successfully!');            
            setEditFolderName(false);
            setEditFileId("");
            setLoading(false);
            setUploadProgress([]);
        } catch (error) {
            console.error('File delete failed:', error);
            message.error('File delete failed. Please try again.');
        }
    }

    const handleFileEditClick = (file) => {
        setEditFileId(file.id);
        setFileName(file.name);
        setTimeout(() => {
            inputRef.current && inputRef.current.focus();
        }, 0);
    };

    const handleFolderEditClick = () => {
        setEditFolderName(!editFolderName)
        setTimeout(() => {
            inputRef.current && inputRef.current.focus();
        }, 0);
    };

    return (
        <>
            {folderNameLoading === false && folderName ?
                <>
                    <div className='flex items-center gap-3 mb-4'>
                        <FolderOpenOutlined style={{ fontSize: 22 }} />
                        {!editFolderName ?
                            <div className='flex items-center gap-2'>
                                <Typography style={{ fontSize: 20 }}>
                                    {folderName}
                                </Typography>
                                <Tooltip title={"Edit Folder Name"}>
                                <IconButton onClick={() => handleFolderEditClick()}>
                                    <Edit style={{ fontSize: 22, marginTop: 3 }} />
                                </IconButton>
                                </Tooltip>
                            </div>
                            :
                            <div className='flex items-center gap-2'>
                                <input className="border border-gray-300 rounded px-2 py-1" ref={inputRef} value={folderName} onChange={handleChangeFolderName} onBlur={() => updateFolderName()} />
                            </div>
                        }
                    </div>
                </> :
                <div className='flex items-center gap-2'>
                    <p className='text-base font-semibold'>No folder found !</p>
                    <p className='text-base'>(start uploading a file, then it will get created )</p>
                </div>
            }
            {folderNameLoading && <div className="flex flex-col mb-2">
                    <Skeleton width={120} />
                    <Skeleton width={320} />
                </div>}
            <Dragger
                customRequest={customRequest}
                multiple={true}
                showUploadList={false}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                    Support for single or multiple file uploads. Ensure your files are appropriate for upload.
                </p>
            </Dragger>
            <div style={{ marginTop: 20 }}>
                {Object.entries(uploadProgress).map(([fileUid, progress]) => (
                    <div key={fileUid}>
                        <p>File UID: {fileUid}</p>
                        <Progress percent={progress} />
                    </div>
                ))}
            </div>
            <div className='w-full'>
                <h2 className='text-lg font-medium my-3'>Uploaded Files</h2>
                {!loading && files.length === 0 ? (
                    <p>No files found</p>
                ) : (
                    <>
                        <div className="w-full grid grid-cols-3 gap-4 px-4">
                                {files.map((file,index) => (
                                    <div className="w-full flex items-center justify-between text-lg px-5 py-3 rounded-md shadow-md hover:bg-gray-50">
                                        {editFileId === file.id ? (
                                            // Input field for editing
                                            <input
                                                type="text"
                                                ref={inputRef}
                                                className="border border-gray-300 rounded px-2 py-1"
                                                value={file?.name}
                                                onChange={(e) => handleChangeFileName(e,index)}
                                                onBlur={() => {
                                                    updateFileName(file.id, file.name); // Call update function
                                                    setEditFileId(null); // Close input field
                                                }} />)
                                            :
                                            <a
                                                key={file.name}
                                                href={file.webViewLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {file.name}
                                            </a>

                                        }
                                        <div className="flex items-center">
                                            {editFileId !== file.id &&
                                            <Tooltip title={"Edit File Name"}>
                                                <IconButton
                                                    className="hover:text-blue-500"
                                                    onClick={() => handleFileEditClick(file)}
                                                >
                                                    <Edit />
                                                </IconButton>
                                                </Tooltip>
                                            }
                                            <Tooltip title={"Delete File"}>
                                            <IconButton className='hover:text-red-500' onClick={() => deleteFile(file?.id)}>
                                                <DeleteFilled />
                                            </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                            ))}
                        </div>
                        {loading &&
                            <div className='flex w-full'>
                                <Spin className='my-0 mx-auto' />
                            </div>
                        }
                    </>
                )}
            </div>
        </>
    );
};


export default DocumentsSection;
