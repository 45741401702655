import React, { useState, useEffect } from 'react';
import { Modal, Button, Spin , message } from 'antd';

const PatientFormComparison = ({ formId, visible, onClose ,fetchUnapprovedForms}) => {
  const [originalData, setOriginalData] = useState(null);
  const [incomingData, setIncomingData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [approving, setApproving] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!formId) return;
      
      setLoading(true);
      try {
        const originalResponse = await fetch(`/original-data/${formId}`);
        if (!originalResponse.ok) throw new Error('Failed to fetch original data');
        const originalDataResult = await originalResponse.json();
        console.log("Original Data:", originalDataResult);
        setOriginalData(originalDataResult);

        const incomingResponse = await fetch(`/incoming-data/${formId}`);
        if (!incomingResponse.ok) throw new Error('Failed to fetch incoming data');
        const incomingDataResult = await incomingResponse.json();
        setIncomingData(incomingDataResult);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (visible && formId) {
      fetchData();
    }
  }, [visible, formId]);

  const formatValue = (value) => {
    if (value === null || value === undefined || value === '') {
      return 'N/A';
    }

    if (Array.isArray(value)) {
      if (value.length === 0) return 'None';
      if (typeof value[0] === 'object') {
        return value.map((item, index) => (
          <div key={index} className="pl-4 border-l-2 border-gray-200 mb-2">
            {Object.entries(item).map(([k, v]) => (
              <div key={k} className="text-sm">
                <span className="font-medium">{k}: </span>
                {formatValue(v)}
              </div>
            ))}
          </div>
        ));
      }
      return value.join(', ');
    }

    if (typeof value === 'object') {
      if (Object.keys(value).length === 0) return 'None';
      return (
        <div className="pl-4">
          {Object.entries(value).map(([k, v]) => (
            <div key={k} className="text-sm">
              <span className="font-medium">{k}: </span>
              {formatValue(v)}
            </div>
          ))}
        </div>
      );
    }

    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }

    if (typeof value === 'string' && value.includes('T')) {
      try {
        const date = new Date(value);
        if (!isNaN(date)) {
          return date.toLocaleString();
        }
      } catch (e) {
        return value;
      }
    }

    return String(value);
  };

  // Fields to exclude from comparison
  const excludedFields = [
    '_id', '__v', 'createdAt', 'updatedAt', 'patientId',
    'description',  'notes', 'tags', 'archive',
    'unread', 'favourite', 'linkPatient', 'MultipleTxNotes',
    'RecommendedSupplements', 'HistoricalSupplements', 'attachments'
  ];

  const ComparisonRow = ({ label, originalValue, incomingValue }) => {
    // Skip excluded fields
    if (excludedFields.includes(label)) {
      return null;
    }


    return (
      <div className="mb-4 p-4 bg-gray-50 rounded-lg">
        <div className="font-medium text-gray-700 mb-2 text-lg">
          {label.split(/(?=[A-Z])/).join(' ')}
        </div>
        <div className="grid grid-cols-2 gap-6">
          <div>
            <div className="text-sm font-medium mb-1 text-gray-600">Original</div>
            <div className="break-words text-red-600">
              {formatValue(originalValue)}
            </div>
          </div>
          <div>
            <div className="text-sm font-medium mb-1 text-gray-600">Incoming</div>
            <div className="break-words text-green-600">
              {formatValue(incomingValue)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getFormattedName = (nameObj) => {
    if (!nameObj) return 'N/A';
    if (typeof nameObj === 'string') return nameObj;
    
    // const { firstName, middleName, lastName } = nameObj;
    const { firstName, lastName } = nameObj;
    // return [firstName, middleName, lastName].filter(Boolean).join(' ');
    return [firstName,  lastName].filter(Boolean).join(' ');
  };

  const getComparableFields = () => {
    if (!originalData || !incomingData) return [];

    const relevantFields = [
      {
        label: 'Full Name',
        original: originalData.fullName || 
                 (originalData['Full Name'] ? { firstName: originalData['First Name'], lastName: originalData['Last Name'] } : null),
        incoming: incomingData.fullName
      },
      {
        label: 'Email',
        original: originalData.Email?.[0]?.email || null,
        incoming: null
      },
      {
        label: 'Phone Number',
        original: originalData.phoneNumbers?.[0]?.number || null,
        incoming: incomingData.phoneNumber
      },
      // Add all other fields from incoming data
      ...Object.entries(incomingData)
        .filter(([key]) => !excludedFields.includes(key) && key !== 'fullName' && key !== 'phoneNumber')
        .map(([key, value]) => ({
          label: key,
          original: originalData[key],
          incoming: value
        }))
    ];

    return relevantFields.map(field => (
      <ComparisonRow
        key={field.label}
        label={field.label}
        originalValue={field.original}
        incomingValue={field.incoming}
      />
    ));
  };

  
  const handleApprove = async () => {
    setApproving(true);
    try {
      const response = await fetch(`/approve-forms/${formId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to approve form');
      }

      message.success('Form approved successfully');
      fetchUnapprovedForms()
      onClose();
    } catch (error) {
      console.error('Error approving form:', error);
      message.error(error.message || 'Failed to approve form');
    } finally {
      setApproving(false);
    }
  };
  return (
    <Modal
      title="Patient Form Comparison"
      visible={visible}
      onCancel={onClose}
      width={1200}
      className="comparison-modal"
      footer={[
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
        <Button 
        key="approve" 
        className="!bg-green-600 !text-white hover:!bg-green-700"
        onClick={handleApprove}
        loading={approving}
        disabled={approving}
      >
        {approving ? 'Approving...' : 'Approve Changes'}
      </Button>
      ]}
    >
      {loading ? (
        <div className="h-64 flex items-center justify-center">
          <Spin size="large" />
        </div>
      ) : error ? (
        <div className="h-64 flex items-center justify-center text-red-600">
          {error}
        </div>
      ) : (
        <div className="max-h-[70vh] overflow-y-auto px-4">
          {getComparableFields()}
        </div>
      )}
    </Modal>
  );
};

export default PatientFormComparison;