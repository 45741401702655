import axios from "axios"
import { message } from "antd"
import jsPDF from "jspdf"
import "jspdf-autotable"
import moment from "moment"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import sunridgeLogo from "../../../../Assets/sunridege_logo.png" 
dayjs.extend(utc)

export const generatePriceListPDF = async (treatmentId, patientProfile, printOptions = {}, optimized = false, type, fdv,enhancedOrderData = null ) => {
  
  try {
    const loadingMessage = message.loading("Generating price list...", 0)

    // Fetch order data - this now includes SOAP note data from the API
    const response = await axios.get(`/orderget-price-quote-with-prices/${treatmentId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
      },
    })

    if (response.status !== 200) {
      throw new Error("Failed to fetch order data")
    }

    const orderData = response.data

    // Get the order date - using the original date format without timezone conversion
    const orderDate = orderData?.orderDate
      ? dayjs.utc(orderData.orderDate).format("MM/DD/YYYY")
      : dayjs.utc().format("MM/DD/YYYY")

    // Get patient information
    const patientName = orderData?.patient?.name || patientProfile["Full Name"] || "Patient"
    const firstName = orderData?.patient?.firstName || patientProfile["First Name"] || ""
    const lastName = orderData?.patient?.lastName || patientProfile["Last Name"] || ""
    
    // Get patient address
    const patientAddress = orderData?.patient?.address || null

    // Create the PDF document
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "letter",
    })

    // Page dimensions
    const pageWidth = doc.internal.pageSize.getWidth()
    const pageHeight = doc.internal.pageSize.getHeight()
    const margin = 40 

    // Initialize pagination tracking
    let currentPage = 1
    let totalPages = 1 // Will be updated after document is complete

    // Store a reference to the original addPage function
    const originalAddPage = doc.addPage.bind(doc)
    
    // Override the addPage function to track page numbers
    doc.addPage = (...args) => {
      originalAddPage(...args)
      currentPage++
      totalPages++
      return doc
    }
    
    const addLogo = async () => {
      return new Promise((resolve, reject) => {
        try {
          const img = new Image()
          img.crossOrigin = "anonymous"
          
          // Set image smoothing properties for better rendering
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          
          img.onload = () => {
            try {
              // Use higher resolution for the canvas to improve quality
              const scale = 2 // Scaling factor for higher resolution
              const logoWidth = 200
              const logoHeight = 60
              
              // Set canvas to a higher resolution
              canvas.width = logoWidth * scale
              canvas.height = logoHeight * scale
              
              // Configure the context for better image quality
              if (ctx) {
                // Turn off image smoothing for sharper edges
                ctx.imageSmoothingEnabled = true
                ctx.imageSmoothingQuality = 'high'
                
                // Scale the context to draw at higher resolution
                ctx.scale(scale, scale)
                
                // Draw the image on the canvas
                ctx.drawImage(img, 0, 0, logoWidth, logoHeight)
                
                // Convert canvas to data URL with high quality
                const dataUrl = canvas.toDataURL('image/png', 1.0)
                
                // Position the logo in the center of the page
                const x = (pageWidth - logoWidth) / 2
                
                // Add the image to the PDF with proper compression options
                doc.addImage(dataUrl, 'PNG', x, margin, logoWidth, logoHeight, undefined, 'FAST')
                
                resolve()
              } else {
                // Fallback to direct image adding if canvas context not available
                const x = (pageWidth - logoWidth) / 2
                doc.addImage(img, "PNG", x, margin, logoWidth, logoHeight)
                resolve()
              }
            } catch (err) {
              console.error("Error processing logo image:", err)
              
              // Fallback to basic image adding if the enhanced approach fails
              try {
                const logoWidth = 200
                const logoHeight = 60
                const x = (pageWidth - logoWidth) / 2
                doc.addImage(img, "PNG", x, margin, logoWidth, logoHeight)
              } catch (fallbackErr) {
                console.error("Fallback logo adding failed:", fallbackErr)
              }
              
              resolve()
            }
          }
          
          img.onerror = () => {
            console.error("Error loading logo")
            resolve()
          }
          
          // Make sure to use a high-quality logo source
          img.src = sunridgeLogo
          
        } catch (err) {
          console.error("Logo error:", err)
          resolve()
        }
      })
    }

    const createHeaderForPage = async (title = "PRICE LIST", fdv = false, isContinuation = false) => {
      // Add logo - only on the first page of each section
      if (!isContinuation) {
        await addLogo();
      }
    
      const headerY = margin;
    
      // Company info on left side - always show this
      doc.setFont("helvetica", "bold");
      doc.setFontSize(10);
      doc.text("SUNRIDGE MEDICAL", margin, headerY);
    
      doc.setFont("helvetica", "normal");
      doc.setFontSize(9);
      doc.text("14200 N Northsight Blvd, Suite 160,", margin, headerY + 12);
      doc.text("Scottsdale, AZ, 85260", margin, headerY + 24);
      doc.text("480-659-9135", margin, headerY + 36);
      doc.text("info@sunridgemedical.com", margin, headerY + 48);
    
      // Patient info on right side - always show this
      doc.setFont("helvetica", "bold");
      doc.setFontSize(10);
      doc.text("Patient Information", pageWidth - margin, headerY, { align: "right" });
    
      // Add patient name and address
      doc.setFont("helvetica", "normal");
      doc.setFontSize(9);
      doc.text(`${firstName} ${lastName}`, pageWidth - margin, headerY + 12, { align: "right" });
      
      let addressY = headerY + 24;
      if (patientAddress) {
        if (patientAddress.address1) {
          doc.text(patientAddress.address1, pageWidth - margin, addressY, { align: "right" });
          addressY += 12;
        }
        if (patientAddress.address2) {
          doc.text(patientAddress.address2, pageWidth - margin, addressY, { align: "right" });
          addressY += 12;
        }
        
        const cityStateZip = [
          patientAddress.city,
          patientAddress.province,
          patientAddress.zip
        ].filter(Boolean).join(", ");
        
        if (cityStateZip) {
          doc.text(cityStateZip, pageWidth - margin, addressY, { align: "right" });
          addressY += 12;
        }
        
        if (patientAddress.country) {
          doc.text(patientAddress.country, pageWidth - margin, addressY, { align: "right" });
        }
      } else {
        // Fallback if no address available
        doc.text("No address on file", pageWidth - margin, addressY, { align: "right" });
      }
    
      // Page title with blue accent color
      const titleY = 140;
      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.setTextColor(41, 128, 185); // Blue color for title
      
      // Show appropriate title
      if (!fdv) {
        // FIXED: If this is a continuation page, append "(continued)" to the title
        const displayTitle = isContinuation ? `${title} (continued)` : title;
        doc.text(displayTitle, pageWidth / 2, titleY, { align: "center" });
        
        // Add date on the same line as title, aligned to the right
        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0); // Black text for date
        doc.text(orderDate || "", pageWidth - margin, titleY, { align: "right" });
      } else {
        // In FDV mode, center the date where the title would be
        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0); // Black text for date
        doc.text(orderDate || "", pageWidth / 2, titleY, { align: "center" });
      }
      
      doc.setTextColor(0, 0, 0); // Reset to black
    
      return titleY + 15; // Space after title
    };

    const generateNonOptimizedPDF = async () => {
      // For detailed view, create individual category pages
      const pageOrder = ["appointment", "labs", "procedures", "medications"]; 
    
      let firstPage = true;
      
      for (const category of pageOrder) {
        // Special handling for appointment
        if (category === "appointment") {
          // Only add appointment page if it has a charge and not treatment type
          let appointmentCharge = 0;
          if (orderData.appointmentType && type !== 'treatment') {
            switch(orderData.appointmentType) {
              case 'initial':
                appointmentCharge = 250;
                break;
              case 'follow-up':
                appointmentCharge = 75;
                break;
            }
          }
          
          if (appointmentCharge > 0 || orderData.appointmentType === 'lab-review') {
            // Only add a new page if not the first page
            if (!firstPage) {
              doc.addPage();
            }
            
            // Setup header - appointment is never a continuation
            const detailsY = await createHeaderForPage("Appointment Details", fdv, false);
            
            // Create appointment details page
            createAppointmentDetailsPage(doc, orderData, detailsY, margin, pageWidth);
            firstPage = false;
          }
          
          continue; // Skip to next category
        }
        
        // Regular category handling for labs and procedures
        if ((category === "labs" || category === "procedures") && 
            printOptions[category] && 
            orderData[category] && 
            orderData[category].length > 0) {
          // Only add a new page if not the first page
          if (!firstPage) {
            doc.addPage();
          }
          
          // Setup header - never a continuation for category pages
          const detailsY = await createHeaderForPage(`${getCategoryTitle(category)}`, fdv, false);
          
          // Create category detail page
          createDetailPage(doc, category, orderData[category], detailsY, margin, pageWidth, fdv);
          firstPage = false;
        }
        
        // Special handling for medications category (combined dispensed and supplements)
        if (category === "medications" && printOptions.medications) {
          let medicationItems = [];
          
          // Add dispensed items if option is enabled
          if (printOptions.dispensed !== false && orderData.dispensed && orderData.dispensed.length > 0) {
            const processedDispensed = orderData.dispensed.map(item => ({
              ...item,
              _source: 'dispensed'
            }));
            medicationItems = [...medicationItems, ...processedDispensed];
          }
          
        
          if (printOptions.supplements !== false) {
            // Add new supplements if enabled
            if (printOptions.newSupplements !== false && orderData.newSupplements && 
                orderData.newSupplements.length > 0) {
              const processedNewSupplements = orderData.newSupplements.map(item => ({
                ...item,
                _source: 'newSupplements'
              }));
              medicationItems = [...medicationItems, ...processedNewSupplements];
            }
            
            // Add active supplements if enabled
            if (printOptions.activeSupplements && orderData.activeMedications && 
                orderData.activeMedications.length > 0) {
              const processedActiveMedications = orderData.activeMedications.map(item => ({
                ...item,
                _source: 'activeMedications'
              }));
              medicationItems = [...medicationItems, ...processedActiveMedications];
            }
          }
          
          // Only create medications page if we have data
          if (medicationItems.length > 0) {
            // Only add a new page if not the first page
            if (!firstPage) {
              doc.addPage();
            }
            
            // Setup header - never a continuation for category pages
            const detailsY = await createHeaderForPage("Medications", fdv, false);
            
            // Create medications detail page with combined data
            createDetailPage(doc, "medications", medicationItems, detailsY, margin, pageWidth, fdv);
            firstPage = false;
          }
        }
      }
    };

    
   // Function to add date with simpler display and moved up
const addDateBox = (yPosition) => {
  // Add date text directly without background, positioned higher
  const dateY = yPosition - 10; // Moved up by 5 points from the original position
  
  doc.setFont("helvetica", "normal");
  doc.setFontSize(10);
  doc.setTextColor(0, 0, 0); // Black text for better visibility
  doc.text(orderDate || "", pageWidth / 2, dateY + 17, { align: "center" });
  
  return dateY + 15; // Reduced spacing after date (was 30)
};

    // New function to check if we need a new page and update page counts
    const checkNewPage = (yPos, requiredSpace = 50) => {
      if (yPos + requiredSpace > pageHeight - margin - 30) { // Leave space for page number
        doc.addPage() // This will increment currentPage and totalPages
        
        // Add a simple header on new pages
        doc.setFont("helvetica", "italic")
        doc.setFontSize(10)
        doc.setTextColor(100, 100, 100) // Gray text
        doc.text("(continued)", pageWidth / 2, margin + 10, { align: "center" })
        doc.setTextColor(0, 0, 0) // Reset to black
        
        return margin + 30 // Space after "continued" text
      }
      return yPos // No change if no new page needed
    }
    
    // Function to add page numbers to all pages
    const addPageNumbers = () => {
      // Save total page count
      totalPages = doc.internal.getNumberOfPages()
      
      // Add page numbers to each page
      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i)
        doc.setFont("helvetica", "normal")
        doc.setFontSize(8)
        doc.setTextColor(100, 100, 100) // Gray text
        doc.text(
          `Page ${i} of ${totalPages}`, 
          pageWidth / 2, 
          pageHeight - 20, 
          { align: "center" }
        )
      }
    }
    
    // Set default values for printOptions if medications is selected but not specific types
    if (printOptions.medications === true) {
      // Ensure dispensed and supplements are also enabled if medications is enabled
      printOptions.dispensed = printOptions.dispensed !== false
      printOptions.supplements = printOptions.supplements !== false
    }
    
    if (optimized) {
      // Create single page price list with all items
      await createMainPriceListPage(doc, orderData, orderDate, margin, pageWidth, pageHeight, createHeaderForPage, optimized, addDateBox, type, printOptions, fdv, checkNewPage)
    } else {
      // For detailed view, create individual category pages
      const pageOrder = ["appointment", "labs", "procedures", "medications"] // Updated order with medications
  
      let firstPage = true;
      
      for (const category of pageOrder) {
        // Special handling for appointment
        if (category === "appointment") {
          // Only add appointment page if it has a charge and not treatment type
          let appointmentCharge = 0;
          if (orderData.appointmentType && type !== 'treatment') {
            switch(orderData.appointmentType) {
              case 'initial':
                appointmentCharge = 250;
                break;
              case 'follow-up':
                appointmentCharge = 75;
                break;
            }
          }
          
          if (appointmentCharge > 0 || orderData.appointmentType === 'lab-review') {
            // Only add a new page if not the first page
            if (!firstPage) {
              doc.addPage();
            }
            
            // Setup header
            const detailsY = await createHeaderForPage("Appointment Details");
            
            // Add date on first page only
            let startY = detailsY;
            // if (firstPage) {
            //   startY = addDateBox(detailsY);
            // }
            
            // Create appointment details page
            createAppointmentDetailsPage(doc, orderData, startY, margin, pageWidth);
            firstPage = false;
          }
          
          continue; // Skip to next category
        }
        
        // Regular category handling for labs and procedures
        if ((category === "labs" || category === "procedures") && 
            printOptions[category] && 
            orderData[category] && 
            orderData[category].length > 0) {
          // Only add a new page if not the first page
          if (!firstPage) {
            doc.addPage();
          }
          
          // Setup header
          const detailsY = await createHeaderForPage(`${getCategoryTitle(category)}`,fdv);
          
          // Add date on first page only
          let startY = detailsY;
          // if (firstPage) {
          //   startY = addDateBox(detailsY);
          // }
          
          // Create category detail page
          createDetailPage(doc, category, orderData[category], startY, margin, pageWidth, fdv);
          firstPage = false;
        }
        
        // Special handling for medications category (combined dispensed and supplements)
        if (category === "medications" && printOptions.medications) {
          let medicationItems = [];
          
          // Add dispensed items if option is enabled
          if (printOptions.dispensed !== false && orderData.dispensed && orderData.dispensed.length > 0) {
            const processedDispensed = orderData.dispensed.map(item => ({
              ...item,
              _source: 'dispensed'
            }));
            medicationItems = [...medicationItems, ...processedDispensed];
          }
          
          // Add supplements if option is enabled
          if (printOptions.supplements !== false) {
            // Add new supplements if enabled
            if (printOptions.newSupplements !== false && orderData.newSupplements && 
                orderData.newSupplements.length > 0) {
              const processedNewSupplements = orderData.newSupplements.map(item => ({
                ...item,
                _source: 'newSupplements'
              }));
              medicationItems = [...medicationItems, ...processedNewSupplements];
            }
            
            // Add active supplements if enabled
            if (printOptions.activeSupplements && orderData.activeMedications && 
                orderData.activeMedications.length > 0) {
              const processedActiveMedications = orderData.activeMedications.map(item => ({
                ...item,
                _source: 'activeMedications'
              }));
              medicationItems = [...medicationItems, ...processedActiveMedications];
            }
          }
          
          // Only create medications page if we have data
          if (medicationItems.length > 0) {
            // Only add a new page if not the first page
            if (!firstPage) {
              doc.addPage();
            }
            
            // Setup header
            const detailsY = await createHeaderForPage("Medications",fdv);
            
            // Add date on first page only
            let startY = detailsY;
            if (firstPage) {
              startY = addDateBox(detailsY);
            }
            
            // Create medications detail page with combined data
            createDetailPage(doc, "medications", medicationItems, startY, margin, pageWidth, fdv);
            firstPage = false;
          }
        }
      }
    }

    // Add page numbers to all pages before finalizing the document
    addPageNumbers();

    // Prepare the file for preview or download
    const pdfBlob = doc.output("blob")
    
    // Stop the loading message
    loadingMessage()
    
    // Prepare file name
    const sanitizedPatientName = patientName.replace(/[^a-zA-Z0-9]/g, "_")
    const sanitizedOrderDate = orderDate.replace(/\//g, "-")
    const filename = `Sunridge_Medical_${sanitizedPatientName}_${sanitizedOrderDate}_PriceList.pdf`
    
    return {
      blob: pdfBlob,
      url: URL.createObjectURL(pdfBlob),
      filename: filename
    }
  } catch (error) {
    console.error("Error generating price list:", error)
    message.error("Failed to generate price list")
    return null
  }
}

// Helper function to get proper category titles
const getCategoryTitle = (category) => {
  switch(category) {
    case "procedures": return "Procedures";
    case "dispensed": return "Dispensed Items";
    case "supplements": return "Supplements";
    case "labs": return "Labs";
    case "appointment": return "Appointment Charges";
    default: return category.charAt(0).toUpperCase() + category.slice(1);
  }
}

const createMainPriceListPage = async (doc, orderData, orderDate, margin, pageWidth, pageHeight, createHeaderForPage, optimized, addDateBox, type, printOptions = {}, fdv, checkNewPage) => {
  // Create header
  const detailsY = await createHeaderForPage("PRICE LIST", fdv);

  // Set up starting position
  let currentY = detailsY + 10; // Reduced spacing after date

  // Define accent color
  const accentColor = [0, 159, 227]; 
  
  // Collect content sections
  const availableSections = organizeContentForDisplay(orderData, printOptions, type);
  
  // Handle empty document case
  if (availableSections.length === 0) {
    currentY = checkNewPage(currentY);
    doc.setFont("helvetica", "italic");
    doc.setFontSize(12);
    doc.text("No items selected for display.", pageWidth / 2, currentY + 20, { align: "center" });
    return;
  }

  // Define content dimensions - REDUCED SPACING
  const contentWidth = pageWidth - (2 * margin);
  const columnWidth = (contentWidth / 2) - 8; // Reduced from 10
  
  // Track grand total
  let grandTotal = 0;
  
  // Get optimized layout with possible table splitting
  const layout = determineOptimalLayout(availableSections, pageWidth, pageHeight, margin, currentY);
  
  // Track sections for grand total placement
  let lastSectionInfo = {
    column: null, // 'left' or 'right'
    finalY: 0,
    x: 0,
    width: columnWidth
  };
  
  // Process each page in the layout
  layout.pages.forEach((page, pageIndex) => {
    // Add new page if needed
    if (pageIndex > 0) {
      doc.addPage();
      
      // Add continuation header
      const continuedY = margin + 10;
      doc.setFont("helvetica", "italic");
      doc.setFontSize(10);
      doc.setTextColor(100, 100, 100);
      doc.text("(continued)", pageWidth / 2, continuedY, { align: "center" });
      doc.setTextColor(0, 0, 0);
      
      // Reset Y position for new page
      currentY = continuedY + 20; // Reduced from 25
    }
    
    // Render left column sections
    let leftColumnY = pageIndex === 0 ? currentY : margin + 32; // Reduced from 40
    
    // Process left sections
    if (page.leftSections && Array.isArray(page.leftSections)) {
      for (const section of page.leftSections) {
        // Skip invalid sections
        if (!section || !section.items || !Array.isArray(section.items) || section.items.length === 0) continue;
        
        let originalItems = section.items;
        if (section._isSplit && section._originalIndex !== undefined) {
          const originalSection = availableSections[section._originalIndex];
          if (originalSection && originalSection.items) {
            originalItems = originalSection.items;
          }
        }

        const result = renderCategorySection(
          doc,
          section.type,
          section.items,
          section.title || getCategoryTitle(section.type) || "",
          margin, // x position
          leftColumnY,
          columnWidth,
          accentColor,
          checkNewPage,
          fdv,
          {
            isRightColumn: false,
            maxY: pageHeight - margin - 48, // Reduced from 60
            _isSplit: section._isSplit,
            _splitPart: section._splitPart,
            _originalItems: originalItems
          }
        );
        
        // Update Y position
        if (typeof result === 'number') {
          leftColumnY = result;
        } else {
          leftColumnY = result.finalY;
          
          // Update last section info
          lastSectionInfo = {
            column: 'left',
            finalY: result.finalY,
            x: margin,
            width: columnWidth
          };
          
          // Handle any remaining items - should not happen with optimized layout
          if (result.continueToNextColumn && result.remainingItems && result.remainingItems.length > 0) {
            console.warn("Unexpected remaining items in left column - layout algorithm needs adjustment");
          }
        }
        
        // Add spacing between sections
        leftColumnY += 12; // Reduced from 15
        
        // Add to grand total (but only once per original section)
        if (!section._isSplit || section._splitPart === 'first') {
          // Calculate total using the full original section
          let originalSection = section;
          
          // If this is a split section, find the original complete section
          if (section._isSplit && section._originalIndex !== undefined) {
            const originalSectionData = availableSections[section._originalIndex];
            if (originalSectionData) {
              originalSection = originalSectionData;
            }
          }
          
          grandTotal += calculateCategoryTotal(originalSection);
        }
      }
    }
    
    // Render right column sections
    let rightColumnY = pageIndex === 0 ? currentY : margin + 32; // Reduced from 40
    
    // Process right sections
    if (page.rightSections && Array.isArray(page.rightSections)) {
      for (const section of page.rightSections) {
        // Skip invalid sections
        if (!section || !section.items || !Array.isArray(section.items) || section.items.length === 0) continue;
        
        // Find the original complete section for accurate totals
        let originalItems = section.items;
        if (section._isSplit && section._originalIndex !== undefined) {
          const originalSection = availableSections[section._originalIndex];
          if (originalSection && originalSection.items) {
            originalItems = originalSection.items;
          }
        }
        
        // Render the section
        const result = renderCategorySection(
          doc,
          section.type,
          section.items,
          section.title || getCategoryTitle(section.type) || "",
          margin + columnWidth + 16, // x position - reduced from 20
          rightColumnY,
          columnWidth,
          accentColor,
          checkNewPage,
          fdv,
          {
            isRightColumn: true,
            maxY: pageHeight - margin - 64, // Reduced from 80
            _isSplit: section._isSplit,
            _splitPart: section._splitPart,
            _originalItems: originalItems
          }
        );
        
        // Update Y position
        if (typeof result === 'number') {
          rightColumnY = result;
        } else {
          rightColumnY = result.finalY;
          
          // Update last section info to track the final section for grand total placement
          lastSectionInfo = {
            column: 'right',
            finalY: result.finalY,
            x: margin + columnWidth + 16,
            width: columnWidth
          };
          
          // Handle any remaining items - should not happen with optimized layout
          if (result.continueToNextColumn && result.remainingItems && result.remainingItems.length > 0) {
            console.warn("Unexpected remaining items in right column - layout algorithm needs adjustment");
          }
        }
        
        // Add spacing between sections
        rightColumnY += 12; // Reduced from 15
        
        // Add to grand total (but only once per original section)
        if (!section._isSplit || section._splitPart === 'first') {
          // Calculate total using the full original section
          let originalSection = section;
          
          // If this is a split section, find the original complete section
          if (section._isSplit && section._originalIndex !== undefined) {
            const originalSectionData = availableSections[section._originalIndex];
            if (originalSectionData) {
              originalSection = originalSectionData;
            }
          }
          
          grandTotal += calculateCategoryTotal(originalSection);
        }
      }
    }
  });

  // IMPROVED: Position the grand total under the last section instead of at the bottom of the page
  // This is a key change that addresses the requirement to prevent the total from going to the next page
  
  // Determine where to place the grand total based on the last section rendered
  let totalY = 0;
  let totalX = 0;
  let totalWidth = 0;
  
  if (lastSectionInfo.column) {
    totalY = lastSectionInfo.finalY + 15; // Space after the last section total
    totalX = lastSectionInfo.x;
    totalWidth = lastSectionInfo.width;
    
    // Check if we need a new page (should be very rare with this optimization)
    if (totalY + 30 > pageHeight - margin - 30) {
      doc.addPage();
      totalY = margin + 40;
    }
    
    // Add divider before grand total - ONLY THE WIDTH OF THE COLUMN
    doc.setLineWidth(0.5);
    doc.setDrawColor(...accentColor);
    doc.line(totalX, totalY, totalX + totalWidth, totalY);
    
    // Add grand total with increased spacing
    totalY += 15; // Reduced spacing
    
    doc.setFont("helvetica", "bold");
    doc.setFontSize(11); // Reduced from 12
    doc.text("TOTAL:", totalX, totalY);
    doc.text(`${grandTotal.toFixed(2)}`, totalX + totalWidth, totalY, { align: "right" });
  } else {
    // Fallback (should not typically happen) - use a centralized approach
    // Calculate a position that won't need a new page
    totalY = Math.min(pageHeight - margin - 60, doc.lastAutoTable ? doc.lastAutoTable.finalY + 30 : pageHeight - 100);
    
    // Add divider
    doc.setLineWidth(0.5);
    doc.setDrawColor(...accentColor);
    doc.line(margin, totalY, pageWidth - margin, totalY);
    
    // Add grand total
    totalY += 15;
    
    doc.setFont("helvetica", "bold");
    doc.setFontSize(11); // Reduced from 12
    doc.text("TOTAL:", pageWidth - margin - 150, totalY);
    doc.text(`${grandTotal.toFixed(2)}`, pageWidth - margin, totalY, { align: "right" });
  }
};
// Helper function to calculate the maximum Y position after rendering sections
const calculateMaxY = (sections) => {
  // Just a rough estimate - will be refined with actual rendered dimensions
  return sections.reduce((maxY, section) => {
    const sectionHeight = estimateSectionHeight(section);
    return maxY + sectionHeight + 15; // Adding spacing between sections
  }, 0);
};

const renderCategorySection = (doc, category, items, title, x, y, width, accentColor, checkNewPage, fdv = false, columnOptions = { isRightColumn: false, maxY: 0 }) => {
  if (!items || items.length === 0) {
    return {
      finalY: y,
      continueToNextColumn: false,
      remainingItems: [],
      initialY: y
    };
  }
  
  // Track the initial Y position for column flow calculations
  const initialY = y;
  
  // Get page dimensions if not provided
  const pageHeight = doc.internal.pageSize.getHeight();
  const margin = 40; // Default margin
  
  // Check if this is a split section (continuation)
  const isSplitSection = columnOptions._isSplit === true;
  const isContinuation = isSplitSection && columnOptions._splitPart === 'second';
  
  // Section Title with appropriate styling - REDUCED SPACING
  if (!isContinuation) {
    // Standard title for first sections
    doc.setFont("helvetica", "bold");
    doc.setFontSize(13); // Reduced from 14
    doc.setTextColor(...accentColor);
    doc.text(title, x, y);
    doc.setTextColor(0, 0, 0);
    
    // Draw underline
    doc.setLineWidth(0.5);
    doc.setDrawColor(...accentColor);
    doc.line(x, y + 4, x + width, y + 4); // Reduced from y+5
    
    // Column Headers
    y += 24; // Reduced from 30
    doc.setFont("helvetica", "bold");
    doc.setFontSize(9); // Reduced from 10
  } else {
    // Continuation title for split sections
    doc.setFont("helvetica", "bold");
    doc.setFontSize(11); // Reduced from 12
    doc.setTextColor(...accentColor);
    doc.text(`${title} (continued)`, x, y);
    doc.setTextColor(0, 0, 0);
    
    // Draw a thinner underline
    doc.setLineWidth(0.3);
    doc.setDrawColor(...accentColor);
    doc.line(x, y + 4, x + width, y + 4); // Reduced from y+5
    
    // Column Headers - slightly smaller gap for continuation
    y += 20; // Reduced from 25
    doc.setFont("helvetica", "bold");
    doc.setFontSize(9); // Reduced from 10
  }
  
  // Track whether we need to continue to next column
  let continueToNextColumn = false;
  let remainingItems = [];
  
  // Initialize variables to track rendered items
  const maxYPosition = columnOptions.maxY || (pageHeight - margin - 35);
  let itemsToRender = [...items]; // Create a copy
  
  // Render section based on type
  if (category === "procedures" || category === "labs") {
    // For procedures and labs: Name and Price columns
    doc.text("Name", x, y);
    doc.text("Price", x + width - 10, y, { align: "right" });
    y += 16; // Reduced from 20
    
    // Items
    doc.setFont("helvetica", "normal");
    let total = 0;
    let itemsRendered = 0;
    
    // Check if we need to split the list
    if (items.length > 0) {
      // OPTIMIZED: Dynamically calculate item height based on total count
      const estimatedItemHeight = items.length > 15 ? 16 : 18; // Reduced from 22
      
      // Render items that will fit
      for (const item of itemsToRender) {
        if (item && item.name) {
          // Check if we're about to exceed the max Y position
          if (y + estimatedItemHeight > maxYPosition - 4 && itemsRendered < itemsToRender.length - 1) {
            // Put the rest in remainingItems
            remainingItems = itemsToRender.slice(itemsRendered);
            continueToNextColumn = true;
            break;
          }
          
          // Display item name - REDUCED FONT SIZE
          doc.setFontSize(9); // Reduced from 10
          doc.text(item.name || "", x, y);
          
          // Handle comped items
          if (item.comped) {
            doc.setTextColor(46, 125, 50);
            doc.text("$0.00 (Comped)", x + width - 10, y, { align: "right" });
            doc.setTextColor(0, 0, 0);
          } else {
            const price = item.price !== null ? item.price : 0;
            total += price;
            doc.text(`$${price.toFixed(2)}`, x + width - 10, y, { align: "right" });
          }
          
          // Dynamic spacing based on item count - OPTIMIZED
          const dynamicSpacing = items.length > 15 ? 16 : (items.length > 10 ? 17 : 18);
          y += dynamicSpacing - 4; // Additional reduction
          
          itemsRendered++;
        }
      }
    }
    
    // Only add total if this is the last part or all items fit
    // AND we're not in a continuation or this is the end of a continuation
    if ((!continueToNextColumn || remainingItems.length === 0) && 
        (!isSplitSection || (isSplitSection && columnOptions._splitPart === 'second'))) {
      
      // Get the category-wide total from originalItems if available
      let fullTotal = total;
      
      // If we're in a split section, we need the total from all items in the category
      if (columnOptions._originalItems && Array.isArray(columnOptions._originalItems)) {
        fullTotal = columnOptions._originalItems.reduce((sum, item) => {
          // Skip comped items
          if (item.comped) return sum;
          // Calculate price
          const price = item.price !== null ? item.price : 0;
          // Handle quantity for medications
          const qty = (category === "medications") ? (item.quantity || 1) : 1;
          return sum + (price * qty);
        }, 0);
      }
      
      y += 4; // Reduced from 5
      doc.setFont("helvetica", "bold");
      doc.setFontSize(10); // Reduced from 11
      doc.text(`${title} Total:`, x, y);
      doc.text(`$${fullTotal.toFixed(2)}`, x + width - 10, y, { align: "right" });
      y += 20; // Reduced from 25
    } else {
      y += 8; // Reduced from 10
    }
  } else if (category === "medications") {
    // For medications - handle FDV mode
    if (!fdv) {
      doc.text("Item", x, y);
      doc.text("Price", x + width - 10, y, { align: "right" });
    } else {
      doc.text("Item", x, y);
      const qtyX = x + width - 80;
      const priceX = x + width - 10;
      doc.text("Qty", qtyX, y, { align: "center" });
      doc.text("Price", priceX, y, { align: "right" });
    }
    y += 16; // Reduced from 20
    
    // Items
    doc.setFont("helvetica", "normal");
    let total = 0;
    let itemsRendered = 0;
    
    // Render medication items
    for (const item of itemsToRender) {
      if (item && item.name) {
        // Calculate name width - SLIGHTLY WIDER to accommodate more text
        const nameWidth = fdv ? (width * 0.72) : (width * 0.72);
        
        // Handle wrapped text with REDUCED FONT SIZE
        doc.setFontSize(9); // Reduced from 10
        const nameLines = doc.splitTextToSize(item.name || "", nameWidth - 5);
        
        // Calculate item height based on wrapping - OPTIMIZED
        const wrappedTextHeight = nameLines.length * 10; // Reduced from 12
        const itemHeight = Math.max(18, wrappedTextHeight + 2); // Reduced from 22
        
        // Check if this item will fit
        if (y + itemHeight > maxYPosition && itemsRendered < itemsToRender.length - 1) {
          remainingItems = itemsToRender.slice(itemsRendered);
          continueToNextColumn = true;
          break;
        }
        
        // Draw the item name
        doc.text(nameLines, x, y);
        
        // Calculate adjusted Y for price alignment
        const adjustedY = y + (wrappedTextHeight > 10 ? (wrappedTextHeight - 10) / 2 : 0);
        
        if (!fdv) {
          // Basic layout
          if (item.comped) {
            doc.setTextColor(46, 125, 50);
            doc.text("$0.00 (Comped)", x + width - 10, adjustedY, { align: "right" });
            doc.setTextColor(0, 0, 0);
          } else {
            const price = item.price !== null ? item.price : 0;
            const qty = item.quantity || 1;
            total += price * qty; // Consider quantity
            doc.text(`$${price.toFixed(2)}`, x + width - 10, adjustedY, { align: "right" });
          }
        } else {
          // FDV layout with quantity
          const qtyX = x + width - 80;
          const priceX = x + width - 10;
          
          const qty = item.quantity || 1;
          doc.text(qty.toString(), qtyX, adjustedY, { align: "center" });
          
          if (item.comped) {
            doc.setTextColor(46, 125, 50);
            doc.text("$0.00 (Comped)", priceX, adjustedY, { align: "right" });
            doc.setTextColor(0, 0, 0);
          } else {
            const price = item.price !== null ? item.price : 0;
            total += price * qty;
            doc.text(`$${price.toFixed(2)}`, priceX, adjustedY, { align: "right" });
          }
        }
        
        // Dynamic spacing based on line count and total items - OPTIMIZED
        const lineCount = nameLines.length;
        // Adjust spacing based on item count (more aggressive reduction)
        const baseSpacing = items.length > 15 ? 15 : (items.length > 10 ? 16 : 18);
        const spacing = Math.max(lineCount * 10, baseSpacing);
        y += spacing - 2;
        
        itemsRendered++;
      }
    }
    
    // Only add total if this is the last part or all items fit
    // AND we're not in a continuation or this is the end of a continuation
    if ((!continueToNextColumn || remainingItems.length === 0) && 
        (!isSplitSection || (isSplitSection && columnOptions._splitPart === 'second'))) {
      
      // KEY FIX: Calculate full total from original items list when available
      let fullTotal = total;
      
      // If we have original items (for split sections), use those for total calculation
      if (columnOptions._originalItems && Array.isArray(columnOptions._originalItems)) {
        fullTotal = columnOptions._originalItems.reduce((sum, item) => {
          // Skip comped items
          if (item.comped) return sum;
          // Calculate price and quantity
          const price = item.price !== null ? item.price : 0;
          const qty = item.quantity || 1;
          return sum + (price * qty);
        }, 0);
      }
      
      y += 4; // Reduced from 5
      doc.setFont("helvetica", "bold");
      doc.setFontSize(10); // Reduced from 11
      doc.text(`${title} Total:`, x, y);
      doc.text(`$${fullTotal.toFixed(2)}`, x + width - 10, y, { align: "right" });
      y += 20; // Reduced from 25
    } else {
      y += 8; // Reduced from 10
    }
  } else if (category === "appointment") {
    // For appointment
    doc.text("Appointment Type", x, y);
    doc.text("Price", x + width - 10, y, { align: "right" });
    y += 16; // Reduced from 20
    
    // Items
    doc.setFont("helvetica", "normal");
    let total = 0;
    
    for (const item of items) {
      if (item && item.name) {
        doc.setFontSize(9); // Reduced from 10
        doc.text(item.name || "", x, y);
        
        if (item.isComped) {
          doc.setTextColor(46, 125, 50);
          doc.text("$0.00 (Comped)", x + width - 10, y, { align: "right" });
          doc.setTextColor(0, 0, 0);
        } else {
          const price = item.price !== null ? item.price : 0;
          total += price;
          doc.text(`$${price.toFixed(2)}`, x + width - 10, y, { align: "right" });
        }
        
        y += 18; // Reduced from 22
      }
    }
    
    y += 12; // Reduced from 15
  }
  
  // Return detailed info about rendering state
  return {
    finalY: y,
    continueToNextColumn: continueToNextColumn,
    remainingItems: remainingItems,
    initialY: initialY
  };
};


const calculateCategoryTotal = (section) => {
  // Validate section and items
  if (!section || !section.items || !Array.isArray(section.items)) return 0;
  
  // Handle empty array case
  if (section.items.length === 0) return 0;
  
  // If this is a split section, use the original items for total calculation
  let itemsToCalculate = section.items;
  if (section._isSplit && section._originalItems && Array.isArray(section._originalItems)) {
    itemsToCalculate = section._originalItems;
  }
  
  // ENHANCED: Calculate total more carefully
  return itemsToCalculate.reduce((total, item) => {
    // Make sure item is an object
    if (!item || typeof item !== 'object') return total;
    
    // Skip comped items (check both comped and isComped properties)
    if (item.comped || item.isComped) return total;
    
    // Safely get price (default to 0 if not a number)
    const price = typeof item.price === 'number' ? item.price : 0;
    
    // For medications (from dispensed or supplements), respect quantity
    const qty = (section.type === 'medications' || 
                 item._source === 'dispensed' || 
                 item._source === 'newSupplements' ||
                 item._source === 'activeMedications') ? 
                (typeof item.quantity === 'number' ? item.quantity : 1) : 1;
    
    // Debug logs (comment out in production)
    // console.log(`Item: ${item.name}, Price: ${price}, Qty: ${qty}, Total: ${price * qty}`);
    
    return total + (price * qty);
  }, 0);
};


function processItems(items, category) {
  return items.map(item => {
    let displayName = item.name;
    const dosage = item.dosage || item.selected_dosage || "";
    
    // Only combine dosage for procedures and dispensed items
    if ((category === "procedures" || category === "dispensed") && dosage) {
      displayName = `${item.name} (${dosage})`;
    }
    
    // Use comped status from the item
    const isComped = item.comped || false;
    
    // Apply comped status to price
    let itemPrice = isComped ? 0 : item.price;
    let totalPrice = isComped ? 0 : (item.total_price || item.price || null);
    
    return {
      name: displayName,
      raw_name: item.name,
      dosage: dosage,
      quantity: (category === "dispensed" || category === "supplements") ? (item.quantity || 1) : null,
      price: itemPrice,
      total_price: totalPrice,
      item_number: item.item_number || "",
      category,
      comped: isComped,
      has_price: itemPrice !== null
    }
  })
}

const createDetailPage = (doc, categoryName, items, startY, margin, pageWidth, fdv = false) => {
  if (!items || items.length === 0) return;
  
  // For medications, items are already processed with _source properties
  // For other categories, we need to process them
  const allItems = categoryName === "medications" ? 
    items : // For medications, items are already processed
    processItems(items, categoryName); // For other categories, process items
    
  if (allItems.length === 0) return;
  
  startY += 25;
  
  let tableHead, tableBody, columnStyles;
  
  if (categoryName === "procedures") {
    // For procedures: Name and Price only
    tableHead = [["Procedure", "Price"]];
    
    tableBody = allItems.map(item => {
      const displayName = item.name;
      
      // Check if procedure is comped
      const isComped = item.comped;
      
      if (isComped) {
        // Return just the first column data - we'll handle the cell drawing separately
        return [displayName, "COMPED_PLACEHOLDER"]; // This text won't actually show
      } else {
        const price = item.price !== null ? item.price : 0;
        return [displayName, `$${price.toFixed(2)}`];
      }
    });
    
    columnStyles = {
      0: { cellWidth: (pageWidth - 2 * margin) * 0.7, halign: "left" },
      1: { cellWidth: (pageWidth - 2 * margin) * 0.3, halign: "right" }
    };
  } else if (categoryName === "medications") {
    // For medications: Combined handling for dispensed and supplements
    if (!fdv) {
      // Without FDV - no quantity column
      tableHead = [["Item", "Price"]];
      
      tableBody = allItems.map(item => {
        const displayName = item.name;
        // Check if item is comped
        const isComped = item.comped;
        
        if (isComped) {
          // Return just the first column data - we'll handle the cell drawing separately
          return [displayName, "COMPED_PLACEHOLDER"]; // This text won't actually show
        } else {
          const price = item.price !== null ? item.price : 0;
          return [displayName, `$${price.toFixed(2)}`];
        }
      });
      
      columnStyles = {
        0: { cellWidth: (pageWidth - 2 * margin) * 0.7, halign: "left" },
        1: { cellWidth: (pageWidth - 2 * margin) * 0.3, halign: "right" }
      };
    } else {
      // With FDV - include quantity column
      tableHead = [["Item", "Qty", "Price"]];
      
      tableBody = allItems.map(item => {
        const displayName = item.name;
        // Check if item is comped
        const isComped = item.comped;
        
        if (isComped) {
          return [
            displayName, 
            item.quantity || 1, 
            "COMPED_PLACEHOLDER" // This text won't actually show
          ];
        } else {
          return [
            displayName, 
            item.quantity || 1, 
            item.price !== null ? `$${item.price.toFixed(2)}` : "$0.00"
          ];
        }
      });
      
      columnStyles = {
        0: { cellWidth: (pageWidth - 2 * margin) * 0.5, halign: "left" },
        1: { cellWidth: (pageWidth - 2 * margin) * 0.2, halign: "center" },
        2: { cellWidth: (pageWidth - 2 * margin) * 0.3, halign: "right" }
      };
    }
  } else if (categoryName === "labs") {
    // For labs: Test Name and Price only
    tableHead = [["Test Name", "Price"]];
    
    tableBody = allItems.map(item => {
      return [item.name, item.price !== null ? `$${item.price.toFixed(2)}` : "$0.00"];
    });
    
    columnStyles = {
      0: { cellWidth: (pageWidth - 2 * margin) * 0.7, halign: "left" },
      1: { cellWidth: (pageWidth - 2 * margin) * 0.3, halign: "right" }
    };
  }
  
  // Set header color
  const headerColor = [41, 128, 185]; // Blue accent color
  
  // Create a list of comped items for reference
  const compedRows = [];
  allItems.forEach((item, index) => {
    if (item.comped) {
      compedRows.push(index);
    }
  });

  // FIXED: Remove incorrect continuation page detection
  // We don't need to show "continued" text here since each category gets its own page
  // Let the header handle this instead

  // Draw the table with improved design
  doc.autoTable({
    startY: startY,
    head: tableHead,
    body: tableBody,
    headStyles: {
      fillColor: headerColor,
      textColor: [255, 255, 255],
      fontStyle: 'bold',
      valign: 'middle',
      fontSize: 12,
      cellPadding: 10
    },
    alternateRowStyles: {
      fillColor: [240, 245, 250]
    },
    columnStyles: columnStyles,
    styles: {
      fontSize: 10,
      cellPadding: 8,
      overflow: 'linebreak',
      valign: 'middle',
      lineWidth: 0.1,
      lineColor: [220, 220, 220]
    },
    willDrawCell: function(data) {
      // If this is a price cell for a comped item, don't draw the default text
      const priceColumn = (categoryName === "medications" && fdv) ? 2 : 1;
      if (data.column.index === priceColumn && 
          data.section === 'body' && 
          compedRows.includes(data.row.index)) {
        data.cell.text = ""; // Clear the text so it doesn't show
      }
    },
    didDrawCell: function(data) {
      // Check if this is a price cell for a comped item
      const priceColumn = (categoryName === "medications" && fdv) ? 2 : 1;
      if (data.column.index === priceColumn && 
          data.section === 'body' && 
          compedRows.includes(data.row.index)) {
        // Get cell position
        const { x, y, width, height } = data.cell;
        // Set text color to green
        doc.setTextColor(46, 125, 50); // Green color for comped items
        // Draw the comped text
        doc.text("$0.00 (Comped)", x + width - 5, y + height/2 + 3, { align: "right" });
        // Reset text color to black
        doc.setTextColor(0, 0, 0);
      }
    },
    bodyStyles: {
      minCellHeight: 18
    },
    margin: { left: margin, right: margin }
  });
  
  // Add category total
  const finalY = doc.lastAutoTable.finalY + 20;

  // Calculate total based on category type and considering comped items
  let total = 0;
  
  // For all categories, exclude comped items from the total
  total = allItems.reduce((sum, item) => {
    // Skip comped items
    if (item.comped) {
      return sum;
    }
    
    const price = item.price !== null ? item.price : 0;
    // Use quantity for medications
    const quantity = (categoryName === "medications") ? (item.quantity || 1) : 1;
    return sum + (price * quantity);
  }, 0);

  // FIXED: Always display the total, regardless of continuation status
  // Display the total
  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.setTextColor(41, 128, 185);
  doc.text(`${getCategoryTitle(categoryName)} Total: $${total.toFixed(2)}`, pageWidth - margin, finalY, { align: "right" });
  doc.setTextColor(0, 0, 0);
};


const createAppointmentDetailsPage = (doc, orderData, startY, margin, pageWidth) => {
  // Only proceed if appointment type exists
  if (!orderData.appointmentType) return;
  
  const appointmentType = orderData.appointmentType;
  const isComped = orderData.isComped || false;
  
  // Get appointment charge based on type
  let appointmentCharge = 0;
  let appointmentTypeText = "";
  switch(appointmentType) {
    case 'initial':
      appointmentCharge = 250;
      appointmentTypeText = "Initial Appointment";
      break;
    case 'follow-up':
      appointmentCharge = 75;
      appointmentTypeText = "Follow-up";
      break;
    case 'lab-review':
      appointmentCharge = 0; // Added charge for lab review
      appointmentTypeText = "Lab Review";
      break;
    case 'none':
      appointmentCharge = 0;
      appointmentTypeText = "";
      break;
    default:
      appointmentCharge = 0;
  }
  
  
  if (appointmentCharge > 0 || appointmentType === 'lab-review') {
    startY += 20;
    
    // Draw a blue accent box at the top
    const accentColor = [41, 128, 185]; // Blue color
    doc.setFillColor(...accentColor);
    doc.rect(margin, startY, pageWidth - (2 * margin), 30, 'F');
    
    // Add title text in white
    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.setTextColor(255, 255, 255); // White text
    doc.text("Appointment Details", margin + 15, startY + 20);
    doc.setTextColor(0, 0, 0); // Reset to black
    
    startY += 50;
    
    // Create a table for appointment details
    const tableHead = [["Appointment Type", "Description", "Price"]];
    
    // Prepare table body based on appointment type
    const tableBody = [
      [appointmentTypeText, "", `$${appointmentCharge.toFixed(2)}`]
    ];
    
    // Add comped information if applicable
    if (isComped) {
      tableBody.push([
        "Comped Discount",
        `Discount applied to ${appointmentTypeText}`,
        `-$${appointmentCharge.toFixed(2)}`
      ]);
    }
    
    // Set column widths
    const columnStyles = {
      0: { cellWidth: (pageWidth - 2 * margin) * 0.3, halign: "left" },
      1: { cellWidth: (pageWidth - 2 * margin) * 0.5, halign: "left" },
      2: { cellWidth: (pageWidth - 2 * margin) * 0.2, halign: "right" }
    };
    
    // Draw the table
    doc.autoTable({
      startY: startY,
      head: tableHead,
      body: tableBody,
      headStyles: {
        fillColor: accentColor,
        textColor: [255, 255, 255],
        fontStyle: 'bold',
        halign: 'left',
        valign: 'middle',
        fontSize: 12,
        cellPadding: 10
      },
      columnStyles: columnStyles,
      styles: {
        fontSize: 10,
        cellPadding: 8,
        overflow: 'linebreak',
        halign: 'left',
        valign: 'middle',
        lineWidth: 0.1,
        lineColor: [220, 220, 220]
      },
      bodyStyles: {
        minCellHeight: 18
      },
      alternateRowStyles: {
        fillColor: [240, 245, 250]
      },
      margin: { left: margin, right: margin }
    });
    
    const finalY = doc.lastAutoTable.finalY + 30;
    
    // Add a note about comped appointments
    if (isComped) {
      doc.setFont("helvetica", "italic");
      doc.setFontSize(10);
      doc.setTextColor(100, 100, 100); // Gray text
      doc.text("* This appointment has been marked as comped. No charge will be applied.", margin, finalY);
      doc.setTextColor(0, 0, 0); // Reset to black
    }
    
    // Add a summary total
    const totalY = finalY + (isComped ? 40 : 20);
    
    doc.setDrawColor(...accentColor);
    doc.setLineWidth(0.75);
    doc.line(margin, totalY - 15, pageWidth - margin, totalY - 15);
    
    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    doc.text("Net Appointment Charge:", margin, totalY);
    
    // Calculate net charge (0 if comped)
    const netCharge = isComped ? 0 : appointmentCharge;
    
    doc.text(`$${netCharge.toFixed(2)}`, pageWidth - margin, totalY, { align: "right" });
  }
};



// Updated function to collect content with dispensed and supplements merged as "Medications"
const organizeContentForDisplay = (orderData, printOptions, type) => {
  // Validate inputs
  if (!orderData) return [];
  if (!printOptions) printOptions = {};
  
  // Array to hold all available sections
  const availableSections = [];
  
  // Helper function to get proper category titles
  const getCategoryTitle = (category) => {
    switch(category) {
      case "procedures": return "Procedures";
      case "medications": return "Medications"; // New combined category
      case "labs": return "Labs";
      case "appointment": return "Appointment Charges";
      default: return category.charAt(0).toUpperCase() + category.slice(1);
    }
  };
  console.log("OrderData appointmentType:", orderData.appointmentType);
  if (type !== 'treatment' && orderData.appointmentType && orderData.appointmentType !== 'none') {
    let appointmentCharge = 0;
    switch(orderData.appointmentType) {
      case 'initial':
        appointmentCharge = 250;
        break;
      case 'follow-up':
        appointmentCharge = 75;
        break;
    }
    
    if (appointmentCharge > 0 || orderData.appointmentType === 'lab-review') {
      availableSections.push({
        type: 'appointment',
        title: getCategoryTitle('appointment'),
        items: [{
          name: orderData.appointmentType === 'initial' ? 'Initial Appointment' : 
                orderData.appointmentType === 'lab-review' ? 'Lab Review' : 'Follow-up',
          price: appointmentCharge,
          isComped: orderData.isComped || false
        }]
      });
    }
  }
  
  // Process labs section if enabled and available
  if (printOptions.labs !== false && orderData.labs && Array.isArray(orderData.labs) && orderData.labs.length > 0) {
    availableSections.push({
      type: 'labs',
      title: getCategoryTitle('labs'),
      items: orderData.labs
    });
  }
  
  // Process procedures section if enabled and available
  if (printOptions.procedures !== false && orderData.procedures && 
      Array.isArray(orderData.procedures) && orderData.procedures.length > 0) {
    availableSections.push({
      type: 'procedures',
      title: getCategoryTitle('procedures'),
      items: orderData.procedures
    });
  }
  
  // Process medications section (combined dispensed and supplements)
  if (printOptions.medications !== false) {
    let medicationItems = [];
    
    // Add dispensed items if enabled
    if (printOptions.dispensed !== false && orderData.dispensed && 
        Array.isArray(orderData.dispensed) && orderData.dispensed.length > 0) {
      const processedDispensed = orderData.dispensed.map(item => ({
        ...item,
        _source: 'dispensed'
      }));
      medicationItems = [...medicationItems, ...processedDispensed];
    }
    
    // Add supplements if enabled
    if (printOptions.supplements !== false) {
      // Add new supplements if enabled
      if (printOptions.newSupplements === true && orderData.newSupplements && 
          Array.isArray(orderData.newSupplements) && orderData.newSupplements.length > 0) {
        const processedNewSupplements = orderData.newSupplements.map(item => ({
          ...item,
          _source: 'newSupplements'
        }));
        medicationItems = [...medicationItems, ...processedNewSupplements];
      }
      
    // Add active supplements if enabled via activeSupplements OR singlePreview
if ((printOptions.activeSupplements === true) && 
orderData.activeMedications && 
Array.isArray(orderData.activeMedications) && 
orderData.activeMedications.length > 0) {
const processedActiveMedications = orderData.activeMedications.map(item => ({
...item,
_source: 'activeMedications'
}));
medicationItems = [...medicationItems, ...processedActiveMedications];
}
    }
    
    // Only add medications section if we have items
    if (medicationItems.length > 0) {
      availableSections.push({
        type: 'medications',
        title: getCategoryTitle('medications'),
        items: medicationItems
      });
    }
  }
  
  return availableSections;
};


const determineOptimalLayout = (availableSections, pageWidth, pageHeight, margin, currentY) => {
  // If we have no sections, return empty layout
  if (!availableSections || !Array.isArray(availableSections) || availableSections.length === 0) {
    return { pages: [] };
  }

  // Initialize layout
  const layout = {
    pages: [{ 
      leftSections: [], 
      rightSections: [] 
    }]
  };

  // Find appointment section if it exists and handle separately
  const appointmentIndex = availableSections.findIndex(section => section.type === 'appointment');
  let appointmentSection = null;
  if (appointmentIndex >= 0) {
    appointmentSection = availableSections.splice(appointmentIndex, 1)[0];
  }

  // Available height for content on first page (considering header and date)
  const firstPageAvailableHeight = pageHeight - margin - currentY - 40; // Reduced buffer to use more space
  
  // Available height for subsequent pages
  const standardAvailableHeight = pageHeight - margin - margin - 40; // Reduced buffer

  // Current tracking variables
  let currentPage = 0;
  let leftHeight = 0;
  let rightHeight = 0;
  
  // Add appointment section first if it exists
  if (appointmentSection) {
    const appointmentHeight = estimateSectionHeight(appointmentSection);
    layout.pages[0].leftSections.push({
      ...appointmentSection,
      _isSplit: false,
      _originalIndex: -1
    });
    leftHeight += appointmentHeight;
  }
  
  // Calculate total content height to better distribute sections
  const totalContentHeight = availableSections.reduce((total, section) => {
    return total + estimateSectionHeight(section);
  }, 0);
  
  // Calculate approximately how much content should go in each column for optimal balance
  const idealColumnHeight = totalContentHeight / 2;
  
  // Process each section with improved balancing
  for (let i = 0; i < availableSections.length; i++) {
    const section = availableSections[i];
    if (!section || !section.items || !Array.isArray(section.items) || section.items.length === 0) continue;
    
    // Deep clone the section to avoid modifying the original
    const sectionToProcess = JSON.parse(JSON.stringify(section));
    sectionToProcess._originalIndex = i;
    
    // Calculate full section height
    const sectionHeight = estimateSectionHeight(sectionToProcess);
    
    // Calculate available height based on current page
    const availableHeight = currentPage === 0 ? firstPageAvailableHeight : standardAvailableHeight;
    
    // Calculate average item height for potential splitting
    const avgItemHeight = section.items.length > 0 ? 
      sectionHeight / section.items.length : 
      0;
    
    // Determine if this section should go to the left or right column
    // IMPROVED: Use a balanced column approach - place in the column with less content
    if (leftHeight <= rightHeight && leftHeight + sectionHeight <= availableHeight) {
      // If left column has less height and section fits, add to left
      layout.pages[currentPage].leftSections.push({
        ...sectionToProcess,
        _isSplit: false,
        title: sectionToProcess.title || getCategoryTitle(sectionToProcess.type)
      });
      leftHeight += sectionHeight;
    } 
    else if (rightHeight < leftHeight && rightHeight + sectionHeight <= availableHeight) {
      // If right column has less height and section fits, add to right
      layout.pages[currentPage].rightSections.push({
        ...sectionToProcess,
        _isSplit: false,
        title: sectionToProcess.title || getCategoryTitle(sectionToProcess.type)
      });
      rightHeight += sectionHeight;
    }
    // If section doesn't fit in the shorter column, try to split it across columns
    else {
      // Calculate space left in left and right columns
      const leftSpaceRemaining = availableHeight - leftHeight;
      const rightSpaceRemaining = availableHeight - rightHeight;
      
      // Determine which column has more remaining space
      if (leftSpaceRemaining >= rightSpaceRemaining && leftSpaceRemaining > avgItemHeight) {
        // Try to place part of the section in left column
        const itemsForLeft = Math.floor(leftSpaceRemaining * 0.98 / avgItemHeight);
        
        if (itemsForLeft > 0) {
          // Split the section
          const leftItems = sectionToProcess.items.slice(0, itemsForLeft);
          const remainingItems = sectionToProcess.items.slice(itemsForLeft);
          
          // Add first part to left column
          layout.pages[currentPage].leftSections.push({
            ...sectionToProcess,
            items: leftItems,
            _isSplit: true,
            _splitPart: 'first',
            title: sectionToProcess.title || getCategoryTitle(sectionToProcess.type)
          });
          
          leftHeight += leftSpaceRemaining;
          
          // If there are remaining items, try to place them in right column
          if (remainingItems.length > 0) {
            const remainingHeight = sectionHeight * (remainingItems.length / sectionToProcess.items.length);
            
            if (rightHeight + remainingHeight <= availableHeight) {
              // Add remaining items to right column
              layout.pages[currentPage].rightSections.push({
                ...sectionToProcess,
                items: remainingItems,
                _isSplit: true,
                _splitPart: 'second',
                title: sectionToProcess.title || getCategoryTitle(sectionToProcess.type)
              });
              rightHeight += remainingHeight;
            } else {
              // Start a new page for remaining items
              currentPage++;
              layout.pages.push({ leftSections: [], rightSections: [] });
              layout.pages[currentPage].leftSections.push({
                ...sectionToProcess,
                items: remainingItems,
                _isSplit: true,
                _splitPart: 'second',
                title: sectionToProcess.title || getCategoryTitle(sectionToProcess.type)
              });
              leftHeight = remainingHeight;
              rightHeight = 0;
            }
          }
        } else {
          // Not enough space to split effectively, try right column
          if (rightSpaceRemaining > avgItemHeight) {
            const itemsForRight = Math.floor(rightSpaceRemaining * 0.98 / avgItemHeight);
            
            if (itemsForRight > 0) {
              // Split the section
              const rightItems = sectionToProcess.items.slice(0, itemsForRight);
              const nextPageItems = sectionToProcess.items.slice(itemsForRight);
              
              // Add first part to right column
              layout.pages[currentPage].rightSections.push({
                ...sectionToProcess,
                items: rightItems,
                _isSplit: true,
                _splitPart: 'first',
                title: sectionToProcess.title || getCategoryTitle(sectionToProcess.type)
              });
              
              rightHeight += rightSpaceRemaining;
              
              // Start a new page for remaining items
              if (nextPageItems.length > 0) {
                currentPage++;
                layout.pages.push({ leftSections: [], rightSections: [] });
                layout.pages[currentPage].leftSections.push({
                  ...sectionToProcess,
                  items: nextPageItems,
                  _isSplit: true,
                  _splitPart: 'second',
                  title: sectionToProcess.title || getCategoryTitle(sectionToProcess.type)
                });
                leftHeight = sectionHeight * (nextPageItems.length / sectionToProcess.items.length);
                rightHeight = 0;
              }
            } else {
              // Not enough space to split effectively, go to new page
              currentPage++;
              layout.pages.push({ leftSections: [], rightSections: [] });
              layout.pages[currentPage].leftSections.push({
                ...sectionToProcess,
                _isSplit: false,
                title: sectionToProcess.title || getCategoryTitle(sectionToProcess.type)
              });
              leftHeight = sectionHeight;
              rightHeight = 0;
            }
          } else {
            // Not enough space in either column, go to new page
            currentPage++;
            layout.pages.push({ leftSections: [], rightSections: [] });
            layout.pages[currentPage].leftSections.push({
              ...sectionToProcess,
              _isSplit: false,
              title: sectionToProcess.title || getCategoryTitle(sectionToProcess.type)
            });
            leftHeight = sectionHeight;
            rightHeight = 0;
          }
        }
      } else if (rightSpaceRemaining > avgItemHeight) {
        // Try to place part of the section in right column
        const itemsForRight = Math.floor(rightSpaceRemaining * 0.98 / avgItemHeight);
        
        if (itemsForRight > 0) {
          // Split the section
          const rightItems = sectionToProcess.items.slice(0, itemsForRight);
          const nextPageItems = sectionToProcess.items.slice(itemsForRight);
          
          // Add first part to right column
          layout.pages[currentPage].rightSections.push({
            ...sectionToProcess,
            items: rightItems,
            _isSplit: true,
            _splitPart: 'first',
            title: sectionToProcess.title || getCategoryTitle(sectionToProcess.type)
          });
          
          rightHeight += rightSpaceRemaining;
          
          // Start a new page for remaining items
          if (nextPageItems.length > 0) {
            currentPage++;
            layout.pages.push({ leftSections: [], rightSections: [] });
            layout.pages[currentPage].leftSections.push({
              ...sectionToProcess,
              items: nextPageItems,
              _isSplit: true,
              _splitPart: 'second',
              title: sectionToProcess.title || getCategoryTitle(sectionToProcess.type)
            });
            leftHeight = sectionHeight * (nextPageItems.length / sectionToProcess.items.length);
            rightHeight = 0;
          }
        } else {
          // Not enough space to split effectively, go to new page
          currentPage++;
          layout.pages.push({ leftSections: [], rightSections: [] });
          layout.pages[currentPage].leftSections.push({
            ...sectionToProcess,
            _isSplit: false,
            title: sectionToProcess.title || getCategoryTitle(sectionToProcess.type)
          });
          leftHeight = sectionHeight;
          rightHeight = 0;
        }
      } else {
        // Not enough space in either column, go to new page
        currentPage++;
        layout.pages.push({ leftSections: [], rightSections: [] });
        layout.pages[currentPage].leftSections.push({
          ...sectionToProcess,
          _isSplit: false,
          title: sectionToProcess.title || getCategoryTitle(sectionToProcess.type)
        });
        leftHeight = sectionHeight;
        rightHeight = 0;
      }
    }
  }

  return layout;
};

// Modified estimateSectionHeight function with further reduced spacing
const estimateSectionHeight = (section) => {
  if (!section || !section.items || !Array.isArray(section.items)) return 0;
  
  // Base height for header and footer - FURTHER REDUCED 
  let baseHeight = 50; // Reduced from 70
  
  // Height per item based on category - FURTHER REDUCED
  let itemHeight;
  switch (section.type) {
    case 'appointment':
      itemHeight = 25; // Reduced from 35
      break;
    case 'labs':
      itemHeight = 16; // Reduced from 22
      break;
    case 'procedures':
      itemHeight = 20; // Reduced from 28
      break;
    case 'medications':
      itemHeight = 25; // Reduced from 36
      break;
    case 'dispensed':
      itemHeight = 25; // Reduced from 36
      break;
    case 'supplements':
      itemHeight = 25; // Reduced from 36
      break;
    default:
      itemHeight = 20; // Reduced from 28
  }
  
  // Calculate height based on number of items with more aggressive scaling
  let adjustedItemHeight = itemHeight;
  if (section.items.length > 20) {
    adjustedItemHeight = itemHeight * 0.6; // More aggressive reduction for many items
  } else if (section.items.length > 15) {
    adjustedItemHeight = itemHeight * 0.65; // More aggressive reduction
  } else if (section.items.length > 10) {
    adjustedItemHeight = itemHeight * 0.7; // More reduction than before
  } else if (section.items.length > 5) {
    adjustedItemHeight = itemHeight * 0.8; // Slight reduction
  }
  
  return baseHeight + (section.items.length * adjustedItemHeight);
};


export const generatePriceList = async (
  treatmentId, 
  patientProfile, 
  printOptions = {}, 
  optimized = false, 
  type = 'treatment', 
  fdv = false
) => {
  try {
    const loadingMessage = message.loading("Generating price list...", 0);
    
    // Fetch order data from API with error handling
    let response;
    try {
      response = await axios.get(`/orderget-price-quote-with-prices/${treatmentId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      });
      
      if (response.status !== 200) {
        throw new Error(`Failed to fetch order data: ${response.statusText}`);
      }
    } catch (apiError) {
      console.error("API Error:", apiError);
      loadingMessage();
      message.error("Failed to fetch order data. Please try again.");
      return null;
    }
    
    // Extract data with validation
    const orderData = response.data || {};
    
    // Get basic info with fallbacks
    const orderDate = orderData?.orderDate
      ? moment(orderData.orderDate).format("MM/DD/YYYY")
      : moment().format("MM/DD/YYYY");
    
    const patientName = orderData?.patient?.name || patientProfile?.["Full Name"] || "Patient";
    const firstName = orderData?.patient?.firstName || patientProfile?.["First Name"] || "";
    const lastName = orderData?.patient?.lastName || patientProfile?.["Last Name"] || "";
    const patientAddress = orderData?.patient?.address || null;
    
    // Create PDF document
    let doc;
    try {
      doc = new jsPDF({
        orientation: "portrait",
        unit: "pt",
        format: "letter",
      });
    } catch (pdfError) {
      console.error("PDF Creation Error:", pdfError);
      loadingMessage();
      message.error("Failed to create PDF document. Please try again.");
      return null;
    }
    
    // Page dimensions
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 40;
    
    // Initialize pagination tracking
    let currentPage = 1;
    let totalPages = 1;
    
    // Store a reference to the original addPage function
    const originalAddPage = doc.addPage.bind(doc);
    
    // Override the addPage function to track page numbers
    doc.addPage = (...args) => {
      originalAddPage(...args);
      currentPage++;
      totalPages++;
      return doc;
    };
    
    // Logo function with better error handling
    const addLogo = async () => {
      return new Promise((resolve) => {
        try {
          const img = new Image();
          img.crossOrigin = "anonymous";
          
          // Set image loading timeout
          const timeout = setTimeout(() => {
            console.warn("Logo loading timeout");
            resolve(); // Continue without logo on timeout
          }, 3000);
          
          // Set image error handler
          img.onerror = () => {
            clearTimeout(timeout);
            console.warn("Error loading logo");
            resolve(); // Continue without logo on error
          };
          
          // Set image load handler
          img.onload = () => {
            clearTimeout(timeout);
            try {
              // Logo rendering logic
              const logoWidth = 200;
              const logoHeight = 60;
              const x = (pageWidth - logoWidth) / 2;
              
              // Simple direct rendering approach
              doc.addImage(img, "PNG", x, margin, logoWidth, logoHeight);
              resolve();
            } catch (renderError) {
              console.warn("Logo rendering error:", renderError);
              resolve(); // Continue without logo on rendering error
            }
          };
          
          // Set image source
          img.src = sunridgeLogo;
        } catch (logoError) {
          console.warn("Logo error:", logoError);
          resolve(); // Continue without logo on any error
        }
      });
    };
    
    // Function to create header with error handling
const createHeaderForPage = async (title = "PRICE LIST", fdv ) => {
  try {
    // Add logo
    await addLogo();

    const headerY = margin;

    // Company info on left side
    doc.setFont("helvetica", "bold");
    doc.setFontSize(10);
    doc.text("SUNRIDGE MEDICAL", margin, headerY);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(9);
    doc.text("14200 N Northsight Blvd, Suite 160,", margin, headerY + 12);
    doc.text("Scottsdale, AZ, 85260", margin, headerY + 24);
    doc.text("480-659-9135", margin, headerY + 36);
    doc.text("info@sunridgemedical.com", margin, headerY + 48);

    // Patient info on right side
    doc.setFont("helvetica", "bold");
    doc.setFontSize(10);
    doc.text("Patient Information", pageWidth - margin, headerY, { align: "right" });

    // Add patient name and address
    doc.setFont("helvetica", "normal");
    doc.setFontSize(9);
    doc.text(`${firstName} ${lastName}`, pageWidth - margin, headerY + 12, { align: "right" });
    
    let addressY = headerY + 24;
    if (patientAddress) {
      if (patientAddress.address1) {
        doc.text(patientAddress.address1, pageWidth - margin, addressY, { align: "right" });
        addressY += 12;
      }
      if (patientAddress.address2) {
        doc.text(patientAddress.address2, pageWidth - margin, addressY, { align: "right" });
        addressY += 12;
      }
      
      const cityStateZip = [
        patientAddress.city,
        patientAddress.province,
        patientAddress.zip
      ].filter(Boolean).join(", ");
      
      if (cityStateZip) {
        doc.text(cityStateZip, pageWidth - margin, addressY, { align: "right" });
        addressY += 12;
      }
      
      if (patientAddress.country) {
        doc.text(patientAddress.country, pageWidth - margin, addressY, { align: "right" });
      }
    } else {
      // Fallback if no address available
      doc.text("No address on file", pageWidth - margin, addressY, { align: "right" });
    }

    // Page title with blue accent color
    const titleY = 140;
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.setTextColor(41, 128, 185); // Blue color for title
    
    // Only show title if not in FDV mode
    if (!fdv) {
      doc.text(title || "PRICE LIST", pageWidth / 2, titleY, { align: "center" });
    }
    
    // Add date on the same line as title
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0); // Black text for date
    doc.text(orderDate || "", pageWidth - margin, titleY, { align: "right" });
    
    doc.setTextColor(0, 0, 0); // Reset to black

    return titleY + 5; // Return next Y position
  } catch (headerError) {
    console.warn("Header creation error:", headerError);
    // Return a fallback position if header creation fails
    return margin + 100;
  }
};
    
  
   // Function to add date with simpler display and moved up
const addDateBox = (yPosition) => {
  // Add date text directly without background, positioned higher
  const dateY = yPosition - 10; // Moved up by 5 points from the original position
  
  doc.setFont("helvetica", "normal");
  doc.setFontSize(10);
  doc.setTextColor(0, 0, 0); // Black text for better visibility
  doc.text(orderDate || "", pageWidth / 2, dateY + 17, { align: "center" });
  
  return dateY + 15; // Reduced spacing after date (was 30)
};
    // Robust checkNewPage function 
    const checkNewPage = (yPos, requiredSpace = 50) => {
      try {
        if (yPos + requiredSpace > pageHeight - margin - 30) { // Leave space for page number
          doc.addPage(); // This will increment currentPage and totalPages
          
          // Add a subtle header on new pages
          doc.setFont("helvetica", "italic");
          doc.setFontSize(10);
          doc.setTextColor(100, 100, 100); // Gray text
          doc.text("(continued)", pageWidth / 2, margin + 10, { align: "center" });
          
          // Reset to normal style
          doc.setFont("helvetica", "normal");
          doc.setFontSize(10);
          doc.setTextColor(0, 0, 0); // Reset to black
          
          return margin + 30; // Increased spacing after "continued" text
        }
        return yPos; // No change if no new page needed
      } catch (pageError) {
        console.warn("Check new page error:", pageError);
        // Return a fallback position if page check fails
        return margin;
      }
    };
    
    // Function to add page numbers to all pages with error handling
    const addPageNumbers = () => {
      try {
        totalPages = doc.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          doc.setFont("helvetica", "normal");
          doc.setFontSize(8);
          doc.setTextColor(100, 100, 100);
          doc.text(
            `Page ${i} of ${totalPages}`, 
            pageWidth / 2, 
            pageHeight - 20, 
            { align: "center" }
          );
        }
      } catch (pageNumError) {
        console.warn("Page numbering error:", pageNumError);
        // Continue without page numbers if there's an error
      }
    };
    
    // Generate the PDF based on mode with error handling
    try {
      if (optimized) {
        // Generate single-page optimized price list with improved layout
        await createMainPriceListPage(
          doc, 
          orderData, 
          orderDate, 
          margin, 
          pageWidth, 
          pageHeight, 
          createHeaderForPage, 
          optimized, 
          addDateBox, 
          type,
          printOptions, 
          fdv,
          checkNewPage
        );
      } else {
        // Generate detailed multi-page price list using the original approach
        // The detailed view still follows ALPDS order for consistency with existing implementation
        const pageOrder = ["appointment", "labs", "procedures", "dispensed", "supplements"];
        let firstPage = true;
        
        for (const category of pageOrder) {
          // Special handling for appointment
          if (category === "appointment") {
            let appointmentCharge = 0;
            if (orderData.appointmentType && type !== 'treatment') {
              switch(orderData.appointmentType) {
                case 'initial':
                  appointmentCharge = 250;
                  break;
                case 'follow-up':
                  appointmentCharge = 75;
                  break;
              }
            }
            
            if (appointmentCharge > 0 || orderData.appointmentType === 'lab-review') {
              if (!firstPage) {
                doc.addPage();
              }
              
              const detailsY = await createHeaderForPage("Appointment Details", fdv);
              let startY = detailsY;
              if (firstPage) {
                startY = addDateBox(detailsY);
              }
              
              createAppointmentDetailsPage(doc, orderData, startY, margin, pageWidth);
              firstPage = false;
            }
            
            continue;
          }
          let lastCategory = "";
          // Regular category handling
          if (printOptions[category] && 
            ((category === "labs" && orderData.labs && orderData.labs.length > 0) ||
             (category === "procedures" && orderData.procedures && orderData.procedures.length > 0) ||
             (category === "dispensed" && orderData.dispensed && orderData.dispensed.length > 0))) {
          
          // Check if we need a new page
          if (!firstPage) {
            doc.addPage();
          }
          
          // Check if this is a continuation of the previous category
          const isContinuation = lastCategory === category;
          
          // Update header with continuation flag
          const detailsY = await createHeaderForPage(getCategoryTitle(category), fdv, isContinuation);
          let startY = detailsY;
          if (firstPage) {
            startY = addDateBox(detailsY);
          }
          
          createDetailPage(doc, category, orderData[category], startY, margin, pageWidth, fdv);
          firstPage = false;
          lastCategory = category;
        }
      
          
          // Special handling for supplements
          if (category === "supplements" && printOptions.supplements) {
            let supplementsData = [];
            
            if (printOptions.newSupplements !== false && orderData.newSupplements && orderData.newSupplements.length > 0) {
              const processedNewSupplements = orderData.newSupplements.map(item => ({
                ...item,
                _source: 'newSupplements'
              }));
              supplementsData = [...supplementsData, ...processedNewSupplements];
            }
            
            if (printOptions.activeSupplements && orderData.activeMedications && orderData.activeMedications.length > 0) {
              const processedActiveMedications = orderData.activeMedications.map(item => ({
                ...item,
                _source: 'activeMedications'
              }));
              supplementsData = [...supplementsData, ...processedActiveMedications];
            }
            
            if (supplementsData.length > 0) {
              if (!firstPage) {
                doc.addPage();
              }
              
              const detailsY = await createHeaderForPage("Supplements");
              let startY = detailsY;
              if (firstPage) {
                startY = addDateBox(detailsY);
              }
              
              createDetailPage(doc, "supplements", supplementsData, startY, margin, pageWidth, fdv);
              firstPage = false;
            }
          }
        }
      }
    } catch (renderError) {
      console.error("PDF rendering error:", renderError);
      loadingMessage();
      message.error("Error rendering PDF content. Please try again.");
      return null;
    }

    // Add page numbers to all pages before finalizing
    addPageNumbers();

    // Prepare the PDF for download with error handling
    try {
      const pdfBlob = doc.output("blob");
      loadingMessage();
      
      const sanitizedPatientName = patientName.replace(/[^a-zA-Z0-9]/g, "_");
      const sanitizedOrderDate = orderDate.replace(/\//g, "-");
      const filename = `Sunridge_Medical_${sanitizedPatientName}_${sanitizedOrderDate}_PriceList.pdf`;
      
      return {
        blob: pdfBlob,
        url: URL.createObjectURL(pdfBlob),
        filename: filename
      };
    } catch (outputError) {
      console.error("PDF output error:", outputError);
      loadingMessage();
      message.error("Error creating final PDF. Please try again.");
      return null;
    }
  } catch (error) {
    console.error("Error generating price list:", error);
    message.error("Failed to generate price list");
    return null;
  }
};

export default generatePriceList;