import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Input, message, Segmented, Table } from 'antd';
import { EyeFilled, SearchOutlined } from '@ant-design/icons';
import { Segment } from '@mui/icons-material';
import axios from 'axios';
import moment from 'moment';
import PatientFormSubmissions from '../../../../Components/PatientFormSubmissions';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

const PatientsList = ({ setSelectedPatient, searchValue, setSearchValue , handleShowPatientForm}) => {
  dayjs.extend(utc);
  const [patients, setPatients] = useState([])
  const [status, setStatus] = useState('Active')
  const [total, setTotal] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'First Name',
      key: 'First Name',
      render: (text, record) => (
        <div>
          <div>{text}</div>
          <div style={{ fontSize: '12px', color: 'gray' }}>{record['Customer ID']}</div>
        </div>
      ),
    },
    {
      title: 'Last Name',
      dataIndex: 'Last Name',
      key: 'lastName',
    },
    {
      title: 'Date of Birth',
      dataIndex: 'DOB',
      key: 'dob',
      render: (DOB) => (
        <h1>{DOB ? dayjs.utc(DOB).format('MM-DD-YYYY') : ""}</h1>
      )

    },
    {
      title: 'Contact Info',

      key: 'contactInfo',
      render: (record) => {
        return (
          <div className='flex gap-3'>
            <h1>{record?.Email[0]?.email}</h1>
            {record?.phoneNumbers[0]?.number &&
              <>
                <h1>|</h1>
                <h1>{record?.phoneNumbers[0]?.number}</h1>
              </>
            }
          </div>
        )
      }
    },
    {
      title: '',
      width: 150, 
      fixed:'right',
      key: 'viewForm',
      render: (record) => {
        return (
          <div className='flex gap-2 flex-row-reverse'>
           
            {/* <Button style={{ whiteSpace: 'none' }} type='default' icon={<EyeFilled/>} onClick={(e) => {
              e.stopPropagation()
              handleRowClick(record)
              handleShowPatientForm(true)
            }
            }>View Form</Button> */}
            {record?.formLink?.isApproved === false &&
              record?.formLink?.isSubmitted === true &&
              record?.formLink?.linkGenerated === true && (
                <p className='bg-orange-300 text-white w-[150px] p-2 text-center rounded-lg'>Approval Pending</p>
              )}

            {record?.formLink?.isSubmitted === false &&
              record?.formLink?.isApproved === false &&
              record?.formLink?.linkGenerated === true && (
                <p className='bg-orange-300 text-white w-[150px] p-2 text-center rounded-lg'>Submission Pending</p>
              )}


            {record?.formLink?.linkGenerated === true &&
              record?.formLink?.isSubmitted !== false &&
              record?.formLink?.isApproved !== false && (
                <p className='bg-orange-300 text-white w-[150px] p-2 text-center rounded-lg'>Link Generated</p>
              )}

          </div>
        )
      }
    },
  ];

  const handleCreateEMR = async (patientId) => {
    try {
      const response = await axios.post('/emr', { patientId: patientId,values:{} });
      console.log('response of emr api', response.data)
    } catch (error) {
      console.error("Error saving EMR data:", error);
      message.error("Failed to save EMR data");
    }
  };

  const handleRowClick = async (record) => {
    try{
      await axios.put(`/patients/${record._id}/last-opened`);
      setSelectedPatient(record);
      handleCreateEMR(record._id)
    }
    catch(error){
      console.error("Error:", error)
    }
  };
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value)
  }

  const fetchPatientProfiles = async (page, pageSize, search, status) => {
    setLoading(true);
    try {
      const { data } = await axios.get('/patient-profiles-with-form-details-with-pagination', {
        params: {
          page,
          pageSize,
          search,
          status
        },
      });
      setPatients(data.users);
      setTotal(data.total); 
    } catch (err) {
      console.error('Error fetching users', err);
    } finally {
      setLoading(false);
    }
  }

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPagination({ current: 1, pageSize: pagination.pageSize }); 
  };

  const handleTableChange = (pagination) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  useEffect(() => {
    fetchPatientProfiles(pagination.current, pagination.pageSize, searchTerm, status);
  }, [pagination.current, pagination.pageSize, searchTerm, status]);
  
  return (
    <>
      <div className='flex flex-col gap-4'>
        <div className='flex items-center gap-2'>

          <Input suffix={<SearchOutlined />} placeholder='Search Patient' className='w-[30%]' value={searchTerm} onChange={handleSearch} />
          <Checkbox checked={status === 'Inactive'} onChange={(event) => event.target.checked ? setStatus('Inactive') : setStatus('Active')}>Show Inactive</Checkbox>
        <PatientFormSubmissions />
        </div>

        <Table
          dataSource={patients}
          columns={columns}
          loading={loading}
          onRow={(record) => ({
            onClick: (e) => {
              e.stopPropagation()
              handleRowClick(record)},
            className: 'cursor-pointer',
          })}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total,
            onChange: (page, pageSize) => {
              handleTableChange({ current: page, pageSize });
            },
          }}
        />
      </div >
    </>)
};

export default PatientsList;
