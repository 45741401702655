import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Row, Col, message } from "antd";
import logo from "../../Assets/Logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {Button} from 'antd'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import { GoogleLogin } from "@react-oauth/google";
import Loader from "../../Components/Loader";
const Login = () => {
  // State to hold the userName and password values
  const location = useLocation();
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);

  const handleLogin = async () => {
    setError("");
    if (!userName.trim() || !password.trim()) {
      return setError("All fields are required.");
    }

    setLoader(true);
    try {
      const response = await axios.post(`/auth/signin`, {
        username: userName,
        password,
      });

      if (response.status === 200) {
        toast.success(response.data.message);
        localStorage.clear("user");
        localStorage.clear("sessionToken");
        localStorage.setItem("sessionToken", response.data.token);

        localStorage.setItem("user", response.data.type);
        setLoader(false);
        // Cookies.set('admin_cookie', response.data.userId, { expires: 1, path: "/", });
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    } catch (error) {
      setLoader(false);

      toast.error(error.response.data.error);

      console.error("Signup Error:", error);
    }
  };

  const handleGoogleLogin = () => {
    console.log("Initiating Google login");
    window.location.href =  "/auth/auth/google";
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handleSignUp = () => {
    navigate("/signup");
  };
  const [messageApi, contextHolder] = message.useMessage();


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token"); // Fetch the token from the URL query
    const idToken = queryParams.get("id_token"); // Fetch the token from the URL query
    const errorMsg = queryParams.get("error");

    if (token) {
      console.log("Token received:", token);
      console.log("Id Token received:", idToken);
      verifyToken(idToken,token); // Call verifyToken with the received ID token
    } else if (errorMsg) {
      console.log("Error received:", errorMsg);
      toast.error(decodeURIComponent(errorMsg));
    }
  }, [location, navigate]);
  
  const verifyToken = async (idToken,token) => {
    setLoader(true);
    try {
      const response = await fetch("/auth/verify-token", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token:idToken }), // Send the token to your backend for verification
      });
      const data = await response.json();
      setLoader(false);

      if (response.status === 200) {
        localStorage.setItem("sessionToken", token); // Store session token from response
        localStorage.setItem("user", data.type);
        navigate("/"); // Redirect to home or dashboard on success
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
    }
  };

  
  return (
    <>
      {/* Loader */}
      {loader && <Loader />}

      <div>
        {/* Login form main container */}
        <div className={`${styles.loginCotainerLoginView}`}>
          {/* Login form fields */}
          <div className={styles.login_box}>
            {/* Logo Image */}
            <div className={`${styles.logo}`}>
              <img className={styles.img1} src={logo} alt="Logo" />
            </div>

            <div className={styles.login_header}>Sign In to Your Account</div>
            {/* <div className={`${styles.login_label} font-semibold`}>Username</div> */}
            <input
              type="userName"
              className={`${styles.login_input} border border-gray-200 px-4`}
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              placeholder="Username"
              onKeyDown={handleKeyPress}
            />
            {/* <div className={styles.login_label}>Password</div> */}
            <input
              type="password"
              className={`${styles.login_input} border border-gray-200 mt-2`}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              onKeyDown={handleKeyPress}
            />
            {/* login button */}
            <div
              className={`${styles.login_btn} font-semibold`}
              onClick={handleLogin}
            >
              Log In
            </div>
            {/* error */}
            {error && <div className={styles.error_message}>{error}</div>}
            {/* Google login aid */}
            {/* <GoogleLogin
              onSuccess={(credentialResponse) => {
                handleGoogleLogin(credentialResponse);
              }}
              onError={() => {}}
              theme="filled_blue"
              shape="circle"
              useOneTap
            /> */}
             <Button
              onClick={handleGoogleLogin}
              className="w-full flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
                <path
                  fill="#EA4335"
                  d="M5.266 9.765A7.077 7.077 0 0 1 12 4.909c1.69 0 3.218.6 4.418 1.582L19.91 3C17.782 1.145 15.055 0 12 0 7.27 0 3.198 2.698 1.24 6.65l4.026 3.115Z"
                />
                <path
                  fill="#34A853"
                  d="M16.04 18.013c-1.09.703-2.474 1.078-4.04 1.078a7.077 7.077 0 0 1-6.723-4.823l-4.04 3.067A11.965 11.965 0 0 0 12 24c2.933 0 5.735-1.043 7.834-3l-3.793-2.987Z"
                />
                <path
                  fill="#4A90E2"
                  d="M19.834 21c2.195-2.048 3.62-5.096 3.62-9 0-.71-.109-1.473-.272-2.182H12v4.637h6.436c-.317 1.559-1.17 2.766-2.395 3.558L19.834 21Z"
                />
                <path
                  fill="#FBBC05"
                  d="M5.277 14.268A7.12 7.12 0 0 1 4.909 12c0-.782.125-1.533.357-2.235L1.24 6.65A11.934 11.934 0 0 0 0 12c0 1.92.445 3.73 1.237 5.335l4.04-3.067Z"
                />
              </svg>
              Sign in with Google
            </Button>
            {/* Button */}
            <div className={styles.login_signup}>
              New Here?{" "}
              <span className={styles.login_signup_highlight}>
                Get Access from Gio Franco
              </span>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
};

export default Login;
