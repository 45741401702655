  import { DeleteOutlined, DownOutlined, InfoCircleOutlined, PlusOutlined, SendOutlined } from '@ant-design/icons';
  import { Print } from '@mui/icons-material';
  import { Fab } from '@mui/material';
  import { Button, Checkbox, ConfigProvider, DatePicker, Dropdown, Form, Input, Menu, message, Modal, Pagination, Segmented, Select, Space, Spin, Tooltip } from 'antd';
  import axios from 'axios';
  import moment from 'moment';
  import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
  import styles from '../../EMR.module.css';
  import PrintModal from '../PrintModal';
  import SupplementsModal from '../../../Treatmentview_FAST/Components/SupplementsModal';
  import { toast } from 'react-toastify';
  import LPDPopup from '../../../../Components/LPDPopup';
  import LPDVIconButtons from '../LPDVIconButtons';
  import dayjs from "dayjs";
  import { Option } from 'antd/es/mentions';
  import TextArea from 'antd/es/input/TextArea';
  import ReceiptModal from '../Shopifyreceipt/ReceiptPrintOptionsModal';
  import CreateOrderModal from '../../../../Components/CreateOrderModal';
  import { FilePlus2 } from 'lucide-react';
  import CreateTreatmentFromExistingOrder from '../../../../Components/CreateTreatmentFromExistingOrder';
  const SummarySection = forwardRef(({ addTab, selectedPatient, selectedChiefConcern, setSelectedChiefConcern, fromSoapNote = false }, ref) => {

    const [receiptModalVisible, setReceiptModalVisible] = useState(false);
    const [type,setType] = useState()
    const [receiptModalId,setReceiptModalId]=useState();
    if(receiptModalId){
      console.log("checking the id",receiptModalId)
    }
    const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'))
    const [addCustomMedication, setAddCustomMedication] = useState()
    const [addCustomICDCode, setAddCustomICDCode] = useState(false)
    const [medications, setMedications] = useState([])
    const [icd10CMCodes, setICD10CMCodes] = useState([])
    const [encounters, setEncounters] = useState([])
    const [encountersLoading, setEncountersLoading] = useState(false)
    const [medicationInputValue, setMedicationInputValue] = useState('')
    const [icdName, setICDName] = useState('')
    const [icdCode, setICDCode] = useState('')
    const [frequencyInputValue, setFrequencyInputValue] = useState('')
    const [printModalVisible, setPrintModalVisible] = useState(false);
    const [activeTreatment, setActiveTreatment] = useState({});
    const [chiefConcernFormField, setChiefConcernFormField] = useState("");
    const [activeTab, setActiveTab] = useState('All'); 
    const [filteredEncounters, setFilteredEncounters] = useState(encounters); 
    const [referencedSoapNotes, setReferencedSoapNotes] = useState([]); 
    const [page, setPage] = useState(1);
    const [limit] = useState(10);
    const [total, setTotal] = useState(0);
    const [printMedications, setPrintMedications] = useState(false);
    const [form] = Form.useForm();
    const [isOrderModalVisible, setIsOrderModalVisible] = useState(false);
    const [selectedPatientSupplements, setSelectedPatientSupplements] = useState([]);
    const [soapNoteCreatedFromLPD, setsoapNoteCreatedFromLPD] = useState(false);
    const [historicalSupplements, setHistoricalSupplements] = useState([]);
    const [patientActiveMedications, setPatientActiveMedications] = useState([]);
    const [activeDispensedItems, setActiveDispensedItems] = useState([]);
    const [activeSupplementsExcludingPatientSupplements, setActiveSupplementsExcludingPatientSupplements] = useState([]);
    const [g6pdcleared, setG6PDCleared] = useState(false);
    const [isCreateTreatmentModalOpen , setIsCreateTreatmentModalOpen ] = useState(false);
    const [selectedOrderForCreateTreatmentModal , setSelectedOrderForCreateTreatmentModal ] = useState({});
    const [addFieldValue, setAddFieldValue] = useState({
      healthConcerns: false,
      diagnoses: false,
      familyHealthHistory: false,
      pastMedicalHistory: {
        majorEvents: false,
        ongoingMedicalProblems: false,
        preventiveCare: false,
        socialHistory: false,
        nutritionHistory: false,
        developmentHistory: false,
      },
      implantableDevices: false,
      goals: false,
    })
    const [otherValue, setOtherValue] = useState(""); 
    const [healthGoals, setHealthGoals] = useState([
      { id: "cognitive", label: "Improve cognitive function", checked: false },
      { id: "energy", label: "Increase energy", checked: false },
      { id: "fatigue", label: "Minimize symptoms of fatigue", checked: false },
      { id: "depression", label: "Improve depression", checked: false },
      { id: "sleep", label: "Improve sleep", checked: false },
      { id: "mood", label: "Stabilize mood", checked: false },
      { id: "osteoporosis", label: "Reduce risk of osteoporosis", checked: false },
      { id: "pain", label: "Reduce joint pain", checked: false },
      { id: "minimize_symptoms_of_menopause", label: "Minimize symptoms of menopause", checked: false },
      { id: "reduce_cancer_risk_care_for_cancer", label: "Reduce cancer risk/care for cancer", checked: false },
      { id: "improve_overall_health", label: "Improve overall health", checked: false },
      { id: "reduce_cardiovascular_disease", label: "Reduce cardiovascular disease", checked: false },
      { id: "improve_memory", label: "Improve memory", checked: false },
      { id: "migraines", label: "Reduce headaches/migraines", checked: false },
      { id: "reduce_food_allergies", label: "Reduce food allergies", checked: false },
      { id: "improve_digestive_issues", label: "Improve digestive issues", checked: false },
      { id: "other", label: "Other", checked: false },
    ]);

    const [familyHealthHistoryOptions, setFamilyHealthHistoryOptions] = useState([
      { id: "asthma", label: "Asthma", checked: false },
      { id: "autoimmune_disease", label: "Autoimmune disease", checked: false },
      { id: "fatigue", label: "Minimize symptoms of fatigue", checked: false },
      { id: "cancer", label: "Cancer", checked: false },
      { id: "heart_disease", label: "Heart disease", checked: false },
      { id: "diabetes", label: "Diabetes", checked: false },
      { id: "high_blood_pressure", label: "High blood pressure", checked: false },
      { id: "mental_illness", label: "Mental illness", checked: false },
      { id: "stroke", label: "Stroke", checked: false },
      { id: "other", label: "Other", checked: false },
    ]);

    useEffect(() => {
      getAllSoapNotes(page);
    }, [page]);

    const handleOtherChange = (parentKey,key,value) => {
      setOtherValue(value);
      if(parentKey){
        form.setFieldValue([`${parentKey}`,`${key}`],value);

      }
      else{
        form.setFieldValue(key,value);
      }
    };
    const handleGoalsCheckboxChange = (id) => {
      const updatedHealthGoals = healthGoals.map((item) => {
        if (item.id === id) {
          return { ...item, checked: !item.checked };
        }
        return item;
      });
    
      setHealthGoals(updatedHealthGoals);
    
      const selectedHealthGoals = updatedHealthGoals
        .filter((item) => item.checked)  
        .map((item) => item.label);
    
      form.setFieldValue(["goals","value"], selectedHealthGoals);
    
      updateGoals();
    };
    
    const handleFamilyHealthHistoryCheckboxChange = (id) => {
      const updatedFamilyHealthHistoryOptions = familyHealthHistoryOptions.map((item) => {
        if (item.id === id) {
          return { ...item, checked: !item.checked };
        }
        return item;
      });
    
      setFamilyHealthHistoryOptions(updatedFamilyHealthHistoryOptions);
    
      const selectedFamilyHealthHistoryOptions = updatedFamilyHealthHistoryOptions
        .filter((item) => item.checked)
        .map((item) => item.label);
    
      form.setFieldValue(["familyHealthHistory",'value'], selectedFamilyHealthHistoryOptions);
      updateFamilyHealthHistory();
    };

    const updateGoals = () => {
      handleFieldBlur('goals.otherValue')
    }
    const updateFamilyHealthHistory = () => {
      handleFieldBlur('familyHealthHistory.otherValue')
    }

    const containerRef = useRef(null);
    const soapNoteCreatedRef = useRef(false);
    const toggleSecondaryField = (key, parentKey = null) => {
      if (parentKey) {
        setAddFieldValue((prevState) => ({
          ...prevState,
          [parentKey]: {
            ...prevState[parentKey],
            [key]: !prevState[parentKey][key],
          },
        }));
      } else {
        setAddFieldValue((prevState) => ({
          ...prevState,
          [key]: !prevState[key],
        }));
      }
    };


    const goalsTooltipContent = (
      <div style={{ maxWidth: "200px", color: "black" }}>
        {healthGoals.map((item) => (
          <div key={item.id} style={{ display: "flex", alignItems: "flex-start", gap: 5 }}>
            <Checkbox
              checked={item.checked}
              onChange={() => handleGoalsCheckboxChange(item.id)}
              style={{ color: "black" }}
            />
            <p>{item.label}</p>
          </div>
        ))}
    
        {/* Show text field if "Other" is checked */}
        {healthGoals.some((item) => item.id === "other" && item.checked) && (
          <Input
            value={form.getFieldValue(['goals','otherValue'])}
            onChange={(event) => handleOtherChange('goals','otherValue', event.target.value)}
            placeholder="Enter other details"
            onBlur={()=>{
              updateGoals()
            }}
            style={{ marginTop: 5, width: "100%" }}
          />
        )}
      </div>
    );
    

    const familyHealthHistoryTooltipContent = (
      <div style={{ maxWidth: "200px", color: "black" }}>
        {familyHealthHistoryOptions.map((item) => (
          <div key={item.id} style={{ display: "flex", alignItems: "flex-start", gap: 5 }}>
            <Checkbox
              checked={item.checked}
              onChange={() => handleFamilyHealthHistoryCheckboxChange(item.id)}
              style={{ color: "black" }}
            />
            <p>{item.label}</p>
          </div>
        ))}
    
        {/* Show text field if "Other" is checked */}
        {familyHealthHistoryOptions.some((item) => item.id === "other" && item.checked) && (
          <Input
            value={form.getFieldValue(['familyHealthHistory','otherValue'])}
            onChange={(event) => handleOtherChange('familyHealthHistory','otherValue', event.target.value)}
            placeholder="Enter other details"
            onBlur={()=>{
              updateFamilyHealthHistory()
            }}
            style={{ marginTop: 5, width: "100%" }}
          />
        )}
      </div>
    );

    const handleSendValue = (parentKey, key) => {
      const mainValue = form.getFieldValue(parentKey ? [parentKey, key] : key) || '';

      const secondaryValue = form.getFieldValue(`${key}Secondary`) || '';    
      if(secondaryValue.trim() && key === "medicationsupplements"){
        addSupplement(secondaryValue)
        setAddFieldValue((prevState) => ({
          ...prevState,
          [parentKey ? parentKey : key]: false,
        }));
        setTimeout(() => handleFieldBlur(key), 100);
      }
      else if (secondaryValue.trim()) {
        if (parentKey) {
          form.setFieldsValue({
            [parentKey]: {
              ...form.getFieldValue(parentKey),
              [key]: mainValue ? `${mainValue}\n${secondaryValue}` : secondaryValue,
            },
            [`${key}Secondary`]: '',
          });
        } else {
          form.setFieldsValue({
            [key]: mainValue ? `${mainValue}\n${secondaryValue}` : secondaryValue,
            [`${key}Secondary`]: '',
          });
        }
        if (parentKey) {
          handleFieldBlur(`${parentKey}.${key}`);
        } else {
          handleFieldBlur(key);
        }
        setAddFieldValue((prevState) => ({
          ...prevState,
          [parentKey ? parentKey : key]: false,
        }));
      }
    };

    const [formData, setFormData] = useState({
      currentMedications: "no",
      medicationSupplements: "no",
    });
    
    const addSupplement = (newValue) => {
      setFormData((prev) => {
        let updatedSupplements = prev.medicationSupplements;
        let updatedCurrentMedications = prev.currentMedications;
    
        if (prev.currentMedications === "no") {
          updatedCurrentMedications = "yes"; 
          updatedSupplements = newValue; 
        } else {
          updatedSupplements = `${prev.medicationSupplements}\n${newValue}`;
        }
    
        return {
          ...prev,
          currentMedications: updatedCurrentMedications,
          medicationSupplements: updatedSupplements,
        };
      });
    };



    const fetchEMRData = async () => {
      try {
        const response = await axios.get(`/emr/${selectedPatient._id}`)
        if (response.data) {
          form.setFieldsValue(response.data)
          const goals = form.getFieldValue('goals')
          const familyHealthHistoryOptions = form.getFieldValue('familyHealthHistory')
          setHealthGoals((prevGoals) =>
            prevGoals.map((goal) => ({
              ...goal,
              checked: goals?.value.includes(goal.label),
            }))
          );
          setFamilyHealthHistoryOptions((prevOptions) =>
            prevOptions.map((option) => ({
              ...option,
              checked: familyHealthHistoryOptions.value.includes(option.label),
            }))
          );
          setFormData({
            currentMedications:response.data.currentMedications,
            medicationSupplements:response.data.medicationsupplements
          })
          setSelectedChiefConcern(form.getFieldValue('chiefConcern'))
        }
        else {
          form.setFieldsValue({})
        }
      } catch (error) {
        console.error('Error fetching EMR data', error);
      }
    }

    const getAllSoapNotes = async (pageNumber) => {
      setEncountersLoading(true)
      try {

        // const response = await axios.get(`/get-soap-notes/${selectedPatient._id}/${selectedPatient['Customer ID']}`, {
        //   headers: {
        //     Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
        //   }
        // })
        const response = await axios.get(`/get-all-encounters/${selectedPatient._id}/${selectedPatient['Customer ID']}?page=${pageNumber}&limit=${limit}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
          }
        })
        if (response.data) {
          // const encounters= response.data.soapNotes;
          // const outputArray = [];
          // const seenOrders = new Set();

  //         encounters.forEach(item => {
  //           if (item.relatedOrders.length > 1) {
  //             item.relatedOrders.forEach(order => {
  //               // Create a unique identifier for each order using its _id
  //               if (!seenOrders.has(order._id)) {
  //                 seenOrders.add(order._id);
  //                 outputArray.push({
  //                   ...item,
  //                   relatedOrders: [order]
  //                 });
  //               }
  //             });
  //     } else {
  //         outputArray.push(item);
  //     }
  // });
          setTotal(response.data.total);
          setEncounters(response.data.data)
          setEncountersLoading(false)
        }
      }
      catch (error) {
        console.log("Error fetching all notes", error)
        setEncountersLoading(false)
      }
    }

    const handleTabSelect = (tab) => {
      setActiveTab(tab);
    };

    useEffect(() => {
      const filterEncounters = () => {
        if (activeTab === 'All') {
          return encounters;
        }
        console.log('***************** get encounters',encounters)
      
        if (activeTab === 'Treatments') {
          return encounters.filter(encounter => encounter?.type && encounter?.type === 'Treatment');
        }
      
        if (activeTab === 'Orders') {
          return encounters.filter(encounter => encounter?.type && (encounter?.type === 'Order' || encounter?.type === 'pickup'));
        }

        if (activeTab === 'SOAP') {
          return encounters.filter(encounter => !Object.keys(encounter).includes("type"));
        }
      
        return encounters;
      };
      

      setFilteredEncounters(filterEncounters());
    }, [activeTab, encounters]); 


    useEffect(() => {
      if (selectedPatient) {
        // getAllSoapNotes();
        fetchEMRData();
        fetchPatientProfile(selectedPatient['Customer ID'])
        fetchActiveDispensedItems(selectedPatient['Customer ID'])
        fetchActiveSupplementsExcludingPatientPlanSupplements(selectedPatient['Customer ID'])
        fetchAllMedications()
        fetchAllICD10CMCodes()
        fetchAllSOAPNotesWithTreatmentReference()
        fetchSupplementNames()
      }
    }, [selectedPatient])

    const handleSelectChiefConcern = (value) => {
      setSelectedChiefConcern(value);
      setChiefConcernFormField(value);
      form.setFieldsValue({ chiefConcern: value });
    };

    
    

    const handleBlur = async (values) => {
      try {
        const response = await axios.post('/emr', { patientId: selectedPatient._id, ...values });
        console.log('response of emr api', response.data)
        message.success(response.data.message);
      } catch (error) {
        console.error("Error saving EMR data:", error);
        message.error("Failed to save EMR data");
      }
    };

    const handleFieldBlur = (field) => {
      form.validateFields([field]).then(() => {
        const values = form.getFieldsValue(true);
        const updatedData = {...values, currentMedications:formData.currentMedications, medicationsupplements:formData.medicationSupplements}
        handleBlur(updatedData);
      });
    };

    const addMedication = async (medication) => {
      try {
        const response = await axios.post('/emr/add-medication', { patientId: selectedPatient._id, medication });
        console.log('response of emr api', response.data)
        if (response.status === 200) {
          setMedications([...response.data.medications])
          message.success(response.data.message);
          setMedicationInputValue('')
          setFrequencyInputValue('')
          addCustomMedication(false)
        }

      } catch (error) {
        console.error("Error saving EMR data:", error);
        message.error(error.response.data.message);
      }
    }

    const handleAddMedication = () => {
      if (medicationInputValue.trim() && frequencyInputValue.trim()) {
        const medication = { supplementName: medicationInputValue.trim(), frequency: frequencyInputValue.trim(), isCustom : true }
        addMedication(medication)
      }
    }

    const fetchAllMedications = async () => {
      try {
        const response = await axios.get(`/emr/get-medications/${selectedPatient._id}`);
        console.log('response of emr api', response.data)
        setMedications(response.data)
      } catch (error) {
        console.error("Error saving EMR data:", error);
      }
    }


    const fetchAllSOAPNotesWithTreatmentReference = async () => {
      try {
        const response = await axios.get(`/get-soap-notes/${selectedPatient._id}`,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
          }
        });
        console.log('response of emr api', response.data)
        setReferencedSoapNotes(response.data)
      } catch (error) {
        console.error("Error saving EMR data:", error);
      }
    }

    const fetchAllICD10CMCodes = async () => {
      try {
        const response = await axios.get(`/get-codes`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
          }
        });
        setICD10CMCodes(response.data)
      } catch (error) {
        console.error("Error saving EMR data:", error);
      }
    }

    const addICD10CMCode = async (icdCode, icdName) => {
      try {
        const response = await axios.post('/add-code', { name: icdName, code: icdCode }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
          }
        });
        console.log('response of emr api', response.data)
        if (response.status === 200 || response.status === 201) {
          setICD10CMCodes([...icd10CMCodes, { name: icdName, code: icdCode }])
          message.success(response.data.message);
          setICDName('');
          setICDCode('');
          setAddCustomICDCode(false);
        }

      } catch (error) {
        console.error("Error saving EMR data:", error);
        message.error(error.response.data.message);
      }
    }
    const handleAddICD10CMCode = () => {
      if (icdName.trim() && icdCode.trim()) {
        addICD10CMCode(icdCode, icdName)
      }
    }

    const closeModal = () => {
      setPrintMedications(false);
      setPrintModalVisible(false);
    };

    const handleUpdateCustomName = async (id) => {
      const encounter = filteredEncounters?.find(item => (item._id === id));
      let customName = "";
      if (encounter?.type) {
        customName = encounter.custom
      }
      else {
        customName = encounter.customName
      }
      if (id) {
        try {
          const response = await axios.post(`/update-encounter-custom-name`, {
            encounterId:encounter._id,
            patientId: selectedPatient._id,
            customName
          }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
            }
          });
          if(response.status===200){
            message.success("Custom name for encounter updated")
          }
        } catch (error) {
          console.error("Error saving EMR data:", error);
        }
      }
    }

    const handleChangeCustomName = (customNameValue, id) => {
      const encounter = filteredEncounters?.find(encounter => (encounter._id === id));
      setFilteredEncounters((prevState) =>
        prevState.map((item) => {
          if (encounter._id === item._id) {
            if(encounter?.type){
              return { ...item, custom: customNameValue };
            }else{
              return { ...item, customName: customNameValue };
            }
          }
          return item;
        })
      );
    };

    const [showModal, setShowModal] = useState(false)
    const [modalType, setModalType] = useState(false)
    const [activeTreatmentId, setActiveTreatmentId] = useState(false)
    function splitByLastDash(columnName) {
      // Find the index of the last dash
      const lastIndex = columnName.lastIndexOf("-");

      // If there's no dash, or the dash is the first character, return an error or handle it as needed
      if (lastIndex <= 0) {
        console.error(
          "No dash found, or the dash is at the start of the string"
        );
        return columnName;
      }

      // Split the string into two parts
      const itemName = columnName.substring(0, lastIndex);
      const field = columnName.substring(lastIndex + 1);

      return [itemName, field];
    }
    const handleCellChange = (
      patientId,
      columnName,
      newValue,
      category,
      itemData,
      review
    ) => {
      let payload;
      if (category === "vitals") {
        // Make sure newValue is an array and directly assign it to updates
        payload = {
          category: category,
          updates: newValue, // Ensure this is correctly formatted as an array of vitals
        };
      } else if (category === "patientLabs") {
        payload = {
          category: category,
          updates: {
            checked: newValue,
            type: "item_number",
            value: itemData.item_number,
          },
        };
      } else if (category === "newpatientLab") {
        payload = {
          category: 'patientLabs',
          newData: {
            checked: newValue,
            isNew: true,
            test_name: itemData?.test_name,
          },
        };
      } else {
        // Handling for other categories remains the same
        const identifierKey =
          category === "userProcedures" ? "procedure_name" : "name";
        let [itemName, field] = splitByLastDash(columnName);

        if (category === "patientInfo") {
          field = columnName;
        }

        payload = {
          category: category,
          identifier: {
            key: identifierKey,
            value: itemName,
          },
          updates: {
            [field]: newValue,
          },
          newData:itemData,
        };
      }

      // Send the update request using axios
      axios
        .put(
          `/patient/order/update/${patientId}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        )
        .then((response) => {
          getAllSoapNotes(page)
          fetchActiveDispensedItems(selectedPatient['Customer ID'])
          fetchActiveSupplementsExcludingPatientPlanSupplements(selectedPatient['Customer ID'])
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    const updateStartDate = async (patientId, medicationId, date) => {
      try {
        const startDate= date.toISOString()
          const response = await axios.put(`/emr/update-start-date/${patientId}/${medicationId}`, {
              startDate
          });

          if (response.status === 200) {
              console.log('Start date updated successfully:', response.data);
              const updatedMedications= medications.map((item)=>{
                if(item._id===response.data.medication._id){
                  return {...item, startDate:response.data?.medication?.startDate}
                }
                else return item
              })
              setMedications(updatedMedications)
          }
      } catch (error) {
          console.error('Error updating start date:', error.response?.data || error.message);
          alert('Failed to update start date. Please try again.');
      }
  };
  const updateEndDate = async (patientId, medicationId, date) => {
    try {
      const endDate= date.toISOString()
        const response = await axios.put(`/emr/update-end-date/${patientId}/${medicationId}`, {
            endDate
        });

        if (response.status === 200) {
            console.log('End date updated successfully:', response.data);
            return response.data;
        }
    } catch (error) {
        console.error('Error updating end date:', error.response?.data || error.message);
        alert('Failed to update end date. Please try again.');
    }
  };

  function getBackgroundColor(type) {  
    
    if(type){
    // if (type === 'Treatment' && type === 'Order') {
    //   return 'bg-yellow-400 hover:!bg-yellow-300'; 
    // } 
    if (type === 'Treatment') {
      return 'bg-blue-500 hover:!bg-blue-400'; 
    }
    else if (type === 'Order' || type === 'pickup') {
      return 'bg-orange-500 hover:!bg-orange-400'; 
    }} else return 'bg-gray-200';
  }

    const refreshAllEncounters = () => {
      getAllSoapNotes(page)
    }

    const supplementsSortingFunction = (items) => {
      const filledItems = items.filter((item) => item.checked).sort((a, b) => a.originalOrder - b.originalOrder);
      const unfilledItems = items.filter((item) => !item.checked).sort((a, b) => a.originalOrder - b.originalOrder);
      return [...filledItems, ...unfilledItems];
    };
    const fetchAllSupplements = async (treatment) => {
      try {
        const response = await axios.get("/getAllSupplements");
        const updatedSupplements = treatment?.userSupplements.map((supp) => {
          const matchedSupplement = response.data.find(
            (supplement) => supplement.supplement_name === supp.name
          );
    
          if (matchedSupplement) {
            return {
              ...supp,
              frequencyOptions: [...matchedSupplement.frequency],
            };
          }
          return supp;
        });
        const updatedTreatment = { ...treatment, userSupplements: supplementsSortingFunction([...updatedSupplements]) };
        return updatedTreatment
      } catch (error) {
        console.error("Error fetching supplements:", error);
        // setShowModal(false);    
      }
    };

      const handleSupplementCheckboxChange = (supId,frequency) => {
        const updatedSupplements= {
          ...selectedPatientSupplements,
          [supId]: selectedPatientSupplements[supId]
            ? { ...selectedPatientSupplements[supId], checked: true, frequency: frequency ? frequency : "" }
            : { checked: true, frequency: frequency ? frequency : "" },
        }
        setSelectedPatientSupplements(updatedSupplements);
      };
      const [defaultSupplements, setDefaultSupplements] = useState([]);

      const fetchSupplementNames = async () => {
        try {
          const response = await axios.get(
            `/defaultSupplements`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              },
            }
          );
      
          const supplements = response.data.map((supplement) => {
            
            if (!supplement.frequency) {
              supplement.frequency = [];
            }
            return supplement;
          });
      
          setDefaultSupplements(supplements);
        } catch (error) {
          console.error("Error fetching supplement names:", error);
          throw error;
        }
      };

        const fetchPatientProfile = async (patientCustomerId) => {
          try {
            const response = await axios.get(`/patient-profiles/${patientCustomerId}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              },
            });
            if(response.status ===200){
              setPatientActiveMedications(response?.data?.RecommendedSupplements)
              setHistoricalSupplements(response?.data?.HistoricalSupplements)
              setG6PDCleared(response?.data?.G6PD)
            }
          } catch (error) {
            console.error("Error fetching patient profile:", error);
          }
        };
  // This part of code is temporarily commented to disable synchronization between Order supplements and Patient Treatment Plan Supplements 
        // useEffect(() => {
        //   if ( selectedPatient) {
        //     const loadPatientData = async () => {
        //       const patientProfile = await fetchPatientProfile(selectedPatient['Customer ID']);
        //       if (!patientProfile) return;
        
        //       const initialSelectedSupplements = {};
        
        //       patientProfile.RecommendedSupplements?.forEach((recSupp) => {
        //         const matchingSupplement = defaultSupplements.find(
        //           (sup) => sup.supplement_name === recSupp.supplementName
        //         );
        //         if (matchingSupplement) {
        //           initialSelectedSupplements[matchingSupplement._id] = {
        //             checked: true,
        //             frequency: recSupp.frequency,
        //             inHistory: false,
        //             startDate: recSupp.startDate,
        //             endDate: recSupp.endDate,
        //           };
        //         }
        //       });
        
        //       patientProfile.HistoricalSupplements?.forEach((histSupp) => {
        //         const matchingSupplement = defaultSupplements.find(
        //           (sup) => sup.supplement_name === histSupp.supplementName
        //         );
        //         if (matchingSupplement) {
        //           initialSelectedSupplements[matchingSupplement._id] = {
        //             checked: false,
        //             frequency: histSupp.frequency,
        //             inHistory: true,
        //             startDate: histSupp.startDate,
        //             endDate: histSupp.endDate,
        //           };
        //         }
        //       });
        //       setSelectedPatientSupplements(initialSelectedSupplements);
        //     };
        
        //     loadPatientData();
        //   }
        // }, [selectedPatient, defaultSupplements, modalType, activeTab]);

              // This part of code is temporarily commented to disable synchronization between Order supplements and Patient Treatment Plan Supplements    
      //  const handleSubmitSupplements = (e) => {
      //       const selectedData = Object.entries(selectedPatientSupplements)
      //         .filter(([, value]) => value.checked)
      //         .map(([key, value]) => {
      //           const supplement = defaultSupplements.find((sup) => sup._id === key);
      //           return {
      //             supplementName: supplement.supplement_name,
      //             frequency: value.frequency,
      //             startDate: value.startDate
      //           };
      //         });
        
      //       const profileData = {
      //         patientId: selectedPatient['Customer ID'],
      //         RecommendedSupplements: selectedData,
      //       };
        
      //       axios
      //         .post(
      //           "/Historical-RecommendedSupplements",
      //           profileData,
      //           {
      //             headers: {
      //               Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
      //             },
      //           }
      //         )
      //         .then((response) => {
      //           setSelectedPatientSupplements([])
      //         })
      //         .catch((error) => {
      //           message.error("Failed to update patient supplements data!");
      //           console.error(error);
      //         });
      //     };

          const [activeTooltip, setActiveTooltip] = useState(null);
          const menuRef = useRef(null);
        
          // Close tooltip when clicking outside
          useEffect(() => {
            const handleClickOutside = (event) => {
              if (menuRef.current && !menuRef.current.contains(event.target)) {
                setActiveTooltip(null);
              }
            };
        
            if (activeTooltip !== null) {
              document.addEventListener("mousedown", handleClickOutside);
            } else {
              document.removeEventListener("mousedown", handleClickOutside);
            }
        
            return () => document.removeEventListener("mousedown", handleClickOutside);
          }, [activeTooltip]);

    const deleteTreatment = async (treatmentId) => {
        try {
    
          const sessionToken = localStorage.getItem('sessionToken');
          if (!sessionToken) {
            throw new Error('No session token found');
          }
    
          const response = await axios.delete(
            `/order-and-soap-reference/delete/${treatmentId}`,
            {
              headers: {
                Authorization: `Bearer ${sessionToken}`,
              },
            }
          );
    
    
          if (response.status === 200) {
            message.success("Treatment deleted successfully");
            setTimeout(() => getAllSoapNotes(page), 200);
            // const updatedFilteredEncounters = filteredEncounters.filter(
            //   (treatment) => treatment._id !== treatmentId
            // );
            // setFilteredEncounters(updatedFilteredEncounters);
            
          } else {
            message.error("Failed to delete treatment");
          }
        } catch (error) {
          console.error("Error deleting treatment:", error);
          message.error("Error deleting treatment");
        }
      };

    const deleteSoapNote = async (id) => {
      const note = referencedSoapNotes?.find(soapNote => soapNote.treatmentId === id || soapNote._id === id)
      try {
        const sessionToken = localStorage.getItem('sessionToken');
        if (!sessionToken) {
          throw new Error('No session token found');
        }

        const response = await axios.delete(
          `/soap-note/${note._id}`,
          {
            headers: {
              Authorization: `Bearer ${sessionToken}`,
            },
          }
        );


        if (response.status === 200) {
          message.success("SOAP note deleted successfully");
          setTimeout(() => getAllSoapNotes(page), 1000);
          const updatedFilteredEncounters = referencedSoapNotes.filter(
            (item) => item._id !== note._id
          );
          setReferencedSoapNotes(updatedFilteredEncounters);
        } else {
          message.error("Failed to delete soap note");
        }
      } catch (error) {
        console.error("Error deleting soap note:", error);
        message.error("Error deleting soap note");
      }
    }

    const deleteBoth = (id) => {
      deleteTreatment(id)
      deleteSoapNote(id)
    }  

    const fetchActiveDispensedItems = async (patientCustomerId) => {
      try {
        const response = await axios.get(`/active-dispensed-items/${patientCustomerId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        });
        if(response.status ===200){
          setActiveDispensedItems(response?.data)
        }
      } catch (error) {
        console.error("Error fetching patient profile:", error);
      }
    };

    const fetchActiveSupplementsExcludingPatientPlanSupplements = async (patientCustomerId) => {
      try {
        const response = await axios.get(`/active-supplements-items/${patientCustomerId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        });
        if(response.status ===200){
          setActiveSupplementsExcludingPatientSupplements(response?.data)
        }
      } catch (error) {
        console.error("Error fetching patient profile:", error);
      }
    };

    const handleG6PDChange = (value) => {
        updatePatientProfile(selectedPatient._id, {G6PD: value});
    };
    
    const updatePatientProfile = async (patientId, updatedDetails) => {
        try {
          const response = await axios.put(
            `/patient-profiles/${patientId}`,{...updatedDetails},
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              },
            }
          );
          if (!response.status===200) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          message.success("Patient profile updated successfully");
          setG6PDCleared(response?.data?.G6PD)
          // Handle successful update here
        } catch (error) {
          console.error("Error updating patient profile:", error);
        }
      };

      const handleCloseCreateTreatmentModal = () => {
        setSelectedOrderForCreateTreatmentModal({})
        setIsCreateTreatmentModalOpen(false)
        refreshAllEncounters()
      }

      const handleSelectOrderForCreateTreatmentModal=(order)=>{
        setSelectedOrderForCreateTreatmentModal(order)
      }

      const handleOpenCreateTreatmentModal = () => {
        setIsCreateTreatmentModalOpen(true)
      }
    return (
      <div>
        {!fromSoapNote &&
          <div className='flex items-center justify-between'>
            <Tooltip title="Print">
              <Button icon={<Print />} iconPosition='start' style={{ margin: '15px 0', fontSize: 16, cursor: 'pointer', marginRight: 5 }} className="cursor-pointer"
                onClick={() => setPrintModalVisible(true)}>
                Print Chart
              </Button>
            </Tooltip>
            <Checkbox checked={g6pdcleared} onChange={(event) => {
              if (event.target.checked) {
                handleG6PDChange(true)
              }
              else {
                handleG6PDChange(false)
              }
            }}
            >
              <p className='text-base font-medium'>
                G6PD Cleared
              </p>
            </Checkbox>
          </div>
        }
        <Form
          form={form}
          layout="vertical"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >

        <div className={`w-full grid ${fromSoapNote ? 'grid-cols-1' : 'grid-cols-[1fr_1fr_1.5fr]'} ${fromSoapNote ? 'h-[650px]' : ''} overflow-auto gap-4 bg-transparent`}>
            <div className={`w-full flex  ${fromSoapNote ? 'h-full' : ''}   justify-center shadow-sm rounded-md border-gray-200 border-[1px]`}>
              <div className='flex flex-col gap-6 p-3 w-full'>
                <div className='w-full border-gray-200 border-[1px] rounded-md p-2'>
                  <div
                    className="w-full mb-2 flex items-center justify-between">
                    <p className="font-bold text-lg">Health Concerns</p>
                    <Button
                      type="text"
                      shape="default"
                      icon={<PlusOutlined className='text-black' />}
                      onClick={() => toggleSecondaryField('healthConcerns')}
                      className="hover:bg-slate-200 text-gray-400 border-none"
                    />
                  </div>
                  <Form.Item
                    name="healthConcerns"
                    className='mb-0'
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 3 }}
                      placeholder='Enter health concerns'
                      onBlur={() => handleFieldBlur('healthConcerns')}
                    />
                  </Form.Item>
                  {addFieldValue['healthConcerns'] && (
                    <SecondaryInput
                      fieldName="healthConcerns"
                      handleSendValue={handleSendValue}
                      placeholder="Add more details for Health Concerns"
                    />
                  )}
                </div>


                <div
                  className='w-full border-gray-200 border-[1px] rounded-md p-2'>
                  <div
                    className="w-full mb-2 flex items-center justify-between">
                    <p className="font-bold text-lg">Diagnoses</p>
                    <Button
                      type="text"
                      shape="default"
                      icon={<PlusOutlined className='text-black' />}
                      onClick={() => toggleSecondaryField('diagnoses')}
                      className="hover:bg-slate-200 text-gray-400 border-none"
                    />
                  </div>
                  <Form.Item
                    name="diagnoses"
                    className='mb-0'
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 3 }}
                      placeholder='Enter diagnoses'
                      onBlur={() => handleFieldBlur('diagnoses')}
                    />
                  </Form.Item>
                  {addFieldValue['diagnoses'] && (
                    <SecondaryInput
                      fieldName="diagnoses"
                      handleSendValue={handleSendValue}
                      placeholder="Add more details for Diagnoses"
                    />
                  )}
                </div>
                <div className='w-full border-gray-200 border-[1px] rounded-md p-2'>
                  <div
                    className="w-full mb-2 flex items-center justify-between">
                    <p className="font-bold text-lg">Family Health History</p>
                    <Tooltip
                      title={familyHealthHistoryTooltipContent}
                      placement="bottom"
                      color='white'
                      overlayInnerStyle={{padding: "10px", borderRadius: "8px" }}
                      onOpenChange={(open)=>{
                        if(open){
                          if(familyHealthHistoryOptions.find((item) => item.id === "other").checked ===true){
                            setOtherValue(form.getFieldValue("familyHealthHistory"))
                          }
                        }
                      }}
                    >
                      <Button type="default">View Options</Button>
                    </Tooltip>
                    {/* <Button
                      type="text"
                      shape="default"
                      icon={<PlusOutlined className='text-black' />}
                      onClick={() => toggleSecondaryField('familyHealthHistory')}
                      className="hover:bg-slate-200 text-gray-400 border-none"
                    /> */}
                  </div>
                  {/* <Form.Item
                    name="familyHealthHistory"
                    className='mb-0'
                  > */}
                  <Input.TextArea
                    autoSize={{ minRows: 3 }}
                    placeholder='Enter family health history'
                    // onBlur={() => handleFieldBlur('familyHealthHistory')}
                    disabled={true}
                    style={{ color: "black", backgroundColor: "#f5f5f5" }}
                    value={(
                      form.getFieldValue("familyHealthHistory")?.value
                        ? [...form.getFieldValue("familyHealthHistory").value.filter((item)=>item!=='Other'), form.getFieldValue("familyHealthHistory").otherValue]
                          .join(", ")
                        : ""
                    )}
                  />

                  {/* </Form.Item>
                  {addFieldValue['familyHealthHistory'] && (
                    <SecondaryInput
                      fieldName="familyHealthHistory"
                      handleSendValue={handleSendValue}
                      placeholder="Add more details for family health history"
                    />
                  )} */}
                </div>

                <div className='flex flex-col w-full border-gray-200 border-[1px] rounded-md p-2'>
                  <div className='font-bold text-lg'>Past Medical History</div>
                  <Form.Item
                    name={['pastMedicalHistory', 'majorEvents']}>
                    <div
                      className="w-full mb-2 flex items-center justify-between">
                      <p className="font-medium text-base mb-2">Major Events</p>
                      <Button
                        type="text"
                        shape="default"
                        icon={<PlusOutlined className='text-black' />}
                        onClick={() => toggleSecondaryField('majorEvents', 'pastMedicalHistory')}
                        className="hover:bg-slate-200 text-gray-400 border-none"
                      />
                    </div>
                    <Form.Item
                      name={['pastMedicalHistory', 'majorEvents']}
                      className='mb-0'
                    >
                      <Input.TextArea
                        autoSize={{ minRows: 3 }}
                        placeholder='Enter major events'
                        onBlur={() => handleFieldBlur('pastMedicalHistory.majorEvents')}
                      />
                    </Form.Item>
                    {addFieldValue['pastMedicalHistory']?.majorEvents && (
                      <SecondaryInput
                        fieldName="majorEvents"
                        parentKey="pastMedicalHistory"
                        handleSendValue={handleSendValue}
                        placeholder="Add more details for Major Events"
                      />)}
                  </Form.Item>
                  <Form.Item
                    name={['pastMedicalHistory', 'ongoingMedicalProblems']}>
                    <div className="w-full mb-2 flex items-center justify-between">
                      <p className="font-medium text-base mb-2">Ongoing Medical Problems</p>
                      <Button
                        type="text"
                        shape="default"
                        icon={<PlusOutlined className='text-black' />}
                        onClick={() => toggleSecondaryField('ongoingMedicalProblems', 'pastMedicalHistory')}
                        className="hover:bg-slate-200 text-gray-400 border-none"
                      />
                    </div>
                    <Form.Item
                      name={['pastMedicalHistory', 'ongoingMedicalProblems']}
                      className='mb-0'
                    >
                      <Input.TextArea
                        autoSize={{ minRows: 3 }}
                        placeholder='Enter ongoing medical problem'
                        onBlur={() => handleFieldBlur('pastMedicalHistory.ongoingMedicalProblems')}
                      />
                    </Form.Item>
                    {addFieldValue['pastMedicalHistory']?.ongoingMedicalProblems && (
                      <SecondaryInput
                        fieldName="ongoingMedicalProblems"
                        parentKey="pastMedicalHistory"
                        handleSendValue={handleSendValue}
                        placeholder="Add more details for ongoing medical problems"
                      />)}
                  </Form.Item>
                  <Form.Item
                    name={['pastMedicalHistory', 'preventiveCare']}>
                    <div
                      className="w-full mb-2 flex items-center justify-between">
                      <p className="font-medium text-base mb-2">Preventive Care</p>
                      <Button
                        type="text"
                        shape="default"
                        icon={<PlusOutlined className='text-black' />}
                        onClick={() => toggleSecondaryField('preventiveCare', 'pastMedicalHistory')}
                        className="hover:bg-slate-200 text-gray-400 border-none"
                      />
                    </div>
                    <Form.Item
                      name={['pastMedicalHistory', 'preventiveCare']}
                      className='mb-0'

                    >
                      <Input.TextArea
                        autoSize={{ minRows: 3 }}
                        placeholder='Enter preventive care'
                        onBlur={() => handleFieldBlur('pastMedicalHistory.preventiveCare')}
                      />
                    </Form.Item>
                    {addFieldValue['pastMedicalHistory']?.preventiveCare && (
                      <SecondaryInput
                        fieldName="preventiveCare"
                        parentKey="pastMedicalHistory"
                        handleSendValue={handleSendValue}
                        placeholder="Add more details for preventive care"
                      />)}
                  </Form.Item>
                  <Form.Item
                    name={['pastMedicalHistory', 'socialHistory']}>
                    <div
                      className="w-full mb-2 flex items-center justify-between">
                      <p className="font-medium text-base mb-2">Social History</p>
                      <Button
                        type="text"
                        shape="default"
                        icon={<PlusOutlined className='text-black' />}
                        onClick={() => toggleSecondaryField('socialHistory', 'pastMedicalHistory')}
                        className="hover:bg-slate-200 text-gray-400 border-none"
                      />
                    </div>
                    <Form.Item
                      name={['pastMedicalHistory', 'socialHistory']}
                      className='mb-0'
                    >
                      <Input.TextArea
                        autoSize={{ minRows: 3 }}
                        placeholder='Enter social history'
                        onBlur={() => handleFieldBlur('pastMedicalHistory.socialHistory')}
                      />
                    </Form.Item>
                    {addFieldValue['pastMedicalHistory']?.socialHistory && (
                      <SecondaryInput
                        fieldName="socialHistory"
                        parentKey="pastMedicalHistory"
                        handleSendValue={handleSendValue}
                        placeholder="Add more details for social history"
                      />)}
                  </Form.Item>
                  <Form.Item
                    name={['pastMedicalHistory', 'nutritionHistory']}>
                    <div
                      className="w-full mb-2 flex items-center justify-between">
                      <p className="font-medium text-base mb-2">Nutrition History</p>
                      <Button
                        type="text"
                        shape="default"
                        icon={<PlusOutlined className='text-black' />}
                        onClick={() => toggleSecondaryField('nutritionHistory', 'pastMedicalHistory')}
                        className="hover:bg-slate-200 text-gray-400 border-none"
                      />
                    </div>
                    <Form.Item
                      name={['pastMedicalHistory', 'nutritionHistory']}
                      className='mb-0'
                    >
                      <Input.TextArea
                        autoSize={{ minRows: 3 }}
                        placeholder='Enter nutrition history'
                        onBlur={() => handleFieldBlur('pastMedicalHistory.nutritionHistory')}
                      />
                    </Form.Item>
                    {addFieldValue['pastMedicalHistory']?.nutritionHistory && (
                      <SecondaryInput
                        fieldName="nutritionHistory"
                        parentKey="pastMedicalHistory"
                        handleSendValue={handleSendValue}
                        placeholder="Add more details for nutrition history"
                      />)}
                  </Form.Item>
                  <Form.Item
                    name={['pastMedicalHistory', 'developmentHistory']}>
                    <div
                      className="w-full mb-2 flex items-center justify-between">
                      <p className="font-medium text-base mb-2">Developmental History</p>
                      <Button
                        type="text"
                        shape="default"
                        icon={<PlusOutlined className='text-black' />}
                        onClick={() => toggleSecondaryField('developmentHistory', 'pastMedicalHistory')}
                        className="hover:bg-slate-200 text-gray-400 border-none"
                      />
                    </div>
                    <Form.Item
                      name={['pastMedicalHistory', 'developmentHistory']}
                      className='mb-0'
                    >
                      <Input.TextArea
                        autoSize={{ minRows: 3 }}
                        placeholder='Enter developmental history'
                        onBlur={() => handleFieldBlur('pastMedicalHistory.developmentHistory')}
                      />
                    </Form.Item>
                    {addFieldValue['pastMedicalHistory']?.developmentHistory && (
                      <SecondaryInput
                        fieldName="developmentHistory"
                        parentKey="pastMedicalHistory"
                        handleSendValue={handleSendValue}
                        placeholder="Add more details for development history"
                      />)}
                  </Form.Item>
                </div>

                {!fromSoapNote && (
          <Form.Item
            name="chiefConcern"
            className="w-full border-gray-200 border-[1px] rounded-md p-2"
          >
            <div className="w-full mb-2 flex items-center justify-between">
              <p className="font-bold text-lg">Chief Concern</p>
              <Button
                type="text"
                shape="default"
                icon={<PlusOutlined className="text-black" />}
                onClick={() => setAddCustomICDCode(!addCustomICDCode)}
                className="hover:bg-slate-200 text-gray-400 border-none"
              />
            </div>
            {addCustomICDCode && (
              <div className="flex gap-2 fadeIn 1s ease-in-out duration-300 my-4">
                <Input.TextArea
                  style={{ width: '45%', flexGrow: 1 }}
                  autoSize={{ minRows: 1 }}
                  type="text"
                  placeholder="Enter Code"
                  value={icdCode}
                  onChange={(event) => setICDCode(event.target.value)}
                />
                <Input.TextArea
                  style={{ width: '45%' }}
                  autoSize={{ minRows: 1 }}
                  type="text"
                  placeholder="Enter Name"
                  value={icdName}
                  onChange={(event) => setICDName(event.target.value)}
                />
                <Fab
                  sx={{
                    fontSize: 12,
                    height: 28,
                    width: 28,
                    minHeight: 'unset',
                    minWidth: 'unset',
                    padding: 0,
                  }}
                  onClick={handleAddICD10CMCode}
                >
                  <SendOutlined />
                </Fab>
              </div>
            )}
            <Select
              placeholder="Select ICD-10 Code"
              showSearch
              optionFilterProp="children"
              value={form.getFieldValue('chiefConcern')} 
              onChange={handleSelectChiefConcern}
              onBlur={() => handleFieldBlur('chiefConcern')}
              filterOption={(input, option) =>
                (option?.children || '').toLowerCase().includes(input.toLowerCase())
              }
            >
              {icd10CMCodes.map(({ code, name }) => (
                <Select.Option key={code} value={code}>
                  {`${code} - ${name}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
              </div>
            </div>
            <div className={`${fromSoapNote ? 'h-full' : ''}  flex  justify-center shadow-sm rounded-md border-gray-200 border-[1px]`}>
              <div className='p-3 w-full flex flex-col gap-6'>
                <div className=' flex flex-col gap-4 w-full border-gray-200 border-[1px] rounded-md p-2'>
                  <div className='font-bold text-lg'>Allergies</div>

                  <Form.Item label={<p className='font-medium text-base'>Drug</p>} name={['allergies', 'drug']} className='mb-0'>
                    <Input.TextArea
                      autoSize={{ minRows: 1 }}
                      placeholder='Enter drug allergies'
                      onBlur={() => handleFieldBlur(`allergies[drug]`)}
                    />
                  </Form.Item>
                  <Form.Item label={<p className='font-medium text-base'>Food</p>} name={['allergies', 'food']} className='mb-0'>
                    <Input.TextArea
                      autoSize={{ minRows: 1 }}
                      placeholder='Enter food allergies'
                      onBlur={() => handleFieldBlur(`allergies[food]`)}
                    />
                  </Form.Item>

                  <Form.Item label={<p className='font-medium text-base'>Environmental</p>} name={['allergies', 'environmental']} className='mb-0'>
                    <Input.TextArea
                      autoSize={{ minRows: 1 }}
                      placeholder='Enter environmental allergies'
                      onBlur={() => handleFieldBlur(`allergies[environmental]`)}
                    />
                  </Form.Item>
                  <Form.Item label={<p className='font-medium text-base'>Medications</p>} name={['allergies', 'medications']} className='mb-0'>
                    <Input.TextArea
                      autoSize={{ minRows: 1 }}
                      placeholder='Enter medications allergies'
                      onBlur={() => handleFieldBlur(`allergies[medications]`)}
                    />
                  </Form.Item>
                </div>
                <div className='flex flex-col w-full border-gray-200 border-[1px] rounded-md p-2'>
                  <div className='w-full mb-2 flex items-center justify-between'>
                    <p className="font-bold text-lg mb-2">Medications</p>
                    <div className='flex items-center gap-2'>
                      {(medications.length > 0 || historicalSupplements.length > 0 || activeDispensedItems.length > 0 || activeSupplementsExcludingPatientSupplements.length > 0) && <Tooltip title="Print">
                        <Button icon={<Print />} iconPosition='start' style={{ margin: '15px 0', fontSize: 16, cursor: 'pointer', marginRight: 5 }} className="cursor-pointer"
                          onClick={() => {
                            setPrintMedications(true)
                          }}/>
                      </Tooltip>}
                      <SupplementsModal
                        selectedPatientId={selectedPatient['Customer ID']}
                        selectedPatient={selectedPatient}
                        toastifyToast={toast}
                        from={'emr'}
                        medications={medications}
                        setMedications={setMedications}
                        fetchAllMedications={fetchAllMedications}
                        refreshPatientProfile={fetchPatientProfile}
                        fetchActiveSupplementsExcludingPatientPlanSupplements={fetchActiveSupplementsExcludingPatientPlanSupplements}
                      />
                      {/* <Button
                        type="text"
                        shape="default"
                        icon={<PlusOutlined className='text-black' />}
                        onClick={() => setAddCustomMedication(!addCustomMedication)}
                        className="hover:bg-slate-200 text-gray-400 border-none"
                      /> */}
                    </div>
                  </div>
                  {/* Right now adding custom supplement is disabled */}
                  {/* {addCustomMedication &&
                    <div className='w-full flex-col gap-2'>
                      <div className='flex gap-2 fadeIn 1s ease-in-out duration-300 my-4'>
                        <Input.TextArea
                          style={{ width: '45%' }}
                          autoSize={{ minRows: 1 }}
                          type='text' placeholder='Enter Name' value={medicationInputValue} onChange={event => setMedicationInputValue(event.target.value)} />
                        <Input.TextArea
                          style={{ width: '45%', flexGrow: 1 }}
                          autoSize={{ minRows: 1 }}
                          type='text' placeholder='Enter Frequency' value={frequencyInputValue} onChange={event => setFrequencyInputValue(event.target.value)} />
                        <Fab sx={{
                          fontSize: 12,
                          height: 28,
                          width: 28,
                          minHeight: 'unset',
                          minWidth: 'unset',
                          padding: 0,
                        }}
                          onClick={handleAddMedication}
                        >
                          <SendOutlined />
                        </Fab>
                      </div>
                    </div>
                  } */}
                  {[...medications, ...activeSupplementsExcludingPatientSupplements].length > 0 && <p className='text-base font-medium'>Active: </p>}
                  {medications.length > 0 &&
                    <>
                      <p className='text-sm font-medium ml-3'>Patient Plan Supplements: </p>
                      <ul className="list-disc ml-5">
                        {[...medications].length > 0 && [...medications]?.map((medication) => (
                          <li key={medication.id} >
                            <div className='flex flex-col items-start'>
                              <div>
                                {medication.supplementName} {`(${medication.frequency})`}
                              </div>
                              {(medication.startDate || medication.endDate) &&
                                <div className="flex items-center gap-3">
                                  {medication.startDate && <div className="flex items-center gap-2"> <p className="text-green-500 font-semibold text-lg">S</p>  <p> {medication?.startDate ? dayjs(medication?.startDate).format('MM-DD-YYYY') : undefined}</p> </div>}
                                  {medication.endDate && <div className="flex items-center gap-2"> <p className="text-red-500 font-semibold text-lg">E</p>  <p> {medication?.endDate ? dayjs(medication?.endDate).format('MM-DD-YYYY') : undefined}</p> </div>}
                                </div>
                              }
                            </div></li>
                        ))
                        }
                      </ul>
                    </>
                  }
                  {activeSupplementsExcludingPatientSupplements.length > 0 &&
                    <>  
                      <p className='text-sm font-medium ml-3'>
                      Consolidated Supplements:
                        <Tooltip title={"These are all consolidated supplements from orders and treatments"} overlayStyle={{ maxWidth: 'none', whiteSpace: 'normal', padding: 2 }}>
                          <InfoCircleOutlined style={{ marginLeft: 4, cursor: 'pointer' }} />
                        </Tooltip> 
                      </p>
                      <ul className="list-disc ml-5">
                        {activeSupplementsExcludingPatientSupplements.length > 0 && [...activeSupplementsExcludingPatientSupplements]?.map((medication) => (
                          <li key={medication.id} >
                            <div className='flex flex-col items-start'>
                              <div>
                                {medication.name} {`(${medication.frequency})`}
                              </div>
                              {medication?.isCustom &&
                                <div className="flex items-center gap-3">
                                  <div className="flex items-center gap-2"> <p className="text-green-500 font-semibold text-lg">S</p>  <DatePicker value={medication?.startDate ? dayjs(medication.startDate) : undefined} onChange={(date) => updateStartDate(selectedPatient?._id, medication?._id, date)} allowClear={false} /> </div>
                                  <div className="flex items-center gap-2"> <p className="text-red-500 font-semibold text-lg">E</p>  <DatePicker value={medication?.endDate ? dayjs(medication.endDate) : undefined} onChange={(date) => updateEndDate(selectedPatient?._id, medication?._id, date)} allowClear={false} /> </div>
                                </div>
                              }
                              {medication?.isCustom === false &&
                                <div className="flex items-center gap-3">
                                  {medication.startDate && <div className="flex items-center gap-2"> <p className="text-green-500 font-semibold text-lg">S</p>  <p> {medication?.startDate ? dayjs(medication?.startDate).format('MM-DD-YYYY') : undefined}</p> </div>}
                                  {medication.endDate && <div className="flex items-center gap-2"> <p className="text-red-500 font-semibold text-lg">E</p>  <p> {medication?.endDate ? dayjs(medication?.endDate).format('MM-DD-YYYY') : undefined}</p> </div>}
                                </div>
                              }
                            </div></li>
                        ))
                        }
                      </ul>
                    </>
                  }
                  {historicalSupplements.length > 0 &&
                    <>
                      <p className='text-base font-medium'>Historical: </p>
                      <ul className="list-disc ml-5">
                        {historicalSupplements.length > 0 && historicalSupplements?.map((medication) => (
                          <li key={medication.id} >
                            <div className='flex flex-col items-start'>
                              <div>
                                {medication.supplementName} {`(${medication.frequency})`}
                              </div>
                              {(medication.startDate || medication.endDate) &&
                                <div className="flex items-center gap-3">
                                  {medication.startDate && <div className="flex items-center gap-2"> <p className="text-green-500 font-semibold text-lg">S</p>  <p> {medication?.startDate ? dayjs(medication?.startDate).format('MM-DD-YYYY') : undefined}</p> </div>}
                                  {medication.endDate && <div className="flex items-center gap-2"> <p className="text-red-500 font-semibold text-lg">E</p>  <p> {medication?.endDate ? dayjs(medication?.endDate).format('MM-DD-YYYY') : undefined}</p> </div>}
                                </div>
                              }
                            </div></li>
                        ))
                        }
                      </ul>
                    </>
                  }
                  {activeDispensedItems.length > 0 &&
                    <>
                      <p className='text-base font-medium'>Dispensed Items: </p>
                      <ul className="list-disc ml-5">
                        {activeDispensedItems.length > 0 && activeDispensedItems?.map((medication) => (
                          <li key={medication.id} >
                            <div className='flex flex-col items-start'>
                              <div>
                                {medication.name} {`(${medication.selectedField})`}
                              </div>
                              {medication.isCustom &&
                                <div className="flex items-center gap-3">
                                  <div className="flex items-center gap-2"> <p className="text-green-500 font-semibold text-lg">S</p>  <DatePicker value={medication?.startDate ? dayjs(medication.startDate) : undefined} onChange={(date) => updateStartDate(selectedPatient?._id, medication?._id, date)} allowClear={false} /> </div>
                                  <div className="flex items-center gap-2"> <p className="text-red-500 font-semibold text-lg">E</p>  <DatePicker value={medication?.endDate ? dayjs(medication.endDate) : undefined} onChange={(date) => updateEndDate(selectedPatient?._id, medication?._id, date)} allowClear={false} /> </div>
                                </div>
                              }
                              {medication.isCustom === false &&
                                <div className="flex items-center gap-3">
                                  {medication.startDate && <div className="flex items-center gap-2"> <p className="text-green-500 font-semibold text-lg">S</p>  <p> {medication?.startDate ? dayjs(medication?.startDate).format('MM-DD-YYYY') : undefined}</p> </div>}
                                  {medication.endDate && <div className="flex items-center gap-2"> <p className="text-red-500 font-semibold text-lg">E</p>  <p> {medication?.endDate ? dayjs(medication?.endDate).format('MM-DD-YYYY') : undefined}</p> </div>}
                                </div>
                              }
                            </div></li>
                        ))
                        }
                      </ul>
                    </>
                  }
                </div>


                <div className='w-full border-gray-200 border-[1px] rounded-md p-2'>
                  <div
                    className="w-full mb-2 flex items-center justify-between">
                    <p className="font-bold text-lg">Current Medications</p>
                    <Button
                      type="text"
                      shape="default"
                      icon={<PlusOutlined className='text-black' />}
                      onClick={() => toggleSecondaryField('medicationsupplements')}
                      className="hover:bg-slate-200 text-gray-400 border-none"
                    />
                  </div>
                  <Input.TextArea
                      autoSize={{ minRows: 3 }}
                      value={formData.medicationSupplements}
                      onChange={(e) => {
                        if (e.target.value.trim().toLowerCase() === 'no' || e.target.value.trim().toLowerCase() === "") {
                          setFormData((prev) => ({
                            ...prev,
                            currentMedications: "no",
                            medicationSupplements: e.target.value
                          }))
                        }
                        else if (e.target.value.trim().toLowerCase() !== 'no' || e.target.value.trim().toLowerCase() !== '') {
                          setFormData((prev) => ({
                            ...prev,
                            currentMedications: "yes",
                            medicationSupplements: e.target.value
                          }))
                        }
                      }
                      }
                      onBlur={()=>handleFieldBlur('medicationssupplements')}
                    />
                  {addFieldValue['medicationsupplements'] && (
                    <SecondaryInput
                      fieldName="medicationsupplements"
                      handleSendValue={handleSendValue}
                      placeholder="Add more details for implantable devices"
                    />
                  )} 
                </div>
                <div className='w-full border-gray-200 border-[1px] rounded-md p-2'>
                  <div
                    className="w-full mb-2 flex items-center justify-between">
                    <p className="font-bold text-lg">Implantable Devices</p>
                    <Button
                      type="text"
                      shape="default"
                      icon={<PlusOutlined className='text-black' />}
                      onClick={() => toggleSecondaryField('implantableDevices')}
                      className="hover:bg-slate-200 text-gray-400 border-none"
                    />
                  </div>
                  <Form.Item
                    name="implantableDevices"
                    className='mb-0'
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 1 }}
                      placeholder='Enter implantable devices'
                      onBlur={() => handleFieldBlur('implantableDevices')}
                    />
                  </Form.Item>
                  {addFieldValue['implantableDevices'] && (
                    <SecondaryInput
                      fieldName="implantableDevices"
                      handleSendValue={handleSendValue}
                      placeholder="Add more details for implantable devices"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={`${fromSoapNote ? 'h-full' : ''}  flex  justify-center shadow-sm rounded-md border-gray-200 border-[1px]`}>
              <div className='p-3 w-full flex flex-col gap-6'>

                <div className='w-full border-gray-200 border-[1px] rounded-md p-2'>
                  <div
                    className="w-full mb-2 flex items-center justify-between">
                    <p className="font-bold text-lg">Goals</p>
                    {/* Tooltip Button */}
                    <Tooltip
                      title={goalsTooltipContent}
                      placement="bottom"
                      color='white'
                      overlayInnerStyle={{padding: "10px", borderRadius: "8px" }}
                      onOpenChange={(open)=>{
                        if(open){
                          if(healthGoals.find((item) => item.id === "other").checked ===true){
                            setOtherValue(form.getFieldValue("goals"))
                          }
                        }
                      }}
                    >
                      <Button type="default">View Options</Button>
                    </Tooltip>

                    {/* <Button
                      type="text"
                      shape="default"
                      icon={<PlusOutlined className='text-black' />}
                      onClick={() => toggleSecondaryField('goals')}
                      className="hover:bg-slate-200 text-gray-400 border-none"
                    /> */}
                  </div>
                  {/* <Form.Item
                    name="goals"
                    className='mb-0'
                  > */}
                  <Input.TextArea
                    autoSize={{ minRows: 3 }}
                    placeholder='Enter goals'
                    // onBlur={() => handleFieldBlur('goals')}
                    disabled={true}
                    style={{ color: "black", backgroundColor: "#f5f5f5" }}
                    value={(
                      form.getFieldValue("goals")?.value
                        ? [...form.getFieldValue("goals").value.filter((item)=>item!=='Other'), form.getFieldValue("goals").otherValue]
                          .join(", ")
                        : ""
                    )}
                  />
                  { }
                  {/* {addFieldValue['goals'] && (
                    <SecondaryInput
                      fieldName="goals"
                      handleSendValue={handleSendValue}
                      placeholder="Add more details for goals"
                    />
                  )} */}
                </div>
                {!fromSoapNote && <div className='w-full border-gray-200 border-[1px] rounded-md p-2'>

                  <div className="w-full mb-2 flex flex-wrap items-center justify-between">
                    <p className="font-bold text-lg">Encounters</p>
                    <Button className="bg-black text-white ml-2" icon={<PlusOutlined />} onClick={() => setIsOrderModalVisible(true)} loading={false}>Create Order</Button>
                    <div className='flex flex-row flex-wrap items-start text-black gap-3'>
                      <div className='flex items-center gap-1'>
                        <div className="bg-gray-200 p-2 mx-1 rounded-xl"></div>
                        <p className='text-lg'>SOAP Note</p>
                      </div>
                      <div className='flex items-center gap-1'>
                        <div className="bg-blue-400 p-2 mx-1 rounded-xl"></div>
                        <p className='text-lg'>Treatment</p>
                      </div>
                      <div className='flex items-center gap-1'>
                        <div className="bg-orange-400 p-2 mx-1 rounded-xl"></div>
                        <p className='text-lg'>Order</p>
                      </div>
                      {/* <div className='flex items-center gap-1'>
                          <div className="bg-yellow-300 p-2 mx-1 rounded-xl"></div>
                          <p className='text-lg'>Both</p>
                        </div> */}
                    </div>
                    
                  
                  </div>

                  {
                    encountersLoading ? <Spin size="small" style={{ margin: '0 auto', width: '100%' }} /> : <div className='w-full items-center pr-2 flex flex-col gap-2'>
                      <ConfigProvider
                        theme={{
                          components: {
                            Segmented: {
                              itemSelectedBg: '#4CAF50',
                              itemSelectedColor:'white'
                            },
                          },
                        }}
                      >
                        <Segmented
                          options={['All', 'Treatments', 'Orders', 'SOAP']}
                          value={activeTab}
                          onChange={handleTabSelect}
                          size="large"
                          block
                          className='w-full font-medium'
                        />
                      </ConfigProvider>
                        {
                          filteredEncounters.length > 0 ?
                          <>
                          {filteredEncounters?.map((item) => {
                            return (  
                              <div ref={menuRef} className='relative w-full' key={item._id}>
                                <Tooltip
                                  open={activeTooltip === item._id}
                                  placement="bottom"
                                  color='white'
                                  key={item._id}
                                  overlayInnerStyle={{ backgroundColor: "white", pointerEvents: "auto" }} // Ensures clicks are registered
                                  overlay={
                                    <div className="bg-white text-black  rounded-md w-fit" onClick={(e) => {
                                      e.stopPropagation();
                                    }}>
                                      {(item.type === "Order" || item.type === "pickup") && (
                                        <div
                                          className="cursor-pointer flex items-center p-2 hover:bg-gray-100"
                                          onMouseDown={(e) => {
                                            e.stopPropagation();
                                            if (item?.review === true) {
                                              Modal.confirm({
                                                title: "Confirmation",
                                                content: "This treatment is finalized. Are you sure you want to delete it? ",
                                                okText: "Continue",
                                                cancelText: "Cancel",
                                                onOk: () => {
                                                  deleteTreatment(item._id);                                                
                                                  setTimeout(() => setActiveTooltip(null), 200);
                                                },
                                                okButtonProps: {
                                                  style: {
                                                    backgroundColor: "#007BFF",
                                                    borderColor: "#007BFF",
                                                    color: "#fff",
                                                  }
                                                },
                                              });
                                            } else {
                                              deleteTreatment(item._id);
                                              setTimeout(() => setActiveTooltip(null), 200);
                                            }

                                          }}
                                        >
                                          <DeleteOutlined className="mr-2" />
                                          Delete Order
                                        </div>
                                      )}
                                      {referencedSoapNotes?.some(soapNote => soapNote.treatmentId === item._id || soapNote._id === item._id) && (
                                        <div
                                          className="cursor-pointer flex items-center p-2 hover:bg-gray-100"
                                          onMouseDown={(e) => {
                                            e.stopPropagation();
                                            deleteSoapNote(item._id);
                                            setTimeout(() => setActiveTooltip(null), 100);
                                          }}
                                        >
                                          <DeleteOutlined className="mr-2" />
                                          Delete SOAP Note
                                        </div>
                                      )}
                                      {(item.type === 'Order' || item.type === 'pickup') && referencedSoapNotes?.some(soapNote => soapNote.treatmentId === item._id || soapNote._id === item._id) && (
                                        <div
                                          className="cursor-pointer flex items-center p-2 hover:bg-gray-100"
                                          onMouseDown={() => {

                                            if (item?.review === true) {
                                              Modal.confirm({
                                                title: "Confirmation",
                                                content: "This treatment is finalized. Are you sure you want to delete it? ",
                                                okText: "Continue",
                                                cancelText: "Cancel",
                                                onOk: () => {
                                                  deleteBoth(item._id);
                                                  setTimeout(() => setActiveTooltip(null), 100);
                                                },
                                                okButtonProps: {
                                                  style: {
                                                    backgroundColor: "#007BFF",
                                                    borderColor: "#007BFF",
                                                    color: "#fff",
                                                  }
                                                },
                                              });
                                            } else {
                                              deleteBoth(item._id);
                                              setTimeout(() => setActiveTooltip(null), 100);
                                            }

                                          }}
                                        >
                                          <DeleteOutlined className="mr-2" />
                                          Delete Both
                                        </div>
                                      )}
                                    </div>
                                  }
            >                        
                                  <div onContextMenu={(e) => {
                                    e.preventDefault();
                                    setActiveTooltip(activeTooltip === item._id ? null : item._id);
                                  }} className={`${getBackgroundColor(item?.type ?? "")} flex w-full items-center justify-between shadow-sm border-[1px] border-gray-200 px-4 py-3 rounded-xl cursor-pointer hover:bg-gray-100 bg-opacity-50 transition-all duration-300`} onClick={() => {
                                    if (item.createdAt){
                                  addTab(item?.createdAt.split('T')[0], item, ref)
                                }
                                else if(item.date){
                                  const hasReference = referencedSoapNotes?.some(
                                    (soapNote) => soapNote.treatmentId === item._id || soapNote._id === item._id
                                  );
                                  if (!hasReference && item.type !=="Treatment") {
                                    Modal.confirm({
                                      title: 'No SOAP Note Found',
                                      content: 'Do you want to create a SOAP note?',
                                      okText: 'Continue',
                                      cancelText: 'Cancel',
                                      okButtonProps: {
                                        className: "bg-blue-600 text-white"
                                      },
                                      onOk() {
                                        addTab(item?.date?.split('T')[0], item)
                                      },
                                      onCancel() {
                                        return; 
                                      }
                                    });
                                  } else if(item.type !=="Treatment"){
                                    addTab(item?.date?.split('T')[0], item)
                                  }
                                  // item.type !=="Treatment" && addTab(item?.date?.split('T')[0], item)
                                  item.type === "Treatment" && message.warning("This is a treatment, please open an order or soap note")
                                }
                                }}  >
        
                                <div className='flex items-center justify-between w-full'>
    {/* Left side: Date, Custom Name, and LPDV buttons */}
    <div className='flex items-center gap-2'>
      {item?.createdAt && <p className="font-medium min-w-fit">{moment(item?.createdAt?.split('T')[0]).format('MM-DD-YYYY')}</p>}
      {item?.date && <p className="font-medium min-w-fit">{moment(item?.date.split('T')[0]).format('MM-DD-YYYY')}</p>}
      
  
        <Input.TextArea 
          autoSize={{maxRows:1}} 
          value={filteredEncounters?.find(encounter => (encounter._id === item._id))?.customName || filteredEncounters?.find(encounter => (encounter._id === item._id))?.custom} 
          style={{maxWidth:200}} 
          className='w-full' 
          onChange={(event)=>handleChangeCustomName(event.target.value,item._id)} 
          onBlur={()=>handleUpdateCustomName(item._id)} 
          onClick={(event) => event.stopPropagation()} 
        />
      
      
      {(item.type === 'Treatment' || item.type === 'Order' || item.type === 'pickup') && 
        <span onClick={(event) => event.stopPropagation()}>
          {item.type=== 'Order'?<LPDVIconButtons 
            treatment={item} 
            setShowModal={setShowModal} 
            setModalType={setModalType} 
            setActiveTreatmentId={setActiveTreatmentId} 
            setActiveTreatment={setActiveTreatment} 
            fetchAllSupplements={fetchAllSupplements}
            iconButtons={["L","P","D","S","V","N"]}
          />:
          <LPDVIconButtons 
            treatment={item} 
            setShowModal={setShowModal} 
            setModalType={setModalType} 
            setActiveTreatmentId={setActiveTreatmentId} 
            setActiveTreatment={setActiveTreatment} 
            fetchAllSupplements={fetchAllSupplements}
            iconButtons={["L","P","D","V","N"]}
          />
          }
        </span>
      }
    </div>

    {/* Right side with Print button only */}
    {(item.type === 'Treatment' || item.type === 'Order' || item.type === 'pickup') && 
      <div className='flex items-center gap-2' onClick={(event) => event.stopPropagation()}>
        {(item.type==='Order' || item.type==='pickup') && referencedSoapNotes?.some(soapNote => (soapNote.treatmentId === item._id || soapNote._id === item._id)) && <div className='px-2 py-1 rounded-[30%] bg-gray-300 text-black'>SOAP</div> }
        {(item.type==='Order' || item.type==='pickup') && referencedSoapNotes?.some(soapNote => (soapNote.treatmentId === item._id || soapNote._id === item._id)) && <Tooltip title="Create Treatment">
                  <Button
                    type="default"
                    onClick={() => {
                      handleSelectOrderForCreateTreatmentModal(item)
                      handleOpenCreateTreatmentModal()
                    }}
                    className="flex items-center justify-center p-2 border-none hover:border-black hover:text-gray-800 transition-all duration-300 ease-in-out"
                  >
                    <FilePlus2  className="w-5 h-5" />
                  </Button>
                </Tooltip> }
        <Tooltip title="Print List">
        <Button
    icon={<Print />}
      className="flex items-center justify-center p-2 border-none hover:border-black hover:text-gray-800 transition-all duration-300 ease-in-out"
    onClick={(event) => {
      event.stopPropagation();
      setReceiptModalVisible(true);
      setReceiptModalId(item._id);
    }}
  />

        </Tooltip>      
      </div>
    }
  </div>    

                                  
                                  </div>
                                </Tooltip>
                              </div>
                            )
                          })}
                          <Pagination
                                  current={page}
                                  pageSize={limit}
                                  total={total}
                                  onChange={(pageNumber) => setPage(pageNumber)}
                                  showSizeChanger={false}
                                  style={{ marginTop: 20, textAlign: "center" }}
                                /> 
                          </>:
                          <div className='h-auto overflow-auto pr-2 flex flex-col gap-2'>
                            <p className='my-0 mx-auto text-sm'>No Encounters</p>
                          </div>
                      }
                    </div>
                  }
                </div>}
              </div>
            </div>
          </div>
        </Form>
        <PrintModal isOpen={printModalVisible || printMedications} onClose={closeModal} selectedPatient={selectedPatient} encounters={referencedSoapNotes} printMedications={printMedications} setPrintMedications={setPrintMedications} activeMedications={[...patientActiveMedications,...activeSupplementsExcludingPatientSupplements]} historicalMedications={historicalSupplements} activeDispensedItems={activeDispensedItems}/>
      

        <ReceiptModal 
    visible={receiptModalVisible}
    onClose={() => {
      setReceiptModalVisible(false);
    }}
    orderId={receiptModalId}
    patientProfile={selectedPatient}
    {...(type ? { type } : { type: "Order" })}
    fdv={false} 
  />
          <LPDPopup
            type={modalType}
            patientName={selectedPatient["Full Name"]}
            setAllTreatments={setEncounters}
            activeTreatment={activeTreatment}
            showModal={showModal}
            setsoapNoteCreatedFromLPD={setsoapNoteCreatedFromLPD}
            onCloseModal={()=>{
              setShowModal(false)
              setModalType(null)
              setActiveTreatment({})
              // This part of code is temporarily commented to disable synchronization between Order supplements and Patient Treatment Plan Supplements 
              // handleSubmitSupplements()
              setTimeout(()=>{
                fetchAllMedications()
              },500)
            }}
            setModalType={setModalType}
            handleCellChange={handleCellChange}
            from="emr"
            ref={{containerRef,soapNoteCreatedRef}}
            handleSupplementCheckboxChange={handleSupplementCheckboxChange}
          />
        <CreateOrderModal
          isOpen={isOrderModalVisible}
          closeModal={() => setIsOrderModalVisible(false)}
          selectedPatient={selectedPatient}
          refreshAllEncounters={refreshAllEncounters}
          from="emr"
        />
        <CreateTreatmentFromExistingOrder visible={isCreateTreatmentModalOpen} onClose={handleCloseCreateTreatmentModal} orderData={selectedOrderForCreateTreatmentModal}/>
        
      </div>
    );
  })

  const SecondaryInput = ({ fieldName, parentKey, handleSendValue, placeholder }) => {
    return (
      <div className="flex items-center w-full gap-2">
        <Form.Item name={`${fieldName}Secondary`} style={{ flexGrow: 10, marginBottom: 0 }}>
          <Input
            name={`${fieldName}Secondary`}
            className='mb-0 mt-1'
            placeholder={placeholder}
          />
        </Form.Item>

        <Fab
          sx={{
            fontSize: 12,
            height: 28,
            width: 28,
            minHeight: 'unset',
            minWidth: 'unset',
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => handleSendValue(parentKey, fieldName)}
        >
          <SendOutlined />
        </Fab>
      </div>

    );
  };


  export default SummarySection;
