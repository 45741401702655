export const labsSortingFunction = (contextLabs, userLabs) => {
  const indexMap = new Map();
  contextLabs.forEach((item) => {
    indexMap.set(item.item_number, item.index);
  });
  userLabs.sort((a, b) => {
    const indexA = indexMap.get(a.item_number);
    const indexB = indexMap.get(b.item_number);
    return indexA - indexB;
  });
  return userLabs;
};
export const dispensesSortingFunction = (contextDispenses, userdispensed) => {
  const indexMap = new Map();
  contextDispenses.forEach((item) => {
    indexMap.set(item.name, item.index);
  });
  userdispensed.sort((a, b) => {
    const indexA = indexMap.get(a.name);
    const indexB = indexMap.get(b.name);
    return indexA - indexB;
  });
  return userdispensed;
};
export const proceduresSortingFunction = (
  contextProcedures,
  userProcedures
) => {
  const indexMap = new Map();
  contextProcedures.forEach((item) => {
    indexMap.set(item.procedure_name, item.index);
  });
  userProcedures.sort((a, b) => {
    const indexA = indexMap.get(a.procedure_name);
    const indexB = indexMap.get(b.procedure_name);
    return indexA - indexB;
  });
  return userProcedures;
};

export const getLabDisplayName = (contextLabs, lab) => {
  const foundLab = contextLabs.find(
    (item) => item.item_number === lab.item_number
  );
  if (foundLab) {
    return foundLab.display_name;
  }
  return null;
};

export const hasG6PDLab = (treatment) => {
  const G6PDLab = treatment.userLabs.find(lab => lab.test_name === "G6PD")
  if (G6PDLab) {
    if (G6PDLab.checked) {
      return true
    }
    else {
      return false
    }
  }
}


export const g6pdLabResult = (treatment) => {
  const G6PDLab = treatment.userLabs.find(lab => lab.test_name === "G6PD")
  if (G6PDLab) {
    if (G6PDLab.result) {
      return true
    }
    else {
      return false
    }
  }
}

export const g6pdLabInRange = (treatment) => {
  const G6PDLab = treatment.userLabs.find(lab => lab.test_name === "G6PD")
  if (G6PDLab) {
    if (G6PDLab.range > 100 && G6PDLab.range < 200) {
      return true
    }
    else {
      return false
    }
  }
}

export const getPatientFormStatus = (formDetails) => {
  if (formDetails.linkGenerated === true) {
    return "Link Generated";
  } else if (formDetails.isSubmitted === false) {
    return "Submission Pending";
  } else if (formDetails.isApproved === false) {
    return "Approval Pending";
  }
  return "Unknown Status";
}