import {
  ChevronLeft,
  ChevronRight,
  ContentCopyRounded,
} from "@mui/icons-material";
import { supplementItemsArray } from './SupplementItems';
import {
  AddCircleRounded,
  RemoveCircleRounded
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

import { BounceLoader } from "react-spinners";
import CustomToast from "../CustomToast";
import { message, Modal, Spin, Switch } from "antd";
import './supplementsModal.css'
import { LoadingOutlined } from "@ant-design/icons";
import debounce from "lodash.debounce";

const SupplementsModal = ({
  open,
  supplements,
  onClose,
  patientId,
  patientIdForPreviousTreatment,
  handleCellChange,
  handleOpenTreatmentNoteModal,
  recordForNotes,
  from,
  treatmentId,
  patientData,
  selectedDate,
  allSupplements,
  toastifyToast,
  clickedTreatmentData
}) => {
  const [updatedItems, setUpdatedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [itemCounters, setItemCounters] = useState({});
  const [modalLoader, setModalLoader] = useState(false);
  const [isCustomFrequencySelected, setIsCustomFrequencySelected] = useState({});
  const [customFrequency, setCustomFrequency] = useState({});
  const [isPermanent, setIsPermanent] = useState(false);
  const [originalOrder, setOriginalOrder] = useState([]);
  const [shouldScroll, setShouldScroll] = useState(false); 
  const containerRef = useRef(null);
  const [toast, setToast] = useState(null);
  const [itemLoader, setItemLoader] = useState([]); 
  const [frequencyType, setFrequencyType] = useState({}); 
  const [pendingUpdate, setPendingUpdate] = useState(null);

  const handleFrequencyChange = (item, id, value) => {
    setUpdatedItems((prevItems) => {
      const itemsCopy = [...prevItems];
      const itemIndex = itemsCopy.findIndex((i) => i._id === id);
      if (itemIndex !== -1) {
        itemsCopy[itemIndex].frequency = value;
      }
      return itemsCopy;
    });
  
    setPendingUpdate({ ...item, frequency: value });
  };

  useEffect(() => {
    const debouncedApiCall = debounce(() => {
      if (pendingUpdate) {
        handleChange(pendingUpdate, pendingUpdate.name, "frequency", pendingUpdate.frequency);
        setPendingUpdate(null);
      }
    }, 500);
  
    debouncedApiCall();
  
    return () => debouncedApiCall.cancel();
  }, [pendingUpdate]);
  
  // This is the key function that needs to be fixed
const handleAddSupplementItem = (itemName) => {
  // Generate a unique name with counter
  const newCounter = (itemCounters[itemName] || 0) + 1;
  const newItemName = `${itemName} ${newCounter}`;
  
  // Find the selected item in the current state
  const selectedItemIndex = updatedItems.findIndex(
    (item) => item.name === itemName
  );
  
  if (selectedItemIndex !== -1) {
    const originalItem = updatedItems[selectedItemIndex];
    
    // Create a new item with a unique temporary ID and necessary properties
    const newItem = {
      ...originalItem,
      _id: `temp_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`, // Temporary ID
      name: newItemName,
      selectedField: "",
      quantity: 0,
      frequency: "",
      isNew: true,
      checked: false,
      frequencyOptions: originalItem.frequencyOptions || [""]
    };
    
    
    const newUpdatedItems = [
      ...updatedItems.slice(0, selectedItemIndex + 1),
      newItem,
      ...updatedItems.slice(selectedItemIndex + 1)
    ];
    
    
    setItemCounters((prevCounters) => ({
      ...prevCounters,
      [itemName]: newCounter
    }));
    
    
    setUpdatedItems(newUpdatedItems);
    
   
    setShouldScroll(true);
  }
};


  const handleRemoveSupplementItem = (item) => {
    const newUpdatedItems = updatedItems.filter(
      (data) => data.name !== item.name
    );
  
    if (item && item._id) {
      // If the item has an ID, it means it's a saved item, so we need to make a delete request
      axios
        .delete(`/delete/supplementitem/${clickedTreatmentData._id}/${item._id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          // Decrement the counter for the corresponding item type
          const itemName = item.name.split(" ")[0]; // Extracting the item name without counter
          setItemCounters((prevCounters) => {
            const newCounters = { ...prevCounters };
            if (newCounters[itemName]) {
              newCounters[itemName]--;
            }
            return newCounters;
          });
          
          setUpdatedItems(newUpdatedItems);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      // If the item doesn't have an ID, it means it's a newly added item, so just update the state
      setUpdatedItems(newUpdatedItems);
    }
  };

  const updateRecommendedSupplements = () => {
    const recommendedSupplements = patientData?.RecommendedSupplements || [];
    const newItems = supplements.map((item) => {
      const recommendedSupplement = recommendedSupplements.find(
        (supp) => supp.supplementName === item.name
      );

      if (recommendedSupplement) {
        return {
          ...item,
          frequency: recommendedSupplement.frequency,
          isRecommended: true,
        };
      }
      return { ...item, isRecommended: false };
    });

    const sortedItems = newItems.sort((a, b) => {
      if (b.isRecommended !== a.isRecommended) {
        return b.isRecommended - a.isRecommended;
      }
      return b.quantity - a.quantity;
    });
    setUpdatedItems(sortedItems);
  };

  useEffect(() => {
    if (open) {
      const itemsWithDetails = supplements.map((item, index) => ({
        ...item,
        originalOrder: index,
        checked: item.quantity > 0 ? true : false
      }));
      setUpdatedItems(itemsWithDetails);
      setSearchTerm("");
      setOriginalOrder(itemsWithDetails);
    }
  }, [open, supplements, patientData]);

  const sortItems = (items) => {
    return [...items];
  };

  const handleRecommendedSupplementsClick = () => {
    const newItems = updatedItems.map((item) => {
      const recommendedSupplement = patientData?.RecommendedSupplements.find(
        (supp) => supp.supplementName === item.name
      );
      if (recommendedSupplement) {
        const updatedItem = {
          ...item,
          quantity: 1,
          frequency: recommendedSupplement.frequency,
        };
        handleChange(updatedItem, item.name, "quantity", 1);
        return updatedItem;
      }
      return item;
    });
    setUpdatedItems(newItems);
  };

  const addFrequency = async (supplement, value) => {
    const matchedSupplement = allSupplements.find(supp => supp.supplement_name === supplement.name)
    try {
      const response = await axios.post(`/supplements/${matchedSupplement._id}/add-frequency`, {
        newFrequency: value
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` },
      });
      toastifyToast('Frequency added successfully', { type: 'success' });
    } catch (error) {
      toastifyToast(`Error adding frequency: ${error.response?.data?.message || error.message}`, { type: 'error' });
    }
  };

  const removeFrequency = async (supplement, value) => {
    const matchedSupplement = allSupplements.find(supp => supp.supplement_name === supplement.name)

    try {
      const response = await axios.post(`/supplements/${matchedSupplement._id}/remove-frequency`, {
        frequencyToRemove: value
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` },
      });
      toastifyToast('Frequency removed successfully', { type: 'success' });
    } catch (error) {
      toastifyToast(`Error adding frequency: ${error.response?.data?.message || error.message}`, { type: 'error' });
    }
  };
  const sortCheckedItems = (items) => {
    return [...items].sort((a, b) => {
      const originalIndexA = originalOrder.findIndex(item => item.name === a.name);
      const originalIndexB = originalOrder.findIndex(item => item.name === b.name);
      return originalIndexA - originalIndexB;
    });
  };

  const handleChange = (itemData, itemName, key, value) => {
    const scrollTop = containerRef.current.scrollTop;
  
    const newItems = updatedItems.map((item) => {
      if (item.name === itemName) {
        let updatedItem = { ...item, [key]: value };
  
        if(key === "quantity" && itemData.isNew && value){
          let updatedSupplementsData = { ...itemData, editItemName: false }; 
          itemData = updatedSupplementsData; 
        }
        if (key === "n" ) {
          updatedItem.checked = value ? true : false;
          updatedItem.isNew= false
          updatedItem.custom= true
        }
  
        if (key === "quantity") {
          updatedItem.quantity = value
          updatedItem.checked = value > 0 ? true : false
          if(value>0 && item.frequency === ""){
            updatedItem.frequency = item.frequencyOptions[0]
            itemData.frequency = item.frequencyOptions[0]
          } else if(value <= 0){
            updatedItem.frequency = ""
            itemData.frequency = ""
          }
          if (itemData?.isNew === true || item?.custom === true) {
            updatedItem.selectedField = itemData.selectedField
            handleCellChange(
              patientId,
              `${itemName}-selectedField`,
              itemData.selectedField,
              "userSupplements",
              itemData
            )}
            else{
            updatedItem.selectedField = value > 0 ? itemData.dosages[0] : ""
            handleCellChange(
              patientId,
              `${itemName}-selectedField`,
              value>0?item.dosages[0]:"",
              "userSupplements",
              itemData
            );
          }
        }
  
        if (key === "selectedField" && (!itemData.isNew || !itemData.custom)) {
          updatedItem.quantity = value ? itemData.quantity> 0 ? itemData.quantity : 1 : 0;
          updatedItem.checked = value ? true : false;          
          if(itemData?.editItemName !== false || (itemData.custom && itemData?.custom !== true)){
          handleCellChange(
            patientId,
            `${itemName}-quantity`,
            updatedItem.quantity,
            "userSupplements",
            itemData
          );
        }
        }
        return updatedItem;
      }
      return item;
    });
    
    const updatedItem = newItems.find((item) => item.name === itemName);
   
    setTimeout(() => {
      handleCellChange(
        patientId,
        `${itemName}-${key}`,
        value,
        "userSupplements",
        itemData,
        updatedItem?.quantity || 0
      );
    },1000) 
  
    const updatedNewItems= newItems.map((item) => {
      if(item.editItemName){
        return {...item,editItemName:false}
      }
      else{
        return item
      }
    });
    
    const sortedItems = sortCheckedItems(updatedNewItems);
    setUpdatedItems(sortedItems);
    
    requestAnimationFrame(() => {
      if (containerRef.current) {
        containerRef.current.scrollTop = scrollTop;
      } else {
        console.warn("containerRef.current is null, cannot set scrollTop");
      }
    });
  };

  const handleDeleteSupplement = async (supplementId) => {
    setItemLoader([...itemLoader,{supplementId,show:true}])
    try {
      const response = await axios.delete(
        `/delete/supplementitem/${clickedTreatmentData._id}/${supplementId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if(response.status===200 || response.status===201){
        const updatedLoader=itemLoader.filter(item => item?.supplementId!==supplementId)
        setItemLoader(updatedLoader)
        const updatedItemsAfterDelete = updatedItems.filter(
          (item) => item._id !== supplementId
        );
        setUpdatedItems(updatedItemsAfterDelete);
      }
    } catch (error) {
      console.error("Error deleting supplement item:", error);
    }
  };

  
  useEffect(() => {
    if (shouldScroll) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
      setShouldScroll(false); 
    }
  }, [shouldScroll]); 

  const handleAddEmptyRow = () => {
    const newItem = {
      name: "",
      selectedField: "",
      quantity: 1,
      isNew: true,
      editItemName: true,
    };
    setUpdatedItems([...updatedItems, newItem]);
    setShouldScroll(true); 
  };

  const handlePrevious = () => {
    handleOpenTreatmentNoteModal(recordForNotes);
    onClose();
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredItems = updatedItems && updatedItems.filter((item) =>
    searchTerm && typeof searchTerm === 'string'
      ? item.name.toLowerCase().includes(searchTerm.toLowerCase())
      : true
  );

  const handleRefreshClick = () => {
    const sortedItems = sortItems([...updatedItems]);
    setUpdatedItems(sortedItems);
  };

  const placeOrder = (types) => {
    fetch(`/shopify/placeorder/${treatmentId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ types }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        onClose();
        toast.success("Order placed successfully");
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(`Error placing order: ${error.message}`);
      });
  };

  const findPreviousTreatment = (treatments) => {
    let previousTreatment = null;
    let currentDate = new Date(selectedDate);
    let found = false;
    while (!found && currentDate) {
      currentDate = getPreviousDate(currentDate);
      previousTreatment = treatments.treatments.find((treatment) => {
        let treatmentDateString = new Date(treatment.date)
          .toISOString()
          .split("T")[0];
        return treatmentDateString === currentDate;
      });

      if (previousTreatment) {
        found = true;
      }
    }

    return previousTreatment;
  };

  const findCurrentTreatment = (treatments) => {
    let currentTreatment = null;
    let currentDate = new Date(selectedDate).toISOString().split("T")[0];
    let found = false;

    currentTreatment = treatments.treatments.find((treatment) => {
      let treatmentDateString = new Date(treatment.date)
        .toISOString()
        .split("T")[0];
      return treatmentDateString === currentDate;
    });

    if (currentTreatment) {
      found = true;
    }

    return currentTreatment;
  };

  const getPreviousDate = (dateString) => {
    const currentDate = new Date(dateString);
    const previousDate = new Date(currentDate);
    previousDate.setDate(previousDate.getDate() - 1);
    return previousDate.toISOString().split("T")[0];
  };

  const showToast = (variant, message) => {
    setToast({ variant, message });

    setTimeout(() => {
      setToast(null);
    }, 5000);
  };

  const handleCopySupplementsFromPreviousTreatment = async () => {
    setModalLoader(true);
    try {
      const previousTreatmentsResponse = await axios.get(
        `/api/patient/${patientData["Customer ID"]}/last-treatments`
      );

      if (previousTreatmentsResponse.data.totalCount <= 0) {
        setModalLoader(false);
        showToast("error", "There are no treatments available to copy from");
        return;
      }

      const treatments = previousTreatmentsResponse.data.treatments.filter(
        (treatment) => treatment.type === "Treatment"
      );

      if (treatments.length <= 0) {
        setModalLoader(false);
        showToast(
          "error",
          "There are no treatments of the required type to copy from"
        );
        return;
      }

      const treatment = findPreviousTreatment({ treatments: treatments });
      const currentTreatment = findCurrentTreatment({ treatments: treatments });

      if (currentTreatment.review == true) {
        showToast(
          "error",
          "The status of this treatment is reviewed, so labs can't be copied"
        );
        setModalLoader(false);
        return;
      }

      const previousSupplements = treatment.userSupplements.filter(
        (previousSupplement) => previousSupplement.quantity >= 1
      );

      if (previousSupplements.length <= 0) {
        setModalLoader(false);
        showToast(
          "error",
          "There are no supplements from the previous treatment to copy from"
        );
        return;
      }

      previousSupplements.forEach((supplement) => {
        if ("quantity" in supplement) {
          handleChange(supplement, supplement.name, "quantity", 1);
        }
      });
      setModalLoader(true);
      setTimeout(async () => {
        setModalLoader(true);
        const response = await axios.get(
          `/orderget/${patientId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );
        const fetchedSupplements = response.data.userSupplements;

        const newUpdatedItems = fetchedSupplements.map((item) => {
          const foundSupplement = previousSupplements.find(
            (supplement) => supplement.name === item.name
          );
          if (foundSupplement) {
            return {
              ...item,
              quantity: foundSupplement.quantity,
              selectedField: foundSupplement.selectedField,
            };
          }
          return item;
        });

        setUpdatedItems(newUpdatedItems);
        setModalLoader(false);
      }, 2000);

      showToast(
        "success",
        "Successfully copied procedures from previous treatments"
      );
    } catch (error) {
      setModalLoader(false);
      showToast("error", "Failed to fetch previous treatments");
    }
  };

  const handleInputChange = (index, newValue) => {
    const updatedElements = [...updatedItems];
    updatedElements[index].value = newValue;
    setUpdatedItems(updatedElements);
  };
  
  const handleChangeSwitch = (id, checked) => {
    setFrequencyType((prev) => ({
      ...prev,
      [id]: checked ? "permanent" : "temporary",
    }));
  
    const frequencyValue = customFrequency[id]?.trim(); // Get the stored custom frequency
  
    if (!frequencyValue) return; // Don't process if no value entered
  
    if (checked) {
      addFrequency(updatedItems.find((item) => item._id === id), frequencyValue);
    } else {
      removeFrequency(updatedItems.find((item) => item._id === id), frequencyValue);
    }
  };
  
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <div className="p-4">
        <div className="flex justify-between items-center my-4">
          <div className="flex items-center">
            <span className="dispensedtitleStyling mt-0">
              {from !== "fdv" && (
                <IconButton onClick={handlePrevious}>
                  <ChevronLeft />
                </IconButton>
              )}
              Update Supplements
              {from !== "fdv" && (
                <IconButton disabled={true}>
                  <ChevronRight />
                </IconButton>
              )}
            </span>
            <Tooltip
              title={
                <h1 className="text-sm">Copy Supplements From Previous Treatment</h1>
              }
              placement="bottom"
            >
              <IconButton
                onClick={() => {
                  if (clickedTreatmentData&&clickedTreatmentData.review) {
                    return message.warning("This treatment is finalized so can't make any changes in it")
                  } else {
                    handleCopySupplementsFromPreviousTreatment()
                  }
                }}
                sx={{ ml: "10px" }}
              >
                <ContentCopyRounded sx={{ fontSize: 30 }} />
              </IconButton>
            </Tooltip>
          </div>
          <button
            className="btn-color px-3 h-14 py-0.5"
            onClick={handleRecommendedSupplementsClick}
          >
            Add Recommended Supplements
          </button>
          <div className="flex items-center gap-4">
            <IconButton>
              <RefreshIcon
                onClick={handleRefreshClick}
                style={{
                  fontSize: 30,
                }}
              />
            </IconButton>
            {from === "fdv" && (
              <IconButton title="Send to Shopify">
                <ShoppingCartCheckoutIcon
                  onClick={() => {
                    if (!clickedTreatmentData.review) {
                            message.warning("This treatment is not finalized yet, so can't place a shopify order for it.")
                    }
                    else {                      
                      const modal = Modal.confirm({
                        title: 'Confirmation',
                        content: 'Are you sure you want to place this order?',
                        okText: 'Place Order',
                        cancelText: 'Cancel',  // Add the cancel button
                        onOk: () => {
                          modal.destroy()
                          placeOrder(["supplement"])
                        },  // Wrap in an arrow function
                        onCancel: () => modal.destroy(),  // Handle cancel button click
                        okButtonProps: {
                          style: {
                            backgroundColor: '#1976d2',
                            borderColor: '#1976d2',
                            color: 'white',
                            fontWeight: 'bold',
                          },
                        },
                      });
                    }
                  }}
                  style={{
                    fontSize: 28,
                  }}
                />
              </IconButton>
            )}
            <IconButton>
              <CloseIcon
                onClick={onClose}
                style={{
                  fontSize: 30,
                }}
              />
            </IconButton>
          </div>
        </div>
        {modalLoader && (
          <div className="loader-backdrop">
            <BounceLoader color="#000" />
          </div>
        )}
        <div>
          {toast && <CustomToast message={toast.message} variant={toast.variant} />}
          <div>
            <input
              className="searchInput"
              placeholder="Search supplements..."
              value={searchTerm}
              onChange={handleSearchChange}
              margin="normal"
            />
          </div>
          <div className="outer-supplements-container">
            <div className="inner-supplements-container" ref={containerRef} >
              {filteredItems.map((item, index) => {
                const loader = itemLoader.find(
                  (itemLoader) => {
                   if(itemLoader.supplementId === item._id && itemLoader.show){
                    return true
                   }
                   return false
                  }
                );
                return(
                <div className="divTableRow" key={item._id}>
                  <div className="w-[75%] flex justify-center items-center">
                    {item.editItemName ? (
                      <>
                        <input
                          className="dispensedTextInput"
                          type="text"
                          placeholder="Supplement Name"
                          value={item.name || ""}
                          onChange={(e) =>{
                            setUpdatedItems((prevState) => {
                              const updatedState = [...prevState];
                              updatedState[index] = { 
                                ...updatedState[index], 
                                name: e.target.value 
                              };
                              return updatedState;
                            });
                          }
                        }
                        />
                      </>
                    ) : (
                      <div className="divTableCell itemName">{item.name}</div>
                    )}
                    {(item.editItemName || item.editItemName===false || item.custom) ? (
                        <input
                          className="dispensedTextInput ml-[20px]"
                          type="text"
                          placeholder="Dosage"
                          value={item.selectedField || ""}
                          onBlur={(e) => {
                            if ((clickedTreatmentData && clickedTreatmentData.review)) {
                              return message.warning("This treatment is finalized so can't make any changes in it")
                            }
                            else {
                              if(!item?.editItemName || item.custom){
                              handleChange(
                                item,
                                item.name,
                                "selectedField",
                                e.target.value
                              ) }
                            }
                          }}
                          onChange={(e)=>{
                            setUpdatedItems((prevState) => {
                              const updatedState = [...prevState];
                              updatedState[index] = { 
                                ...updatedState[index], 
                                selectedField: e.target.value 
                              };
                              return updatedState;
                            });
                          }}
                          fullWidth
                        />
                      ) :
                    <div className="w-[70%] flex flex=row">
                      {item.quantity > 0 && (
                        <FormControl
                          key={item._id}
                          fullWidth
                          style={{
                            marginLeft: "10px",
                            minWidth: "100%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 3,
                          }}
                        >
                          <Select
                            value={isCustomFrequencySelected[item._id] ? "custom" : item.frequency}
                            fullWidth
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value === "custom") {
                                setIsCustomFrequencySelected((prev) => ({
                                  ...prev,
                                  [item._id]: true,
                                }));
                                setCustomFrequency((prev) => ({
                                  ...prev,
                                  [item._id]: "",
                                }));
                                setFrequencyType((prev) => ({
                                  ...prev,
                                  [item._id]: "temporary",
                                }));
                                handleChange(
                                  { ...item, frequency: "" },
                                  item.name,
                                  "quantity",
                                  1
                                );
                              } else {
                                setIsCustomFrequencySelected((prev) => ({
                                  ...prev,
                                  [item._id]: false,
                                }));
                                handleFrequencyChange(item, item._id, value);
                              }
                            }}
                            displayEmpty
                            renderValue={(selected) =>
                              selected ? selected : item?.frequencyOptions?.length > 0 ? item.frequencyOptions[0] : <em>Select Frequency</em>
                            }
                            style={{
                              padding: "8px 12px",
                              height: "40px",
                            }}
                          >
                            {item.frequencyOptions && item.frequencyOptions.map((freq, freqIndex) => (
                              <MenuItem key={freqIndex} value={freq}>
                                {freq}
                              </MenuItem>
                            ))}
                            <MenuItem value="custom">Custom</MenuItem>
                          </Select>

                          {isCustomFrequencySelected[item._id] && (
                            <>
                              <TextField
                                value={customFrequency[item._id] || ""}
                                size="small"
                                fullWidth
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setCustomFrequency((prev) => ({
                                    ...prev,
                                    [item._id]: value,
                                  }));
                                  handleFrequencyChange(item, item._id, value);
                                  handleInputChange(index, value)
                                }}
                                onBlur={(event) => {
                                  if (!item.checked && event.target.value.trim() !== "") {
                                    addFrequency(item, event.target.value)
                                  }
                                }}
                                placeholder="Enter custom frequency"
                              />
                              <Switch
                                checked={frequencyType[item._id] === "permanent"}
                                className="bg-gray-300 ml-4 min-w-[100px]"
                                onChange={(checked) => handleChangeSwitch(item._id, checked)}
                                checkedChildren="Permanent"
                                unCheckedChildren="Temporary"
                              />
                            </>
                          )}
                        </FormControl>
                      )}
                    </div>}
                  </div>
                  <div className="w-1/4 flex items-center justify-between mr-[10px]">
                    <div className="flex justify-center items-center">
                      <Checkbox
                        checked={item.checked || false}
                        onChange={(e) => {
                          if (clickedTreatmentData&&clickedTreatmentData.review === true) {
                            return message.warning("This treatment is finalized so can't make any changes in it")
                          }
                          else {
                            if ((item.isNew || item.custom) && (!item.selectedField || !item.name)) {
                              message.warning(`Please add ${!item.name ? 'name value':""} ${(!item.name && !item.selectedField) ? 'and dosage value' : !item.selectedField ? 'dosage value':""} in order to check the item`)
                            } 
                            else {
                              if (item.isNew) {
                                handleChange(
                                  item,
                                  item.name,
                                  "n",
                                  item.name
                                )
                              } else {
                                handleChange(
                                  item,
                                  item.name,
                                  "quantity",
                                  e.target.checked ? 1 : 0
                                )
                              }
                            }
                          }
                        }
                        }
                      />
                    </div>
                    <div className="flex justify-center items-center">
                      <div className="divTableCell contentFit">
                        {item.dosages && item.dosages.length > 0 && (
                          <Select
                            className="dispensedDropdownInput"
                            value={item.selectedField || ""}
                            onChange={(e) =>
                              handleChange(
                                item,
                                item.name,
                                "selectedField",
                                e.target.value
                              )
                            }
                            fullWidth
                          >
                            <MenuItem key="empty-option" value="">
                              Empty
                            </MenuItem>
                            {item.dosages.map((dosage, idx) => (
                              <MenuItem key={idx} value={dosage}>
                                {dosage}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </div>
                      {item.custom && (
                        <div>
                          {loader
                            ?
                            <Spin indicator={<LoadingOutlined spin />} size="small" />

                            : <IconButton
                              onClick={() => handleDeleteSupplement(item._id)}
                            >
                              <DeleteIcon />
                            </IconButton>}
                        </div>
                      )}
                      <div className="divTableCell contentFitthreeC">
                        <input
                          className="dispensedNumberInput"
                          type="number"
                          value={item.quantity || 0}
                          onChange={(e) => {
                            if (clickedTreatmentData&&clickedTreatmentData.review) {
                              return message.warning("This treatment is finalized so can't make any changes in it")
                            } else {
                              handleChange(item, item.name, "quantity", Number(e.target.value))
                            }
                          }
                          }
                          fullWidth
                        />
                      </div>
                      <div>
                      {/* {!item.isNew && ( // Only show add/remove for existing items
    supplementItemsArray.includes(item.name) ? (
      <IconButton 
        onClick={() => {
          if (clickedTreatmentData && clickedTreatmentData.review) {
            return message.warning("This treatment is finalized so can't make any changes in it");
          } else { 
            handleAddSupplementItem(item.name);
          }
        }}
      >
        <AddCircleRounded className="text-green-500" />
      </IconButton>
    ) : (
      loader ? (
        <Spin indicator={<LoadingOutlined spin />} size="small" />
      ) : (
        <IconButton onClick={() => handleRemoveSupplementItem(item)}>
          <RemoveCircleRounded className="text-red-500" />
        </IconButton>
      )
    )
  )} */}

                      </div>
                    </div>
                  </div>
                </div>
              )})}
            </div>
          </div>
          <DialogActions>
            <button
              className="btn-color py-2 px-14 text-xs md:text-sm w-full md:w-auto"
              onClick={handleAddEmptyRow}
            >
              Add Custom Supplement
            </button>
            <button
              className="btn-color py-2 px-14 text-xs md:text-sm w-full md:w-auto"
              onClick={onClose}
            >
              Finish
            </button>
          </DialogActions>
        </div>
      </div>
    </Dialog>
  );
};

export default SupplementsModal;