import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Checkbox,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import NotesIcon from "@mui/icons-material/Notes";
import "./proceduresModel.css";
import {
  ChevronLeft,
  ChevronRight,
  ContentCopyRounded,
  ShoppingCartCheckout,
} from "@mui/icons-material";
import MuiButton from "@mui/material/Button";
import { BounceLoader } from "react-spinners";
import { Button, message, Modal, Spin } from "antd";
import CustomToast from "../CustomToast";
import { LoadingOutlined } from "@ant-design/icons";

const ProcedureModal = ({
  open,
  procedures,
  onClose,
  patientId,
  patientIdForPreviousTreatment,
  handleCellChange,
  handleOpenLabsModal,
  handleOpenDispenseModal,
  recordForNotes,
  selectedDate,
  clickedTreatmentData,
  from,
  treatmentId
}) => {

  const [updatedProcedures, setUpdatedProcedures] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalLoader, setModalLoader] = useState(false);
  const [toast, setToast] = useState(null);
  const containerRef = useRef(null);
  const toastCount = useRef(0);  
  const [shouldScroll, setShouldScroll] = useState(false); 
  const [itemLoader, setItemLoader] = useState([]); 

  const handleRefreshAndSortProcedures = () => {
    const sortedProcedures = proceduresSortingFunction([...updatedProcedures]);
    setUpdatedProcedures(sortedProcedures);
  };

  const proceduresSortingFunction = (procedures) => {
    
    return [...procedures].sort((a, b) => a.originalOrder - b.originalOrder);
  };

  useEffect(() => {
    if (open) {
      const proceduresWithOrder = procedures.map((p, index) => ({
        ...p,
        originalOrder: index,
        showNoteInputRow: false,
      }));
      setUpdatedProcedures(proceduresSortingFunction(proceduresWithOrder));
    } else {
      setSearchTerm("");
    }
  }, [open, procedures]);


  const handleChange = (procedureData, procedureName, key, value) => {
    const scrollTop = containerRef?.current?.scrollTop;
    const newProcedures = updatedProcedures.map((procedure) => {
      if (procedure.procedure_name === procedureName && key !== "dosages") {
        let updatedProcedure = { ...procedure, [key]: value };
        if (key === "checked" && (procedureData.isNew=== true || procedureData.custom===true)) {
          let updatedProcedureData = { ...procedureData, editItemName: false, checked:value };
          procedureData = updatedProcedureData;
          handleCellChange(
            patientId,
            `${procedureName}-selectedField`,
            procedureData.selectedField,
            "userProcedures",
            procedureData,
          );
        }
        else if (key==="checked" && (!procedureData.isNew && !procedureData.isCustom)){
          let updatedProcedureData = { ...procedureData, editItemName: false, checked:value };
          procedureData = updatedProcedureData;

          handleCellChange(
            patientId,
            `${procedureName}-selectedField`,
            value?procedureData.dosages[0]:"",
            "userProcedures",
            procedureData,
          );
        }
        


        if (key === "n" ) {
          updatedProcedure.checked = true;
          updatedProcedure.isNew= false
          updatedProcedure.custom= true
        }
        if ((key === "selectedField" && (value === "Empty" || value === "" ) && (!procedureData?.isNew && !procedureData?.custom) )) {
          // Remove the procedure from active procedures
          updatedProcedure = {
            ...updatedProcedure,
            selectedField: "",
            checked: false,
          }; // Uncheck the checkbox
          
          handleCellChange(
            patientId,
            `${procedureName}-checked`,
            false,
            "userProcedures",
            procedureData,
          );
        } else if (key === "selectedField" && value !== "" && (!procedureData.isNew && !procedureData.custom)) {
          // Mark the procedure as checked when a dosage is selected
          updatedProcedure.checked = true;
          handleChange(
            procedure,
            procedure.procedure_name,
            "checked",
            true
          );
        }
        return updatedProcedure;
      }
      return procedure;
    });

    const updatedNewProcedures= newProcedures.map((item) => {
      if(item.editItemName){
        return {...item,editItemName:false}
      }
      else{
        return item
      }
      })
    // Sort procedures based on selectedField value and checked status
    const sortedProcedures = proceduresSortingFunction(updatedNewProcedures);
    setUpdatedProcedures(sortedProcedures);

    // Was causing issue in adding custom item , by making it false cusotm item was being ignored always
    // procedureData.isNew = false;
    requestAnimationFrame(() => {
      containerRef.current.scrollTop = scrollTop;
    });

    setTimeout(() => {
      handleCellChange(
        patientId,
        `${procedureName}-${key}`,
        value,
        "userProcedures",
        procedureData,
        clickedTreatmentData&&clickedTreatmentData.review
      );
    }, 1000);
  };




  const toggleNoteInputRow = (procedureName) => {
    const newProcedures = updatedProcedures.map((procedure) => {
      if (procedure.procedure_name === procedureName) {
        return { ...procedure, showNoteInputRow: !procedure.showNoteInputRow };
      }
      return procedure;
    });

    setUpdatedProcedures(newProcedures);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredProcedures = updatedProcedures.filter((procedure) =>
    procedure.procedure_name.toLowerCase().includes(searchTerm)
  );
  // 

  useEffect(() => {
    if (shouldScroll) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
      setShouldScroll(false); 
    }
  }, [shouldScroll]); 
  const handleAddEmptyRow = () => {
    const newItem = {
      procedure_name: "", // Set a default name for the new item
      selectedField: "", // Initialize with an empty value
      note: "",
      checked:false,
      isNew: true, // Flag indicating it's a new item
      editItemName: true, // Show the input box for editing the item name
    };

    setUpdatedProcedures([...updatedProcedures, newItem]); // Add the new item to the end of the array
    setShouldScroll(true); 
  };

  const getActiveProceduresSummary = () => {
    return updatedProcedures
      .filter(
        (procedure) =>
          procedure.checked ||
          (procedure.selectedField && procedure.selectedField !== "Empty")
      )
      .map(
        (procedure) =>
          `${procedure.procedure_name} (${procedure.selectedField || ""})`
      )
      .join(", ");
  };

  const handleDeleteProcedure = async (procedureId) => {
    setItemLoader([...itemLoader,{procedureId,show:true}])

    try {

      const response = await axios.delete(
        `/delete/procedureitem/${clickedTreatmentData._id}/${procedureId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        const updatedLoader=itemLoader.filter(item => item?.procedureId!==procedureId)
        setItemLoader(updatedLoader)
        // Successfully deleted item, you can now remove it from your UI or refetch the procedures list
        const newProcedures = updatedProcedures.filter(
          (procedure) => procedure._id !== procedureId
        );
        setUpdatedProcedures(newProcedures);
      }
    } catch (error) {
      console.error("Error deleting procedure item:", error);
      // Handle error (e.g., show an error message)
    }
  };

  const handleNext = () => {
    handleOpenDispenseModal(patientId, recordForNotes);

    onClose();
  };

  const handlePrevious = () => {
    handleOpenLabsModal(patientId, recordForNotes);

    onClose();
  };

  // Function to find the previous treatment based on a given date
  const findPreviousTreatment = (treatments) => {
    let previousTreatment = null;
    let currentDate = new Date(selectedDate);
    let found = false;

    while (!found && currentDate) {
      currentDate = getPreviousDate(currentDate);
      previousTreatment = treatments.treatments.find((treatment) => {
        let treatmentDateString = new Date(treatment.date)
          .toISOString()
          .split("T")[0];
        return treatmentDateString === currentDate;
      });

      if (previousTreatment) {
        found = true; // Found treatment on previous date
      }
    }

    return previousTreatment;
  };

  const findCurrentTreatment = (treatments) => {
    let currentTreatment = null;
    let currentDate = new Date(selectedDate).toISOString().split("T")[0];
    let found = false;

    currentTreatment = treatments.treatments.find((treatment) => {
      let treatmentDateString = new Date(treatment.date)
        .toISOString()
        .split("T")[0];
      return treatmentDateString === currentDate;
    });

    if (currentTreatment) {
      found = true;
    }

    return currentTreatment;
  };
  const getPreviousDate = (dateString) => {
    const currentDate = new Date(dateString);
    const previousDate = new Date(currentDate);
    previousDate.setDate(previousDate.getDate() - 1);
    return previousDate.toISOString().split("T")[0];
  };
  const showToast = (variant, message) => {
    setToast({ variant, message });

    setTimeout(() => {
      setToast(null);
    }, 5000);
  };
  const handleCopyProceduresFromPreviousTreatment = async () => {
    setModalLoader(true);
    try {
      // Fetch previous treatments
      const previousTreatmentsResponse = await axios.get(
        `/api/patient/${patientIdForPreviousTreatment}/last-treatments`
      );

      // Filter to keep only treatments of type "Treatment"
      const treatments = previousTreatmentsResponse.data.treatments.filter(
        (treatment) => treatment.type === "Treatment"
      );

      if (treatments.length <= 0) {
        setModalLoader(false);
        showToast(
          "error",
          "There are no procedures from the previous treatment to copy from"
        );
        return;
      }

      // Assume findPreviousTreatment and findCurrentTreatment take a list of treatments and return a single treatment
      const treatment = findPreviousTreatment({ treatments: treatments });
      const currentTreatment = findCurrentTreatment({ treatments: treatments });

      if (currentTreatment.review == true) {
        showToast(
          "error",
          "The status of this treatment is reviewed, so labs can't be copied"
        );
        setModalLoader(false);
        return;
      }
      const previousProcedures = treatment.userProcedures.filter(
        (previousProcedure) =>
          previousProcedure.checked === true ||
          (previousProcedure.selectedField !== undefined &&
            previousProcedure.selectedField !== null &&
            previousProcedure.selectedField !== "")
      );
      if (previousProcedures.length <= 0) {
        setModalLoader(false);
        showToast(
          "error",
          "There are no procedures from the previous treatment to copy from"
        );
        return;
      }
      previousProcedures.forEach((procedure) => {
        if ("note" in procedure) {
          handleChange(
            procedure,
            procedure.procedure_name,
            "note",
            procedure.note
          );
        }
        if ("customDosage" in procedure) {
          handleChange(
            procedure,
            procedure.procedure_name,
            "customDosage",
            procedure.customDosage
          );
        }

        if (procedure.checked == true && !procedure.selectedField) {
          handleChange(procedure, procedure.procedure_name, "checked", true);
        }
        if ("selectedField" in procedure) {
          procedure.checked = false;
          handleChange(
            procedure,
            procedure.procedure_name,
            "selectedField",
            procedure.selectedField
          );
        }
      });

      setTimeout(async () => {
        setModalLoader(true);
        const response = await axios.get(
          `/orderget/${patientId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );

        const fetchedProcedures = response.data.userProcedures;

        const updatedProceduresCopy = [...updatedProcedures];

        previousProcedures.forEach((procedure) => {
          const existingProcedureIndex = updatedProceduresCopy.findIndex(
            (p) => p.procedure_name === procedure.procedure_name
          );

          if (existingProcedureIndex !== -1) {
            if ("note" in procedure) {
              updatedProceduresCopy[existingProcedureIndex].note =
                procedure.note;
            }
            if ("customDosage" in procedure) {
              updatedProceduresCopy[existingProcedureIndex].customDosage =
                procedure.customDosage;
            }
            if (procedure.checked && !procedure.selectedField) {
              updatedProceduresCopy[existingProcedureIndex].checked = true;
            }
            if ("selectedField" in procedure) {
              updatedProceduresCopy[existingProcedureIndex].selectedField =
                procedure.selectedField;
              updatedProceduresCopy[existingProcedureIndex].checked = false;
            }
          } else {
            updatedProceduresCopy.push({
              ...procedure,
              isNew: true,
              showNoteInputRow: false,
            });
          }
        });
        const sortedProcedures = proceduresSortingFunction([
          ...updatedProceduresCopy,
        ]);

        setUpdatedProcedures(sortedProcedures);
        setModalLoader(false);

        setModalLoader(false);
      }, 2000);

      setModalLoader(true);

      showToast(
        "success",
        "Successfully copied procedures from previous treatments"
      );
    } catch (error) {
      setModalLoader(false);

      showToast("error", "Failed to fetch previous treatments");
    }
  };

  const placeOrder = (types) => {
    fetch(`/shopify/placeorder/${treatmentId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ types }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        onClose();
        toast.success("Order placed successfully");
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(`Error placing order: ${error.message}`);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        setSearchTerm("");
      }}
      maxWidth="md"
      fullWidth
    >
      <div className="flex justify-between items-center">
        <DialogTitle>
          <span className="proctitleStyling">
            <IconButton onClick={handlePrevious}>
              <ChevronLeft />
            </IconButton>
            Update Procedures
            <IconButton onClick={handleNext}>
              <ChevronRight />
            </IconButton>
          </span>
          <Tooltip
            title={
              <h1 className="text-sm">
                Copy Procedures From Previous Treatment
              </h1>
            }
            placement="right"
          >
            <IconButton
              onClick={() => {
                if ((clickedTreatmentData&&clickedTreatmentData.review)) {
                  return message.warning("This treatment is finalized so can't make any changes in it")
                } else { handleCopyProceduresFromPreviousTreatment() }
              }}
              sx={{ ml: "10px" }}
            >
              <ContentCopyRounded sx={{ fontSize: 30 }} />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <div className="flex items-center gap-4">
          <IconButton onClick={handleRefreshAndSortProcedures}>
            <RefreshIcon
              style={{
                fontSize: 30,
              }}
            />
          </IconButton>
          {from === "fdv" && (
            <IconButton title="Send to Shopify">
              <ShoppingCartCheckout
                onClick={() => {
                  if (!clickedTreatmentData.review) {
                            message.warning("This treatment is not finalized yet, so can't place a shopify order for it.")
                  }
                  else {
                    const modal = Modal.confirm({
                      title: 'Confirmation',
                      content: 'Are you sure you want to place this order?',
                      okText: 'Place Order',
                      cancelText: 'Cancel',  // Add the cancel button
                      onOk: () => {
                        modal.destroy()
                      placeOrder(["procedure"])
                    },  // Wrap in an arrow function
                    onCancel: () => modal.destroy(),  // Handle cancel button click
                    okButtonProps: {
                      style: {
                        backgroundColor: '#1976d2',
                        borderColor: '#1976d2',
                        color: 'white',
                        fontWeight: 'bold',
                      },
                    },
                  });
                }
                }}
                style={{
                  fontSize: 28,
                }}
              />
            </IconButton>
          )}
        </div>
      </div>
      {modalLoader && (
        <div className="loader-backdrop">
          <BounceLoader color="#000" />
        </div>
      )}

      <div className="px-5">
        {toast && (
          <CustomToast
            message={toast.message}
            variant={toast.variant}
            customStyles={{ bottom: "40px" }}
          />
        )}
        <div className="activeStylingD">{procedures?.patientName}</div>
        <div>
          <input
            placeholder="Search Procedures"
            className="procsearchInput"
            fullWidth
            margin="normal"
            onChange={handleSearchChange}
          />
        </div>
        <div className="procactiveStyling">
          <strong>Active Procedures:</strong> {getActiveProceduresSummary()}
        </div>
        <div className="outer-procedures-container">

          <div className="procedures-container inner-procedures-container" ref={containerRef} >
            {filteredProcedures.map((procedure,index) => {
              const loader = itemLoader.find(
                (loaderItem) => {
            
                 if( loaderItem.procedureId === procedure._id && loaderItem.show){
                  return true
                 }
                 return false
                }
              );
              return(
              <div key={index} className="procedure-row flex">
                <div className="procedure-name w-3/5">
                  {procedure.editItemName ? (
                    <>
                      <div className="flex">
                        {(procedure.procedure_name && !procedure.editItemName) ? (
                          procedure.procedure_name
                        ) : (
                          <input
                            className="dispensedTextInput"
                            type="text"
                            placeholder="Name"
                            value={procedure.procedure_name || ""}
                            onBlur={(e) =>{
                              if(!procedure?.editItemName){
                              handleChange(
                                {...procedure,procedure_name:e.target.value},
                                procedure.procedure_name,
                                "procedure_name",
                                e.target.value
                              )}
                            }}
                            onChange={(event) => {
                              setUpdatedProcedures((prevState) => {
                                const updatedState = [...prevState];
                                updatedState[index] = { 
                                  ...updatedState[index], 
                                  procedure_name: event.target.value 
                                };
                                return updatedState;
                              });
                            }}
                            fullWidth
                          />
                        )}

                        {procedure.procedure_name && <input
                          className="dispensedTextInput ml-[20px]"
                          type="text"
                          placeholder="Dosage"
                          value={procedure.selectedField || ""}
                          onChange={(event) => {
                            setUpdatedProcedures((prevState) => {
                              const updatedState = [...prevState];
                              updatedState[index] = { 
                                ...updatedState[index], 
                                selectedField: event.target.value 
                              };
                              return updatedState;
                            });
                          }}
                          fullWidth
                        />}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex items-center">
                        {procedure.procedure_name && (
                        <div className="procedure-name">
                          {procedure.procedure_name}
                        </div>
                      )}
                      {procedure.procedure_name && (procedure.editItemName===false || procedure.custom) && (
                        <input
                        className="dispensedTextInput ml-[20px]"
                        type="text"
                        placeholder="Dosage"
                        value={procedure.selectedField || ""}
                        onBlur={(e) => {
                          if ((clickedTreatmentData && clickedTreatmentData.review)) {
                            return message.warning("This treatment is finalized so can't make any changes in it")
                          }
                          else {
                            if(!procedure?.editItemName){
                            handleChange(
                              procedure,
                              procedure.procedure_name,
                              "selectedField",
                              e.target.value
                            )}
                          }
                        }
                        }
                        onChange={(event) => {
                          setUpdatedProcedures((prevState) => {
                            const updatedState = [...prevState]; // Create a copy to avoid direct mutation
                            updatedState[index] = { 
                              ...updatedState[index], 
                              selectedField: event.target.value 
                            };
                            return updatedState;
                          });
                        }}
                        fullWidth
                      />
                      )}
                      </div>
                    </>
                  )}
                </div>

                <div className="procedure-checkbox w-1/5 flex justify-center items-center">
                  {("checked" in procedure || "selectedField" in procedure) && (
                    <Checkbox
                      checked={procedure.checked}
                      onChange={(e) => {
                        if ((clickedTreatmentData&&clickedTreatmentData.review) ) {
                          return message.warning("This treatment is finalized so can't make any changes in it")
                        }
                        else {
                          if (e.target.checked) {
                            if(procedure.isNew){
                              handleChange(
                                procedure,
                                procedure.procedure_name,
                                "n",
                                procedure.procedure_name,
                              );
                            }
                            else{
                            handleChange(
                              procedure,
                              procedure.procedure_name,
                              "checked",
                              e.target.checked
                            );
                            }}
                          else {
                            handleChange(
                              procedure,
                              procedure.procedure_name,
                              "checked",
                              e.target.checked
                            );
                          }
                        }
                      }}
                    // disabled={("selectedField" in procedure)}
                    />
                  )}
                </div>

                <div className="procedure-details w-1/5 flex justify-start items-center">
                  {procedure.custom && (
                     loader
                     ?
                     <Spin indicator={<LoadingOutlined spin />} size="small" />

                     : <IconButton
                      onClick={() => handleDeleteProcedure(procedure._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                  {/* {"checked" in procedure && !procedure.dosages?.length > 0 && (
                <>
                  {proceduresArray.includes(procedure.procedure_name) ? (
                    <div className="procedure-checked">
                      <Checkbox
                        checked={procedure.checked || false}
                        onChange={(e) =>
                          handleChange(
                            procedure,
                            procedure.procedure_name,
                            "checked",
                            e.target.checked
                          )
                        }
                      />
                    </div>
                  ) : (
                    <>
                      <div className="mr-[20px] mt-[7px]">
                        {procedure?.selectedField}
                      </div>
                    </>
                  )}
                </>
              )} */}
                  {procedure.dosages && procedure.dosages.length > 0 && (
                    <div className="procedure-dosage my-auto">
                      <Select
                        className="dosageSelect"
                        value={procedure.selectedField ? procedure.selectedField : procedure.checked ? procedure.dosages[0] : ""}
                        onChange={(e) => {
                          if ((clickedTreatmentData&&clickedTreatmentData.review) ) {
                            return message.warning("This treatment is finalized so can't make any changes in it")
                          }
                          else {
                            handleChange(
                              procedure,
                              procedure.procedure_name,
                              "selectedField",
                              e.target.value
                            )
                          }
                        }
                        }
                        fullWidth
                      >
                        <MenuItem key="empty-option" value="">
                          Empty
                        </MenuItem>
                        {procedure.dosages.map((dosage, idx) => (
                          <MenuItem key={idx} value={dosage}>
                            {dosage}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  )}
                  {"customDosage" in procedure && (
                    <div className="procedure-note my-auto pr-2">
                      <input
                        className="note-input"
                        value={procedure.customDosage || ""}
                        onChange={(e) => {
                          if ((clickedTreatmentData&&clickedTreatmentData.review) ) {
                            return message.warning("This treatment is finalized so can't make any changes in it")
                          } else {
                            handleChange(
                              procedure,
                              procedure.procedure_name,
                              "customDosage",
                              e.target.value
                            )
                          }
                        }
                        }
                        fullWidth
                        placeholder="Dosage"
                      />
                    </div>
                  )}
                  {"note" in procedure && (
                    <div className="procedure-note my-auto pr-2">
                      {procedure.showNoteInputRow ||
                        (procedure.note && procedure.note.trim() !== "") ? (
                        <input
                          className="note-input"
                          value={procedure.note || ""}
                          onChange={(e) => {
                            if ((clickedTreatmentData&&clickedTreatmentData.review) ) {
                              return message.warning("This treatment is finalized so can't make any changes in it")
                            } else {
                              handleChange(
                                procedure,
                                procedure.procedure_name,
                                "note",
                                e.target.value
                              )
                            }
                          }
                          }
                          fullWidth
                        />
                      ) : (
                        !procedure.isNew && <IconButton
                          onClick={() =>
                            toggleNoteInputRow(procedure.procedure_name)
                          }
                        >
                          <NotesIcon />
                        </IconButton>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )})}
          </div>
        </div>
        <DialogActions>
          <button
            className="btn-color py-2 px-14 text-xs md:text-sm w-full md:w-auto"
            onClick={handleAddEmptyRow}
          >
            Add Custom Item
          </button>
          <button
            className="btn-color py-2 px-14 text-xs md:text-sm w-full md:w-auto"
            onClick={onClose}
          >
            Finish
          </button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

const proceduresArray = [
  "Vitamin D",
  "Red Light Bed",
  "Hydrogen IV",
  "Hydrogen In",
  "Glutathione",
  "HOT",
  "ALA",
  "CaEDTA",
  "Artemisinin",
  "IV Vitamin C",
  "Hydrogen Peroxide",
  "IV Nutra-Peroxide",
  "IV Nutritional Therapy",
  "MIC",
  "Inositol",
  "PTC",
  "Selenomethionine",
  "Sodium Selenite",
  "Amino Acid Essentials",
  "Sodium Bicarbonate",
  "Chelidonium",
  "Germanium",
  "Amygdalin",
  "Helixor",
  "Curcumin",
  "Resveratrol",
  "Quercetin",
  "Silymarin",
  "Bilberry Extract",
  "EGCG",
  "NAD+",
  "Zinc",
  "Echinacea FE",
  "D-Ribose",
  "Glutamine",
  "L-Lysine",
  "DCA",
  "Lipoic P",
  "Ondamed",
  "StemCells",
  "Prolo Injection",
];

export default ProcedureModal;
