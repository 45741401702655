import React, { useState, useEffect } from 'react';
import { Modal, Checkbox, Button, Row, Col, Spin, Radio, Space } from 'antd';
import { generatePriceListPDF } from './ReceiptGenerator';
import { FilePdfOutlined, PrinterOutlined, EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';

const PriceListModal = ({ visible, onClose, orderId, patientProfile, type, fdv, appointmentType, isComped }) => {
 
  const [printOptions, setPrintOptions] = useState({
    labs: true,
    procedures: true,
    medications: true, // New parent option for medications
    dispensed: true,
    supplements: true,
    activeSupplements: true, // Using the same option for both views
    newSupplements: true,
    selectAll: true,
  });
  
  const [optimized, setOptimized] = useState(true); // Default to optimized version
  const [isGenerating, setIsGenerating] = useState(false);
  const [pdfPreview, setPdfPreview] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  
  // States to track if items exist
  const [hasLabs, setHasLabs] = useState(false);
  const [hasProcedures, setHasProcedures] = useState(false);
  const [hasDispensed, setHasDispensed] = useState(false);
  const [hasSupplements, setHasSupplements] = useState(false);
  const [hasMedications, setHasMedications] = useState(false); // Track if medications exist
  const [hasActiveMedications, setHasActiveMedications] = useState(false); // Track if active medications exist
  const [medications, setMedications] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch all medications for the patient (active supplements)
  const fetchAllMedications = async () => {
    try {
      const response = await axios.get(`/emr/get-medications/${patientProfile._id}`);
      console.log('response of emr api', response.data);
      const activeMeds = response.data;
      setMedications(activeMeds);
      
      // Check if there are any active medications
      setHasActiveMedications(activeMeds && activeMeds.length > 0);
    } catch (error) {
      console.error("Error fetching medications:", error);
      setHasActiveMedications(false);
    }
  };

  // Updated useEffect with the provided code
  useEffect(() => {
    if (visible && orderId) {
      // Fetching active items
      axios.get(`/order/check-active-items/${orderId}`)
        .then(response => {
          const { hasLabs, hasProcedures, hasDispensed, hasSupplements } = response.data;
          setHasLabs(hasLabs);
          setHasProcedures(hasProcedures);
          setHasDispensed(hasDispensed);
          setHasSupplements(hasSupplements);
          // Rest of the logic for setting print options...
          setLoading(false);
        })
        .catch(error => {
          console.error("Error fetching active items:", error);
          setLoading(false);
        });
      
      // Ensure patient profile exists before fetching medications
      if (patientProfile && patientProfile._id) {
        fetchAllMedications();
      }
    }
  }, [visible, orderId, patientProfile]);
  
  const handleOptionChange = (option) => {
    if (option === 'selectAll') {
      const newValue = !printOptions.selectAll;
      setPrintOptions({
        ...printOptions,
        labs: newValue && hasLabs,
        procedures: newValue && hasProcedures,
        medications: newValue && hasMedications,
        dispensed: newValue && hasDispensed,
        supplements: newValue && hasSupplements,
        selectAll: newValue
      });
    } else if (option === 'medications') {
      // When medications is toggled, update both dispensed and supplements
      const newMedicationsValue = !printOptions.medications;
      setPrintOptions({
        ...printOptions,
        medications: newMedicationsValue,
        dispensed: newMedicationsValue ? (hasDispensed && printOptions.dispensed) : false,
        supplements: newMedicationsValue ? (hasSupplements && printOptions.supplements) : false,
        newSupplements: newMedicationsValue ? (hasSupplements && printOptions.newSupplements) : false,
        selectAll: checkSelectAll({
          ...printOptions,
          medications: newMedicationsValue,
          dispensed: newMedicationsValue ? (hasDispensed && printOptions.dispensed) : false,
          supplements: newMedicationsValue ? (hasSupplements && printOptions.supplements) : false
        })
      });
    } else if (option === 'dispensed') {
      // When dispensed is toggled, update medications parent if needed
      const newDispensedValue = !printOptions.dispensed;
      const newOptions = {
        ...printOptions,
        dispensed: newDispensedValue,
      };
      
      // Update medications parent checkbox state
      newOptions.medications = newOptions.dispensed || newOptions.supplements;
      
      newOptions.selectAll = checkSelectAll(newOptions);
      setPrintOptions(newOptions);
    } else if (option === 'supplements') {
      // When supplements is toggled, update medications parent if needed
      const newSupplementsValue = !printOptions.supplements;
      const newOptions = {
        ...printOptions,
        supplements: newSupplementsValue,
        newSupplements: newSupplementsValue ? printOptions.newSupplements : false,
      };
      
      // Update medications parent checkbox state
      newOptions.medications = newOptions.dispensed || newOptions.supplements;
      
      newOptions.selectAll = checkSelectAll(newOptions);
      setPrintOptions(newOptions);
    } else if (option === 'activeSupplements' || option === 'newSupplements') {
      // Handle supplement sub-options
      const newOptions = {
        ...printOptions,
        [option]: !printOptions[option],
      };
      
      // Make sure supplements is checked if any sub-option is checked
      if (newOptions.activeSupplements || newOptions.newSupplements) {
        newOptions.supplements = true;
        newOptions.medications = true;
      }
      
      newOptions.selectAll = checkSelectAll(newOptions);
      setPrintOptions(newOptions);
    } else {
      const newOptions = {
        ...printOptions,
        [option]: !printOptions[option]
      };
      
      newOptions.selectAll = checkSelectAll(newOptions);
      setPrintOptions(newOptions);
    }
  };

  // Helper to check if selectAll should be true
  const checkSelectAll = (options) => {
    return (
      (!hasLabs || options.labs) &&
      (!hasProcedures || options.procedures) &&
      (!hasMedications || options.medications)
    );
  };

  const handleVersionChange = (e) => {
    const isOptimized = e.target.value;
    setOptimized(isOptimized);
    
    // No need to modify activeSupplements when switching views
    // We're now using the same state for both views
    setPrintOptions({
      ...printOptions,
      labs: true,
      procedures: true,
      medications: true,
      dispensed: true,
      supplements: true,
      newSupplements: true,
      selectAll: true
    });
  };

  const generatePdf = async () => {
    setIsGenerating(true);
    
    try {
      // Make sure medications are loaded if needed
      let medsData = medications;
      
      // Always fetch medications if we have a patient profile
      if (patientProfile && patientProfile._id) {
        try {
          const response = await axios.get(`/emr/get-medications/${patientProfile._id}`);
          medsData = response.data;
          setMedications(medsData); // Update state for future use
        } catch (error) {
          console.error("Error fetching medications:", error);
        }
      }
      
      // Fetch the order data first
      const orderResponse = await axios.get(`/orderget-price-quote-with-prices/${orderId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      });
      
      if (orderResponse.status !== 200) {
        throw new Error("Failed to fetch order data");
      }
      
      // Create a copy of the order data to avoid mutation
      const enhancedOrderData = { ...orderResponse.data };
      
      // Add activeMedications directly to the orderData if activeSupplements option is enabled
      if (printOptions.activeSupplements && medsData && medsData.length > 0) {
        enhancedOrderData.activeMedications = medsData;
      }
      
      // Pass the current "type" prop to the PDF generator with the enhanced order data
      const result = await generatePriceListPDF(
        orderId, 
        patientProfile, 
        printOptions, 
        optimized,
        type,
        fdv,
        enhancedOrderData // Pass the enhanced order data instead of the original
      );
      
      if (result) {
        setPdfPreview(result);
        setShowPreview(true);
      }
    } catch (error) {
      console.error("Error generating price list:", error);
    } finally {
      setIsGenerating(false);
    }
  };
  
  const handleDownload = () => {
    if (!pdfPreview) return;
    
    const downloadLink = document.createElement("a");
    downloadLink.href = pdfPreview.url;
    downloadLink.download = pdfPreview.filename;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  
  const handlePrint = () => {
    if (!pdfPreview) return;
    
    const printWindow = window.open(pdfPreview.url, '_blank');
    if (printWindow) {
      printWindow.addEventListener('load', () => {
        printWindow.print();
      });
    }
  };
  
  const closePreview = () => {
    setShowPreview(false);
    // Clean up URL object
    if (pdfPreview && pdfPreview.url) {
      URL.revokeObjectURL(pdfPreview.url);
    }
  };
  
  // Preview Modal
  const renderPreviewModal = () => {
    if (!pdfPreview || !showPreview) return null;
    
    return (
      <Modal
        title="Price List Preview"
        open={showPreview}
        onCancel={closePreview}
        width="80%"
        style={{ top: 20 }}
        footer={[ 
          <Button 
            key="close" 
            onClick={closePreview}
          >
            Close
          </Button>,
          <Button 
            key="download" 
            onClick={handleDownload}
            icon={<DownloadOutlined />}
            className='text-black'
          >
            Download
          </Button>,
          <Button 
            key="print" 
            type="primary" 
            icon={<PrinterOutlined />} 
            onClick={handlePrint}
            className='text-black'
          >
            Print
          </Button>
        ]}>
        <div style={{ height: "70vh", overflow: "auto" }}>
          <iframe 
            src={pdfPreview.url} 
            width="100%" 
            height="100%" 
            title="Price List Preview"
            style={{ border: "1px solid #ddd" }}
          />
        </div>
      </Modal>
    )
  };

  return (
    <>
      <Modal
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FilePdfOutlined style={{ marginRight: 8, color: '#2980b9' }} />
            <span>Generate Price List</span>
          </div>
        }
        open={visible}
        onCancel={onClose}
        footer={null}
        width={500}
        maskClosable={false}
        className="price-list-modal"
      >
        <Spin spinning={isGenerating || loading}>
          <div className="mb-3">
            <p className="mb-2">Create a price list for this {type || "treatment"}:</p>
            
            <div className="my-3">
              <Radio.Group 
                value={optimized} 
                onChange={handleVersionChange}
                className="w-full"
              >
                <Space direction="vertical" className="w-full" size="small">
                  <Radio value={true} className="border p-2 w-full rounded">
                    <div>
                      <div className="font-medium">Single Page (Default)</div>
                      <div className="text-gray-500 text-sm">
                        Simplified view with items and prices only
                      </div>
                      
                      {hasActiveMedications && (
                        <div className="mt-1 ml-1">
                          <Checkbox 
                            checked={printOptions.activeSupplements}
                            onChange={() => handleOptionChange('activeSupplements')}
                            className="text-black"
                          >
                            Include patient supplements
                          </Checkbox>
                          <div className="text-gray-500 text-xs pl-4 mt-0.5">
                            Shows active supplements from patient profile
                          </div>
                        </div>
                      )}
                    </div>
                  </Radio>
                  <Radio value={false} className="border p-2 w-full rounded">
                    <div>
                      <div className="font-medium">Detailed View</div>
                      <div className="text-gray-500 text-sm">
                        Include additional pages with detailed information
                      </div>
                    </div>
                  </Radio>
                </Space>
              </Radio.Group>
            </div>
            
            {!optimized && !loading && (
              <div className="mt-3 p-3 border rounded">
                <p className="mb-1 font-medium">Additional detail pages to include:</p>
                
                {(hasLabs || hasProcedures || hasDispensed || hasSupplements) && (
                  <Checkbox
                    checked={printOptions.selectAll}
                    onChange={() => handleOptionChange('selectAll')}
                    className="text-black mb-1"
                  >
                    Select All
                  </Checkbox>
                )}
                
                <Row gutter={[16, 4]}>
                  {hasLabs && (
                    <Col span={24}>
                      <Checkbox 
                        checked={printOptions.labs}
                        onChange={() => handleOptionChange('labs')}
                        className="text-black"
                      >
                        Labs
                      </Checkbox>
                    </Col>
                  )}
                
                  {hasProcedures && (
                    <Col span={24}>
                      <Checkbox 
                        checked={printOptions.procedures}
                        onChange={() => handleOptionChange('procedures')}
                        className="text-black"
                      >
                        Procedures
                      </Checkbox>
                    </Col>
                  )}
                  
                  {(hasDispensed || hasSupplements || hasActiveMedications) && (
                    <Col span={24}>
                      <Checkbox 
                        checked={printOptions.medications}
                        onChange={() => handleOptionChange('medications')}
                        className="text-black"
                      >
                        Medications
                      </Checkbox>
                      
                      {printOptions.medications && (
                        <div className="pl-4 mt-0.5">
                          <Row gutter={[16, 4]}>
                            {hasDispensed && (
                              <Col span={24}>
                                <Checkbox 
                                  checked={printOptions.dispensed}
                                  onChange={() => handleOptionChange('dispensed')}
                                  className="text-black"
                                >
                                  Dispensed Items
                                </Checkbox>
                              </Col>
                            )}
                            
                            {(hasSupplements || hasActiveMedications) && (
                              <Col span={24}>
                                <Checkbox 
                                  checked={printOptions.supplements}
                                  onChange={() => handleOptionChange('supplements')}
                                  className="text-black"
                                >
                                  Supplements
                                </Checkbox>
                                
                                {printOptions.supplements && (
                                  <div className="pl-4 mt-0.5">
                                    <Row gutter={[16, 4]}>
                                      {hasSupplements && (
                                        <Col span={24}>
                                          <Checkbox 
                                            checked={printOptions.newSupplements}
                                            onChange={() => handleOptionChange('newSupplements')}
                                            className="text-black"
                                          >
                                            Order Supplements
                                          </Checkbox>
                                          <div className="text-gray-500 text-xs pl-4 mt-0.5">
                                            Supplements selected only for this order
                                          </div>
                                        </Col>
                                      )}
                                      
                                      {hasActiveMedications && (
                                        <Col span={24}>
                                          <Checkbox 
                                            checked={printOptions.activeSupplements}
                                            onChange={() => handleOptionChange('activeSupplements')}
                                            className="text-black"
                                          >
                                            Patient Supplements
                                          </Checkbox>
                                          <div className="text-gray-500 text-xs pl-4 mt-0.5">
                                            All supplements visible in patient profile
                                          </div>
                                        </Col>
                                      )}
                                    </Row>
                                  </div>
                                )}
                              </Col>
                            )}
                          </Row>
                        </div>
                      )}
                    </Col>
                  )}
                </Row>
              </div>
            )}
            
            {!optimized && !loading && !hasLabs && !hasProcedures && !hasDispensed && !hasSupplements && (
              <div className="mt-3 p-2 border rounded bg-gray-50">
                <p className="text-gray-500 text-center text-sm">
                  No details available for this {type || "treatment"}
                </p>
              </div>
            )}
          </div>
          
          <div className="flex justify-end gap-2 mt-4">
            <Button onClick={onClose}>
              Cancel
            </Button>
            <Button 
              type="primary"
              onClick={generatePdf}
              icon={<EyeOutlined />}
              loading={isGenerating}
              className="bg-[#2980b9] border-[#2980b9] hover:bg-[#3498db]"
              disabled={!optimized && !hasLabs && !hasProcedures && !hasDispensed && !hasSupplements && !hasActiveMedications}
            >
              Generate Preview
            </Button>
          </div>
        </Spin>
      </Modal>
      
      {renderPreviewModal()}
    </>
  );
};

export default PriceListModal;