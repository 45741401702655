import { IconButton } from '@mui/material';
import { Tooltip } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const LPDVIconButtons = ({ treatment, setShowModal, setModalType, setActiveTreatmentId, iconButtons , fromSoapNote=false, setActiveTreatment=()=>{}, fetchAllSupplements }) => {
  
  const handleOpenModal = async (type) => {
    try {
      if (type === "Supplements") {
        const updatedTreatment = await fetchAllSupplements(treatment);
  
        if (!updatedTreatment) {
          console.error("Error: updatedTreatment is undefined or null");
          return;
        }
  
        setActiveTreatment(updatedTreatment);  
        setActiveTreatmentId(updatedTreatment._id);
        setModalType("Supplements");
        setTimeout(()=>{
          setShowModal(true);
        },1000)
      } else {
        setActiveTreatment(treatment);
        setActiveTreatmentId(treatment?._id);
        setModalType(type);
        setShowModal(true);
      }
    } catch (error) {
      console.error("There was an error fetching the order:", error);
    }
  };

  const getTooltipContent = (labs, dispensed, procedures, supplements) => {
    // Handle Labs tooltip specific case (your main example)
    if (labs && labs.length > 0) {
      // Calculate how many columns we need (max 5 items per column)
      const numColumns = Math.ceil(labs.length / 5);
      const columns = Array(numColumns).fill().map(() => []);
      
      // Distribute items into columns (up to 5 per column)
      labs.forEach((lab, index) => {
        const columnIndex = Math.floor(index / 5);
        columns[columnIndex].push(lab);
      });
      
      // Use fixed column width
      const COLUMN_WIDTH = 130;
      const VISIBLE_COLUMNS = 2; // Show only 2 columns at a time
      
      return (
        <div style={{ maxWidth: COLUMN_WIDTH * VISIBLE_COLUMNS + 20, overflow: 'hidden' }}>
          <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>Labs:</div>
          <div style={{ overflowX: 'auto', overflowY: 'hidden' }}>
            <table style={{ tableLayout: 'fixed', borderCollapse: 'separate', borderSpacing: '8px 0' }}>
              <colgroup>
                {Array(numColumns).fill().map((_, i) => (
                  <col key={i} style={{ width: COLUMN_WIDTH }} />
                ))}
              </colgroup>
              <tbody>
                {[0, 1, 2, 3, 4].map(rowIndex => (
                  <tr key={rowIndex}>
                    {columns.map((column, colIndex) => {
                      if (column[rowIndex]) {
                        return (
                          <td key={colIndex} style={{ 
                            padding: '2px 4px', 
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                          }}>
                            {column[rowIndex].test_name}
                          </td>
                        );
                      }
                      return <td key={colIndex}></td>;
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    }

    // Handle other tooltip types similarly
    if (dispensed && dispensed.length > 0) {
      const numColumns = Math.ceil(dispensed.length / 5);
      const columns = Array(numColumns).fill().map(() => []);
      
      dispensed.forEach((item, index) => {
        const columnIndex = Math.floor(index / 5);
        columns[columnIndex].push(item);
      });
      
      // Use fixed column width
      const COLUMN_WIDTH = 130;
      const VISIBLE_COLUMNS = 2; // Show only 2 columns at a time
      
      return (
        <div style={{ maxWidth: COLUMN_WIDTH * VISIBLE_COLUMNS + 20, overflow: 'hidden' }}>
          <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>Dispensed:</div>
          <div style={{ overflowX: 'auto', overflowY: 'hidden' }}>
            <table style={{ tableLayout: 'fixed', borderCollapse: 'separate', borderSpacing: '8px 0' }}>
              <colgroup>
                {Array(numColumns).fill().map((_, i) => (
                  <col key={i} style={{ width: COLUMN_WIDTH }} />
                ))}
              </colgroup>
              <tbody>
                {[0, 1, 2, 3, 4].map(rowIndex => (
                  <tr key={rowIndex}>
                    {columns.map((column, colIndex) => {
                      if (column[rowIndex]) {
                        return (
                          <td key={colIndex} style={{ 
                            padding: '2px 4px', 
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                          }}>
                            {column[rowIndex].name}
                          </td>
                        );
                      }
                      return <td key={colIndex}></td>;
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    }

    if (procedures && procedures.length > 0) {
      const numColumns = Math.ceil(procedures.length / 5);
      const columns = Array(numColumns).fill().map(() => []);
      
      procedures.forEach((item, index) => {
        const columnIndex = Math.floor(index / 5);
        columns[columnIndex].push(item);
      });
      
      // Use fixed column width
      const COLUMN_WIDTH = 130;
      const VISIBLE_COLUMNS = 2; // Show only 2 columns at a time
      
      return (
        <div style={{ maxWidth: COLUMN_WIDTH * VISIBLE_COLUMNS + 20, overflow: 'hidden' }}>
          <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>Procedures:</div>
          <div style={{ overflowX: 'auto', overflowY: 'hidden' }}>
            <table style={{ tableLayout: 'fixed', borderCollapse: 'separate', borderSpacing: '8px 0' }}>
              <colgroup>
                {Array(numColumns).fill().map((_, i) => (
                  <col key={i} style={{ width: COLUMN_WIDTH }} />
                ))}
              </colgroup>
              <tbody>
                {[0, 1, 2, 3, 4].map(rowIndex => (
                  <tr key={rowIndex}>
                    {columns.map((column, colIndex) => {
                      if (column[rowIndex]) {
                        return (
                          <td key={colIndex} style={{ 
                            padding: '2px 4px', 
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                          }}>
                            {column[rowIndex].procedure_name}
                          </td>
                        );
                      }
                      return <td key={colIndex}></td>;
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    }

    if (supplements && supplements.length > 0) {
      const numColumns = Math.ceil(supplements.length / 5);
      const columns = Array(numColumns).fill().map(() => []);
      
      supplements.forEach((item, index) => {
        const columnIndex = Math.floor(index / 5);
        columns[columnIndex].push(item);
      });
      
      // Use fixed column width
      const COLUMN_WIDTH = 130;
      const VISIBLE_COLUMNS = 2; // Show only 2 columns at a time
      
      return (
        <div style={{ maxWidth: COLUMN_WIDTH * VISIBLE_COLUMNS + 20, overflow: 'hidden' }}>
          <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>Supplements:</div>
          <div style={{ overflowX: 'auto', overflowY: 'hidden' }}>
            <table style={{ tableLayout: 'fixed', borderCollapse: 'separate', borderSpacing: '8px 0' }}>
              <colgroup>
                {Array(numColumns).fill().map((_, i) => (
                  <col key={i} style={{ width: COLUMN_WIDTH }} />
                ))}
              </colgroup>
              <tbody>
                {[0, 1, 2, 3, 4].map(rowIndex => (
                  <tr key={rowIndex}>
                    {columns.map((column, colIndex) => {
                      if (column[rowIndex]) {
                        return (
                          <td key={colIndex} style={{ 
                            padding: '2px 4px', 
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                          }}>
                            {column[rowIndex].name}
                          </td>
                        );
                      }
                      return <td key={colIndex}></td>;
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    }

    // Empty case
    return <div>No items</div>;
  };

  // Helper function to render each section for the simple case (5 or fewer items)
  const renderSection = (title, items) => {
    if (!items || items.length === 0) return null;
    
    return (
      <div style={{ marginBottom: '12px' }}>
        <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>{title}:</div>
        {items.map((item) => {
          let displayText = '';
          
          // Handle different item types
          if (title === 'Labs') {
            displayText = item.test_name;
          } else if (title === 'Dispensed') {
            displayText = `${item.name} - Quantity: ${item.quantity} - Dosage: ${item.selectedField}`;
          } else if (title === 'Supplements') {
            displayText = `${item.name} - Quantity: ${item.quantity}`;
          } else if (title === 'Procedures') {
            displayText = `${item.procedure_name}${item.selectedField ? ` - Selected: ${item.selectedField}` : ''}${item.note ? ` - Note: ${item.note}` : ''}`;
          }
          
          return (
            <div key={item._id || Math.random()} style={{ marginTop: '2px', marginBottom: '2px' }}>
              {displayText}
            </div>
          );
        })}
      </div>
    );
  };

  const getCheckedLabs = (userLabs) => {
    return userLabs?.filter((lab) => lab.checked);
  };
  const getDispensedItemsWithSelectedField = (userdispensed) => {
    return userdispensed?.filter((item) => item.selectedField);
  };
  const getRelevantProcedures = (userProcedures) => {
    return userProcedures?.filter(
      (procedure) => procedure.checked || procedure.selectedField
    );
  };

  const getRelevantSupplements = (userSupplements) => {
    return userSupplements?.filter(
      (supplement) => supplement.quantity > 0
    );
  };

  const tooltipLabs = getCheckedLabs(treatment.userLabs);
  const tooltipDispensed = getDispensedItemsWithSelectedField(
    treatment?.userdispensed
  );
  const tooltipProcedures = getRelevantProcedures(
    treatment?.userProcedures
  );
  const tooltipSupplements = getRelevantSupplements(
    treatment?.userSupplements
  );

  const [showTooltipLabs, setShowTooltipLabs] = useState(false);
  const [showTooltipProcedures, setShowTooltipProcedures] = useState(false);
  const [showTooltipDispensed, setShowTooltipDispensed] = useState(false);
  const [showTooltipSupplements, setShowTooltipSupplements] = useState(false);

  return (
    <div className="flex items-center">
      {iconButtons.includes("L")&&<Tooltip
        onVisibleChange={(visible) => setShowTooltipLabs(visible)}
        visible={showTooltipLabs}
        title={getTooltipContent(tooltipLabs, [], [], [])}
      >
        <IconButton
          size="small"
          onClick={() => handleOpenModal("Labs")}
          aria-label="labs"
          sx={{
            width: 24,
            height: 24,
            fontSize: "0.9rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: treatment?.userLabs?.some(
              (lab) => lab.checked
            )
              ? "#b9f6ca"
              : "#e3f2fd",
            margin: "2px",
            color: "black",
          }}
        >
          L
        </IconButton>
      </Tooltip>}

      {iconButtons.includes("P")&&<Tooltip
        onVisibleChange={(visible) => setShowTooltipProcedures(visible)}
        visible={showTooltipProcedures}
        title={getTooltipContent([], [], tooltipProcedures, [])}
      >
        <IconButton
          size="small"
          onClick={() => handleOpenModal("Procedures")}
          aria-label="procedures"
          sx={{
            width: 24,
            height: 24,
            fontSize: "0.9rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: treatment?.userProcedures?.some(
              (procedure) =>
                (procedure.selectedField &&
                  procedure?.selectedField?.trim()?.length > 0) ||
                procedure.checked
            )
              ? "#b9f6ca"
              : "#90caf9",
            margin: "2px",
            color: "black",
          }}
        >
          P
        </IconButton>
      </Tooltip>}

      {iconButtons.includes("D")&&<Tooltip
        onVisibleChange={(visible) => setShowTooltipDispensed(visible)}
        visible={showTooltipDispensed}
        title={getTooltipContent([], tooltipDispensed, [], [])}
      >
        <IconButton
          size="small"
          onClick={() => handleOpenModal("Dispensed")}
          aria-label="dispense"
          sx={{
            width: 24,
            height: 24,
            fontSize: "0.9rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: treatment?.userdispensed?.some(
              (dispensed) =>
                dispensed.selectedField &&
                dispensed?.selectedField?.trim()?.length > 0
            )
              ? "#b9f6ca"
              : "#42a5f5",
            margin: "2px",
            color: "black",
          }}
        >
          D
        </IconButton>
      </Tooltip>}

      {iconButtons.includes("S")&&<Tooltip
        onVisibleChange={(visible) => setShowTooltipSupplements(visible)}
        visible={showTooltipSupplements}
        title={getTooltipContent([], [], [], tooltipSupplements)}
      >
        <IconButton
          size="small"
          onClick={() => handleOpenModal("Supplements")}
          aria-label="supplement"
          sx={{
            width: 24,
            height: 24,
            fontSize: "0.9rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: treatment?.userSupplements?.some(
              (supplement) => supplement.quantity>0 )
              ? "#00cc00"
              : "#f0ad4e",
            margin: "2px",
            color: "black",
          }}
        >
          S
        </IconButton>
      </Tooltip>}

      {iconButtons.includes("V")&&<IconButton
        size="small"
        onClick={() => handleOpenModal("Vitals")}
        aria-label="dispense"
        sx={{
          width: 24,
          height: 24,
          fontSize: "0.9rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor:
            treatment?.vitals?.length > 0 ? "#81e984" : "#1e88e5",
          margin: "2px",
          color: "black",
        }}
      >
        V
      </IconButton>}
      {(iconButtons.includes("N") && treatment?.type === "Treatment") && <IconButton
        size="small"
        onClick={() => handleOpenModal("Notes")}
        aria-label="dispense"
        sx={{
          width: 24,
          height: 24,
          fontSize: "0.9rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor:"#1e88e5",
          margin: "2px",
          color: "black",
        }}
      >
        N
      </IconButton>}
    </div>
  );
};

export default LPDVIconButtons;