import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { Checkbox, Col, Form, Input, Radio, Row, Space } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import Paragraph from 'antd/es/typography/Paragraph'
import Title from 'antd/lib/typography/Title'
import React, { useState, useEffect } from 'react'

const HealthQuestionsForm = ({ form, handleNext, handlePrev, formData, setFormData, updateFormData }) => {


  const [familyHistoryOptions, setFamilyHistoryOptions] = useState([
    { id: "asthma", label: "Asthma", checked: false },
    { id: "autoimmune_disease", label: "Autoimmune disease", checked: false },
    { id: "fatigue", label: "Minimize symptoms of fatigue", checked: false },
    { id: "cancer", label: "Cancer", checked: false },
    { id: "heart_disease", label: "Heart disease", checked: false },
    { id: "diabetes", label: "Diabetes", checked: false },
    { id: "high_blood_pressure", label: "High blood pressure", checked: false },
    { id: "mental_illness", label: "Mental illness", checked: false },
    { id: "stroke", label: "Stroke", checked: false },
    { id: "other", label: "Other", checked: false },
  ]);
  const [healthHistoryOtherValue, setHealthHistoryOtherValue] = useState("");
  useEffect(() => {
    if (formData?.familyHealthHistory.value?.length > 0) {
      const retrievedOptions = formData?.familyHealthHistory?.value || [];
      
      if (Array.isArray(retrievedOptions)) {

          const updatedOptions = familyHistoryOptions.map((goal) => ({
            ...goal,
            checked: retrievedOptions.includes(goal.label)
          }));
          setFamilyHistoryOptions(updatedOptions)

      }
    }
  }, [formData]);




  const handleHealthHistoryCheckboxChange = (id) => {
    let updatedHistoryOptions = familyHistoryOptions.map((goal) =>
      goal.id === id ? { ...goal, checked: !goal.checked } : goal
    );

    setFamilyHistoryOptions(updatedHistoryOptions);

    const selectedGoals = updatedHistoryOptions
      .filter((goal) => goal.checked)
      .map((goal) => goal.label);

    setFormData({ ...formData, familyHealthHistory:{...formData.familyHealthHistory, value: selectedGoals} });
  };

  const handleFamilyHistoryOtherInputChange = (e) => {
    const value = e.target.value;
    setHealthHistoryOtherValue(value);

    if (familyHistoryOptions.find((g) => g.id === "other")?.checked) {
      setFormData({ ...formData, familyHealthHistory: { ...formData.familyHealthHistory, otherValue: value.trim() } });
    }
  };


  const [weightProblemsOptions, setWeightProblemsOptions] = useState([
    { id: "30_over_your_recommended_weight", label: "30% over your recommended weight", checked: false },
    { id: "anorexia", label: "Anorexia", checked: false },
    { id: "binge_eating", label: "Binge eating", checked: false },
    { id: "eat_to_gain_weight", label: "Eat to gain weight", checked: false },
    { id: "eat_to_lose_weight", label: "Eat to lose weight", checked: false },
    { id: "bulimia", label: "Bulimia", checked: false },
    { id: "unable_to_gain_weight", label: "Unable to gain weight", checked: false },
    { id: "rapid_unexplained_weight_loss", label: "Rapid/unexplained weight loss", checked: false },
    { id: "none", label: "None", checked: false },
    { id: "other", label: "Other", checked: false },
  ]);
  const [weightProblemsOtherValue, setWeightProblemsOtherValue] = useState("");
  useEffect(() => {
    if (formData?.pastMedicalHistory?.weightissues?.value?.length > 0) {
      const retrievedOptions = formData?.pastMedicalHistory?.weightissues?.value || [];

      if (Array.isArray(retrievedOptions)) {

          const updatedOptions = weightProblemsOptions.map((goal) => ({
            ...goal,
            checked: retrievedOptions.includes(goal.label)
          }));
          setWeightProblemsOptions(updatedOptions)
      }
    }
  }, [formData]);




  const handleWeightIssuesCheckboxChange = (id) => {

    let updatedOptions = weightProblemsOptions.map((goal) =>
      goal.id === id ? { ...goal, checked: !goal.checked } : goal
    );

    setWeightProblemsOptions(updatedOptions);

    const selectedGoals = updatedOptions
      .filter((goal) => goal.checked)
      .map((goal) => goal.label);

    setFormData({ ...formData, pastMedicalHistory: { ...formData.pastMedicalHistory, weightissues: { ...formData.pastMedicalHistory.weightissues, value: selectedGoals } } });
  };

  const handleWeightProblemOtherInputChange = (e) => {
    const value = e.target.value;
    setWeightProblemsOtherValue(value);

    if (weightProblemsOptions.find((g) => g.id === "other")?.checked) {
      setFormData({ ...formData, pastMedicalHistory: { ...formData.pastMedicalHistory, weightissues: { ...formData.pastMedicalHistory.weightissues, otherValue: value } } });
    }
  };





  // const [medications,setMedications]=useState("");

  // useEffect(()=>{
  //   const medications= form.getFieldsValue('currentMedications')
  //   setMedications(medications)
  // }, [form])

  const handleAllergiesChange = (e) => {
    const value = e.target.value;

    if (value === "yes") {
      setFormData({ ...formData, allergies: { ...formData?.allergies, allergic: "yes", allergicTypes: "" } });
    } else {
      updateFormData('allergies.allergic', e.target.value)
    }
  };

  const handleAllergiesInputChange = (e) => {
    updateFormData('allergies.allergicTypes', e.target.value)
  };

  const handleMedicationsChange = (e) => {
    const value = e.target.value;

    if (value === "yes") {
      setFormData({ ...formData, currentMedications: "yes", medicationsupplements: "" });
    } else {
      updateFormData('currentMedications', e.target.value)
    }
  };

  const handleMedicationsInputChange = (e) => {
    updateFormData('medicationsupplements', e.target.value)
  };

  return (
    <>
      <div className="bg-white p-6 rounded-2xl w-full">
        <Space direction="vertical" size="large" style={{ width: '100%' }}>

          <div
            className='w-full flex flex-col'
          >
            <Row gutter={[16]}>
              {/* Column 1 */}
              <Col xs={24} sm={12} style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <Row gutter={16}>
                  <Col span={24}>
                    <Title level={4}>Family History</Title>
                    <p className='text-sm pb-2'>
                      Has anyone in your immediate family (blood relative) ever had any of the following diseases or conditions?
                    </p>
                    <Row gutter={16}>
                      <Col span={24}>
                        {/* <p className='text-sm pb-2'>Family Health History</p> */}
                        <Row gutter={[16, 16]}>
                          {familyHistoryOptions.map((option) => (
                            <Col span={12} key={option.id}>
                              <Checkbox
                                checked={option.checked}
                                onChange={() => handleHealthHistoryCheckboxChange(option.id)}
                                // disabled={familyHistoryOptions.find((g) => g.id === "other")?.checked && option.id !== "other"}
                              >
                                {option.label}
                              </Checkbox>
                            </Col>
                          ))}
                        </Row>

                        {familyHistoryOptions.find((g) => g.id === "other")?.checked && (
                          <Input
                            style={{ marginTop: 10, width: '50%' }}
                            placeholder="Enter other goal"
                            value={formData?.familyHealthHistory?.otherValue}
                            onChange={handleFamilyHistoryOtherInputChange}
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Title level={4}>Allergies</Title>
                    <Col span={24}>
                      <p className='text-sm pb-2'>Do you have any allergies to medications, foods, or environmental allergens?</p>
                      <Radio.Group onChange={handleAllergiesChange} value={formData?.allergies?.allergic || "no"}>
                        <Radio value="yes">Yes</Radio>
                        <Radio value="no">No</Radio>
                        <Radio value="notSure">Not Sure</Radio>
                      </Radio.Group>
                      {formData?.allergies?.allergic === "yes" && (
                        <Input
                          placeholder="Enter details..."
                          value={formData.allergicTypes}
                          onChange={handleAllergiesInputChange}
                          style={{ marginTop: 10, width: "100%" }}
                        />
                      )}
                    </Col>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Title level={4}>Medications / Supplements</Title>

                    <Col span={24}>
                      <p className='text-sm pb-2'>Are you currently taking any medications or supplements?</p>
                      <Radio.Group onChange={handleMedicationsChange} value={formData?.currentMedications || "no"}>
                        <Radio value="yes">Yes</Radio>
                        <Radio value="no">No</Radio>
                      </Radio.Group>
                      {formData?.currentMedications === "yes" && (
                        <Input.TextArea
                          autoSize={{ minRows: 3 }}
                          placeholder="Enter details..."
                          value={formData.medicationsupplements}
                          onChange={handleMedicationsInputChange}
                          style={{ marginTop: 10, width: "100%" }}
                        />
                      )}
                    </Col>

                    <Col span={24}>

                      <p className='text-sm pb-2'>Has your weight changed in the last year? If so how?</p>
                      <Input value={formData?.pastMedicalHistory?.weightChange} onChange={(e) => updateFormData('pastMedicalHistory.weightChange', e.target.value)} />
                    </Col>

                    <Col span={24}>
                      <p className='text-sm pb-2'>Have you ever had problems with your weight? Please describe the problem:</p>
                      <Row gutter={16}>
                        <Col span={24}>                          
                          <Row gutter={[16, 16]}>
                            {weightProblemsOptions.map((goal) => (
                              <Col span={12} key={goal.id}> {/* Each column takes half the width (12 out of 24) */}
                                <Checkbox
                                  checked={goal.checked}
                                  onChange={() => handleWeightIssuesCheckboxChange(goal.id)}
                                  // disabled={weightProblemsOptions.find((g) => g.id === "other")?.checked && goal.id !== "other"}
                                >
                                  {goal.label}
                                </Checkbox>
                              </Col>
                            ))}

                          </Row>


                          {weightProblemsOptions.find((g) => g.id === "other")?.checked && (
                            <Input
                              style={{ marginTop: 10, width: '50%' }}
                              placeholder="Enter other goal"
                              value={formData?.pastMedicalHistory?.weightissues?.otherValue}
                              onChange={handleWeightProblemOtherInputChange}
                            />
                          )}
                        </Col>
                      </Row>

                    </Col>
                  </Col>
                </Row>
              </Col>

              {/* Column 2 */}
              <Col xs={24} sm={12} style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <Row gutter={16}>
                  <Col xs={12}>
                    <Row gutter={16}>
                      <Col span={24}>
                        <p className='text-sm pb-2'>Weight</p>
                        <Input value={formData?.pastMedicalHistory?.weight} onChange={(e) => updateFormData('pastMedicalHistory.weight', e.target.value)} />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12}>
                    <Row gutter={16}>
                      <Col span={24}>
                        <p className='text-sm pb-2'>Height</p>
                        <Input value={formData?.pastMedicalHistory?.height} onChange={(e) => updateFormData('pastMedicalHistory.height', e.target.value)} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <p className='text-sm pb-2'>Diet</p>
                    <TextArea rows={4} value={formData?.pastMedicalHistory?.diet} onChange={(e) => updateFormData('pastMedicalHistory.diet', e.target.value)} />
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={24}>
                    <p className='text-sm pb-2'>Please describe any regular exercise</p>
                    <TextArea rows={4} value={formData?.pastMedicalHistory?.exercise} onChange={(e) => updateFormData('pastMedicalHistory.exercise', e.target.value)} />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>

                    <p className='text-sm pb-2'>Please describe your current level of stress and any ways you use to manage your stress</p>
                    <TextArea rows={4} value={formData?.pastMedicalHistory?.stressLevel} onChange={(e) => updateFormData('pastMedicalHistory.stressLevel', e.target.value)} />

                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <p className='text-sm pb-2'>How often do you consume alcohol?</p>
                    <Radio.Group onChange={(e) => updateFormData('healthQuestions.alcoholConsumption', e.target.value)} value={formData?.healthQuestions?.alcoholConsumption}>
                      <Radio value="daily">Daily</Radio>
                      <Radio value="weekly">Weekly</Radio>
                      <Radio value="monthly">Monthly</Radio>
                      <Radio value="occasionally">Occasionally</Radio>
                      <Radio value="never">Never</Radio>
                    </Radio.Group>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={24}>
                    <p className='text-sm pb-2'>Have you ever smoked cigarettes, cigars, marijuana or a pipe?</p>
                    <Radio.Group onChange={(e) => updateFormData('healthQuestions.smokingHistory', e.target.value)} value={formData?.healthQuestions?.smokingHistory}>
                      <Radio value="yes">Yes</Radio>
                      <Radio value="no">No</Radio>
                    </Radio.Group>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={24}>
                    <p className='text-sm pb-2'>Do you use or do you have history of using illegal drugs?</p>
                    <Radio.Group onChange={(e) => updateFormData('healthQuestions.illegalDrugUse', e.target.value)} value={formData?.healthQuestions?.illegalDrugUse}>
                      <Radio value="yes">Yes</Radio>
                      <Radio value="no">No</Radio>
                    </Radio.Group>

                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>

                    <p className='text-sm pb-2'>Have you ever been vaccinated?</p>
                    <Radio.Group onChange={(e) => updateFormData('healthQuestions.vaccinated', e.target.value)} value={formData?.healthQuestions?.vaccinated}>
                      <Radio value="yes">Yes</Radio>
                      <Radio value="no">No</Radio>
                    </Radio.Group>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>

                    <p className='text-sm pb-2'>Have you ever had a reaction to a vaccine?</p>
                    <Radio.Group onChange={(e) => updateFormData('healthQuestions.reactionToVaccine', e.target.value)} value={formData?.healthQuestions?.reactionToVaccine}>
                      <Radio value="yes">Yes</Radio>
                      <Radio value="no">No</Radio>
                    </Radio.Group>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={24}>
                    <p className='text-sm pb-2'>Have you ever had surgery?</p>
                    <Radio.Group onChange={(e) => updateFormData('healthQuestions.surgeryHistory', e.target.value)} value={formData?.healthQuestions?.surgeryHistory}>
                      <Radio value="yes">Yes</Radio>
                      <Radio value="no">No</Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            <div className="flex justify-between">
              <IconButton onClick={handlePrev}>
                <NavigateBefore />
              </IconButton>
              <IconButton>
                <NavigateNext onClick={handleNext} />
              </IconButton>
            </div>
          </div>

        </Space>

      </div>
    </>
  )
}

export default HealthQuestionsForm