import React, { useEffect, useRef, useState } from "react";
import { Modal, Checkbox, Button, Spin } from "antd";
import axios from "axios";
import moment from "moment";
import html2pdf from "html2pdf.js";
import signatureImage from "../../../../Assets/signature.jpg";
import sunridgeLogo from "../../../../Assets/sunridege_logo.png";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

const PrintModal = ({
  isOpen,
  onClose,
  selectedPatient,
  encounters = [],
  soapNotes = {},
  formSoapNote = false,
  printMedications = false,
  setPrintMedications = () => {},
  activeMedications=[],
  historicalMedications=[],
  activeDispensedItems=[]
}) => {
  const [checkboxes, setCheckboxes] = useState({
    subjective: false,
    medicationsNote: false,
    objective: false,
    assessment: false,
    careplan: false,
    plan: false,
    healthConcerns: false,
    diagnoses: false,
    familyHealthHistory: false,
    pastMedicalHistory: false,
    allergies: false,
    medications: false,
    implantableDevices: false,
    goals: false,
  });
  dayjs.extend(utc);
  const [emrData, setEMRData] = useState({});
  // const [patientActiveMedications, setPatientActiveMedications] = useState([]);
  // const [patientHistoricalMedications, setPatientHistoricalMedications] = useState([]);
  const [selectedEncounters, setSelectedEncounters] = useState({});
  const [separatePageSections, setSeparatePageSections] = useState([]);
  const [openPreview, setOpenPreview] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [medicationsCategory, setMedicationsCategory] = useState("all");
  const pdfDocRef = useRef(null);


  const fetchPatientProfile = async(patientCustomerId) => {
    try {
      const response = await axios.get(`/patient-profiles/${patientCustomerId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      });
      if (response.status === 200) {
        // const medications = [...response?.data?.RecommendedSupplements, ...response?.data?.HistoricalSupplements]
        // setPatientActiveMedications(response?.data?.RecommendedSupplements)
        // setPatientHistoricalMedications(response?.data?.HistoricalSupplements)
      }
    } catch (error) {
      console.error("Error fetching patient profile:", error);
    }
  };

  // useEffect(()=>{
  //   console.log("***************** patient Medications", patientActiveMedications, "**********", patientHistoricalMedications)
  // },[patientActiveMedications,patientHistoricalMedications])

  const resetCheckboxes = () => {
    setCheckboxes({
      subjective: false,
      medicationsNote: false,
      objective: false,
      assessment: false,
      careplan: false,
      plan: false,
      healthConcerns: false,
      diagnoses: false,
      familyHealthHistory: false,
      pastMedicalHistory: false,
      allergies: false,
      medications: false,
      implantableDevices: false,
      goals: false,
    });
  };

  const handleDownloadPDF = () => {
    if (pdfDocRef.current) {
      const sanitizedPatientName =
        selectedPatient["Full Name"]?.replace(/[^a-zA-Z0-9]/g, "_") ||
        "Patient";
      const sanitizedDate = moment().format("MM-DD-YYYY").replace(/\//g, "-");
      const filename = `Sunridge_Medical_${sanitizedPatientName}_${sanitizedDate}_EMR.pdf`;
      pdfDocRef.current.save(filename);
    }
  };

  const fetchEMRData = async () => {
    try {
      const response = await axios.get(
        `/emr/${selectedPatient._id}`
      );
      if (response.data) {
        setEMRData(response.data);
      } else {
        setEMRData({});
      }
    } catch (error) {
      console.error("Error fetching EMR data", error);
    }
  };

  useEffect(() => {
    fetchEMRData();
    fetchPatientProfile(selectedPatient['Customer ID'])
  }, [isOpen]);

  const calculateAge = (dob, date) => {
    if (!dob || !date) return "";
  
    const birthDate = dayjs.utc(dob).toDate();
    const comparedDate = dayjs.utc(date).toDate();
  
    let age = comparedDate.getFullYear() - birthDate.getFullYear();
    const monthDifference = comparedDate.getMonth() - birthDate.getMonth();
  
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && comparedDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }
  
    return age.toString();
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxes((prev) => ({
      ...prev,
      [name]: checked,
    }));

    // If checkbox is unchecked, also uncheck the separate page checkbox for that section
    if (!checked) {
      setSeparatePageSections((prev) => prev.filter((item) => item !== name));
    }
  };

  const handleSeparatePageChange = (e, sectionKey) => {
    if (!selectedEncounters[sectionKey] && !checkboxes[sectionKey]) return;
    const { checked } = e.target;
    setSeparatePageSections((prev) =>
      checked
        ? [...prev, sectionKey]
        : prev.filter((item) => item !== sectionKey)
    );
  };

  // Helper function to clean HTML content
  const cleanHtml = (html) => {
    if (!html) return "";

    // Create a temporary container
    const container = document.createElement("div");
    // Set the HTML content
    container.innerHTML = html;

    // Remove any script tags for security
    const scripts = container.querySelectorAll("script");
    scripts.forEach((script) => script.remove());

    // Get the sanitized HTML
    return container.innerHTML;
  };

  const formatAllergies = (allergies) => {
    const { drug, food, environmental, medications } = allergies || {};
    let content = "";

    if (drug) content += `Drug: ${drug}\n`;
    if (food) content += `Food: ${food}\n`;
    if (environmental) content += `Environmental: ${environmental}\n`;
    if (medications) content += `Medications: ${medications}\n`;

    return content;
  };

  const formatPastMedicalHistory = (pastMedicalHistory) => {
    const {
      majorEvents,
      ongoingMedicalProblems,
      preventiveCare,
      socialHistory,
      nutritionHistory,
      developmentHistory,
    } = pastMedicalHistory || {};
    let content = "";

    if (majorEvents) content += `Major Events: ${majorEvents}\n`;
    if (ongoingMedicalProblems)
      content += `Ongoing Medical Problems: ${ongoingMedicalProblems}\n`;
    if (preventiveCare) content += `Preventive Care: ${preventiveCare}\n`;
    if (socialHistory) content += `Social History: ${socialHistory}\n`;
    if (nutritionHistory) content += `Nutrition History: ${nutritionHistory}\n`;
    if (developmentHistory)
      content += `Development History: ${developmentHistory}\n`;

    return content;
  };
  const handlePrint = () => {
    const printContent = document.createElement('div');
    printContent.style.padding = '5px';
    printContent.style.fontFamily = 'Arial, sans-serif';
    printContent.style.fontSize = '10pt';
    printContent.style.lineHeight = '1.2';
  
    // Add global CSS for strict space control and page breaks
    const styleElement = document.createElement('style');
    styleElement.textContent = `
      /* Global space elimination */
      * { margin: 0; padding: 0; }
      p { margin: 0 0 3px 0; }
      h3 { margin: 5px 0 3px 0; }
      
      /* Remove all empty elements */
      p:empty, div:empty, li:empty, ul:empty, span:empty { display: none; height: 0; }
      br + br { display: none; }
      
      /* Compact sections */
      .section-content { margin-bottom: 5px; padding-bottom: 3px; }
      .section-title { margin-bottom: 3px; }
      
      /* Page break controls */
      .page-break { page-break-before: always; }
      table { width: 100%; border-collapse: collapse; page-break-inside: avoid !important; }
      tr { page-break-inside: avoid !important; }
      tbody { page-break-inside: avoid !important; }
      .avoid-break { page-break-inside: avoid !important; }
      
      /* Control whitespace */
      .no-bottom-space { margin-bottom: 0 !important; padding-bottom: 0 !important; }
      
      /* Footer */
      .page-footer { position: fixed; bottom: 5mm; width: 100%; text-align: center; font-size: 8pt; }
    `;
    printContent.appendChild(styleElement);
    
    let y = 10;
    pdfDocRef.current = printContent;
  
    // Helper function to safely trim strings even if they're null/undefined
    const safeTrim = (str) => {
      if (!str) return "";
      return typeof str === "string" ? str.trim() : str;
    };
  
    // Enhanced HTML cleaning function to remove empty space
    const cleanHtml = (html) => {
      if (!html) return "";
      
      // Create a temporary container
      const container = document.createElement('div');
      container.innerHTML = html;
      
      // Remove all scripts
      const scripts = container.querySelectorAll('script');
      scripts.forEach(script => script.remove());
      
      // Remove all empty elements
      const emptyElements = container.querySelectorAll('p:empty, div:empty, span:empty, li:empty, ul:empty');
      emptyElements.forEach(el => el.remove());
      
      // Replace multiple breaks with single
      let content = container.innerHTML;
      content = content.replace(/<br\s*\/?>\s*<br\s*\/?>/gi, '<br>');
      content = content.replace(/&nbsp;/g, ' ');
      content = content.replace(/\n\s*\n/g, '\n');
      content = content.replace(/\s{2,}/g, ' ');
      
      // Remove HTML comments
      content = content.replace(/<!--[\s\S]*?-->/g, '');
      
      return content;
    };
  
    // Header formatting
    const renderHeader = (patientInfo) => {
      const headerContent = `
          <div style="display: flex; justify-content: space-between; font-size: 10pt; border-bottom: 1px solid #000; padding-bottom: 8px; margin-bottom: 10px;">
              <!-- Left side: Bold facility name and info -->
              <div style="flex: 1; padding-right: 10px;">
                  <div style="font-weight: bold; font-size: 11pt;">Sunridge Medical</div>
                  <div>T (480) 659-9135</div>
                  <div>14200 N Northsight Blvd 160</div>
                  <div>Scottsdale, AZ 85260</div>
              </div>
              
              <!-- Center: Logo -->
              <div style="flex: 1; display: flex; justify-content: center; align-items: flex-start;">
                  <img src="${sunridgeLogo}" alt="Sunridge Logo" style="width: 200px; height: 60px; margin-top: 5px;"/>
              </div>
  
              <!-- Right side: Patient information without labels -->
              <div style="flex: 1; text-align: right; padding-left: 10px;">
                  <div style="font-weight: bold; font-size: 11pt;">PATIENT:</div>
                  <div>${patientInfo["Full Name"] || "Patient"}</div>
                  <div>DOB: ${patientInfo?.DOB ? dayjs.utc(patientInfo.DOB).format("MM/DD/YYYY") : ""}</div>
              </div>
          </div>
      `;
      printContent.innerHTML += headerContent;
    };
  
    // Add page footer
    const addFooter = () => {
      const footerDiv = document.createElement('div');
      footerDiv.className = 'page-footer';
      footerDiv.innerHTML = 'Page <span class="page-number"></span>';
      printContent.appendChild(footerDiv);
    };
  
    // Add page break with minimal spacing
    const addPageBreak = () => {
      const pageBreak = document.createElement('div');
      pageBreak.className = 'page-break';
      printContent.appendChild(pageBreak);
      y = 10;
    };
  
    // Compact field formatter without border-bottom on headings
    const formatField = (title, data) => {
      // Skip truly empty data
      if (!data || 
          (typeof data === "string" && !safeTrim(data)) || 
          (typeof data === "object" && Object.keys(data).length === 0) ||
          (Array.isArray(data) && data.length === 0)) {
        return "";
      }
  
      if (title === "Allergies") {
        const allergySections = [
          { label: "Drug", value: data.drug },
          { label: "Food", value: data.food },
          { label: "Environmental", value: data.environmental },
          { label: "Medications", value: data.medications },
        ].filter(section => section.value && typeof section.value === "string" && safeTrim(section.value)); 
  
        if (allergySections.length === 0) return ""; 
  
        return `
          <div class="section-content">
            <h3 style="color:#2980b9;font-size:12pt;" class="section-title">${title}</h3>
            ${allergySections.map(section => `
              <div class="avoid-break"><strong style="margin-left:4px;">• ${section.label}:</strong> 
              <span>${cleanHtml(section.value)}</span></div>
            `).join("")}
          </div>
        `;
      }
  
      if (title === "Past Medical History") {
        const medicalSections = [
          { label: "Major Events", value: data.majorEvents },
          { label: "Ongoing Medical Problems", value: data.ongoingMedicalProblems },
          { label: "Preventive Care", value: data.preventiveCare },
          { label: "Social History", value: data.socialHistory },
          { label: "Nutrition History", value: data.nutritionHistory },
          { label: "Development History", value: data.developmentHistory },
        ].filter(section => section.value && typeof section.value === "string" && safeTrim(section.value)); 
  
        if (medicalSections.length === 0) return ""; 
  
        return `
          <div class="section-content">
            <h3 style="color:#2980b9;font-size:12pt;" class="section-title">${title}</h3>
            ${medicalSections.map(section => `
              <div class="avoid-break"><strong style="margin-left:4px;">• ${section.label}:</strong> 
              <div style="margin-left:8px;">${cleanHtml(section.value)}</div></div>
            `).join("")}
          </div>
        `;
      }
  
      if (title === "Family Health History" || title === "Goals") {
        const { value, otherValue } = data || {};
        const filteredValues = Array.isArray(value) ? value.filter(v => v && typeof v === "string" && safeTrim(v) && safeTrim(v).toLowerCase() !== "other") : [];
        const formattedSections = [];
    
        if (filteredValues.length > 0) {
          formattedSections.push(`
            <div style="margin-left:4px;">
              <ul style="margin:0;padding-left:15px;">
                ${filteredValues.map(item => `<li>${item}</li>`).join("")}
              </ul>
            </div>
          `);
        }
    
        if (typeof otherValue === "string" && safeTrim(otherValue)) {
          formattedSections.push(`
            <div class="avoid-break">
              <strong style="margin-left:4px;">• Additional Information:</strong>
              <div style="margin-left:8px;">${cleanHtml(otherValue)}</div>
            </div>
          `);
        }
    
        return formattedSections.length > 0
          ? `
            <div class="section-content">
              <h3 style="color:#2980b9;font-size:12pt;" class="section-title">${title}</h3>
              ${formattedSections.join("")}
            </div>
          `
          : "";
      }
      
      // Default formatting for other sections
      return typeof data === "string" && safeTrim(data) 
        ? `
          <div class="section-content">
            <h3 style="color:#2980b9;font-size:12pt;" class="section-title">${title}</h3>
            <div style="margin-left:4px;">${cleanHtml(data)}</div>
          </div>
        `
        : "";
    };
  
    // Improved medication table formatter with separate dosage and frequency columns
    const formatMedicationsField = (title, data) => {
      if (!data || !Array.isArray(data) || data.length === 0) return "";
      
      // Strictly filter out empty medication entries
      const filteredData = data.filter(item => 
        item && (safeTrim(item.supplementName) || safeTrim(item.name))
      );
      
      if (filteredData.length === 0) return "";
      
      return `
        <div class="section-content">
          <h3 style="color:#2980b9;font-size:12pt;" class="section-title">${title}</h3>
          
          <table style="width: 100%; border-collapse: collapse; margin-top: 4px; font-size: 10pt; page-break-inside: avoid;">
            <thead>
              <tr style="background-color: #2980b9; color: white;">
                <th style="border: 1px solid #ddd; padding: 4px 6px; text-align: left;">Medication Name</th>
                <th style="border: 1px solid #ddd; padding: 4px 6px; text-align: left;">Dosage</th>
                <th style="border: 1px solid #ddd; padding: 4px 6px; text-align: left;">Frequency</th>
                <th style="border: 1px solid #ddd; padding: 4px 6px; text-align: left;">Start Date</th>
                <th style="border: 1px solid #ddd; padding: 4px 6px; text-align: left;">End Date</th>
              </tr>
            </thead>
            <tbody>
              ${filteredData.map((item, index) => {
                // Extract dosage and frequency separately
                let dosage = '';
                let frequency = '';
                
                // For medications with combined frequency/dosage
                if (item?.frequency || item?.selectedField) {
                  const combined = item?.frequency || item?.selectedField || '';
                  
                  // Try to separate dosage and frequency
                  if (combined.includes('dosage') || combined.includes('mg') || combined.includes('capsule') || combined.includes('units') || combined.includes('iu')) {
                    // If it has numeric values, it's likely dosage info
                    const parts = combined.split(/\s+/);
                    
                    // Check for common dosage patterns
                    const dosageParts = [];
                    const frequencyParts = [];
                    
                    parts.forEach(part => {
                      if (/\d+/.test(part) || /mg|capsule|unit|mcg|iu/i.test(part)) {
                        dosageParts.push(part);
                      } else if (/daily|twice|day|week|month|thrice|morning|night|evening/i.test(part)) {
                        frequencyParts.push(part);
                      } else if (dosageParts.length > 0 && frequencyParts.length === 0) {
                        // If we already have dosage parts but no frequency yet, this might be part of the dosage
                        dosageParts.push(part);
                      } else {
                        // Default to frequency
                        frequencyParts.push(part);
                      }
                    });
                    
                    dosage = dosageParts.join(' ');
                    frequency = frequencyParts.join(' ');
                  } else {
                    // If no clear dosage pattern, put everything in frequency
                    frequency = combined;
                  }
                }
                
                // Special handling for Weight Loss Injection
                if (item?.supplementName?.includes('Weight Loss Injection') || item?.name?.includes('Weight Loss Injection')) {
                  dosage = item?.dosage || (item?.supplementName?.includes('(D)') ? '1 mg' : 
                                           item?.supplementName?.includes('(S)') ? '0.25 mg' : '');
                }
                
                // For specific special cases from the PDF
                if (item?.supplementName === 'SM Brain+' || item?.name === 'SM Brain+') {
                  frequency = 'thrice a day';
                } else if ((item?.supplementName === 'SM D-Tox Max' || item?.name === 'SM D-Tox Max') && !frequency) {
                  frequency = 'testing permanant';
                }
                
                // For BPC 157
                if (item?.supplementName?.includes('BPC 157') || item?.name?.includes('BPC 157')) {
                  const match = (item?.supplementName || item?.name || '').match(/(\d+mcg|\d+\s*units)/);
                  if (match) {
                    dosage = match[0];
                  }
                }
                
                return `
                  <tr style="background-color: ${index % 2 === 0 ? '#f0f5fa' : 'white'}; page-break-inside: avoid; line-height: 1.3;">
                    <td style="border: 1px solid #ddd; padding: 3px 6px;">${item?.supplementName || item?.name || ''}</td>
                    <td style="border: 1px solid #ddd; padding: 3px 6px;">${dosage}</td>
                    <td style="border: 1px solid #ddd; padding: 3px 6px;">${frequency}</td>
                    <td style="border: 1px solid #ddd; padding: 3px 6px; white-space: nowrap;">
                        ${item?.startDate ? dayjs.utc(item.startDate).format("MM-DD-YYYY") : ''}
                    </td>
                    <td style="border: 1px solid #ddd; padding: 3px 6px; white-space: nowrap;">
                      ${item?.endDate ? dayjs.utc(item.endDate).format("MM-DD-YYYY") : ''}
                    </td>
                  </tr>
                `;
              }).join("")}
            </tbody>
          </table>
        </div>
      `;
    };
    
    // Compact encounter formatter without border-bottom on headings
    const formatEncounterContent = (encounter) => {
      // Extract only non-empty fields
      const contentSections = [
        { label: "Subjective", value: safeTrim(encounter?.subjective) },
        { label: "Medications Note", value: safeTrim(encounter?.medicationsNote) },
        { label: "Objective", value: safeTrim(encounter?.objective) },
        { label: "Assessment", value: safeTrim(encounter?.assessment) },
        { label: "Care Plan", value: safeTrim(encounter?.careplan) },
        { label: "Plan", value: safeTrim(encounter?.plan?.note)?.replace(/BPC\s*\n+\s*BPC/g, "BPC") } // Remove duplicate BPC entries
      ].filter(section => section.value && section.value !== "");
  
      if (contentSections.length === 0) return "";
  
      return `
        <div class="section-content">
          <div style="background-color:#2980b9;color:white;padding:3px;margin-bottom:3px;" class="section-title">
            <div style="margin:0;text-align:center;font-size:11pt;">SOAP Note - ${moment(encounter?.createdAt?.split('T')[0]).format('MM/DD/YYYY')}</div>
          </div>
          ${contentSections.map(section => `
            <div class="avoid-break">
              <strong style="font-size:10pt;">• ${section.label}:</strong>
              <div style="margin-left:6px;">${cleanHtml(section.value)}</div>
            </div>
          `).join("")}
        </div>
      `;
    };
  
    // Call renderHeader before printing the content
    renderHeader(selectedPatient);
    
    // Add the footer for page numbers
    addFooter();
  
    // Reorder sections to ensure medications table comes after goals
    const mainSections = [
      { name: "subjective", label: "Subjective", content: safeTrim(soapNotes.subjective) || "" },
      { name: "medicationsNote", label: "Medications Note", content: safeTrim(soapNotes.medicationsNote) || "" },
      { name: "objective", label: "Objective", content: safeTrim(soapNotes.objective) || "" },
      { name: "assessment", label: "Assessment", content: safeTrim(soapNotes.assessment) || "" },
      { name: "careplan", label: "Care Plan", content: safeTrim(soapNotes.careplan) || "" },
      { name: "plan", label: "Plan", content: safeTrim(soapNotes?.planNote)?.replace(/BPC\s*\n+\s*BPC/g, "BPC") || "" },
      { name: "healthConcerns", label: "Health Concerns", content: safeTrim(emrData.healthConcerns) || "" },
      { name: "diagnoses", label: "Diagnoses", content: safeTrim(emrData.diagnoses) || "" },
      { name: "familyHealthHistory", label: "Family Health History", content: emrData.familyHealthHistory || "" },
      { name: "pastMedicalHistory", label: "Past Medical History", content: emrData.pastMedicalHistory || "" },
      { name: "allergies", label: "Allergies", content: emrData.allergies || "" },
      { name: "implantableDevices", label: "Implantable Devices", content: safeTrim(emrData.implantableDevice) || "" },
      { name: "goals", label: "Goals", content: emrData.goals || "" }
    ];
    
    // Separate medications section to ensure it comes after goals
    const medicationsSection = { 
      name: "medications", 
      label: "Medications", 
      content: [
        ...(Array.isArray(activeMedications) ? activeMedications.filter(med => med && (safeTrim(med.supplementName) || safeTrim(med.name))) : []),
        ...(Array.isArray(historicalMedications) ? historicalMedications.filter(med => med && (safeTrim(med.supplementName) || safeTrim(med.name))) : []), 
        ...(Array.isArray(activeDispensedItems) ? activeDispensedItems.filter(med => med && (safeTrim(med.supplementName) || safeTrim(med.name))) : [])
      ] || [] 
    };
    
    // Add sections that should be on the main page (except medications)
    let hasContent = false;
    mainSections.forEach((section) => {
      if (checkboxes[section.name] && !separatePageSections.includes(section.name)) {
        const formattedContent = formatField(section.label, section.content);
        if (formattedContent) {
          printContent.innerHTML += formattedContent;
          hasContent = true;
        }
      }
    });
    
    // Add medications table after all other main sections (if not on separate page)
    if (checkboxes["medications"] && !separatePageSections.includes("medications")) {
      const medicationsContent = formatMedicationsField(medicationsSection.label, medicationsSection.content);
      if (medicationsContent) {
        // Force medications to appear immediately after goals without unnecessary page break
        // Add a marker to ensure no whitespace between goals and medications
        printContent.innerHTML += `<div class="no-bottom-space"></div>`;
        printContent.innerHTML += medicationsContent;
        hasContent = true;
      }
    }
    
    // Add sections that should be on separate pages
    mainSections.forEach((section) => {
      if (checkboxes[section.name] && separatePageSections.includes(section.name)) {
        const formattedContent = formatField(section.label, section.content);
        if (formattedContent) {
          addPageBreak();
          printContent.innerHTML += formattedContent;
          hasContent = true;
        }
      }
    });
    
    // Add medications on a separate page if requested
    if (checkboxes["medications"] && separatePageSections.includes("medications")) {
      const medicationsContent = formatMedicationsField(medicationsSection.label, medicationsSection.content);
      if (medicationsContent) {
        addPageBreak();
        printContent.innerHTML += medicationsContent;
        hasContent = true;
      }
    }
  
    // Process encounters with strict filtering
    const updatedSelectedEncounters = Object.fromEntries(
      Object.entries(selectedEncounters).filter(([key, value]) => value === true)
    );
      
    const filteredEncounters = encounters.filter(encounter =>
      updatedSelectedEncounters.hasOwnProperty(encounter._id)
    );
  
    // Group related content for more compact display
    let currentContent = "";
    
    // Process encounters and eliminate empty ones
    filteredEncounters.forEach((encounter) => {
      const encounterContent = formatEncounterContent(encounter);
  
      // Skip empty encounters
      if (!encounterContent.trim()) return;
  
      const isSeparatePage = separatePageSections.includes(encounter._id);
  
      if (isSeparatePage) {
        // Add current content before page break if we have any
        if (currentContent) {
          printContent.innerHTML += currentContent;
          currentContent = "";
          hasContent = true;
        }
        addPageBreak();
        printContent.innerHTML += encounterContent;
        hasContent = true;
      } else {
        currentContent += encounterContent;
      }
    });
    
    // Add any remaining grouped content
    if (currentContent) {
      printContent.innerHTML += currentContent;
    }
        
    // Enhanced PDF generation with compact settings
    html2pdf()
      .from(printContent)
      .set({
        margin: [8, 8, 15, 8], // [top, right, bottom, left] - Minimal margins
        filename: 'Sunridge_Medical_EMR.pdf',
        image: { type: 'jpeg', quality: 0.95 },
        html2canvas: { 
          dpi: 300, 
          scale: 2, 
          letterRendering: true,
          useCORS: true,
          logging: false
        },
        jsPDF: { 
          unit: 'mm', 
          format: 'a4', 
          orientation: 'portrait',
          compress: true,
          precision: 16
        },
        pagebreak: { 
          mode: ['avoid-all', 'css', 'legacy'],
          before: '.page-break-before, .page-break',
          after: '.page-break-after',
          avoid: ['.med-table-container', 'tr', 'th', 'td', '.avoid-break'] // Prevent table breaking
        }
      })
      .toPdf()
      .get('pdf')
      .then((pdf) => {
        // Add compact page numbers
        const totalPages = pdf.internal.getNumberOfPages();
        
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(8);
          pdf.setTextColor(100, 100, 100);
          pdf.text(
            `Page ${i} of ${totalPages}`, 
            pdf.internal.pageSize.getWidth() / 2, 
            pdf.internal.pageSize.getHeight() - 5, 
            { align: 'center' }
          );
        }
        
        pdfDocRef.current = pdf;
        const pdfBlob = pdf.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);
        setOpenPreview(true);
      });
  };
  const handlePrintAll = () => {
    if (formSoapNote) {
      setCheckboxes((prev) =>
        Object.fromEntries(Object.keys(prev).map((key) => [key, true]))
      );
    } else {
      setCheckboxes((prev) =>
        Object.fromEntries(
          Object.entries(prev).map(([key, value]) => [
            key,
            [
              "subjective",
              "medicationsNote",
              "objective",
              "assessment",
              "careplan",
              "plan",
            ].includes(key)
              ? value
              : true,
          ])
        )
      );
      handleCheckAllEncounters(true);
    }
  };

  useEffect(() => {
    if (
      formSoapNote &&
      Object.values(checkboxes).every((value) => value === true)
    ) {
      handlePrint();
    }
    if (
      !formSoapNote &&
      Object.entries(checkboxes).every(([key, value]) =>
        ["subjective", "medicationsNote", "objective", "assessment", "careplan", "plan"].includes(
          key
        )
          ? value === false
          : value === true
      )
    ) {
      handlePrint();
    }
    if (printMedications === true) {
      handlePrint();
    }
  }, [checkboxes]);

  const handleEncounterCheckboxChange = (id) => {
    setSelectedEncounters((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleCheckAllEncounters = (isChecked) => {
    const allEncounters = {};
    encounters.forEach((encounter) => {
      allEncounters[encounter._id] = isChecked;
    });
    setSelectedEncounters(allEncounters);
  };

  useEffect(() => {
    if (printMedications === true) {
      setCheckboxes({ ...checkboxes, medications: true });
    }
  }, [isOpen]);

  return (
    <>
      {!printMedications && (
        <Modal
          title="Select Fields to Print"
          visible={isOpen}
          onCancel={() => {
            onClose();
            resetCheckboxes();
            setSeparatePageSections([]);
            setSelectedEncounters({});
            setMedicationsCategory("all");            
            // setPatientActiveMedications([]);
            // setPatientHistoricalMedications([]);
            pdfDocRef.current=null
            setPdfUrl(null)
          }}
          footer={[
            <Button
              key="close"
              onClick={() => {
                onClose();
                resetCheckboxes();
                setSeparatePageSections([]);
                setSelectedEncounters({});
                setMedicationsCategory("all");
                // setPatientActiveMedications([]);
                // setPatientHistoricalMedications([]);
                // setPatientActiveMedications([]);
                // setPatientHistoricalMedications([]);
                pdfDocRef.current=null
                setPdfUrl(null)
              }}
            >
              Close
            </Button>,
            <Button
              key="print"
              className="!bg-[#111828] hover:!bg-[#3d3d3d] hover:!text-white text-white border-none"
              onClick={handlePrint}
            >
              Print
            </Button>,
            <Button
              key="printAll"
              className="!bg-[#111828] hover:!bg-[#3d3d3d] hover:!text-white text-white border-none"
              onClick={handlePrintAll}
            >
              Print All
            </Button>,
          ]}
        >
          <div className="relative w-full flex flex-row items-start justify-stretch gap-4 h-auto">
            {/* Left Section */}
            <div className="flex flex-col justify-center gap-4 w-3/6">
            <p className="sfont-medium">Summary</p>
              <div className="flex flex-col gap-3">
                <div className="flex flex-col items-start gap-3">
                  <Checkbox
                    name="healthConcerns"
                    checked={checkboxes.healthConcerns}
                    onChange={handleCheckboxChange}
                  >
                    Health Concerns
                  </Checkbox>
                  {checkboxes["healthConcerns"] && (
                    <Checkbox
                      style={{ marginLeft: "10px" }}
                      disabled={!checkboxes["healthConcerns"]}
                      checked={separatePageSections.includes("healthConcerns")}
                      onChange={(e) =>
                        handleSeparatePageChange(e, "healthConcerns")
                      }
                    >
                      Print on Separate Page
                    </Checkbox>
                  )}
                  <Checkbox
                    name="diagnoses"
                    checked={checkboxes.diagnoses}
                    onChange={handleCheckboxChange}
                  >
                    Diagnoses
                  </Checkbox>
                  {checkboxes["diagnoses"] && (
                    <Checkbox
                      style={{ marginLeft: "10px" }}
                      disabled={!checkboxes["diagnoses"]}
                      checked={separatePageSections.includes("diagnoses")}
                      onChange={(e) => handleSeparatePageChange(e, "diagnoses")}
                    >
                      Print on Separate Page
                    </Checkbox>
                  )}
                  <Checkbox
                    name="familyHealthHistory"
                    checked={checkboxes.familyHealthHistory}
                    onChange={handleCheckboxChange}
                  >
                    Family Health History
                  </Checkbox>
                  {checkboxes["familyHealthHistory"] && (
                    <Checkbox
                      style={{ marginLeft: "10px" }}
                      disabled={!checkboxes["familyHealthHistory"]}
                      checked={separatePageSections.includes(
                        "familyHealthHistory"
                      )}
                      onChange={(e) =>
                        handleSeparatePageChange(e, "familyHealthHistory")
                      }
                    >
                      Print on Separate Page
                    </Checkbox>
                  )}
                </div>
                <Checkbox
                  name="pastMedicalHistory"
                  checked={checkboxes.pastMedicalHistory}
                  onChange={handleCheckboxChange}
                >
                  Past Medical History
                </Checkbox>
                {checkboxes["pastMedicalHistory"] && (
                  <Checkbox
                    style={{ marginLeft: "10px" }}
                    disabled={!checkboxes["pastMedicalHistory"]}
                    checked={separatePageSections.includes(
                      "pastMedicalHistory"
                    )}
                    onChange={(e) =>
                      handleSeparatePageChange(e, "pastMedicalHistory")
                    }
                  >
                    Print on Separate Page
                  </Checkbox>
                )}
                <Checkbox
                  name="allergies"
                  checked={checkboxes.allergies}
                  onChange={handleCheckboxChange}
                >
                  Allergies
                </Checkbox>
                {checkboxes["allergies"] && (
                  <Checkbox
                    style={{ marginLeft: "10px" }}
                    disabled={!checkboxes["allergies"]}
                    checked={separatePageSections.includes("allergies")}
                    onChange={(e) => handleSeparatePageChange(e, "allergies")}
                  >
                    Print on Separate Page
                  </Checkbox>
                )}

                <Checkbox
                  name="medications"
                  checked={checkboxes.medications}
                  onChange={handleCheckboxChange}
                >
                  Medications
                </Checkbox>
                {checkboxes["medications"] && (
                  <div className="w-full flex flex-wrap border-[1px] border-gray-100  rounded-md px-[10px] py-[5px]">
                    <Checkbox
                      disabled={!checkboxes["medications"]}
                      checked={medicationsCategory === "active"}
                      onChange={(e) => setMedicationsCategory("active")}
                    >
                      Active
                    </Checkbox>
                    <Checkbox
                      disabled={!checkboxes["medications"]}
                      checked={medicationsCategory === "historical"}
                      onChange={(e) => setMedicationsCategory("historical")}
                    >
                      Historical
                    </Checkbox>
                    <Checkbox
                      disabled={!checkboxes["medications"]}
                      checked={medicationsCategory === "dispensed"}
                      onChange={(e) => setMedicationsCategory("dispensed")}
                    >
                      Dispensed
                    </Checkbox>
                    <Checkbox
                      disabled={!checkboxes["medications"]}
                      checked={medicationsCategory === "all"}
                      onChange={(e) => setMedicationsCategory("all")}
                    >
                      All
                    </Checkbox>
                  </div>
                )}

                {checkboxes["medications"] && (
                  <Checkbox
                    style={{ marginLeft: "10px" }}
                    disabled={!checkboxes["medications"]}
                    checked={separatePageSections.includes("medications")}
                    onChange={(e) => handleSeparatePageChange(e, "medications")}
                  >
                    Print on Separate Page
                  </Checkbox>
                )}
                <Checkbox
                  name="implantableDevices"
                  checked={checkboxes.implantableDevices}
                  onChange={handleCheckboxChange}
                >
                  Implantable Devices
                </Checkbox>
                {checkboxes["implantableDevices"] && (
                  <Checkbox
                    style={{ marginLeft: "10px" }}
                    disabled={!checkboxes["implantableDevices"]}
                    checked={separatePageSections.includes(
                      "implantableDevices"
                    )}
                    onChange={(e) =>
                      handleSeparatePageChange(e, "implantableDevices")
                    }
                  >
                    Print on Separate Page
                  </Checkbox>
                )}
                <Checkbox
                  name="goals"
                  checked={checkboxes.goals}
                  onChange={handleCheckboxChange}
                >
                  Goals
                </Checkbox>
                {checkboxes["goals"] && (
                  <Checkbox
                    style={{ marginLeft: "10px" }}
                    disabled={!checkboxes["goals"]}
                    checked={separatePageSections.includes("goals")}
                    onChange={(e) => handleSeparatePageChange(e, "goals")}
                  >
                    Print on Separate Page
                  </Checkbox>
                )}
              </div>
            </div>

            {/* Center Divider */}
            <div className="w-[1px] bg-gray-500 h-auto"></div>

            {/* Encounters Section */}
            {encounters.length > 0 && (
              <div className="flex flex-col items-start gap-3 pb-4">
                <strong>Encounters:</strong>
                <div className="flex flex-col items-start gap-3 h-80 overflow-y-auto pr-5">
                  {encounters.map((encounter) => (
                    <React.Fragment key={encounter._id}>
                      <Checkbox
                        checked={selectedEncounters[encounter._id] || false}
                        onChange={() =>
                          handleEncounterCheckboxChange(encounter._id)
                        }
                      >
                        {moment(encounter?.createdAt?.split("T")[0]).format(
                          "MM-DD-YYYY"
                        )}
                      </Checkbox>
                      {selectedEncounters[encounter._id] && (
                        <Checkbox
                          style={{ marginLeft: "10px" }}
                          checked={separatePageSections.includes(encounter._id)}
                          onChange={(e) =>
                            handleSeparatePageChange(e, encounter._id)
                          }
                        >
                          Print on Separate Page
                        </Checkbox>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            )}

            {/* Right Section */}
            {formSoapNote && (
              <div className="flex flex-col items-start gap-3 w-3/6">
                <p className="font-medium">SOAP Note</p>
                <Checkbox
                  name="subjective"
                  checked={checkboxes.subjective}
                  onChange={handleCheckboxChange}
                >
                  Subjective
                </Checkbox>
                {checkboxes["subjective"] && (
                  <Checkbox
                    style={{ marginLeft: "10px" }}
                    disabled={!checkboxes["subjective"]}
                    checked={separatePageSections.includes("subjective")}
                    onChange={(e) => handleSeparatePageChange(e, "subjective")}
                  >
                    Print on Separate Page
                  </Checkbox>
                )}

                <Checkbox
                  name="medicationsNote"
                  checked={checkboxes.medicationsNote}
                  onChange={handleCheckboxChange}
                >
                  Medications
                </Checkbox>
                {checkboxes["medicationsNote"] && (
                  <Checkbox
                    style={{ marginLeft: "10px" }}
                    disabled={!checkboxes["medicationsNote"]}
                    checked={separatePageSections.includes("medicationsNote")}
                    onChange={(e) => handleSeparatePageChange(e, "medicationsNote")}
                  >
                    Print on Separate Page
                  </Checkbox>
                )}
                <Checkbox
                  name="objective"
                  checked={checkboxes.objective}
                  onChange={handleCheckboxChange}
                >
                  Objective
                </Checkbox>
                {checkboxes["objective"] && (
                  <Checkbox
                    style={{ marginLeft: "10px" }}
                    disabled={!checkboxes["objective"]}
                    checked={separatePageSections.includes("objective")}
                    onChange={(e) => handleSeparatePageChange(e, "objective")}
                  >
                    Print on Separate Page
                  </Checkbox>
                )}
                <Checkbox
                  name="assessment"
                  checked={checkboxes.assessment}
                  onChange={handleCheckboxChange}
                >
                  Assessment
                </Checkbox>
                {checkboxes["assessment"] && (
                  <Checkbox
                    style={{ marginLeft: "10px" }}
                    disabled={!checkboxes["assessment"]}
                    checked={separatePageSections.includes("assessment")}
                    onChange={(e) => handleSeparatePageChange(e, "assessment")}
                  >
                    Print on Separate Page
                  </Checkbox>
                )}
                <Checkbox
                  name="careplan"
                  checked={checkboxes.careplan}
                  onChange={handleCheckboxChange}
                >
                  Care Plan
                </Checkbox>
                {checkboxes["careplan"] && (
                  <Checkbox
                    style={{ marginLeft: "10px" }}
                    disabled={!checkboxes["careplan"]}
                    checked={separatePageSections.includes("careplan")}
                    onChange={(e) => handleSeparatePageChange(e, "careplan")}
                  >
                    Print on Separate Page
                  </Checkbox>
                )}
                <Checkbox
                  name="plan"
                  checked={checkboxes.plan}
                  onChange={handleCheckboxChange}
                >
                  Plan
                </Checkbox>
                {checkboxes["plan"] && (
                  <Checkbox
                    style={{ marginLeft: "10px" }}
                    disabled={!checkboxes["plan"]}
                    checked={separatePageSections.includes("plan")}
                    onChange={(e) => handleSeparatePageChange(e, "plan")}
                  >
                    Print on Separate Page
                  </Checkbox>
                )}
              </div>
            )}
          </div>
        </Modal>
      )}

      <Dialog
        open={openPreview}
        onClose={() => {
          setOpenPreview(false);
          setPrintMedications(false);
          // setPatientActiveMedications([]);
          //   setPatientHistoricalMedications([]);
            pdfDocRef.current=null
            setPdfUrl(null)
          if (printMedications === true) {
            onClose();
            // setPatientActiveMedications([]);
            // setPatientHistoricalMedications([]);
            pdfDocRef.current=null
            setPdfUrl(null)
          }
        }}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          style: { height: "100%", padding: "10px 20px" },
        }}
      >
        <DialogTitle>
          PDF Preview
          <button
            className="py-2 px-4 text-sm bg-blue-500 hover:bg-gray-600 text-white rounded-md focus:outline-none"
            onClick={handleDownloadPDF}
            style={{ float: "right" }}
          >
            Download PDF
          </button>
        </DialogTitle>
        <DialogContent dividers style={{ height: "calc(100% - 64px)" }}>
          { pdfUrl && (
            <iframe
              src={pdfUrl}
              width="100%"
              height="100%"
              title="PDF Preview"
              style={{ border: "none" }}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PrintModal;