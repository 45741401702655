import { ArrowBack, PlusOneOutlined } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  DatePicker,
  Modal,
  Space,
  Spin,
  Tabs,
  Tooltip,
  message,
} from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import "../../../../App.css"; // Import the global CSS
import styles from "../../EMR.module.css";
import InnerTabsSection from "../InnerTabsSection";
import SOAPNotesSection from "../SOAPNotesSection";
import SummarySection from "../SummarySection";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import {
  PlusOutlined,
  PushpinFilled,
  PushpinOutlined,
  MailOutlined,
} from "@ant-design/icons";
import TreatmentNotes from "../../../../Components/TreatmentNotes";
import axios from "axios";
import UpdatePatient from "../../../../Components/Messages/UpdatePatient";
import DocumentsSection from "../../../../Components/DocumentsSection";

import AddPatientFormNew from "../../../AddPatientFormNew";

const { TabPane } = Tabs;

const PatientProfileSection = ({
  selectedPatient,
  setSelectedPatient,
  showPatientForm,
  setShowPatientsList,
  setActiveParentTab,
}) => {
  const targetDivRef = useRef(null);
  const [isPinnedTooltipVisible, setIsPinnedTooltipVisible] = useState(false);
  const [selectedPatientDetails, setSelectedPatientDetails] =
    useState(selectedPatient);
  const [
    mergedPatientAndTreatmentDetails,
    setMergedPatientAndTreatmentDetails,
  ] = useState({});
  const [previousTreatmentsCount, setPreviousTreatmentsCount] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  dayjs.extend(utc);
  // const userTimezone = moment.tz.guess();
  // const currentDate = moment().tz(userTimezone).format('YYYY-MM-DD');
  const [selectedChiefConcern, setSelectedChiefConcern] = useState(null);
  const [isAnotherDate, setIsAnotherDate] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [initialTabs, setInitialTabs] = useState([
    { key: "1", tab: "Summary", closable: false },
    { key: "2", tab: "Timeline", closable: false },
    { key: "3", tab: "Documents", closable: false },
    { key: "4", tab: "Profile", closable: false },
    { key: "5", tab: "Patient Information", closable: false },
  ]);
  // State for tabs
  const [tabs, setTabs] = useState(initialTabs);
  const [activeKey, setActiveKey] = useState(initialTabs[0].key);
  const [loading, setLoading] = useState(false);
  const [newTabName, setNewTabName] = useState("");
  const childRef = useRef(null);
  
  const handleSave = () => {
    if (childRef.current) {
      childRef.current.save(); 
    }
  };  

  const updateTabName = (tabKey, newDate) => {
    setTabs((prevTabs) =>
      prevTabs.map((tab) =>
        tab.key === tabKey
          ? { ...tab, tab: dayjs.utc(newDate).format("MM-DD-YYYY") }
          : tab
      )
    );
  };

  const tooltipRef = useRef(null);

  const handleClickOutside = (event) => {
    const dynamicAllowedElements = document.querySelectorAll(
      ".ant-tooltip, .ant-modal, .some-other-class"
    );

    const isOutsideClick = Array.from(dynamicAllowedElements).every(
      (element) => !element.contains(event.target)
    );

    if (isOutsideClick) {
      setIsPinnedTooltipVisible(false);
    }
  };

  const handleAnotherDateChange = (e) => {
    setIsAnotherDate(e.target.checked);
    if (!e.target.checked) {
      setSelectedDate(moment().format("YYYY-MM-DD"));
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchTreatments = async () => {
    const response = await axios.get(
      `/api/patient/${
        selectedPatient?.["Customer ID"]
      }/current-date-treatment/${dayjs(selectedDate).format("YYYY-MM-DD")}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }
    );
    return response;
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchTreatments();
      setPreviousTreatmentsCount(response.data.totalCount);
      setMergedPatientAndTreatmentDetails({
        ...selectedPatient,
        ...response.data.treatments[response.data.treatments.length - 1],
      });
    };

    fetchData();
  }, [selectedPatient]);

  const scrollToDiv = () => {
    targetDivRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    // Debug logging for tabs and SOAP note IDs
    console.log("Current tabs:", tabs);
    
    // Check for duplicate SOAP note IDs
    const soapNoteIds = tabs
      .filter(tab => tab.soapNoteId) // Only check tabs with soapNoteId set
      .map(tab => tab.soapNoteId);
    
    const duplicates = soapNoteIds.filter((id, index, arr) => 
      arr.indexOf(id) !== index
    );
    
    if (duplicates.length > 0) {
      console.warn("Duplicate SOAP note IDs detected:", duplicates);
      
      // Log the full tab details for duplicates
      tabs.filter(tab => duplicates.includes(tab.soapNoteId))
        .forEach(tab => console.warn("Duplicate tab:", tab));
    }
  }, [tabs]);

 
  const addTab = async (newTabName, notesData = {}, chiefConcern = "", ref = childRef) => {
    if (newTabName.trim() === "") return;
    
    console.log("Add tab called with:", { id: notesData?._id, type: notesData?.type });
    
    // Get the ID from the incoming data
    const id = notesData?._id;
    let soapNoteId = null;
    
    // Case 1: This is a direct SOAP note
    if (id && !notesData.type) {
      soapNoteId = id;
      console.log("Direct SOAP note ID:", soapNoteId);
      
      // Need to get the latest tabs state
      const currentTabs = [...tabs]; // Create a copy to work with the most current state
      
      // Check if we've already opened this SOAP note
      const existingTabIndex = currentTabs.findIndex(tab => tab.soapNoteId === soapNoteId);
      if (existingTabIndex !== -1) {
        console.log("Found existing tab at index:", existingTabIndex);
        setActiveKey(currentTabs[existingTabIndex].key);
        return; // Exit immediately
      }
    }
    
    // Case 2: This is an order - check if it has an associated SOAP note
    if (id && notesData.type) {
      try {
        console.log("Checking if order has SOAP note:", id);
        const response = await axios.get(
          `/check-order-soapnote/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );
        
        if (response.data.hasSOAPNote) {
          soapNoteId = response.data.soapNoteId;
          console.log("Order has associated SOAP note:", soapNoteId);
          
          // Need to get the latest tabs state again in case it changed
          const latestTabs = [...tabs]; 
          
          // Check if we've already opened this SOAP note
          const existingTabIndex = latestTabs.findIndex(tab => tab.soapNoteId === soapNoteId);
          if (existingTabIndex !== -1) {
            console.log("Found existing tab at index:", existingTabIndex);
            setActiveKey(latestTabs[existingTabIndex].key);
            return; // Exit immediately
          }
          
          // Fetch the SOAP note data
          try {
            const soapResponse = await axios.get(
              `/get-soap-note/${soapNoteId}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
                },
              }
            );
            
            if (soapResponse.status === 200) {
              notesData = soapResponse.data.soapNote;
              newTabName = notesData.createdAt.split('T')[0];
            }
          } catch (error) {
            console.error("Error fetching SOAP note:", error);
          }
        }
      } catch (error) {
        console.error("Error checking for associated SOAP note:", error);
      }
    }
    
    // Use setTabs with a function to ensure we're using the most current state
    setTabs(prevTabs => {
      // Check one more time if a tab with this SOAP ID already exists
      if (soapNoteId) {
        const existingTab = prevTabs.find(tab => tab.soapNoteId === soapNoteId);
        if (existingTab) {
          console.log("Final check found existing tab with SOAP note ID:", soapNoteId);
          // We need to use setTimeout here because we can't call setActiveKey during state update
          setTimeout(() => setActiveKey(existingTab.key), 0);
          return prevTabs; // Return unchanged tabs to avoid adding a duplicate
        }
      }
      
      // Create a new tab
      const newKey = `${Date.now()}`;
      const formattedTabDate = dayjs.utc(newTabName).format("MM-DD-YYYY");
      
      const newTab = {
        key: newKey,
        soapNoteId: soapNoteId,
        tab: formattedTabDate,
        closable: true,
        type: "notes",
      };
      
      // Update tabContent in a separate effect to ensure it's in sync
      setTimeout(() => {
        setTabContent(prevTabContent => ({
          ...prevTabContent,
          [newKey]: createTabContent(
            newKey,
            {
              ...notesData,
              createdAt: newTabName,
            },
            chiefConcern,
            ref
          ),
        }));
        setActiveKey(newKey);
      }, 0);
      
      return [...prevTabs, newTab];
    });
  };

  const createTabContent = (
    key = "",
    notesData = {},
    chiefConcern = "",
    ref
  ) => {
    return (
      <div className="flex gap-4 h-[900px] " key={key}>
        <div className="w-[27%] h-full">
          <InnerTabsSection
            addTab={addTab}
            ref={targetDivRef}
            selectedPatient={selectedPatient}
          />
        </div>
        <div className="w-[73%] h-full">
          <SOAPNotesSection
            scrollToDiagnosisDiv={scrollToDiv}
            patientProfile={selectedPatient}
            notesData={notesData}
            chiefConcern={chiefConcern}
            tabKey={key}
            newDate={notesData?.createdAt} // Add this line
            createTabContent={createTabContent}
            setTabContent={setTabContent}
  setShowSOAPNotesSection={(message) => {
              if (message.action === "updateTabName") {
                updateTabName(message.tabKey, message.newDate);
              }
              else if (message.action === "updateSoapId") {
                // Update the tab with the SOAP note ID
                setTabs(prevTabs => 
                  prevTabs.map(tab => 
                    tab.key === message.tabKey ? { ...tab, soapNoteId: message.soapNoteId } : tab
                  )
                );
                console.log("Updated tabs with SOAP note ID:", message.soapNoteId);
              }
            }}
  ref={ref}
/>
        </div>
      </div>
    );
  };

  console.log("selected patient ", selectedPatient);
  const [tabContent, setTabContent] = useState({
    1: (
      <SummarySection
        addTab={addTab}
        ref={childRef}
        selectedPatient={selectedPatient}
        selectedChiefConcern={selectedChiefConcern}
        setSelectedChiefConcern={setSelectedChiefConcern}
      />
    ),
    2: <p>Content for Timeline tab</p>,
    4: (
      <ProfileTab selectedPatientCustomerId={selectedPatient["Customer ID"]} />
    ),
    3: <DocumentsSection selectedPatient={selectedPatient} />,
    5: <AddPatientFormNew patient={selectedPatient} from="emr" />,
  });
  console.log("selected patient  is ", selectedPatient);

  const onTabChange = (key) => {
    setActiveKey(key);
  };
  const generateFormLink = async () => {
    const selectedPatientName = selectedPatient["Full Name"];
    const payload = {
      patientId: selectedPatient,
      name: selectedPatientName,
    };
    try {
      const response = await axios.post(
        "/generate-form-link",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred while generating the form link.");
    }
  };

  const resetFormStatus = async (patientId) => {
    try {
      const response = await axios.put(
        "/reset-form-link-submission",
        { patientId }
      );
      console.log("Form updated successfully:", response.data);
      return response.data;
    } catch (error) {
      console.error(
        "Error updating form status:",
        error.response?.data || error.message
      );
      throw error;
    }
  };

  const sendEmail = async () => {
    try {
      setLoading(true);
      const linkData = await generateFormLink();
      if (
        linkData?.result?.isSubmitted === true &&
        linkData?.result?.isApproved === true
      ) {
        await resetFormStatus(linkData?.result?.patientId);
      }
      console.log("Link Data", linkData);
      const token = localStorage.getItem("sessionToken");

      if (!token) {
        message.error("No access token found. Please log in again.");
        return;
      }

      const patientName = linkData?.result?.patientName;
      const emailAddress = selectedPatient.Email[0].email;
      const link = linkData?.result?.link;
      const clinicPhoneNumber = "+18009237878";
      const clinicEmail = "info@sunridgemedical.com";
      const clinicWebsite = "sunridgemedical.com";

      const emailContent =
        `To: ${emailAddress}\r\n` +
        `Subject: Complete Your Health Information Form Before Your Visit\r\n` +
        `Content-Type: text/html; charset="UTF-8"\r\n` +
        `Content-Transfer-Encoding: 7bit\r\n\r\n` +
        `<p>Dear ${patientName},</p>` +
        `<To>Thank you for scheduling your appointment at Sunridge Medical. To ensure we provide you with the best possible care, please take a few minutes to complete your Health Information Form before your visit.</p>` +
        `<p>You can access the form here: <a href="${link}" style="color:blue;">${link}</a></p>` +
        `<p>Completing this in advance will help us streamline your check-in process and focus on your care.</p>` +
        `<p>If you have any questions, feel free to reach out to us at <a href="tel:${clinicPhoneNumber}">${clinicPhoneNumber}</a> or <a href="mailto:${clinicEmail}">${clinicEmail}</a>.</p>` +
        `<p>We look forward to seeing you soon!</p>` +
        `<p>Best regards,<br/>` +
        `Gio Franco<br/>` +
        `Sunridge Medical<br/>` +
        `<a href="tel:${clinicPhoneNumber}">${clinicPhoneNumber}</a><br/>` +
        `<a href="${clinicWebsite}">${clinicWebsite}</a></p>`;
      const response = await axios.post(
        "/sendemail",
        {
          text: emailContent,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.message) {
        message.success("Email sent successfully!");
      } else {
        message.error("Failed to send email");
      }
    } catch (error) {
      message.error("Error while sending email: " + error.message);
    } finally {
      setLoading(false);
    }
  };
  
  const removeTab = (targetKey) => {
    handleSave()
    const newTabs = tabs.filter(tab => tab.key !== targetKey);
    const newActiveKey = activeKey === targetKey ? newTabs[0]?.key : activeKey;
    const { [targetKey]: removedContent, ...newTabContent } = tabContent;
    setTabs(newTabs);
    setTabContent(newTabContent);
    setActiveKey(newActiveKey);
  };

  const handleSOAPNoteClick = async () => {
    const datePart = dayjs.utc(selectedDate).format("YYYY-MM-DD");
    const todayDatePart = dayjs.utc().format("YYYY-MM-DD");

    setIsAnotherDate(false);
    setSelectedDate(dayjs.utc().format("YYYY-MM-DD"));
    setTooltipOpen(false);
    const existingTreatment = await axios.get(
      `/check-soapnote-exists?patientId=${selectedPatient._id}&date=${datePart}`
    );
    if (existingTreatment?.data?.exists) {
      try {
        const userConfirmed = await new Promise((resolve) => {
          Modal.confirm({
            title: "Encounter Already Exists",
            content: `Encounter already exists for ${
              selectedDate === todayDatePart
                ? "today"
                : `for this date ${moment(datePart).format("MM-DD-YYYY")}`
            }. Do you want to create another encounter?`,
            okButtonProps: {
              className: "bg-blue-600 text-white",
            },
            okText: "Create",
            onOk: () => resolve(true),
            onCancel: () => resolve(false),
          });
        });

        if (!userConfirmed) {
          return;
        }
      } catch (error) {
        console.error("Error in confirmation:", error);
        return;
      }
    }

    addTab(
      dayjs.utc(selectedDate).format("YYYY-MM-DD"),
      {
        createdAt: dayjs.utc(selectedDate).format("YYYY-MM-DD"),
      },
      selectedChiefConcern
    );
  };

  useEffect(()=>{
    console.log("****** tabs: ", tabs, "********* tabContent: ", tabContent)
  },[tabs,tabContent])
  return (
    <div className="relative tabs-container">
      <div className="flex flex-col mb-2 gap-2">
        <p className="text-4xl font-semibold">{selectedPatient["Full Name"]}</p>
        <div className="w-full flex gap-5">
          {selectedPatient?.addresses[0] && (
            <>
              <p className="text-md font-medium">
                <span className="font-semibold text-black">Address:</span>{" "}
                {selectedPatient?.addresses[0]?.address1}{" "}
                {selectedPatient?.addresses[0]?.city &&
                  `, ${selectedPatient?.addresses[0]?.city}`}
                {selectedPatient?.addresses[0]?.country &&
                  `, ${selectedPatient?.addresses[0]?.country}`}
              </p>
            </>
          )}
          {selectedPatient?.sex && (
            <>
              <p className="text-md font-medium">
                <span className="font-semibold text-black">Gender:</span>{" "}
                {selectedPatient?.sex}
              </p>
            </>
          )}
          {selectedPatient?.phoneNumbers[0]?.number && (
            <>
              <p className="text-md font-medium">
                <span className="font-semibold text-black">Phone Numer: </span>{" "}
                {selectedPatient.phoneNumbers[0]?.number}
              </p>
            </>
          )}
          {selectedPatient?.DOB && (
            <>
              <span className="font-semibold text-black">DOB:</span>{" "}
              {dayjs.utc(selectedPatient.DOB).format("MM-DD-YYYY")}
            </>
          )}
          {selectedPatient?.Email[0]?.email && (
            <>
              <p className="text-md font-medium">
                <span className="font-semibold text-black">Email:</span>{" "}
                {selectedPatient?.Email[0]?.email}
              </p>
            </>
          )}
        </div>
      </div>
      {/* <Button className={`my-4 ${styles.button}`} icon={<ArrowBack />} onClick={() => {
        setShowPatientsList(true);
        setActiveParentTab(null);
        removeFormTab()
        handleShowPatientForm(false)
      }
      }>Back</Button> */}
      <Tabs
        type="editable-card"
        activeKey={activeKey}
        onChange={onTabChange}
        onEdit={(targetKey, action) =>
          action === "remove" && removeTab(targetKey)
        }
        hideAdd
        destroyInactiveTabPane
        tabBarExtraContent={
          <div className=" flex items-center gap-2">
            <Button className={`${styles.button}`} onClick={sendEmail}>
              Request Patient Details
            </Button>
            <Tooltip
              color="white"
              placement="bottom"
              trigger={"click"}
              open={tooltipOpen}
              onOpenChange={(open) => {
                setTooltipOpen(open);
                if (!open) {
                  setIsAnotherDate(false);
                  setSelectedDate(dayjs().format("YYYY-MM-DD"));
                }
              }}
              title={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    padding: "8px",
                    minWidth: 200,
                  }}
                  onMouseDown={(e) => e.stopPropagation()}
                >
                  <Space direction="vertical">
                    <Checkbox
                      checked={!isAnotherDate}
                      onChange={() => {
                        setIsAnotherDate(false);
                        setSelectedDate(dayjs().format("YYYY-MM-DD"));
                      }}
                    >
                      Current Date ({dayjs().format("MM-DD-YYYY")})
                    </Checkbox>

                    <Checkbox
                      checked={isAnotherDate}
                      onChange={(e) => {
                        setIsAnotherDate(e.target.checked);
                        if (e.target.checked) {
                          setSelectedDate(null);
                        } else {
                          setSelectedDate(dayjs().format("YYYY-MM-DD"));
                        }
                      }}
                    >
                      Another Date
                    </Checkbox>

                    {isAnotherDate && (
                      <div onMouseDown={(e) => e.stopPropagation()}>
                        <DatePicker
                          value={selectedDate ? dayjs(selectedDate) : null}
                          onChange={(date) => {
                            const formattedDate = date
                              ? date.format("YYYY-MM-DD")
                              : null;
                            setSelectedDate(formattedDate);
                          }}
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          format={"MM-DD-YYYY"}
                        />
                      </div>
                    )}
                  </Space>

                  <Button
                    className={styles.button}
                    onClick={handleSOAPNoteClick}
                  >
                    SOAP Note
                  </Button>
                  <Button className={styles.button}>Phone/SMS</Button>
                </div>
              }
            >
              <Button
                className={` ${styles.buttonBlue}`}
                icon={<PlusOutlined />}
                iconPosition="start"
              >
                New Encounter
              </Button>
            </Tooltip>
            {mergedPatientAndTreatmentDetails &&
            Object.keys(mergedPatientAndTreatmentDetails).length > 0 ? (
              <div ref={tooltipRef}>
                <Tooltip
                  open={isPinnedTooltipVisible}
                  color="white"
                  overlayInnerStyle={{
                    color: "black",
                    width: "100%",
                    maxWidth: "none",
                    whiteSpace: "normal",
                    overflowY: "auto",
                    overflowX: "auto",
                    padding: "10px",
                    borderRadius: "4px",
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                  }}
                  overlayClassName="!max-w-[1200px] !w-auto max-w-none"
                  title={
                    isPinnedTooltipVisible && (
                      <div style={{ width: "100%" }}>
                        {previousTreatmentsCount > 0 ? (
                          <TreatmentNotes
                            treatment={mergedPatientAndTreatmentDetails}
                            from="emr"
                            currentDate={dayjs
                              .utc(selectedDate)
                              .format("YYYY-MM-DD")}
                          />
                        ) : (
                          <p className="text-lg p-6">
                            No treatments scheduled for today
                          </p>
                        )}
                      </div>
                    )
                  }
                  placement="left"
                  autoAdjustOverflow={true}
                >
                  {isPinnedTooltipVisible ? (
                    <PushpinFilled
                      onClick={() => setIsPinnedTooltipVisible((prev) => !prev)}
                      style={{
                        fontSize: "18px",
                        cursor: "pointer",
                        marginLeft: 8,
                      }}
                    />
                  ) : (
                    <PushpinOutlined
                      onClick={() => setIsPinnedTooltipVisible((prev) => !prev)}
                      style={{
                        fontSize: "18px",
                        cursor: "pointer",
                        marginLeft: 8,
                      }}
                    />
                  )}
                </Tooltip>
              </div>
            ) : (
              <Spin size="small" />
            )}
          </div>
        }
      >
        {tabs.map((tab) => (
          <TabPane tab={tab.tab} key={tab.key} closable={tab.closable}>
            <div>
              {tabContent[tab.key] || <p>No content available for this tab.</p>}
            </div>
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default PatientProfileSection;

const ProfileTab = ({ selectedPatientCustomerId }) => {
  const [selectedPatient, setSelectedPatient] = useState(null);

  const fetchPatientProfile = async (patientCustomerId) => {
    if (!patientCustomerId) return;
    try {
      const response = await axios.get(
        `/patient-profiles/${patientCustomerId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200) {
        setSelectedPatient(response.data);
      }
    } catch (error) {
      console.error("Error fetching patient profile:", error);
    }
  };

  useEffect(() => {
    fetchPatientProfile(selectedPatientCustomerId);
  }, [selectedPatientCustomerId]);

  return (
    <div className="border-none">
      {selectedPatient ? (
        <UpdatePatient patient={selectedPatient} showSection={true} />
      ) : (
        <div className="flex justify-center items-center h-32 w-full">
          <Spin spinning={true} size="large" />
        </div>
      )}
    </div>
  );
};
