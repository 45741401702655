import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, colors, Dialog, DialogContent, DialogTitle, Paper } from "@mui/material";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Steps,
  Tooltip,
  Typography
} from "antd";
import axios from "axios";
import moment from 'moment';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from "react";
import { useParams } from 'react-router-dom';
import { BarLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";
import DocumentsSection from "../../Components/DocumentsSection";
import styles from "./AddPatientFormNew.module.css";
import FormLinkModal from "./Components/FormLinkModal";
import HealthQuestionsForm from "./Components/HealthQuestionsForm";
import PersonalInformationForm from "./Components/PersonalInformationForm";
import SymptomsChecklistForm from "./Components/SymptomsChecklistForm";
import WaiversAndSignatureForm from "./Components/WaiversAndSignatureForm";
import { CopyOutlined } from '@ant-design/icons';
import { getPatientFormStatus } from '../../utils';
import PatientFormSubmissions from '../../Components/PatientFormSubmissions';
import html2pdf from 'html2pdf.js';
import { PrintOutlined } from '@mui/icons-material';


const AddPatientFormNew = ({patient,from=""}) => {
  const { Step } = Steps;
  const [currentStep, setCurrentStep] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [publicForm, setPublicForm] = useState(false);

  const [activePatient, setActivePatient] = useState([]);
  const [patientName, setPatientName] = useState("");

  const [patientLoader, setPatientLoader] = useState(false);
  const [form] = Form.useForm();
  const [selectedPatient, setSelectedPatient] = useState(patient?._id || null);
  const [authStatus, setAuthStatus] = useState(null);
  const [formLinkDetails, setFormLinkDetails] = useState({});
  const [formGenerateButtonVisible, setFormGenerateButtonVisible] = useState(false);
  const [validUrl, setValidUrl] = useState(false);
  const [authenticatedUser, setAuthenticatedUser] = useState(false);
  const [link, setLink] = useState("");
  const [patientId, setPatientId] = useState("");
  const { name, formId } = useParams();
  const [patientData, setPatientData] = useState({});
  const [formData, setFormData] = useState(null);
  const [formDataUpdated, setFormDataUpdated] = useState({});
  const [openPreview, setOpenPreview] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const pdfDocRef = useRef(null);

  const { Option } = Select;
  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const fetchFormLinkDetailsWithPatientId = async (patientId) => {
    if (!patientId) {
      console.error('Patient ID is required to fetch form link details.');
      return { error: 'Patient ID is required.' };
    }

    try {
      const response = await axios.get('/get-form-details-with-patient-id', {
        params: { patientId },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      });

      if (response.status === 200) {
        console.log('Form link details fetched successfully:', response.data.result);
        setFormGenerateButtonVisible(false)
        setFormLinkDetails(response?.data?.result)
      }
    } catch (error) {
      setFormGenerateButtonVisible(true)
      if (error.response) {
        console.error('Error fetching form link:', error.response.data.message);
        return { error: error.response.data.message };
      } else if (error.request) {
        console.error('No response from server:', error.request);
        return { error: 'No response from server.' };
      } else {
        console.error('Unexpected error:', error.message);
        return { error: error.message };
      }
    }
  };

  const fetchFormLinkDetailsWithFormId = async (formId) => {
    if (!formId) {
      console.error('Patient ID is required to fetch form link details.');
      return { error: 'Patient ID is required.' };
    }

    try {
      const response = await axios.get('/get-form-details-with-form-id', {
        params: { formId },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      });

      if (response.status === 200) {
        console.log('Form link details fetched successfully:', response.data.result);
        setFormGenerateButtonVisible(false)
        setFormLinkDetails(response?.data?.result)
      }
    } catch (error) {
      setFormGenerateButtonVisible(true)
      if (error.response) {
        console.error('Error fetching form link:', error.response.data.message);
        return { error: error.response.data.message };
      } else if (error.request) {
        console.error('No response from server:', error.request);
        return { error: 'No response from server.' };
      } else {
        console.error('Unexpected error:', error.message);
        return { error: error.message };
      }
    }
  };

  const validateForm = async (patientName, formId) => {
    if (!formId) {
      console.error('form Id ID is required to validate form link.');
      return { error: 'Form Id is required.' };
    }

    try {
      const response = await axios.post('/validate-form-link',
        { formId, patientName },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        });

      if (response.status === 200) {
        console.log('Form link details fetched successfully:', response.data.result);
        setValidUrl(response?.data?.valid)
      }
    } catch (error) {
      setValidUrl(false)
      console.error('Unexpected error:', error.message);
      return { error: error.message };
    }
  };

  const handlePatientChange = (value) => {
    setSelectedPatient(value);
    setPatientId(value)
    fetchFormLinkDetailsWithPatientId(value);
  };

  const GetActivePatientProfiles = async () => {
    try {
      setPatientLoader(true);
      const response = await axios.get(
        `/patient-profiles/active`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (response.status === 200) {
        setActivePatient(response.data);
        setPatientLoader(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {

      }
      if (error.response && error.response.status === 409) {

      }
      console.error("Error:", error);
    }
  };

  const verifyToken = async () => {
    const token = localStorage.getItem("sessionToken");
    if (!token) {
      setAuthStatus(false);
      return;
    }

    try {
      const response = await axios.post(
        "/verify-token",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setAuthStatus(true);
      } else {
        setAuthStatus(false);
      }
    } catch (error) {
      setAuthStatus(false);
    }
  };
  useEffect(() => {
    if (!name && !formId) {
      if(from==="emr"){
        handlePatientChange(patient._id)
      }
      else{
        GetActivePatientProfiles();
      }
      verifyToken();
      setPublicForm(false)
    } else {
      validateForm(name, formId)
      setPublicForm(true)
      fetchFormLinkDetailsWithFormId(formId);
    }

    const authStatus = localStorage.getItem("sessionToken") ? true : false;
    setAuthenticatedUser(authStatus);

  }, []);

  useEffect(() => {
    if (name) {
      setPatientName(decodeURIComponent(name))
    }
  }, [name]);

  const handlePublicUserFormSubmit = async () => {
    const data = form.getFieldsValue(true)

    try {
      const response = await axios.post("/submit-public-user-form", {
        patientId: formLinkDetails?.patientId,
        formData: data
      });
      if (response.ok) {
        setConfirmLoading(false);
        toast("Patient form added successfully", "success");
        form.resetFields();
      } else {
        setConfirmLoading(false);
        console.error("Failed to add patient form");
      }
    } catch (error) {
      setConfirmLoading(false);
      console.error("Error adding patient form:", error);
    }
  };

  const handleAdminFormSubmit = async() => {
    // Structure the formData into patientProfileData and emrData
    const values = form.getFieldsValue(true);
    const formDataValues = { ...formData, waivers: { ...values.waivers, medicalRecordAccessExceptions: values?.waivers?.medicalRecordAccessExceptions }, additionMedicalNotes: values.additionMedicalNotes, signature: { date: dayjs().format("MM-DD-YYYY"), signature: values?.signature?.signature, patientInitials: values?.signature?.patientInitials } }
    const structuredData = mapFormToApiData(formDataValues)
    const formSubmission = await axios.patch(`/update-patient-profile-and-emr-data/${patientId}`, {
      patientProfileUpdates: structuredData.data.patientProfile[0],
      emrUpdates: structuredData.data.emrData[0]
    })
    console.log('Patient Profile Data:', structuredData);
    console.log('data submission response:', formSubmission);
    // console.log('EMR Data:', emrData);
    if(formSubmission.status ===200){
      message.success("Form submitted successfully")
    }
    // Here you can handle form submission, e.g., send data to a server
  };

  useEffect(() => {
    const data = form.getFieldsValue(true)
  }, [form, formData])

  const sendEmail = async (linkData,patientDetails) => {
    
    try {      
      console.log("Link Data", linkData)
      const token = localStorage.getItem('sessionToken');  
  
      if (!token) {
        message.error('No access token found. Please log in again.');
        return;
      }

      const patientName = linkData?.patientName
      const emailAddress = patientDetails.Email[0].email
      const link = linkData?.link
      const clinicPhoneNumber = "+18009237878"
      const clinicEmail = "info@sunridgemedical.com"
      const clinicWebsite = "sunridgemedical.com"
      
      const emailContent =
        `To: ${emailAddress}\r\n` +
        `Subject: Complete Your Health Information Form Before Your Visit\r\n` +
        `Content-Type: text/html; charset="UTF-8"\r\n` +
        `Content-Transfer-Encoding: 7bit\r\n\r\n` +
        `<p>Dear ${patientName},</p>` +
        `<To>Thank you for scheduling your appointment at Sunridge Medical. To ensure we provide you with the best possible care, please take a few minutes to complete your Health Information Form before your visit.</p>` +
        `<p>You can access the form here: <a href="${link}" style="color:blue;">${link}</a></p>` +
        `<p>Completing this in advance will help us streamline your check-in process and focus on your care.</p>` +
        `<p>If you have any questions, feel free to reach out to us at <a href="tel:${clinicPhoneNumber}">${clinicPhoneNumber}</a> or <a href="mailto:${clinicEmail}">${clinicEmail}</a>.</p>` +
        `<p>We look forward to seeing you soon!</p>` +
        `<p>Best regards,<br/>` +
        `Gio Franco<br/>` +
        `Sunridge Medical<br/>` +
        `<a href="tel:${clinicPhoneNumber}">${clinicPhoneNumber}</a><br/>` +
        `<a href="${clinicWebsite}">${clinicWebsite}</a></p>`;
      const response = await axios.post(
        '/sendemail',
        {
          text: emailContent,
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );
  
      if (response.data.message) {
        message.success('Email sent successfully!');
      } else {
        message.error('Failed to send email');
      }
    } catch (error) {
      message.error('Error while sending email: ' + error.message);
    }
  };
  const generateFormLink = async () => {
    const protocol = window.location.protocol
    const host = window.location.host
    const selectedPatientName = activePatient.find(patient => patient._id === selectedPatient)['Full Name'] || patient['Full Name']
    const selectedPatientDetails = activePatient.find(patient => patient._id === selectedPatient) || patient
    const payload = {
      patientId: selectedPatient,
      name: selectedPatientName
    }
    try {
      const response = await axios.post('/generate-form-link', payload, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("sessionToken")}`
        }
      });

      if (response.status === 200 || response.status === 201) {
        setLink(response.data.result.link);
        setFormGenerateButtonVisible(false)
        setFormLinkDetails(response.data.result)
        await sendEmail(response?.data?.result,selectedPatientDetails)
      } else {
        setFormGenerateButtonVisible(true)
        const { message } = await response.json();
        alert(`Failed to generate form link: ${message}`);
      }
    } catch (error) {
      setFormGenerateButtonVisible(true)
      console.error(error);
      alert('An error occurred while generating the form link.');
    }
  };

  const handleCopyFormLink = () => {
    navigator.clipboard.writeText(formLinkDetails?.link)
      .then(() => {
        message.success('Text copied to clipboard!');
      })
      .catch((err) => {
        message.error('Failed to copy text.');
        console.error('Copy error:', err);
      });
  };


  const handleFetchPatientFormData = async (patientId) => {
    const patientData = await axios.get(`/patient-form-combined-data/${patientId}`)
    const formData = mapApiDataToForm(patientData.data);
    form.setFieldsValue(formData);
    setFormData(formData)
    // setPatientData(restructuredData);
  }

  useEffect(() => {
    handleFetchPatientFormData(patientId)

  }, [patientId])


  const mapApiDataToForm = (apiData) => {
    const { patientProfile, emrData } = apiData.data;
    // Assuming the patientProfile is an array with one object
    const patient = patientProfile[0];
    const emr = emrData[0];
    // Mapping patientProfile and emrData to the form fields
    return {
      // Mapping patientProfile fields to form fields
      patientId: patient._id, // Assuming patientProfile has an _id field
      fullName: patient["Full Name"] || "", // Full Name from patient profile
      firstName: patient["First Name"] || "", // First Name
      lastName: patient["Last Name"] || "", // Last Name
      email: patient?.Email?.length > 0 ? patient?.Email[0]?.email : "", // Take the first email
      phoneNumber: patient?.phoneNumbers?.length > 0 ? patient?.phoneNumbers[0]?.number : "", // Take the first email
      addressLine1: patient?.addresses?.length > 0 ? patient?.addresses[0]?.address1 : "", // Take the first email
      addressLine2: patient?.addresses?.length > 0 ? patient?.addresses[0]?.address2 : "", // Take the first email
      addressCity: patient?.addresses?.length > 0 ? patient?.addresses[0]?.city : "", // Take the first email
      addressProvince: patient?.addresses?.length > 0 ? patient?.addresses[0]?.province : "", // Take the first email
      addressZipCode: patient?.addresses?.length > 0 ? patient?.addresses[0]?.zip : "", // Take the first email
      addressCountry: patient?.addresses?.length > 0 ? patient?.addresses[0]?.country : "", // Take the first email
      status: patient?.Status || "", // Status
      g6pd: patient?.G6PD || "", // G6PD
      dob: patient?.DOB || "", // Date of Birth
      sex: patient?.sex || "", // Sex
      allergies: emr?.allergies || "", // Allergies
      consentForm: patient?.ConsentForm || false, // Consent Form

      emergencyContact:{
        firstName: emr?.emergencyContact?.name?.firstName || "",
        lastName: emr?.emergencyContact?.name?.lastName || "",
        relationship: emr?.emergencyContact?.relationship || "",
        phoneNumber: emr?.emergencyContact?.phoneNumber || "",
      },
      // Assuming RecommendedSupplements and HistoricalSupplements should be mapped to a similar form structure
      recommendedSupplements: patient.RecommendedSupplements.map((supplement) => ({
        supplementName: supplement?.supplementName || "",
        frequency: supplement?.frequency || "",
        startDate: supplement?.startDate || "",
        endDate: supplement?.endDate || "",
      })),

      historicalSupplements: patient.HistoricalSupplements.map((supplement) => ({
        supplementName: supplement?.supplementName || "",
        frequency: supplement?.frequency || "",
        startDate: supplement?.startDate || "",
        endDate: supplement?.endDate || "",
      })),


      // Mapping EMR data fields to form fields
      referralSource: emr?.referralSource || "",
      race: emr?.race || "",
      maritalStatus: emr?.maritalStatus || "",

      // Mapping emergencyContact (nested object)
      emergencyContactFirstName: emr?.emergencyContact?.name?.firstName || "",
      emergencyContactLastName: emr?.emergencyContact?.name?.lastName || "",
      emergencyContactRelationship: emr?.emergencyContact?.relationship || "",
      emergencyContactPhoneNumber: emr?.emergencyContact?.phoneNumber || "",
      medicationsupplements: emr?.medicationsupplements || "",
      familyHealthHistory: emr?.familyHealthHistory || [],
      currentMedications: emr?.currentMedications || "no",

      goals: {value:emr?.goals.value, otherValue:emr?.goals.otherValue} || {value:[], otherValue:""},
      // Mapping Past Medical History fields
      pastMedicalHistory: {
        majorEvents: emr?.pastMedicalHistory?.majorEvents || "",
        ongoingMedicalProblems: emr?.pastMedicalHistory?.ongoingMedicalProblems || "",
        preventiveCare: emr?.pastMedicalHistory?.preventiveCare || "",
        socialHistory: emr?.pastMedicalHistory?.socialHistory || "",
        nutritionHistory: emr?.pastMedicalHistory?.nutritionHistory || "",
        developmentHistory: emr?.pastMedicalHistory?.developmentHistory || "",
        weight: emr?.pastMedicalHistory?.weight || "",
        height: emr?.pastMedicalHistory?.height || "",
        weightissues: emr?.pastMedicalHistory?.weightissues || "",
        weightChange: emr?.pastMedicalHistory?.weightChange || "",
        diet: emr?.pastMedicalHistory?.diet || "",
        exercise: emr?.pastMedicalHistory?.exercise || "",
        stressLevel: emr?.pastMedicalHistory?.stressLevel || "",
      },

      // Mapping Health Questions
      healthQuestions: {
        vaccinated: emr?.healthQuestions?.vaccinated || "",
        reactionToVaccine: emr?.healthQuestions?.reactionToVaccine || "",
        alcoholConsumption: emr?.healthQuestions?.alcoholConsumption || "",
        smokingHistory: emr?.healthQuestions?.smokingHistory || "",
        illegalDrugUse: emr?.healthQuestions?.illegalDrugUse || "",
        surgeryHistory: emr?.healthQuestions?.surgeryHistory || "",
        headEarNoseMouthEyesThroatProblems: emr?.healthQuestions?.headEarNoseMouthEyesThroatProblems || "",
        lungsProblems: emr?.healthQuestions?.lungsProblems || "",
        heartProblems: emr?.healthQuestions?.heartProblems || "",
        gastrointestinalProblems: emr?.healthQuestions?.gastrointestinalProblems || "",
      },
      // Symptoms
      symptoms: emr?.symptoms || [],

      // Mapping General Health Rating and Additional Medical Notes
      generalHealthRating: emr?.generalHealthRating || "",
      additionMedicalNotes: emr?.additionMedicalNotes || "",

      // Waivers
      waivers: {
        hipaaWaiver: emr?.waivers?.hipaaWaiver || "",
        medicalRecordAccessExceptions: emr?.waivers?.medicalRecordAccessExceptions || "",
        paymentResponsibilityAgreement: emr?.waivers?.paymentResponsibilityAgreement || "",
      },

      // Signature
      signature: {
        date: emr?.signature?.date || "",
        signature: emr?.signature?.signature || "",
        patientInitials: emr?.signature?.patientInitials || "",
      },
    };
  };

  const mapFormToApiData = (formData) => {
    // Reconstructing patientProfile data
    const patientProfile = [
      {
        _id: formData.patientId, // Assuming patientProfile has an _id field
        "Full Name": formData.fullName || "",
        "First Name": formData.firstName || "",
        "Last Name": formData.lastName || "",
        Email: formData.email ? [{ email: formData.email }] : [],
        phoneNumbers: formData.phoneNumber ? [{ number: formData.phoneNumber }] : [],
        addresses: formData.addressLine1
          ? [
            {
              address1: formData.addressLine1,
              address2: formData.addressLine2,
              city: formData.addressCity,
              province: formData.addressProvince,
              zip: formData.addressZipCode,
              country: formData.addressCountry,
            },
          ]
          : [],
        Status: formData.status || "",
        DOB: formData.dob || "",
        sex: formData.sex || "",
        allergies: formData.allergies || "",
        ConsentForm: formData.consentForm || false,
        RecommendedSupplements: formData.recommendedSupplements.map((supplement) => ({
          supplementName: supplement.supplementName || "",
          frequency: supplement.frequency || "",
          startDate: supplement.startDate || "",
          endDate: supplement.endDate || "",
        })),
        HistoricalSupplements: formData.historicalSupplements.map((supplement) => ({
          supplementName: supplement.supplementName || "",
          frequency: supplement.frequency || "",
          startDate: supplement.startDate || "",
          endDate: supplement.endDate || "",
        })),
      },
    ];

    // Reconstructing EMR data
    const emrData = [
      {
        referralSource: formData.referralSource || "",
        race: formData.race || "",
        maritalStatus: formData.maritalStatus || "",
        emergencyContact: {
          name: {
            firstName: formData.emergencyContactFirstName || "",
            lastName: formData.emergencyContactLastName || "",
          },
          relationship: formData.emergencyContactRelationship || "",
          phoneNumber: formData.emergencyContactPhoneNumber || "",
        },
        familyHealthHistory: formData.familyHealthHistory || [],
        currentMedications: formData.currentMedications || "no",
        medicationsupplements: formData?.medicationsupplements || "",
        goals: { value:formData.goals.value, otherValue:formData.goals.otherValue} || {value:[], otherValue:""},
        pastMedicalHistory: {
          majorEvents: formData.pastMedicalHistory.majorEvents || "",
          ongoingMedicalProblems: formData.pastMedicalHistory.ongoingMedicalProblems || "",
          preventiveCare: formData.pastMedicalHistory.preventiveCare || "",
          socialHistory: formData.pastMedicalHistory.socialHistory || "",
          nutritionHistory: formData.pastMedicalHistory.nutritionHistory || "",
          developmentHistory: formData.pastMedicalHistory.developmentHistory || "",
          weight: formData.pastMedicalHistory.weight || "",
          height: formData.pastMedicalHistory.height || "",
          weightissues: formData.pastMedicalHistory.weightissues || "",
          weightChange: formData.pastMedicalHistory.weightChange || "",
          diet: formData.pastMedicalHistory.diet || "",
          exercise: formData.pastMedicalHistory.exercise || "",
          stressLevel: formData.pastMedicalHistory.stressLevel || "",
        },
        healthQuestions: {
          vaccinated: formData.healthQuestions.vaccinated || "",
          reactionToVaccine: formData.healthQuestions.reactionToVaccine || "",
          alcoholConsumption: formData.healthQuestions.alcoholConsumption || "",
          smokingHistory: formData.healthQuestions.smokingHistory || "",
          illegalDrugUse: formData.healthQuestions.illegalDrugUse || "",
          surgeryHistory: formData.healthQuestions.surgeryHistory || "",
          headEarNoseMouthEyesThroatProblems:
            formData.healthQuestions.headEarNoseMouthEyesThroatProblems || "",
          lungsProblems: formData.healthQuestions.lungsProblems || "",
          heartProblems: formData.healthQuestions.heartProblems || "",
          gastrointestinalProblems: formData.healthQuestions.gastrointestinalProblems || "",
        },
        symptoms: formData.symptoms || [],
        generalHealthRating: formData.generalHealthRating || "",
        additionMedicalNotes: formData.additionMedicalNotes || "",
        waivers: {
          medicalRecordAccessExceptions: formData.waivers.medicalRecordAccessExceptions || "",
        },
        signature: {
          date: formData.signature.date || "",
          signature: formData.signature.signature || "",
          patientInitials: formData.signature.patientInitials || "",
        },
      },
    ];

    // Returning the API structured data
    return {
      data: {
        patientProfile,
        emrData,
      },
    };
  };

  const watchedValues = Form.useWatch([], form);


  const updateFormData = (fieldKey, value) => {
    setFormData((prevData) => {
      const updatedData = { ...prevData };

      // Handling nested fields (dot notation like "pastMedicalHistory.majorEvents")
      const keys = fieldKey.split(".");
      let temp = updatedData;

      for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];
        if (!temp[key]) {
          temp[key] = {}; // Create the nested object if it doesn't exist
        }
        temp = temp[key];
      }

      temp[keys[keys.length - 1]] = value; // Update the final field

      return updatedData;
    });
  };

  // const handlePrint = () => {
  //   debugger
  //   const printContent = document.createElement('div');
  //   printContent.style.padding = '10px';
  //   printContent.style.fontFamily = 'Arial, sans-serif';
  //   printContent.style.fontSize = '12pt';
  //   printContent.style.lineHeight = '1.5';

  //   const keyMappings = {
  //     medicationsupplements: "Medications & Supplements",
  //     physicalHistory: "Physical Health History",
  //     patient_allergies: "Known Allergies",
  //     // Add more custom mappings as needed
  // };
  
  // function formatKey(key) {
  //     if (keyMappings[key]) return keyMappings[key]; // Use mapped value if available
  
  //     return key
  //         .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before uppercase letters
  //         .replace(/_/g, ' ') // Replace underscores with spaces
  //         .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize first letter of each word
  // }
  
  // const formatObjectToHTML = (data, level = 1) => {
  //     if (!data || typeof data !== "object") return "";
  
  //     let formattedHTML = "";
  
  //     Object.entries(data).forEach(([key, value]) => {
  //         let headingTag = `h1`; // Ensures headings don't go beyond h6
  //         let formattedKey = formatKey(key); // Format the key before displaying
  
  //         formattedHTML += `<${headingTag}>${formattedKey}</${headingTag}>`;
  
  //         if (Array.isArray(value)) {
  //             // If value is an array, format each item as a list
  //             formattedHTML += `<ul>${value.map(item => `<li>${item}</li>`).join("")}</ul>`;
  //         } else if (typeof value === "object" && value !== null) {
  //             // Recursively format nested objects with a deeper heading level
  //             formattedHTML += formatObjectToHTML(value, level + 1);
  //         } else {
  //             // For simple key-value pairs
  //             formattedHTML += `<p>${value}</p>`;
  //         }
  //     });
  
  //     return formattedHTML;
  // };

  //   pdfDocRef.current = printContent;
    
  //   const formattedHTML = formatObjectToHTML(formData);
  //   printContent.innerHTML += formattedHTML;

  //   debugger
  //   html2pdf()
  //     .from(printContent)
  //     .set({
  //       margin: 10,
  //       filename: 'emr.pdf',
  //       image: { type: 'jpeg', quality: 0.98 },
  //       html2canvas: { dpi: 300, scale: 2 },
  //       jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
  //     })
  //     .toPdf()
  //     .get('pdf')
  //     .then((pdf) => {

  //       pdfDocRef.current = pdf;
  //       const pdfBlob = pdf.output('blob');
  //       const pdfUrl = URL.createObjectURL(pdfBlob);
  //       setPdfUrl(pdfUrl);
  //       setOpenPreview(true);
  //     });

  // };

  const handlePrint = () => {

    const printContent = document.createElement('div');
    printContent.style.padding = '10px';
    printContent.style.fontFamily = 'Arial, sans-serif';
    printContent.style.fontSize = '12pt';
    printContent.style.lineHeight = '1.5';

    let fieldsSpacing = 15;
    pdfDocRef.current = printContent;

    const formatField = (title, data, indentLevel = 0) => {
      const indent = indentLevel * 20;

      if (typeof data === "object" && data !== null) {
        if ((title === "Goals" || title === "Family Health History" || title === "Weight Issues") && Array.isArray(data.value) && typeof data.otherValue === "string") {
          const combinedGoals = [...data.value, data.otherValue].filter(Boolean).join(", ");
          return `<p style="margin-bottom: ${fieldsSpacing}px; margin-left: ${indent}px;"><strong>${title}:</strong> ${combinedGoals}</p>`;
        }
        if (title === "Symptoms" && Array.isArray(data)) {
          const symptoms = [...data].filter(Boolean).join(", ");
          return `<p style="margin-bottom: ${fieldsSpacing}px; margin-left: ${indent}px;"><strong>${title}:</strong> ${symptoms}</p>`;
        }
        if (title === "waivers") {
          const medicalRecordAccessExceptions = data?.medicalRecordAccessExceptions || "N/A";
          return `<p style="margin-bottom: ${fieldsSpacing}px; margin-left: ${indent}px;"><strong>Medical Record Access Exceptions:</strong> ${medicalRecordAccessExceptions}</p>`;
        }
        if (title === "Signature") {
          const patientInitials = data?.patientInitials || "N/A";
          const date = data?.date || "N/A";
          const signature = data?.signature || "N/A";
          return `
                      <p style="margin-bottom: ${fieldsSpacing}px; margin-left: ${indent}px;"><strong>Patient Initials:</strong> ${patientInitials}</p>
                      <p style="margin-bottom: ${fieldsSpacing}px; margin-left: ${indent}px;"><strong>Date:</strong> ${moment(date).format('MM-DD-YYYY')}</p>
                      <p style="margin-bottom: ${fieldsSpacing}px; margin-left: ${indent}px;"><strong>Signature:</strong> ${signature}</p>
                  `;
        }

        let formattedContent = `<p style="margin-bottom: ${fieldsSpacing}px; margin-left: ${indent}px;"><strong>${title}</strong></p>`;

        Object.keys(data).forEach(key => {
          const customKey = titleMapping[key] || key;
          let value = data[key];

          if (Array.isArray(value)) {
            value = value.length > 0 ? value.join(", ") : "N/A";
          } else if (typeof value === "object" && value !== null) {
            formattedContent += formatField(customKey, value, indentLevel + 1);
          } else {
            formattedContent += `<p style="font-weight:500; margin-left: ${indent + 20}px; margin-bottom: ${fieldsSpacing-2}px;"><strong>${customKey}: </strong> ${value ? value : "N/A"}</p>`;
          }
        });

        return formattedContent;
      }

      return `<p style="margin-bottom: ${fieldsSpacing}px; margin-left: ${indent}px;"><strong>${title}:</strong> ${data}</p>`;
    };

    const filterKeys = (obj, keysToRemove) => {
      if (!obj || typeof obj !== "object" || Array.isArray(obj)) return obj;

      return Object.keys(obj).reduce((acc, key) => {
        if (!keysToRemove.includes(key)) {
          acc[key] = typeof obj[key] === "object" && obj[key] !== null
            ? filterKeys(obj[key], keysToRemove)
            : obj[key];
        }
        return acc;
      }, {});
    };

    const keysToRemove = ["patientId", "consentForm", 'food', 'environmental', 'medications', 'drug', 'historicalSupplements', 'recommendedSupplements', 'currentMedications', 'emergencyContactFirstName', 'emergencyContactLastName', 'emergencyContactRelationship', 'emergencyContactPhoneNumber','g6pd','addressLine2'];
    const filteredData = filterKeys(formData, keysToRemove);

    const titleMapping = {
      fullName: "Full Name",
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      phoneNumber: "Phone Number",
      addressLine1: "Address",
      addressCity: "City",
      addressProvince: "Province",
      addressZipCode: "Zip Code",
      addressCountry: "Country",
      status: "Status",
      dob: "Date of Birth",
      sex: "Sex",
      allergies: "Allergies",
      referralSource: "Referral Source",
      race: "Race",
      maritalStatus: "Marital Status",
      emergencyContactFirstName: "First Name",
      emergencyContactLastName: "Last Name",
      emergencyContactRelationship: "Relationship",
      emergencyContactPhoneNumber: "Phone Number",
      medicationsupplements: "Medication Supplements",
      pastMedicalHistory: "Past Medical History",
      symptoms: "Symptoms",
      generalHealthRating: "General Health Rating",
      additionMedicalNotes: "Additional Medical Notes",
      patientInitials: "Patient Initials",
      date: "Date",
      signature: "Signature",
      emergencyContact: "Emergency Contact",
      familyHealthHistory: "Family Health History",
      diagnoses: "Diagnoses",
      goals: "Goals",
      implantableDevices: "Implantable Devices",
      healthConcerns: "Health Concerns",
      healthQuestions: "Health Questions",
      vaccinated: "Vaccinated",
      reactionToVaccine: "Reaction To Vaccine",
      alcoholConsumption: "Alcohol Consumption",
      smokingHistory: "Smoking History",
      illegalDrugUse: "Illegal Drug Use",
      surgeryHistory: "Surgery History",
      headEarNoseMouthEyesThroatProblems: "Head, Ear, Nose, Mouth, Eyes and Throat Problems",
      lungsProblems: "Lungs Problems",
      heartProblems: "Heart Problems",
      gastrointestinalProblems: "Gastrointestinal Problems",
      weightissues: "Weight Issues",
      weight: "Weight",
      height: "Height",
      allergic: "Allergic",
      allergicTypes: "Allergic Types",
      majorEvents: "Major Events",
      ongoingMedicalProblems: "Ongoing Medical Problems",
      preventiveCare: "Preventive Care",
      socialHistory: "Social History",
      nutritionHistory: "Nutrition History",
      developmentHistory: "Development History",
      relationship: "Relationship"
    };


    Object.keys(filteredData).forEach(key => {
      const customTitle = titleMapping[key] || key;
      printContent.innerHTML += formatField(customTitle, filteredData[key]);
    });

    html2pdf()
      .from(printContent)
      .set({
        margin: 10,
        filename: 'Patient_Information.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { dpi: 300, scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      })
      .toPdf()
      .get('pdf')
      .then((pdf) => {

        pdfDocRef.current = pdf;
        const pdfBlob = pdf.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);
        setOpenPreview(true);
      });
  };

  const handleDownloadPDF = () => {
    if (pdfDocRef.current) {
        pdfDocRef.current.save("EMR_Data.pdf");
    }
};
  return (
    <div className='w-full relative'>
      {
        <>
          {from !== "emr" && <div className={`${styles.headerFlexbox} mb-[10px]`}>
            {(validUrl || authenticatedUser) && <div>
              <h3 className={`${styles.exportHeading}`}>Add Patient</h3>
            </div>}
          </div>}
          <ToastContainer />
          {selectedPatient && <Button key="print" className={`!bg-[#111828] hover:!bg-[#3d3d3d] hover:!text-white text-white border-none absolute ${from === 'emr' ? ' right-0' : 'right-3'}`} onClick={handlePrint} icon={<PrintOutlined />}>
            Print
          </Button>}
          {(selectedPatient || (publicForm && validUrl)) && <Row className="px-[40px] flex justify-center" gutter={16}>
            <Col xs={20}>
              <Steps className="cursor-pointer" current={currentStep}>
                <Step onClick={() => setCurrentStep(0)} title="Step 1" />
                <Step onClick={() => setCurrentStep(1)} title="Step 2" />
                <Step onClick={() => setCurrentStep(2)} title="Step 3" />
                <Step onClick={() => setCurrentStep(3)} title="Step 4" />
                <Step onClick={() => setCurrentStep(4)} title="Step 5" />
              </Steps>
            </Col>
          </Row>}
          <div className="px-[40px]">

            {(publicForm && validUrl) && <div className="flex w-full items-center justify-between my-6">
              <p className="text-lg font-semibold">{patientName}</p>
              {formLinkDetails && Object.keys(formLinkDetails).length > 0 && formLinkDetails?.status !== undefined && (
                <div className='inline-flex items-center shadow-md py-2 px-4 rounded-2xl gap-2 border-gray-300 border-[1px] bg-white'>
                  <div className='w-2 h-2 rounded-lg bg-orange-500'></div>
                  <p className='text-md font-semibold'>{getPatientFormStatus(formLinkDetails)}</p>
                </div>
              )}
            </div>}

            {(!publicForm) &&
              <div className="flex w-full items-center justify-between my-6">
                {from!=="emr" && <div className='flex items-center gap-3'>
                  {patientLoader ? (
                    <div className="m-[10px]">
                      <BarLoader color="#000" />
                    </div>
                  ) : (
                    <Form form={form}>
                      {
                        <Form.Item
                          name="Customer ID"
                          rules={[{ required: true, message: "Please select Patient" }]}
                        >
                          <Select
                            className="mt-6"
                            showSearch
                            style={{ width: 200, backgroundColor: 'white' }}
                            placeholder="Select a patient"
                            optionFilterProp="children"
                            onChange={handlePatientChange}
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                              0
                            }
                          >
                            {activePatient.map((patient) => (
                              <Option key={patient["_id"]} value={patient["_id"]}>
                                {patient["Full Name"]}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      }
                    </Form>
                  )}
                  <PatientFormSubmissions />
                </div>}
                <div className='flex gap-4 items-center'>

                  {formLinkDetails && Object.keys(formLinkDetails).length > 0 && formLinkDetails?.status !== undefined && (
                    <div className='inline-flex items-center shadow-md py-2 px-4 rounded-2xl gap-2 border-gray-300 border-[1px] bg-white'>
                      <div className='w-2 h-2 rounded-lg bg-orange-500'></div>
                      <p className='text-md font-semibold'>{getPatientFormStatus(formLinkDetails)}</p>
                    </div>
                  )}
                  {from !=="emr" && (formGenerateButtonVisible && authenticatedUser) ? (
                    <Button type="primary" className="btn-color  py-2 px-6" onClick={generateFormLink}>Generate Form Link</Button>
                  ) : formLinkDetails && Object.keys(formLinkDetails).length > 0 &&
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Input
                      value={formLinkDetails?.link}
                      readOnly
                      style={{ width: '640px' }}
                    />
                    <Tooltip title="Copy to clipboard">
                      <Button icon={<CopyOutlined />} onClick={handleCopyFormLink} />
                    </Tooltip>
                  </div>
                  }
                </div>
              </div>
            }


            {!selectedPatient && !name && <div className="w-full h-96 shadow-xl rounded-xl flex justify-center items-center bg-white">
              <p className="flex items-center gap-2 text-lg font-semibold"><WarningAmberIcon sx={{ color: colors.orange['600'], fontSize: 24 }} /> Please select a patient first to further proceed with form </p>
            </div>}

            {((selectedPatient && authenticatedUser) || (publicForm && validUrl)) &&
              (<>
                {currentStep === 0 && (<PersonalInformationForm form={form} handleNext={handleNext} formData={formData} setFormData={setFormData} updateFormData={updateFormData} />)}
                {currentStep === 1 && (<HealthQuestionsForm form={form} handleNext={handleNext} handlePrev={handlePrev} formData={formData} setFormData={setFormData} updateFormData={updateFormData} />)}
                {currentStep === 2 && (<SymptomsChecklistForm form={form} handleNext={handleNext} handlePrev={handlePrev} formData={formData} setFormData={setFormData} updateFormData={updateFormData} />)}
                {currentStep === 3 && (
                  <>
                    <div className="bg-white p-6 rounded-2xl">
                      {
                        !selectedPatient &&
                        <div className="w-full h-2/4 flex items-center justify-center">
                          <h1>Please Select Patient First</h1>
                        </div>
                      }
                      {
                        selectedPatient &&
                        <>
                          <h1>
                          {activePatient.find(patient => patient._id === selectedPatient)?.['Full Name'] || patient?.['Full Name'] || "N/A"}
                        </h1>
                        </>
                      }
                      {selectedPatient && <DocumentsSection selectedPatient={activePatient.find(patient => patient._id === selectedPatient) || patient} />}
                    </div>
                  </>
                )}
                {currentStep === 4 && (<WaiversAndSignatureForm form={form} handlePrev={handlePrev} confirmLoading={confirmLoading} handleFormSubmit={handlePublicUserFormSubmit} handleAdminFormSubmit={handleAdminFormSubmit} />)}
              </>)
            }
          </div>
          {!validUrl && !authenticatedUser &&
            <div className="h-96 shadow-xl w-7/12 rounded-xl flex items-centerbg-white mx-auto my-10 justify-center items-center">
              <p className="flex items-center gap-2 text-lg font-semibold"><WarningAmberIcon sx={{ color: colors.orange['600'], fontSize: 24 }} /> This form link is invalid please try to enter valid form link </p>
            </div>}
        </>
      }
      {/* <FormLinkModal isFormLinkModalOpen={isFormLinkModalOpen} handleFormLinkCancel={handleFormLinkCancel} link={link} /> */}
      <Dialog
        open={openPreview}
        onClose={() => {
          setOpenPreview(false);
        }}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          style: { height: "100%", padding: "10px 20px" },
        }}
      >
        <DialogTitle>
          PDF Preview
          <button
            className="py-2 px-4 text-sm bg-blue-500 hover:bg-gray-600 text-white rounded-md focus:outline-none"
            onClick={handleDownloadPDF}
            style={{ float: "right" }}
          >
            Download PDF
          </button>
        </DialogTitle>
        <DialogContent
          dividers
          style={{ height: "calc(100% - 64px)" }}
        >
          {pdfUrl && (
            <iframe
              src={pdfUrl}
              width="100%"
              height="100%"
              title="PDF Preview"
              style={{ border: "none" }}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddPatientFormNew;