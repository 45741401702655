import React, { useEffect, useState } from 'react';

import Header from './Components/Header';
import PatientProfileSection from './Components/PatientProfileSection';
import PatientsList from './Components/PatientsList';
import styles from './EMR.module.css';
import { Button, Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import { ArrowBack } from '@mui/icons-material';

const EMR = () => {

    const [searchValue, setSearchValue] = useState("");
    const [showPatientForm, setShowPatientForm] = useState(false);
    const [openedPatients, setOpenedPatients] = useState([]);
    const [activeTab, setActiveTab] = useState(null);
    const [showPatientsList, setShowPatientsList] = useState(true);

    const handleShowPatientForm=(value)=>{
        setShowPatientForm(value);
    }

      const handlePatientSelect = (patient) => {
        setShowPatientsList(false)
        if (!patient || !patient._id) return;

        if (!openedPatients.some((p) => p._id === patient._id)) {
          setOpenedPatients([...openedPatients, patient]);
        }
        setActiveTab(patient._id);
      };
    
      const handleCloseTab = (targetKey) => {
        const newTabs = openedPatients.filter((p) => p._id !== targetKey);
        setOpenedPatients(newTabs);
        if (newTabs.length > 0) {
          setActiveTab(newTabs[newTabs.length - 1]._id);
        } else {
          setActiveTab(null);
        }
      };
    
      const OperationsSlot = {
        left: activeTab && <Button className={`mr-4 ${styles.button}`} icon={<ArrowBack />} onClick={() => {
            setShowPatientsList(true);
            setActiveTab(null);
          }
          }>Back</Button>,
      };
    return (
        <>
            <div className={` ${styles.container}`}>
                <div className='w-full'>
                    <Header styles={styles} />
                    <div className='relative tabs-container w-[100%] flex flex-col items-start justify-center mt-6 mb-20 px-10'>
                        {openedPatients.length > 0 && (
                            <div className='w-full'>
                            <Tabs
                                activeKey={activeTab}
                                onChange={(targetKey)=>{
                                    setShowPatientsList(false) 
                                    setActiveTab(targetKey)  
                                }}
                                type="editable-card"
                                onEdit={(targetKey, action) =>
                                    action === "remove" && handleCloseTab(targetKey)
                                }
                                tabBarExtraContent={ OperationsSlot}
                                tabBarStyle={{margin:0}}
                                hideAdd
                                size='large'
                                destroyInactiveTabPane={false}
                            >
                                {openedPatients.map((patient) => (
                                    <TabPane tab={patient["Full Name"]} key={patient._id} closable>
                                        {!showPatientsList && 
                                        <div className='p-[26px]'>
                                        <PatientProfileSection
                                            selectedPatient={patient}
                                            setSelectedPatient={handlePatientSelect}
                                            showPatientForm={showPatientForm}
                                            handleShowPatientForm={handleShowPatientForm}
                                            setShowPatientsList={setShowPatientsList}
                                            setActiveParentTab={setActiveTab}
                                        />
                                        </div>
                                        }
                                    </TabPane>
                                ))}
                            </Tabs>
                            </div>
                        )}
                        
                        {(openedPatients.length === 0 || showPatientsList) && (
                            <div className={   `w-full ${openedPatients.length > 0 && `mt-2`}`}>
                            <PatientsList
                                setSelectedPatient={handlePatientSelect}
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                                handleShowPatientForm={handleShowPatientForm}
                            />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default EMR